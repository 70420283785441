import appAxios from 'api/appAxios';
import { setHeadersInStorage } from 'api/utilities/headersHandler';

const makePostRequest = async (endpoint, body, config = {}) => {
  const { data } = await appAxios.post(endpoint, body, config);
  setHeadersInStorage(data);
  return data;
};

export default makePostRequest;
