import { Box } from '@chakra-ui/react';
import SideBarContent from 'components/AppDrawer/components/SideBarContent';
import React, { useState } from 'react';

export default function AppDrawer({ menu, type, menuName }) {
  // const { isOpen } = useDisclosure();
  const [isExpand, setIsExpand] = useState(false);

  return (
    <Box
      minH="100vh"
      width={menu ? '25%' : !isExpand ? '15%' : '5%'}
      backgroundColor="#FFFFFF"
      position="relative"
      top="-75px"
    >
      <SideBarContent
        display={{
          base: 'none',
          md: 'block',
          width: menu ? '20%' : !isExpand ? '10%' : '5%',
        }}
        isMenu={menu}
        type={type}
        menuName={menuName}
        setIsExpand={setIsExpand}
        isExpand={isExpand}
      />
      {/* <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        returnFocusOnClose={false}
        size="full"
      >
        <DrawerContent>
          <SideBarContent
            isMenu={menu}
            type={type}
            menuName={menuName}
            setIsExpand={setIsExpand}
            isExpand={isExpand}
          />
        </DrawerContent>
      </Drawer> */}
    </Box>
  );
}
