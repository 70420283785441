import { AUTH_LOGIN, AUTH_STATUS } from 'api/urls';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import useStore from 'AppZustand/store';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AppLink from 'components/AppLink/AppLink';
import AuthCard from 'components/AuthCard/AuthCard';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import useHandleShowPassword from 'hooks/formHelpers/useHandleShowPassword';
import AuthLayout from 'layout/AuthLayout';
import { APP_SIGNUP_SCREEN } from 'navigation/routes/auth';
import { APP_DASHBOARD } from 'navigation/routes/dashboard';
import RedirectionCTA from 'pages/Auth/components/RedirectionCTA/RedirectionCTA';
import TermsAndConditionsText from 'pages/Auth/components/TermsAndConditionsText/TermsAndConditionsText';
import { useState } from 'react';
import { MdEmail } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

/**
 * Probable Solutions
 * - Write a useEffect hook that handles form data and checks for validation stuff and before moving on to the next step
 * - Extract a common `useMutation` and `useQuery` hook
 * - Good Luck
 * */

const AppLoginScreen = () => {
  // React Hooks - Data
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const [formError, setFormErrors] = useState({
    data: {
      non_field_errors: [''],
    },
  });

  // Custom Hooks
  const { passwordProps } = useHandleShowPassword();
  const { fetchUseDb } = useStore((state) => state);
  const navigate = useNavigate();

  // API
  const { mutate: getStatus } = useMutation(AUTH_STATUS, () =>
    makeGetRequest(AUTH_STATUS),
  );
  const { mutate, isLoading } = useMutation(
    (formVal) => makePostRequest(AUTH_LOGIN, formVal),
    {
      onSuccess: (data) => {
        getStatus();
        fetchUseDb(data.data.use_db);

        navigate(APP_DASHBOARD);
      },
      onError: (error) => setFormErrors(error?.response?.data),
    },
  );

  // Form Fields

  const formFields = [
    {
      fieldPlaceholder: 'Email Address',
      fieldType: 'email',
      fieldName: 'email',
      icon: MdEmail,
      isRequired: true,
    },
    {
      fieldPlaceholder: 'Password',
      fieldName: 'password',
      isRequired: true,
      ...passwordProps,
    },
  ];

  const handleFormSubmit = (event) => {
    event.preventDefault();
    mutate(formValues);
  };

  return (
    <AuthLayout>
      <AuthCard>
        <AppHeader
          text="Login to your account"
          customStyles={{
            mb: 10,
          }}
        />

        <form onSubmit={handleFormSubmit}>
          <AppText
            text={formError?.data?.non_field_errors[0]}
            size="xs"
            variant="auth"
          />
          {formFields.map((field) => (
            <AppInput
              {...field}
              key={field.fieldName}
              formValues={formValues}
              setFormValues={setFormValues}
              fieldError={formError}
            />
          ))}

          <AppLink
            text="Forgot Password?"
            to="/forgot-password"
            customStyles={{
              textAlign: 'right',
              display: 'block',
              mb: 5,
            }}
          />

          <AppButton
            text="Login"
            customStyles={{
              type: 'submit',
            }}
            isLoading={isLoading}
          />
        </form>

        <TermsAndConditionsText
          customStyles={{ display: 'flex', gap: '5px' }}
        />

        <RedirectionCTA
          titleText={'Don’t have an account?'}
          ctaText={'Sign Up'}
          ctaLink={APP_SIGNUP_SCREEN}
        />
      </AuthCard>
    </AuthLayout>
  );
};

export default AppLoginScreen;
