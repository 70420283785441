import { Flex, useToast } from '@chakra-ui/react';
import {
  GET_ACCESS_MANAGEMENT_LISTING,
  GET_TEAM_MEMBER_CREATION_META,
  TEAM_MEMBER_CUD,
} from 'api/urls/settings/accessManagement';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AppSelect from 'components/AppSelect/AppSelect';
import React, { useEffect, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const AddUser = ({ setState, option, onClose }) => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    role: '',
  });

  const formFields = [
    {
      fieldPlaceholder: 'Name',
      fieldType: 'text',
      fieldName: 'name',
      isRequired: true,
    },
    {
      fieldPlaceholder: 'Email address',
      fieldType: 'email',
      fieldName: 'email',
      isRequired: true,
    },
  ];

  const formSelectFields = [
    {
      fieldPlaceholder: 'Select role',
      fieldName: 'role',
      fieldOptions: 'roles',
      isRequired: true,
    },
  ];

  const closeHandler = () => {
    onClose();
  };

  const queryClient = useQueryClient();
  const { data: teamMemberMetaData, isLoading: teamMemberMetaIsLoading } =
    useQuery(GET_TEAM_MEMBER_CREATION_META, () =>
      makeGetRequest(GET_TEAM_MEMBER_CREATION_META),
    );

  const {
    mutate: teamMemberCreationMutation,
    error: teamMemberCreationError,
    isLoading: teamMemberCreationIsLoading,
    data: teamMemberCreationData,
  } = useMutation(
    TEAM_MEMBER_CUD(),
    (formBody) => makePostRequest(TEAM_MEMBER_CUD(), formBody),
    {
      onSuccess: () => {
        setState({ ...option, type: 'Access management' });
        queryClient.invalidateQueries(GET_ACCESS_MANAGEMENT_LISTING);
      },
    },
  );
  const [formSelectOptions, setFormSelectOptions] = useState(null);

  const toast = useToast();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    teamMemberCreationMutation(formValues);
  };

  useEffect(() => {
    if (!teamMemberMetaIsLoading && teamMemberMetaData) {
      setFormSelectOptions(teamMemberMetaData?.data?.meta);
    }
  }, [teamMemberMetaIsLoading, teamMemberMetaData]);

  useEffect(() => {
    if (!teamMemberCreationIsLoading && teamMemberCreationData) {
      toast({
        title: 'Created User Successfully',
        status: 'success',
        duration: 3000,
      });
      onClose();
    }
  }, [teamMemberCreationIsLoading, teamMemberCreationData]);

  return (
    <form onSubmit={handleFormSubmit}>
      {formFields.map((field) => (
        <>
          <AppInput
            {...field}
            key={field.fieldName}
            formValues={formValues}
            setFormValues={setFormValues}
            fieldError={teamMemberCreationError?.response?.data}
          />
        </>
      ))}

      {formSelectFields.map((field, i) => (
        <div style={{ marginBottom: '1.25rem' }} key={i}>
          <AppSelect
            variant="filled"
            fieldName={field.fieldName}
            icon={MdArrowDropDown}
            customStyles={{ fontSize: '14px' }}
            formValues={formValues}
            setFormValues={setFormValues}
            fieldError={teamMemberCreationError?.response?.data}
          >
            <option value="" hidden>
              {field.fieldPlaceholder}
            </option>
            {formSelectOptions &&
              formSelectOptions?.[field.fieldOptions].map((data) => (
                <option key={data.id} value={data.id}>
                  {data.identity}
                </option>
              ))}
          </AppSelect>
        </div>
      ))}
      <Flex>
        <AppButton
          text="Cancel"
          variant="cancelBtn"
          customStyles={{ margin: '0px 22px 27px 22px' }}
          onClick={closeHandler}
        />
        <AppButton
          type="submit"
          text="Create"
          customStyles={{ margin: '0px 22px 27px 0px' }}
          isLoading={teamMemberCreationIsLoading}
        />
      </Flex>
    </form>
  );
};

export default AddUser;
