import {
  APP_EMAIL_TEMPLATES,
  APP_EMAIL_TEMPLATES_ADD,
  APP_EMAIL_TEMPLATES_EDIT,
} from 'navigation/routes';
import EmailTemplates from 'pages/appSettings/email/EmailTemplates';
import EmailTemplatesAdd from 'pages/appSettings/email/EmailTemplatesAdd';
import EmailTemplatesEdit from 'pages/appSettings/email/EmailTemplatesEdit';
import { Route } from 'react-router-dom';

const EmailRoutes = () => {
  return (
    <>
      <Route path={APP_EMAIL_TEMPLATES} element={<EmailTemplates />} />
      <Route path={APP_EMAIL_TEMPLATES_ADD} element={<EmailTemplatesAdd />} />
      <Route
        path={APP_EMAIL_TEMPLATES_EDIT()}
        element={<EmailTemplatesEdit />}
      />
    </>
  );
};

export default EmailRoutes;
