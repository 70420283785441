import { Box, useStyleConfig } from '@chakra-ui/react';
import React from 'react';

const Card = ({
  children,
  customStyles = {},
  variant,
  onClick,
  hoverStyles = '',
  onDrop,
  onDragOver,
}) => {
  const styles = useStyleConfig('CardStyles', { variant });
  return (
    <Box
      __css={styles}
      {...customStyles}
      onClick={onClick}
      _hover={hoverStyles}
      onDrop={onDrop}
      onDragOver={onDragOver}
    >
      {children}
    </Box>
  );
};

export default Card;
