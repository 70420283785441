export const tabsHeading = [
  {
    name: 'Access management',
  },
  {
    name: 'Roles and permissions',
  },
];

export const accessData = [
  {
    id: 1,
    name: 'Chris Evans',
    status: 'Active',
    email: 'captain_america@gmail.com',
    role: 'Admin',
  },
  {
    id: 2,
    name: 'Chris Evans',
    status: 'Inactive',
    email: 'captain_america@gmail.com',
    role: 'Admin',
  },
  {
    id: 3,
    name: 'Chris Evans',
    status: 'Offline',
    email: 'captain_america@gmail.com',
    role: 'Admin',
  },
];

export const rolesData = [
  {
    id: 1,
    roles: 'Super Admin',
    users: 5,
    about: 'about',
  },
  {
    id: 2,
    roles: 'Client Admin',
    users: 5,
    about: 'about',
  },
  {
    id: 3,
    roles: 'Super Admin',
    users: 5,
    about: 'about',
  },
];

export const permissionSData = [
  {
    id: 1,
    name: 'Edit the project meta details',
  },
  {
    id: 2,
    name: 'Add new users',
  },
  {
    id: 3,
    name: 'Edit Team management',
  },
];

export const permissionCData = [
  {
    id: 1,
    name: 'View meta details',
  },
  {
    id: 2,
    name: 'Edit meta details',
  },
  {
    id: 3,
    name: 'View meta details',
  },
  {
    id: 4,
    name: 'Edit meta details',
  },
];
