import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';
import makePostRequest from 'api/utilities/makePostRequest';
import AppButton from 'components/AppButton/AppButton';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import appColors from 'theme/appColors';

const getIcon = ({ Icon }) => {
  return React.createElement(Icon, {
    color: appColors.brandTeal['900'],
  });
};

const AppFileInput = ({
  fieldPlaceholder,
  icon,
  fieldName,
  fieldError,
  iconClick,
  formValues,
  setFormValues,
  id,
  uploadEndpoint,
  allowedFileTypes,
  customStyles,
  button = false,
  disabled = false,
  answerId,
  urlSlug,
  questions,
  cascadeAns,
  type,
  cascadedFieldKey,
}) => {
  /**
   * A common wrapper for all inputs in the application
   * */

  const [fileName, setFileName] = useState();

  const {
    mutate: fileUploadMutate,
    isLoading: isFileUploadLoading,
    data: fileUploadData,
  } = useMutation(
    urlSlug ? uploadEndpoint(urlSlug) : uploadEndpoint,
    (formBody) =>
      urlSlug
        ? makePostRequest(uploadEndpoint(urlSlug), formBody)
        : makePostRequest(uploadEndpoint, formBody),
  );

  const handleFileUpload = (e) => {
    const [file] = e.target.files;
    setFileName(file.name);

    const formData = new FormData();
    formData.append('file', file);
    fileUploadMutate(formData);
  };

  const inputRef = useRef();

  useEffect(() => {
    let img = [];

    let uniqueId = Math.floor(Math.random() * 100);

    if (!isFileUploadLoading && fileUploadData) {
      if (type !== 'cascaded' && questions) {
        let obj = { ...formValues[answerId] };

        Array.isArray(obj[fieldName])
          ? obj[fieldName].push(fileUploadData?.data)
          : (obj[fieldName] = fileUploadData?.data);
        setFormValues({
          ...formValues,
          [answerId]: { ...obj },
        });
      } else if (type === 'cascaded' && questions) {
        img.push(fileUploadData.data.file);
        setFormValues({
          ...formValues,
          [uniqueId]: {
            ...questions,
            cascadeAnsId: cascadeAns.id,
            ['cascadedAnswer']: {
              [cascadedFieldKey]: JSON.stringify(img),
              key: cascadedFieldKey,
            },
            questionType: 'cascaded',
          },
        });
      } else {
        setFormValues({ ...formValues, [fieldName]: fileUploadData?.data?.id });
      }
    }
  }, [isFileUploadLoading, fileUploadData, type, questions, cascadeAns]);

  return (
    <FormControl
      isInvalid={Boolean(fieldError?.data?.[fieldName])}
      marginBottom={5}
      style={{
        ...customStyles,
        position: 'relative',
        cursor: 'pointer',
      }}
      disabled={disabled}
    >
      <InputGroup>
        <Input
          ref={inputRef}
          type="file"
          display="none"
          id={id}
          name={fieldName}
          accept={allowedFileTypes}
          onChange={handleFileUpload}
          disabled={disabled}
        />
        <Input
          placeholder={fieldPlaceholder}
          // _placeholder={{ color: '#606060' }}
          variant="fileUploadFilled"
          size="md"
          disabled={disabled}
          value={fileName}
        />
        {icon && (
          <label htmlFor={id}>
            <InputRightElement
              top="4px"
              role="button"
              width={'100%'}
              cursor={iconClick && 'pointer'}
              onClick={iconClick}
              zIndex={0}
              justifyContent={'flex-end'}
              paddingRight={'10px'}
            >
              {isFileUploadLoading ? (
                <Spinner size="sm" />
              ) : (
                getIcon({ Icon: icon })
              )}
            </InputRightElement>
          </label>
        )}
        {button && (
          <AppButton
            text="Upload image"
            customStyles={{
              position: 'absolute',
              width: '120px',
              right: '5px',
              top: '5px',
              height: '30px',
            }}
          />
        )}
      </InputGroup>
      <FormErrorMessage>{fieldError?.data?.[fieldName]}</FormErrorMessage>
    </FormControl>
  );
};

export default AppFileInput;
