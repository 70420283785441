import appColors from 'theme/appColors';

const styles = {
  global: () => ({
    body: {
      backgroundColor: appColors.brandGray['400'],
    },
    '.chakra-select__icon-wrapper': {
      width: 'auto !important',
    },
    '.ant-picker-dropdown': {
      zIndex: '999999',
    },
    '.ant-picker-input > input': {
      color: appColors.brandGray['500'],
    },
    '.ant-picker-input>input::placeholder': {
      color: appColors.brandGray['placeholderColor'],
    },
    '.ant-picker-suffix': {
      color: `${appColors.brandTeal['900']} !important`,
    },
    '.ant-picker-focused': {
      boxShadow: 'none',
    },
    '.ant-picker-today-btn:hover': {
      cursor: 'pointer',
    },
    '.css-1wa3eu0-placeholder': {
      color: `${appColors.brandGray['placeholderColor']} !important`,
    },
  }),
};

export default styles;
