import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Icon,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { CascadeGroup, FormGroup } from 'assets';
import AppButton from 'components/AppButton/AppButton';
import AppCard from 'components/AppCard/AppCard';
import AppModal from 'components/AppModal/AppModal';
import AppRightDrawer from 'components/AppRightDrawer/AppRightDrawer';
import AppText from 'components/typography/AppText';
import DashboardLayout from 'layout/DashboardLayout';
import { PDF_Builder_List } from 'navigation/routes';
import React, { useEffect, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { BsChevronLeft } from 'react-icons/bs';
import { MdSettings } from 'react-icons/md';
import { Rnd } from 'react-rnd';
import { useLocation, useNavigate } from 'react-router-dom';
import appColors from 'theme/appColors';

import ProperitiesRender from './components/ProperitiesRender';
import { PdfComponentConstant, PdfWidgets } from './Constant';

const PdfBuilder = () => {
  const TabItems = [
    {
      name: 'Components',
      icon: FormGroup,
    },
    {
      name: 'Overall Components',
      icon: CascadeGroup,
    },
  ];

  const navigate = useNavigate();

  const { state } = useLocation();

  const {
    isOpen: propertiesIsOpen,
    onOpen: propertiesOnOpen,
    onClose: propertiesOnClose,
  } = useDisclosure();

  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  const [initialState, setInitialState] = useState({
    activeTabIndex: 0,
    isActive: true,
    isEdit: false,
    fieldIsEdit: false,
  });

  const [draggedComponent, setDraggedComponent] = useState({});

  const [menuState, setMenuState] = useState({
    id: '',
    enableSetting: false,
    enableDelete: false,
  });

  const [properityForm, setProperityForm] = useState({});

  const onFormBuilderSave = () => {};

  const widgetDragStart = (item, args) => {
    args.dataTransfer.setData('widget', item);
  };

  const drop = (args) => {
    let widgetName = args.dataTransfer.getData('widget');

    setDraggedComponent({
      ...draggedComponent,
      [Math.random().toString(36).substr(2, 9)]: {
        type: widgetName,
        name: PdfWidgets[widgetName]?.name,
        width: PdfWidgets[widgetName]?.width,
        height: PdfWidgets[widgetName]?.height,
        x: PdfWidgets[widgetName]?.x,
        y: PdfWidgets[widgetName]?.y,
      },
    });
  };

  const dragOver = (args) => {
    args.stopPropagation();
    args.preventDefault();
  };

  const settingsHandler = (type) => {
    if (type === 'setting') {
      setMenuState({
        ...menuState,
        enableSetting: !menuState.enableSetting,
        enableDelete: false,
      });
    } else {
      setMenuState({
        ...menuState,
        enableDelete: !menuState.enableDelete,
        enableSetting: false,
      });
    }
  };

  useEffect(() => {
    if (menuState.enableSetting) {
      propertiesOnOpen();
    } else if (menuState.enableDelete) {
      modalOnOpen();
    }
  }, [menuState]);

  useEffect(() => {
    if (properityForm) {
      setDraggedComponent({
        ...draggedComponent,
        [menuState.id]: { ...draggedComponent[menuState.id], ...properityForm },
      });
    }
  }, [properityForm]);

  const onRemoveField = () => {};

  const saveHandler = () => {};

  return (
    <DashboardLayout
      menu={true}
      type="Settings"
      menuName="Survey Templates"
      customStyles={{ padding: '0px', overflowY: 'hidden' }}
      isSideBar={false}
    >
      <Flex
        h={'65px'}
        backgroundColor={appColors.brandTeal.tableHead}
        p="0 33px"
        justifyContent={'space-between'}
        alignItems="center"
      >
        <Flex onClick={() => navigate(PDF_Builder_List)} cursor={'pointer'}>
          <Icon color={'#F8F8F8'} fontSize="18px" as={BsChevronLeft} />
          <AppText
            customStyles={{
              marginLeft: '10px',
              fontSize: '16px',
              lineHeight: '19.36px',
              color: appColors.brandWhite[100],
            }}
            text="Back"
          />
        </Flex>
        <Flex>
          {TabItems.map((item, inx) => {
            return (
              <Box
                key={inx}
                w="201px"
                h="65px"
                cursor={'pointer'}
                backgroundColor={
                  inx === initialState.activeTabIndex
                    ? appColors.brandTeal[500]
                    : 'transparent'
                }
                onClick={() =>
                  setInitialState({
                    ...initialState,
                    activeTabIndex: inx,
                  })
                }
              >
                <Center h="100%">
                  <img src={item.icon} alt="icon" />
                  <AppText
                    customStyles={{
                      color: appColors.brandWhite[100],
                      marginLeft: '15px',
                      fontSize: '16px',
                      lineHeight: '19.36px',
                    }}
                    text={item.name}
                  />
                </Center>
              </Box>
            );
          })}
        </Flex>
        <Box>
          <AppButton
            customStyles={{
              height: '44px',
              width: '112px',
            }}
            text="Done"
            onClick={() => onFormBuilderSave()}
          />
        </Box>
      </Flex>
      {initialState.activeTabIndex === 0 ? (
        <Flex
          gap="15px"
          overflowY="auto"
          height="79vh"
          position="relative"
          //   ref={formbuilderRef}
        >
          <Stack
            w="415px"
            h="790px"
            borderRight="0.5px solid #C3C4CC"
            borderBottom={'0.5px solid #C3C4CC'}
            background={appColors.brandWhite[100]}
            position="sticky"
            height="79vh"
            top="0px"
          >
            <AppText
              customStyles={{
                padding: '25px 0 19px 20px',
                borderBottom: '0.5px solid #CDCDCD',
              }}
              text="Field Types"
            />
            <Box p="10px 20px 0 20px">
              <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                {Object.entries(PdfComponentConstant || {}).map((item, inx) => {
                  let widget = PdfWidgets[item[1]];

                  return (
                    <GridItem
                      data-group
                      key={inx}
                      w="175px"
                      h="39px"
                      mb="3px"
                      draggable
                      style={{
                        border: '1px solid #CDCDCD',
                        borderRadius: '4px',
                      }}
                      _hover={{
                        borderColor: `${appColors.brandTeal[500]} !important`,
                        color: appColors.brandTeal[500],
                      }}
                      onDragStart={(e) => widgetDragStart(item[1], e)}
                    >
                      <Box
                        style={{
                          fontSize: '14px',
                          lineHeight: '21px',
                          paddingLeft: '14px',
                          justifyContent: 'flex-start',
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%',
                          marginTop: 0,
                          cursor: 'move',
                          background: 'transparent',
                        }}
                        hover={{
                          color: appColors.brandTeal[500],
                        }}
                      >
                        <Icon
                          as={widget?.icon}
                          style={{
                            width: '18px',
                            height: '18px',
                            marginRight: '10px',
                          }}
                        />

                        <AppText
                          text={widget?.name}
                          customStyles={{
                            fontSize: '14px',
                            lineHeight: '21px',
                          }}
                        />
                      </Box>
                    </GridItem>
                  );
                })}
              </Grid>
            </Box>
          </Stack>
          <Stack
            w="60%"
            border="1px solid #E0E6FF"
            backgroundColor={appColors.brandWhite[100]}
            boxShadow="0px -3px 9px rgba(21, 38, 38, 0.12)"
            minHeight="500px"
            height="max-content"
            p="30px 0"
            mt="30px"
            mb="30px"
          >
            <Flex
              justifyContent={'space-between'}
              pb="30px"
              borderBottom="1px solid #CDCDCD"
            >
              <AppText
                customStyles={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: 600,
                  marginLeft: '50px',
                }}
                text={state?.identity}
              />
              {/* <Center mr="21px" w="150px" justifyContent={'flex-start'}>
                <Switch
                  //   isChecked={activeState}
                  colorScheme="teal"
                  id="status"
                  size="md"
                  style={{
                    marginRight: '10px',
                  }}
                  onChange={(args) => {
                    // formGroupUpdate({
                    //   ...getMetaFormData?.data?.initial,
                    //   status:
                    //     args?.target?.checked === true ? 'active' : 'inactive',
                    // });
                    setActiveState(args?.target?.checked);
                  }}
                />
                <FormLabel mb={'0px !important'} htmlFor="status">
                  {activeState ? 'Active' : 'InActive'}
                </FormLabel>
              </Center> */}
            </Flex>
            <Box
              style={{ width: '100%', height: '100vh' }}
              onDrop={(e) => drop(e)}
              onDragOver={(e) => {
                dragOver(e);
              }}
            >
              {Object.entries(draggedComponent)?.map((item, inx) => {
                return (
                  <>
                    <Rnd
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: 'dashed 2px #ddd',
                      }}
                      bounds="parent"
                      key={inx}
                      size={{
                        width: draggedComponent[item[0]]?.width,
                        height: draggedComponent[item[0]]?.height,
                      }}
                      position={{
                        x: draggedComponent[item[0]]?.x,
                        y: draggedComponent[item[0]]?.y,
                      }}
                      onDragStop={(e, d) => {
                        setMenuState({ id: item[0] });
                        setDraggedComponent({
                          ...draggedComponent,
                          [item[0]]: {
                            ...draggedComponent[item[0]],
                            x: d.x,
                            y: d.y,
                          },
                        });
                      }}
                      onResizeStop={(e, direction, ref, delta, position) => {
                        setDraggedComponent({
                          ...draggedComponent,
                          [item[0]]: {
                            ...draggedComponent[item[0]],
                            width: ref.style.width,
                            height: ref.style.height,
                            ...position,
                          },
                        });
                      }}
                    >
                      <p style={{ overflowWrap: 'anywhere' }}>{item[1].name}</p>
                      {menuState.id === item[0] && item[0] !== '' && (
                        <Flex style={{ position: 'absolute', right: '-40px' }}>
                          <AppCard
                            variant="pdfBuilderMenuCard"
                            customStyles={{ marginTop: '0px' }}
                          >
                            <Flex flexDirection="column" gap="23px">
                              <MdSettings
                                onClick={() =>
                                  settingsHandler('setting', item[0])
                                }
                                style={{
                                  cursor: 'pointer',
                                  color: menuState.enableSetting
                                    ? appColors.brandTeal['activeMenu']
                                    : appColors.brandGray['iconGrey'],
                                }}
                              />

                              <AiFillDelete
                                onClick={() =>
                                  settingsHandler('delete', item[0])
                                }
                                style={{
                                  cursor: 'pointer',
                                  color: menuState.enableDelete
                                    ? appColors.brandTeal['activeMenu']
                                    : appColors.brandGray['iconGrey'],
                                }}
                              />
                            </Flex>
                          </AppCard>
                        </Flex>
                      )}
                    </Rnd>
                  </>
                );
              })}
            </Box>
          </Stack>
        </Flex>
      ) : (
        <div>under development</div>
      )}

      <AppRightDrawer
        isOpen={propertiesIsOpen}
        onClose={propertiesOnClose}
        // properityForm={properityForm}
        // setError={setError}
        // setProperityForm={setProperityForm}
        // setInitialProperityData={setInitialProperityData}
        setDraggedComponent={setDraggedComponent}
        text="Properties"
        saveHandler={saveHandler}
        // isLoading={getFormFieldIsLoading || getFormFieldUpdationIsLoading}
      >
        <>
          <ProperitiesRender
            properityForm={properityForm}
            setProperityForm={setProperityForm}
          />
        </>
      </AppRightDrawer>
      <AppModal
        isOpen={modalIsOpen}
        onClose={modalOnClose}
        closeCheck={true}
        size="xl"
      >
        <AppText
          text="Are you sure you want to delete?"
          customStyles={{ mb: 5, textAlign: 'center' }}
        />
        <Flex>
          <AppButton
            text="Cancel"
            variant="cancelBtn"
            customStyles={{ margin: '0px 22px 27px 22px' }}
            onClick={modalOnClose}
          />
          <AppButton
            // isLoading={
            //   getFormFieldDeletionIsLoading || getFormGroupDeletionIsLoading
            // }
            text="Delete"
            variant="deleteBtn"
            onClick={() => onRemoveField()}
            customStyles={{ margin: '0px 22px 27px 0px' }}
          />
        </Flex>
      </AppModal>
    </DashboardLayout>
  );
};

export default PdfBuilder;
