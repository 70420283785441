import { Box, Flex, useToast } from '@chakra-ui/react';
import { SIGNUP_TEAM_MEMBERS } from 'api/urls';
import { TEAM_MEMBER_ACCEPT_INVITE } from 'api/urls/settings';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AuthCard from 'components/AuthCard/AuthCard';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import useHandleShowPassword from 'hooks/formHelpers/useHandleShowPassword';
import AuthLayout from 'layout/AuthLayout';
import { APP_LOGIN_SCREEN } from 'navigation/routes';
import { APP_DASHBOARD } from 'navigation/routes/dashboard';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const InitialFormErrors = {
  data: {
    non_field_errors: [''],
  },
};
const AppTeamMemberInviteMagicLinkVerification = () => {
  const { passwordProps } = useHandleShowPassword();
  const { passwordProps: confirmPasswordProps } = useHandleShowPassword();

  const [error, setError] = useState(InitialFormErrors);
  const [formValues, setFormValues] = useState({});

  const params = useParams();
  const toast = useToast();

  const navigate = useNavigate();

  const userDatabaseId = params?.userDatabaseID;
  const userId = params?.userID;

  const { data: verificationData } = useQuery(
    SIGNUP_TEAM_MEMBERS(userDatabaseId, userId),
    () => makeGetRequest(SIGNUP_TEAM_MEMBERS(userDatabaseId, userId)),
  );

  const {
    mutate,
    isLoading,
    error: passwordResetError,
    isSuccess: passwordResetSuccess,
    data: teamMemberAcceptInviteData,
  } = useMutation(
    TEAM_MEMBER_ACCEPT_INVITE(userDatabaseId, userId),
    (formVal) =>
      makePostRequest(
        TEAM_MEMBER_ACCEPT_INVITE(userDatabaseId, userId),
        formVal,
      ),
    {
      onError: (formError) => {
        setError(formError.response.data);
      },
    },
  );

  const formFields = [
    {
      fieldName: 'password',
      fieldPlaceholder: 'New Password',
      ...passwordProps,
    },
    {
      fieldPlaceholder: 'Confirm New Password',
      fieldName: 'confirm_password',
      ...confirmPasswordProps,
    },
  ];

  useEffect(() => {
    if (passwordResetSuccess && !isLoading) {
      toast({
        title: 'Password has been set successfully',
        status: 'success',
        duration: 3000,
      });
      navigate(APP_DASHBOARD);
    }
  }, [isLoading, passwordResetSuccess]);

  useEffect(() => {
    if (
      verificationData?.data?.redirect_to_login ||
      teamMemberAcceptInviteData?.data?.redirect_to_login
    ) {
      navigate(APP_LOGIN_SCREEN);
    }
  }, [verificationData, teamMemberAcceptInviteData]);

  return (
    <AuthLayout>
      <AuthCard>
        <AppHeader text="Create New Password" />

        <Flex flexFlow="column" minH={300} justifyContent={'space-between'}>
          <Box textAlign="center">
            <AppText text="Email Verified Successfully" size="sm" />
            <AppText text="Please enter your new password" size="sm" />
            <AppText
              text={passwordResetError?.response?.data?.data?.detail}
              variant="errorMessage"
            />
          </Box>

          <Box>
            {formFields.map((field) => (
              <AppInput
                {...field}
                key={field.fieldName}
                formValues={formValues}
                setFormValues={setFormValues}
                fieldError={error}
              />
            ))}
          </Box>

          <AppButton
            isLoading={isLoading}
            text={'Set my password'}
            onClick={() => mutate(formValues)}
          />
        </Flex>
      </AuthCard>
    </AuthLayout>
  );
};

export default AppTeamMemberInviteMagicLinkVerification;
