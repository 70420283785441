import { Flex } from '@chakra-ui/react';
import AppButton from 'components/AppButton/AppButton';
import AppText from 'components/typography/AppText';
import React from 'react';

const DeleteUser = ({ onClose, isSubmitLoading, handleDelete, text }) => {
  return (
    <>
      <AppText
        text={text ? text : 'Are you sure you wanna delete this user'}
        customStyles={{ mb: 5, textAlign: 'center' }}
      />
      <Flex>
        <AppButton
          text="Cancel"
          variant="cancelBtn"
          customStyles={{ margin: '0px 22px 27px 22px' }}
          onClick={onClose}
        />
        <AppButton
          isLoading={isSubmitLoading}
          text="Delete"
          variant="deleteBtn"
          onClick={handleDelete}
          customStyles={{ margin: '0px 22px 27px 0px' }}
        />
      </Flex>
    </>
  );
};

export default DeleteUser;
