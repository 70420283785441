import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import React from 'react';
import { MdArrowDropDown } from 'react-icons/md';

const AppMenu = ({ children, buttonStyles, iconStyles, label, isDisabled }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        _focus={{ boxShadow: 'none' }}
        style={{
          ...buttonStyles,
        }}
        rightIcon={
          <MdArrowDropDown
            style={{
              ...iconStyles,
            }}
          />
        }
        disabled={isDisabled}
      >
        {label}
      </MenuButton>
      <MenuList>{children}</MenuList>
    </Menu>
  );
};

export default AppMenu;
