import {
  Divider,
  Flex,
  MenuDivider,
  MenuItem,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  GET_PROJECT_LISTING_DATA,
  GET_PROJECT_LISTING_META,
  PROJECT_STATUS_CHANGE,
  PROJECT_TABLE_EXPORT,
  SEND_PDF_TO_EMAIL,
} from 'api/urls/projects';
import isUserAuthenticated from 'api/utilities/isUserAuthenticated';
import makePostRequest from 'api/utilities/makePostRequest';
import makePutRequest from 'api/utilities/makePutRequest';
import useStore from 'AppZustand/store';
import AppAlertModal from 'components/AppAlertModal/AppAlertModal';
import AppBox from 'components/AppBox/AppBox';
import AppButton from 'components/AppButton/AppButton';
import Card from 'components/AppCard/AppCard';
import AppLoader from 'components/AppLoader/AppLoader';
import AppMenu from 'components/AppMenu/AppMenu';
import AppModal from 'components/AppModal/AppModal';
import AppSearch from 'components/AppSearch/AppSearch';
import CustomTable from 'components/AppTable';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import useGetTableFunctions from 'hooks/tableHelpers/useGetTableFunctions';
import useWorkflowConfig from 'hooks/workflow/useWorkflowConfig';
import DashboardLayout from 'layout/DashboardLayout';
import ActionProject from 'pages/appSettings/project/components/projectComponents/ActionProject';
import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import appColors from 'theme/appColors';
import { fontSizes } from 'theme/typographyStyles';
import { timeStamp } from 'utils/TimeStampModifier';

import AddNewProject from './components/projectComponents/AddNewProject';
import Filter from './components/projectComponents/Filter';
// import AddSurvey from './components/AddSurvey';
import Settings from './components/projectComponents/Settings';
import Sort from './components/projectComponents/Sort';
import { iconsArr } from './Data';

const ProjectsList = () => {
  // const navigate = useNavigate();
  const [order, setOrder] = useState('asc');

  let columnsIdentity = {
    generate_id: 'Project ID',
    identity: 'Project Name',
    address: 'P Address',
    project_type__identity: 'P Identity',
    survey_type__identity: 'Survey Identity',
    created_by__name: 'Created By',
    current_workflow_state: 'Current Workflow State',
    previous_workflow_state: 'Previous Workflow State',
    assigned_field_auditor__name: 'Assigned Field Auditor',
    created: 'Created',
    status: 'Status',
  };

  const [selectedFilters, setSelectedFilters] = useState({ status: '' });

  const { permissions } = useStore((state) => state);

  const [formValues, setFormValues] = useState({
    search: '',
  });

  const [selectedData, setSelectedData] = useState([]);

  const { projectsCheckedId, projectsData, pageSize, fetchPageSize } = useStore(
    (state) => state,
  );

  const { actionsList, fromState, nextStates } = useWorkflowConfig({
    projectsData,
  });

  const [setMetaData] = useState({});
  const [sortBy, setSortBy] = useState('');
  const [optionId, setOptionId] = useState(0);
  const [option, setOption] = useState({
    filter: false,
    setting: false,
    sort: null,
    addNew: false,
    addSurvey: false,
    id: 0,
  });

  const {
    isOpen: isAddNewProjectOpen,
    onClose: onAddNewProjectClose,
    onOpen: onAddNewProjectOpen,
  } = useDisclosure();

  const {
    isOpen: isActionOpen,
    onClose: onActionClose,
    onOpen: onActionOpen,
  } = useDisclosure();

  const {
    isOpen: isPermissionAlertOpen,
    onClose: onPermissionAlertClose,
    onOpen: onPermissionAlertOpen,
  } = useDisclosure();

  useEffect(() => {
    isUserAuthenticated().then((res) => {
      setMetaData(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (selectedData.length > 0) {
      onActionOpen();
    }
  }, [selectedData]);

  useEffect(() => {
    getTableMetaMutation();
    fetchPageSize(25);
  }, []);

  const toast = useToast();

  const {
    columnsData,
    handleApplyFilter,
    tableData,
    isTableLoading,
    getTableDataMutation,
    getTableMetaMutation,
    setSearchParams,
  } = useGetTableFunctions({
    tableDataURL: GET_PROJECT_LISTING_DATA,
    tableMetaURL: GET_PROJECT_LISTING_META,
    columnsIdentity: columnsIdentity,
    permissionAlert: onPermissionAlertOpen,
  });

  const { mutate: ProjectUpdateMutate, isLoading: isProjectUpdateLoading } =
    useMutation(
      PROJECT_STATUS_CHANGE(),
      (formBody) => makePutRequest(PROJECT_STATUS_CHANGE(), formBody),
      {
        onSuccess: () => {
          getTableDataMutation();
        },
      },
    );

  const { mutate: sendPDFToEmailMutate } = useMutation(
    SEND_PDF_TO_EMAIL(),
    (formBody) => makePostRequest(SEND_PDF_TO_EMAIL(), formBody),
    {
      onSuccess: () => {
        toast({
          title: 'PDF has been sent to your email successfully',
          status: 'success',
          duration: 3000,
        });
      },
    },
  );

  const [projectTableData, setProjectTableData] = useState();

  useEffect(() => {
    if (location.search.includes('status=active')) {
      const activeData = tableData?.data?.results?.filter((data) => {
        return data?.status === 'active';
      });

      setProjectTableData(activeData || []);
    } else if (location.search.includes('status=inactive')) {
      const activeData = tableData?.data?.results?.filter((data) => {
        return data?.status === 'inactive';
      });

      setProjectTableData(activeData || []);
    } else {
      setProjectTableData(tableData?.data?.results);
    }
  }, [tableData, option, location?.search]);

  let isStatus = location?.search?.includes('status=active');

  useEffect(() => {
    if (option.filter || option.setting) {
      setOptionId(option.id);
    } else if (formValues.search) {
      setSearchParams(
        `?search=${formValues.search}&${
          isStatus ? 'status=active' : 'status=inactive'
        }`,
      );
      // setProjectTableData(tableData?.data?.results);
    } else if (option.sort === false && pageSize && order === 'asc') {
      setSearchParams(
        `?ordering=${sortBy}&page-${pageSize}&${
          isStatus ? 'status=active' : 'status=inactive'
        }`,
      );
      // setProjectTableData(tableData?.data?.results);
    } else if (option.sort === false && pageSize && order === 'desc') {
      setSearchParams(
        `?ordering=-${sortBy}&page-${pageSize}&${
          isStatus ? 'status=active' : 'status=inactive'
        }`,
      );
      // setProjectTableData(tableData?.data?.results);
    }
  }, [option, formValues, tableData, pageSize]);

  const optionHandler = (id, type) => {
    if (type === 'filter') {
      setOption({
        filter: !option.filter,
        setting: false,
        sort: false,
        id: id,
      });
    } else if (type === 'setting') {
      setOption({
        setting: !option.setting,
        filter: false,
        sort: false,
        id: id,
      });
    } else if (type === 'sort') {
      setOption({ sort: !option.sort, filter: false, setting: false, id: id });
    }
  };
  const addModalHandler = () => {
    onAddNewProjectOpen();
  };

  const actionsHandler = (data) => {
    if (data?.value === 'active' || data?.value === 'inactive') {
      ProjectUpdateMutate({ projects: projectsCheckedId, state: data?.value });
    } else {
      Object.entries(nextStates)?.map((d) => {
        if (data.name === d[1].name) {
          setSelectedData(d);
        }
      });
    }
  };

  return (
    <DashboardLayout menu={false} type="Projects">
      <>
        {/* <AppHeader
          text={`Welcome ${
            metaData?.organisation_user?.name
              ? metaData?.organisation_user?.name
              : ''
          }`}
          variant="heading"
          customStyles={{ margin: '0px 0px 30px 0px', textAlign: 'start' }}
        /> */}
        <AppBox
          customStyles={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <AppHeader text="Projects" variant="heading" />
          <Link to={'/bulk/upload/Projects'}>
            <AppButton>Bulk Upload</AppButton>
          </Link>
        </AppBox>

        {(permissions?.includes('all_projects_view') ||
          permissions?.includes('only_assigned_projects_view')) && (
          <>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              marginTop="24px"
            >
              <Card
                variant="tableHeaderCard"
                customStyles={{ margin: '0px 20px 0px 0px' }}
              >
                <Flex alignItems="center" gap={2}>
                  <AppText
                    variant="emailText"
                    text={`Projects Selected ${projectsCheckedId.length}`}
                    customStyles={{ width: '150px' }}
                  />
                  <Divider
                    orientation="vertical"
                    height="50px"
                    marginRight="20px"
                  />
                  <AppMenu
                    label="Action"
                    iconStyles={{
                      width: '20px',
                      height: '20px',
                      color: appColors.brandTeal['900'],
                    }}
                    buttonStyles={{
                      fontSize: fontSizes.sm,
                      fontWeight: 400,
                      color: appColors.brandGray[500],
                      width: '200px',
                      textAlign: 'start',
                    }}
                    isDisabled={projectsCheckedId?.length <= 0}
                  >
                    {actionsList.length === 0 || !isStatus ? (
                      <MenuItem color={appColors.brandBlack[600]} key="noData">
                        No Action
                      </MenuItem>
                    ) : (
                      <>
                        {[...actionsList]?.map((data) => {
                          return (
                            <MenuItem
                              color={appColors.brandBlack[600]}
                              key={data.name}
                              onClick={() => actionsHandler(data)}
                            >
                              {data.name}
                            </MenuItem>
                          );
                        })}
                        <MenuDivider />
                      </>
                    )}
                    {(isStatus
                      ? [{ name: 'Mark as Inactive', value: 'inactive' }]
                      : [{ name: 'Mark as Active', value: 'active' }]
                    )?.map((data) => {
                      return (
                        <MenuItem
                          color={appColors.brandBlack[600]}
                          key={data.name}
                          onClick={() => actionsHandler(data)}
                        >
                          {data.name}
                        </MenuItem>
                      );
                    })}
                  </AppMenu>
                </Flex>
              </Card>
              <Flex alignItems="center" gap={2}>
                <AppSearch
                  fieldPlaceholder="Search..."
                  fieldType="text"
                  fieldName="search"
                  icon={<FiSearch color={appColors.brandGray['iconGrey']} />}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  variant="searchField"
                  customStyles={{ marginRight: '16px' }}
                  width="300px"
                  top="4px"
                />
                {iconsArr?.map((d) => {
                  return (
                    <Card
                      onClick={() => {
                        optionHandler(d.id, d.name);
                      }}
                      variant={
                        option.id === d.id ? 'activeIconCard' : 'iconCard'
                      }
                      customStyles={{
                        margin: '0px 16px 0px 0px',
                        cursor: 'pointer',
                      }}
                      key={d.id}
                    >
                      <d.icon
                        color={
                          option.id === d.id
                            ? appColors.brandWhite['100']
                            : appColors.brandBlack['600']
                        }
                        width="16px"
                        height="16px"
                      />
                    </Card>
                  );
                })}
                {permissions?.includes('projects_manage') && (
                  <AppButton
                    text="+ Add New"
                    customStyles={{ width: '100px' }}
                    check="dash"
                    onClick={addModalHandler}
                  />
                )}
                {/* <AppButton
                  text=""
                  customStyles={{
                    width: '140px',
                    _hover: { opacity: 1 },
                  }}
                  check="dash"
                > */}
                <Flex
                  alignItems="center"
                  style={{
                    width: '150px',
                    backgroundColor: appColors.brandTeal['500'],
                    fontSize: fontSizes.sm,

                    _hover: {
                      backgroundColor: appColors.brandTeal['500'],

                      _disabled: {
                        backgroundColor: appColors.brandTeal['500'],
                        opacity: 0.4,
                      },
                    },

                    _loading: {
                      backgroundColor: appColors.brandTeal['500'],
                    },

                    _disabled: {
                      backgroundColor: appColors.brandTeal['500'],
                    },
                    color: appColors.brandWhite['100'],
                    padding: '3.5px 0px',
                    borderRadius: '5px',
                  }}
                >
                  <AppMenu
                    buttonStyles={{
                      background: 'none',
                      minWidth: '0px',
                      fontSize: '14px',
                      width: '100%',
                    }}
                    label="Export"
                    iconStyles={{
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    {/* <MenuItem
                  color={appColors.brandBlack[600]}
                  onClick={() => navigate(APP_BULK_UPLOAD)}
                >
                  Bulk Upload
                </MenuItem> */}

                    <MenuItem
                      style={{
                        color: appColors.brandBlack[600],
                        width: '100%',
                        height: '100%',
                      }}
                      onClick={() => {
                        makePostRequest(
                          PROJECT_TABLE_EXPORT(),
                          {
                            ids: projectsCheckedId,
                          },
                          {
                            responseType: 'blob',
                          },
                        ).then((blob) => {
                          let fileName =
                            Math.random().toString(36).substring(2, 8) +
                            Math.random().toString(36).substring(2, 8);

                          const fileURL = window.URL.createObjectURL(blob);
                          let alink = document.createElement('a');
                          alink.href = fileURL;
                          alink.download = fileName;
                          alink.click();
                        });
                      }}
                    >
                      Export To CSV
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      style={{ color: appColors.brandBlack[600] }}
                      onClick={() => {
                        sendPDFToEmailMutate({ ids: projectsCheckedId });
                      }}
                    >
                      Send PDF To Email
                    </MenuItem>
                  </AppMenu>
                </Flex>
                {/* </AppButton> */}
              </Flex>
            </Flex>
            <Flex>
              <Card
                variant="tableCard"
                customStyles={{
                  width: option.filter || option.setting ? '70%' : '100%',
                  margin: '0px',
                  borderTopLeftRadius: '0px',
                }}
              >
                <AppLoader isLoading={isTableLoading || isProjectUpdateLoading}>
                  {!isTableLoading && (
                    <CustomTable
                      columns={columnsData}
                      data={timeStamp(projectTableData) || []}
                      check={true}
                      // tableData={tableData}
                      // getTableDataMutation={getTableDataMutation}
                    />
                  )}
                </AppLoader>
              </Card>
              {option.filter && (
                <Card
                  variant="tableCard"
                  customStyles={{
                    width: '30%',
                    marginLeft: '24px',
                    marginTop: '0px',
                    borderTopLeftRadius: '12px',
                    height: '60%',
                  }}
                >
                  <Filter
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    setState={setOption}
                    filterCallback={handleApplyFilter}
                    setOptionId={setOptionId}
                    getTableDataMutation={getTableDataMutation}
                  />
                </Card>
              )}
              {option.sort && (
                <Card variant="settingCard" customStyles={{ width: '400px' }}>
                  <Sort
                    option={option}
                    setState={setOption}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    columnsIdentity={columnsIdentity}
                    order={order}
                    setOrder={setOrder}
                  />
                </Card>
              )}
              {option.setting && (
                <Card variant="settingCard" customStyles={{ width: '45%' }}>
                  <Settings
                    option={option}
                    setState={setOption}
                    optionId={optionId}
                    setOptionId={setOptionId}
                    columnsIdentity={columnsIdentity}
                    getTableDataMutation={getTableDataMutation}
                    getTableMetaMutation={getTableMetaMutation}
                  />
                </Card>
              )}
            </Flex>
          </>
        )}

        <AppModal
          isOpen={isAddNewProjectOpen}
          onClose={onAddNewProjectClose}
          text="Add New Project"
          closeCheck={false}
        >
          <AddNewProject
            onClose={onAddNewProjectClose}
            getTableDataMutation={getTableDataMutation}
          />
        </AppModal>

        <AppModal
          isOpen={isActionOpen}
          onClose={onActionClose}
          text={selectedData && selectedData?.[1]?.name}
          closeCheck={true}
        >
          <ActionProject
            onClose={onActionClose}
            projectsCheckedId={projectsCheckedId}
            selectedData={selectedData}
            fromState={fromState}
            getTableDataMutation={getTableDataMutation}
            getTableMetaMutation={getTableMetaMutation}
          />
        </AppModal>
      </>

      <AppAlertModal
        isOpen={isPermissionAlertOpen}
        onClose={onPermissionAlertClose}
      />
    </DashboardLayout>
  );
};

export default ProjectsList;
