import appColors from 'theme/appColors';
import { fontSizes, fontWeights } from 'theme/typographyStyles';

const LinkStyles = {
  sizes: {
    xs: {
      fontSize: fontSizes.xs,
      lineHeight: '17px',
      fontWeight: fontWeights.normal,
      color: appColors.brandGray['700'],
    },

    sm: {
      fontSize: fontSizes.sm,
    },
  },

  defaultProps: {
    size: 'xs',
  },
};

export default LinkStyles;
