import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { AUTH_STATUS, SWITCH_ORGANISATION } from 'api/urls';
import { COMPANY_SETTING_UPDATING_META } from 'api/urls/settings/company';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import useStore from 'AppZustand/store';
import { SiteScribeLogo } from 'assets';
import { Select } from 'chakra-react-select';
import AppBox from 'components/AppBox/AppBox';
import Card from 'components/AppCard/AppCard';
import AppText from 'components/typography/AppText';
import { APP_HEADERS } from 'constants';
import AppOverallSearch from 'hooks/overallSearchHelpers/AppOverallSearch';
import useOverallSearch from 'hooks/overallSearchHelpers/useOverallSearch';
import {
  APP_DASHBOARD,
  APP_LOGIN_SCREEN,
  APP_PROFILE,
} from 'navigation/routes';
import React, { useEffect, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { BsBellFill } from 'react-icons/bs';
import { FaUserCircle } from 'react-icons/fa';
import { MdLogout } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import appColors from 'theme/appColors';

const AppNavBar = () => {
  const [formValues, setFormValues] = useState({
    search: '',
    associated_organisations: '',
    associated_fetched: false,
    dataloaded: false,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: getStatus, data: statusData } = useMutation(
    AUTH_STATUS,
    () => makeGetRequest(AUTH_STATUS),
    {
      onSuccess: (data) => {
        setFormValues({
          ...formValues,
          associated_organisations: data.data.associated_organisations.filter(
            (item) => item.is_logged_in === true,
          )[0].id,
          dataloaded: true,
        });
      },
    },
  );
  const { mutate: getOrganisation } = useMutation(
    SWITCH_ORGANISATION(),
    (org_id) => makePostRequest(SWITCH_ORGANISATION(org_id)),
    {
      onSuccess: () => {
        setFormValues({ ...formValues, associated_fetched: false });
      },
      onSettled: () => {
        navigate(APP_DASHBOARD);
        window.location.reload();
      },
    },
  );

  const {
    overallSearchResult,
    searchInput,
    setSearchInput,
    setSearchStringToQueryParam,
  } = useOverallSearch();

  const {
    setCompanySettings,
    setDuplicateCompanySettings,
    setCompanySettingsData,
  } = useStore((state) => state);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      formValues.associated_organisations === '' &&
      !formValues.associated_fetched
    ) {
      getStatus();
    }
  }, []);

  useEffect(() => {
    if (formValues.dataloaded && formValues.associated_fetched) {
      getOrganisation(formValues.associated_organisations);
    } else if (formValues.dataloaded) {
      setFormValues({ ...formValues, associated_fetched: true });
    }
  }, [formValues.associated_organisations]);

  const iconsArr = [
    // {
    //   id: 1,
    //   icon: IoMdVideocam,
    // },
    {
      id: 2,
      icon: BsBellFill,
    },
  ];

  const profileHandler = () => {
    navigate(APP_PROFILE);
  };

  const logoutHandler = () => {
    localStorage.removeItem(APP_HEADERS);
    navigate(APP_LOGIN_SCREEN);
    setCompanySettings({});
    setCompanySettingsData({});
    setDuplicateCompanySettings({});
    queryClient.removeQueries(COMPANY_SETTING_UPDATING_META);
  };

  const findOrganisation = (value) => {
    return statusData?.data?.associated_organisations?.filter(
      (item) => item?.id === value,
    );
  };

  const SearchInnerIcons = () => {
    return (
      <Flex>
        {/* <Card
          variant="searchIconCard"
          customStyles={{
            margin: '0px 10px 0px 0px',
            cursor: 'pointer',
          }}
        >
          ⌘
        </Card>
        <Card
          variant="searchIconCard"
          customStyles={{
            margin: '0px 10px 0px 0px',
            cursor: 'pointer',
          }}
        >
          J
        </Card> */}
        <Card
          variant="searchIconCard"
          customStyles={{
            margin: '0px 10px 0px 0px',
            cursor: 'pointer',
            width: '100px',
            fontWeight: 700,
            color: '#B1B1B1',
          }}
        >
          CTRL + I
        </Card>
      </Flex>
    );
  };

  return (
    <Box
      background={appColors.brandWhite['100']}
      borderBottom="1px solid #D9DFEA"
      p={3}
    >
      <Flex alignItems="center">
        {location.pathname.includes('form-builder') && (
          <Flex h="70px" w="250px" mb={1} p={5} alignItems="center">
            <Link to="/dashboard">
              <Image src={SiteScribeLogo} />
            </Link>
          </Flex>
        )}
        {/* <AppSearch
          fieldPlaceholder="Search any customer name, etc..."
          fieldType="text"
          fieldName="search"
          icon={<FiSearch color={appColors.brandGray['iconGrey']} />}
          rightIcon={<SearchInnerIcons />}
          formValues={formValues}
          setFormValues={setFormValues}
          handleSubmit={handleSearch}
          variant="searchField"
          customStyles={{
            marginLeft: '40px',
            background: '#F0F0F0',
            width: '50%',
          }}
          groupStyles={{
            display: 'flex',
            justifyContent: 'center',
          }}
          iconStyles={{
            left: '26.5%',
            top: '5px',
          }}
          rightIconStyles={{
            right: '26.5%',
            top: '4px',
          }}
        /> */}
        <AppOverallSearch
          listOfResult={overallSearchResult}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setSearchStringToQueryParam={setSearchStringToQueryParam}
          rightIcon={<SearchInnerIcons />}
          customStyles={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}
        />

        <Flex width="auto" alignItems="center" gap={2}>
          {/* <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton isActive={isOpen}>
                  <AppButton
                    size="sm"
                    customStyles={{ color: 'white !important' }}
                  >
                    Switch Org
                    <MdArrowDropDown
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  </AppButton>
                </MenuButton>
                <MenuList>
                  {statusData?.data?.associated_organisations?.map((item) => {
                    return (
                      <MenuItem
                        key={item?.id}
                        onClick={() =>
                          setFormValues({
                            ...formValues,
                            associated_organisations: item?.id,
                          })
                        }
                        style={{
                          pointerEvents: item?.is_logged_in
                            ? 'none'
                            : 'initial',
                        }}
                      >
                        <Flex alignItems="center">
                          {item?.is_logged_in && (
                            <FcCheckmark
                              style={{
                                width: '18px',
                                height: '18px',
                                marginRight: '8px',
                                marginBottom: '3px',
                              }}
                            />
                          )}
                          <AppText text={item?.identity} />
                        </Flex>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </>
            )}
          </Menu> */}

          <AppBox customStyles={{ width: '180px' }}>
            <Select
              options={statusData?.data?.associated_organisations?.map(
                function (el) {
                  var obj = Object.assign({}, el);
                  obj.value = el.id;
                  obj.label = el.identity;
                  return obj;
                },
              )}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  associated_organisations: e?.id,
                });
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
              value={[
                {
                  label: `${
                    findOrganisation(formValues?.associated_organisations)?.[0]
                      ?.identity || 'Loading...'
                  }`,
                  value: `${
                    findOrganisation(formValues?.associated_organisations)?.[0]
                      .id
                  }`,
                },
              ]}
              selectedOptionStyle="check"
              classNamePrefix="react-select"
            />
          </AppBox>
          {iconsArr.map((d) => (
            <d.icon
              key={d.id}
              color={appColors.brandGray['iconGrey']}
              cursor="pointer"
              style={{ width: '24px', height: '24px', margin: '0px 10px' }}
            />
          ))}

          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton isActive={isOpen}>
                  <FaUserCircle
                    cursor="pointer"
                    style={{
                      width: '24px',
                      height: '24px',
                      margin: '0px 10px',
                    }}
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem padding="5px 20px" onClick={profileHandler}>
                    <BiUser style={{ marginRight: '10px' }} />
                    <AppText text="My Profile" />
                  </MenuItem>
                  <MenuItem padding="5px 20px" onClick={logoutHandler}>
                    <MdLogout style={{ marginRight: '10px' }} />
                    <AppText text="Logout" />
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </Flex>
      </Flex>
      <style jsx="true">
        {`
          .react-select__control {
            outline: none !important;
            box-shadow: none !important;
            border-color: white !important;
            background-color: #edf2f7 !important;
          }
          .react-select__single-value {
            color: ${appColors.brandGray['500']};
          }
        `}
      </style>
    </Box>
  );
};

export default AppNavBar;
