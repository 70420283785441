const appColors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
  brandGray: {
    400: '#E5E5E5',
    500: '#606060',
    300: '#87919E',
    700: '#636262',
    900: '#2E3935',
    100: '#F4F6FD',
    tableFooterText: '#737E95',
    iconGrey: '#8F8F8F',
    background: '#F2F7FA',
    menuIcon: '#5C5E61',
    graymenuIcon: '#B8B9BF',
    label: '#A6B5D6',
    paginationText: '#737E95',
    pieLabel: '#B1B1B1',
    placeholderColor: '#aaaaaa',
    selectedField: '#E2E8F0',
  },
  brandTeal: {
    900: '#4C8A7F',
    800: '#1F8098',
    500: '#1ECAAB',
    300: '#F3F7F9',
    100: '#CDE6F5',
    600: '#10757A',
    tableHead: '#0F6F78',
    activeMenu: '#45B6A1',
    active: '#2CAE66',
    activeWidget: '#409F88',
  },

  brandWhite: {
    100: '#FFFFFF',
  },

  brandRed: {
    400: '#FF2F2F',
    300: '#E23A3A',
    error: '#E53E3E',
  },

  brandBlack: {
    600: '#152626',
    400: '#33373C',
  },
};

export default appColors;
