import { Stack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import AppBox from 'components/AppBox/AppBox';
import AppText from 'components/typography/AppText';
import { memo, useEffect, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';

const MultiSelectionField = ({
  isEditable,
  state,
  setState,
  error,
  index,
  item,
  fieldName,
}) => {
  const [option, setOption] = useState([]);

  const [defaultOption, setDefaultOption] = useState(null);

  useEffect(() => {
    let arr = [];
    let arr2 = [];

    item?.question?.multi_selection_input_choices_validation?.map((data) => {
      arr.push({ label: data, value: data });
    });
    setOption(arr);

    if (state?.[item?.question?.custom_id]?.answer_multi_selection_input) {
      state?.[item?.question?.custom_id]?.answer_multi_selection_input?.map(
        (data) => {
          return arr2.push({ label: data, value: data });
        },
      );
      setDefaultOption(arr2);
    }
  }, [state, item]);

  return (
    <Stack>
      <AppBox>
        {Array.isArray(defaultOption) && (
          <Select
            name={'answer_multi_selection_input'}
            options={option}
            isMulti
            variant="filled"
            defaultValue={defaultOption}
            onChange={(ele) => {
              if (item?.question?.field_type === 'cascade') {
                setState({
                  ...state,
                  [item?.question?.custom_id]: {
                    ...item.question,
                    [fieldName]: ele.map((data) => data.value),
                  },
                });
              } else {
                setState({
                  ...state,
                  [item?.question?.custom_id]: {
                    ...item.answer,
                    [fieldName]: ele.map((data) => data.value),
                  },
                });
              }
            }}
            icon={MdArrowDropDown}
            customStyles={{ fontSize: '14px' }}
            isDisabled={!isEditable}
          />
        )}
        {/* <CheckboxGroup
          onChange={(e) => onCheckChange(e)}
          defaultValue={state?.[answers]?.answer_multi_selection_input}
          disabled={!isEditable}
          style={{
            pointerEvents: isEditable ? 'auto' : 'none',
          }}
        >
          <Stack>
            {questions?.multi_selection_input_choices_validation?.map(
              (item, inx) => {
                return (
                  <Checkbox key={inx} value={item} disabled={!isEditable}>
                    {item}
                  </Checkbox>
                );
              },
            )}
          </Stack>
        </CheckboxGroup> */}
      </AppBox>
      <AppText
        text={
          error?.data?.answers_data[index]?.answer_multi_selection_input ||
          error?.data?.answers_data[index]?.non_field_errors
        }
        variant="errorMessage"
      />
    </Stack>
  );
};

export default memo(MultiSelectionField);
