import { Box, Flex, Text } from '@chakra-ui/react';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { capitalize } from 'constants';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  cutout: 65,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const COLORS = ['#FF5E5E', '#27B1FF', '#F2C962', '#FF8042'];
export function DoughChart({ reportdata }) {
  const plugins = [
    {
      id: 'dough',
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();

        var fontSize = 16;
        ctx.font = fontSize + 'px sans-serif';
        ctx.fillStyle = '#4D4F5C';

        ctx.textAlign = 'center';
        const sum = chart.data.datasets[0].data.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0,
        );

        var text = `All Projects`,
          textX = width / 2,
          textY = height / 2 + chart.chartArea.top;
        ctx.fillText(text, textX, textY);
        ctx.restore();

        ctx.font = fontSize + 'px sans-serif';
        ctx.fillStyle = '#4D4F5C';
        ctx.textAlign = 'center';
        ctx.fillText(
          `${sum > 100 ? Math.floor(sum) : Math.ceil(sum)}`,
          textX,
          textY + 30,
        );

        ctx.save();
      },
    },
  ];
  const data = {
    labels: reportdata.map((item) => capitalize(item.display)),
    datasets: [
      {
        label: '# of Votes',
        data: reportdata.map((item) => item.value),
        backgroundColor: COLORS,
        borderColor: COLORS,
        borderWidth: 0,
      },
    ],
  };
  return (
    <Box>
      {reportdata.length !== 0 && (
        <Flex
          gap="40px"
          width="100%"
          flexWrap="wrap"
          className="doughnutgraph"
          alignItems="center"
          py="20px"
        >
          <Box flex={1}>
            <Box width="200px" height="200px" margin="auto">
              <Doughnut data={data} options={options} plugins={plugins} />
            </Box>
          </Box>
          <Box flex={1}>
            <Flex
              flexDirection="column"
              justifyContent="center"
              gap="20px"
              flexWrap="wrap"
              height="220px"
              width="auto"
              flex="auto"
            >
              {reportdata.map((item, index) => {
                return (
                  <Flex
                    alignItems="center"
                    gap="13px"
                    flexDirection="row"
                    key={item.display}
                  >
                    <Box
                      width="30px"
                      height="30px"
                      background={COLORS[index]}
                      borderRadius="3px"
                    ></Box>
                    <Box display="flex" flexDirection="column">
                      <Text color="#B1B1B1">{capitalize(item.display)}</Text>
                      <Text>{item.value}</Text>
                    </Box>
                  </Flex>
                );
              })}
            </Flex>
          </Box>
        </Flex>
      )}
      {reportdata.length === 0 && (
        <Flex alignItems="center" padding="40px" justifyContent="center">
          Data Not Available
        </Flex>
      )}
    </Box>
  );
}
