import { Flex, Spinner } from '@chakra-ui/react';
import { AUTH_SIGNUP_MAGIC_LINK_VERIFY } from 'api/urls';
import makePostRequest from 'api/utilities/makePostRequest';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import { ADD_TEAM_MEMBERS_SCREEN } from 'navigation/routes/auth';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const AppUserSignUpMagicLinkVerification = () => {
  const navigate = useNavigate();
  const params = useParams();
  const verificationToken = params.verificationToken;
  const { mutate, isLoading, error } = useMutation(
    AUTH_SIGNUP_MAGIC_LINK_VERIFY(verificationToken),
    () => makePostRequest(AUTH_SIGNUP_MAGIC_LINK_VERIFY(verificationToken)),
    {
      onSuccess: () => {
        navigate(ADD_TEAM_MEMBERS_SCREEN);
      },
    },
  );

  useEffect(() => {
    if (!isLoading) {
      mutate();
    }
  }, []);

  return (
    <Flex
      height="100vh"
      alignItems="center"
      justifyContent="center"
      flexFlow="column"
    >
      <AppHeader text="Kindly wait while we verify your email" />

      {isLoading && (
        <>
          <AppText
            text={
              'The page will be automatically redirected once the verification is done'
            }
          />
          <Spinner mt={5} />
        </>
      )}

      <AppText
        text={error?.response?.data?.data?.detail}
        variant="errorMessage"
      />
    </Flex>
  );
};

export default AppUserSignUpMagicLinkVerification;
