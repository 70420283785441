import { Box, Flex, Select, useToast } from '@chakra-ui/react';
import { GET_ORGANSATION_SCHEMA, INVITE_TEAM_MEMBERS } from 'api/urls';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AuthCard from 'components/AuthCard/AuthCard';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import AuthLayout from 'layout/AuthLayout';
import { DOWNLOAD_MOBILE_APP_SCREEN } from 'navigation/routes/auth';
import RedirectionCTA from 'pages/Auth/components/RedirectionCTA/RedirectionCTA';
import StepHelper from 'pages/Auth/components/StepHelper/StepHelper';
import TermsAndConditionsText from 'pages/Auth/components/TermsAndConditionsText/TermsAndConditionsText';
import React, { useEffect, useState } from 'react';
import { MdEmail } from 'react-icons/md';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const GetOptionForRoles = ({ data }) => {
  return data?.map((roleData) => (
    <option key={roleData.id} value={roleData.id}>
      {roleData.identity}
    </option>
  ));
};
const AddTeamMemberScreen = () => {
  const [formValues, setFormValues] = useState({});
  const navigate = useNavigate();

  const { data: organisationMeta, isLoading: organisationMetaIsLoading } =
    useQuery(GET_ORGANSATION_SCHEMA, () =>
      makeGetRequest(GET_ORGANSATION_SCHEMA),
    );

  const toast = useToast();
  const {
    mutate: inviteTeamMemberMutation,
    isLoading: inviteTeamMembersLoading,
    isSuccess: inviteTeamMembersIsSuccess,
    error: inviteTeamMembersError,
  } = useMutation(INVITE_TEAM_MEMBERS, (formBody) =>
    makePostRequest(INVITE_TEAM_MEMBERS, formBody),
  );

  const handleInviteTeamMembers = (e) => {
    // TODO: Refactor later to accumulate `n` number of users
    e.preventDefault();

    const reqBody = {
      team_members: [
        {
          email: formValues['team_member_email_1'],
          role_id: formValues['team_member_role_1'],
        },
        {
          email: formValues['team_member_email_2'],
          role_id: formValues['team_member_role_2'],
        },
      ],
    };

    inviteTeamMemberMutation(reqBody);
  };

  useEffect(() => {
    if (inviteTeamMembersIsSuccess) {
      toast({
        title: 'Invite Sent Successfully',
        status: 'success',
        duration: 3000,
      });

      navigate(DOWNLOAD_MOBILE_APP_SCREEN);
    }
  }, [inviteTeamMembersIsSuccess]);

  return (
    <AuthLayout>
      <AuthCard isLoading={organisationMetaIsLoading}>
        <StepHelper totalCount={3} currentStep={2} />
        <AppHeader text={'Welcome My CTO Live!'} />
        <Flex flexFlow="column" minH={270} justifyContent="space-between">
          <AppText
            text="Let’s get started by adding a few team members"
            size="sm"
            customStyles={{
              textAlign: 'center',
            }}
          />

          <Box>
            <form onSubmit={handleInviteTeamMembers}>
              <Flex>
                <AppInput
                  fieldPlaceholder={'Team Member'}
                  fieldType={'email'}
                  fieldName={'team_member_email_1'}
                  icon={MdEmail}
                  customStyles={{
                    flexBasis: '65%',
                  }}
                  isRequired
                  formValues={formValues}
                  setFormValues={setFormValues}
                  fieldErrorMessage={
                    inviteTeamMembersError?.response?.data?.data
                      ?.team_members?.[0]?.email
                  }
                />
                <Select
                  placeholder="Roles"
                  variant="filled"
                  size="sm"
                  flexBasis={'35%'}
                  ml={5}
                  name="team_member_role_1"
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  required
                >
                  <GetOptionForRoles data={organisationMeta?.data?.roles} />
                </Select>
              </Flex>

              <Flex>
                <AppInput
                  fieldPlaceholder={'Team Member'}
                  fieldType={'email'}
                  fieldName={'team_member_email_2'}
                  icon={MdEmail}
                  customStyles={{
                    flexBasis: '65%',
                  }}
                  isRequired
                  formValues={formValues}
                  setFormValues={setFormValues}
                  fieldErrorMessage={
                    inviteTeamMembersError?.response?.data?.data
                      ?.team_members?.[0]?.email
                  }
                />
                <Select
                  placeholder="Roles"
                  variant="filled"
                  size="sm"
                  flexBasis={'35%'}
                  ml={5}
                  name="team_member_role_2"
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  required
                >
                  <GetOptionForRoles data={organisationMeta?.data?.roles} />
                </Select>
              </Flex>
              <AppButton
                isLoading={inviteTeamMembersLoading}
                type="submit"
                text={'Add Team Members'}
              />
            </form>
          </Box>
        </Flex>
        <TermsAndConditionsText
          customStyles={{ display: 'flex', gap: '5px' }}
        />
        <RedirectionCTA
          titleText={''}
          ctaText={'Skip for now'}
          ctaLink={DOWNLOAD_MOBILE_APP_SCREEN}
        />{' '}
      </AuthCard>
    </AuthLayout>
  );
};

export default AddTeamMemberScreen;
