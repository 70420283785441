/* eslint-disable no-dupe-else-if */
import { useToast } from '@chakra-ui/react';
import {
  GET_PROJECT_CREATION_META,
  PROJECT_CUD,
  UPLOAD_PROJECT_BANNER_IMAGE,
} from 'api/urls/projects';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import AppButton from 'components/AppButton/AppButton';
import AppFileInput from 'components/AppFileInput/AppFileInput';
import AppInput from 'components/AppInput/AppInput';
import AppInputDate from 'components/AppInputDate/AppInputDate';
import AppSelect from 'components/AppSelect/AppSelect';
import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdArrowDropDown } from 'react-icons/md';
import { useMutation, useQuery } from 'react-query';
import appColors from 'theme/appColors';
import { fontSizes } from 'theme/typographyStyles';

const AddNewProject = ({ onClose, getTableDataMutation }) => {
  const formFields = [
    {
      fieldPlaceholder: 'Project name',
      fieldType: 'text',
      fieldName: 'identity',
      isRequired: true,
    },
    // {
    //   fieldPlaceholder: 'Project address',
    //   fieldType: 'text',
    //   fieldName: 'address',
    //   isRequired: true,
    // },
  ];

  const formSelectFields = [
    {
      fieldPlaceholder: 'Select project type',
      fieldName: 'project_type',
      fieldOptions: 'project_types',
      isRequired: true,
    },
    {
      fieldName: 'survey_type',
      fieldPlaceholder: 'Select survey',
      fieldOptions: 'survey_types',
      isRequired: true,
    },
    {
      fieldName: 'form_template',
      fieldPlaceholder: 'Select form template',
      fieldOptions: 'form_templates',
      isRequired: true,
    },
  ];

  const formDateFields = [
    {
      fieldPlaceholder: 'From Date',
      fieldName: 'survey_from_date',
      isRequired: true,
    },
    {
      fieldPlaceholder: 'To Date',
      fieldName: 'survey_to_date',
      isRequired: true,
    },
  ];

  const formStatusField = [
    {
      fieldPlaceholder: 'Status',
      fieldName: 'status',
      fieldOptions: 'statusOptions',
      isRequired: true,
    },
  ];

  const [address, setAddress] = useState(null);

  const { data: projectMetaData, isLoading: projectMetaIsLoading } = useQuery(
    GET_PROJECT_CREATION_META,
    () => makeGetRequest(GET_PROJECT_CREATION_META),
  );

  const {
    mutate: projectCreationMutation,
    error: projectCreationError,
    isLoading: projectCreationIsLoading,
    data: projectCreationData,
  } = useMutation(
    PROJECT_CUD,
    (formBody) => makePostRequest(PROJECT_CUD, formBody),
    {
      onSuccess: () => {
        getTableDataMutation();
      },
    },
  );
  const [formValues, setFormValues] = useState({
    identity: '',
    address: '',
    banner_image: '',
    project_type: '',
    survey_type: '',
    form_template: '',
    status: '',
  });

  const [formSelectOptions, setFormSelectOptions] = useState(null);

  const toast = useToast();

  const handleFormSubmit = (event) => {
    event.preventDefault();

    projectCreationMutation({
      ...formValues,
      address: address?.value?.description,
      banner_image: formValues.banner_image || null,
    });
  };

  useEffect(() => {
    if (!projectMetaIsLoading && projectMetaData) {
      setFormSelectOptions({
        form_templates: projectMetaData?.data?.meta?.form_templates,
        project_types: projectMetaData?.data?.meta?.project_types,
        statusOptions: projectMetaData?.data?.meta?.statuses,
      });
    }
    if (formValues.project_type) {
      setFormSelectOptions((prevState) => ({
        ...prevState,
        survey_types: projectMetaData?.data.meta.survey_types.filter(
          (data) => data.project_type === formValues.project_type,
        ),
      }));
    }
    if (formValues.project_type && formValues.survey_type) {
      const filterData = projectMetaData?.data.meta.form_templates.filter(
        (data) =>
          data.project_type === formValues.project_type &&
          data.survey_type === formValues.survey_type,
      );

      setFormSelectOptions((prevState) => ({
        ...prevState,
        form_templates: filterData,
      }));
    }
  }, [projectMetaIsLoading, projectMetaData, formValues]);

  useEffect(() => {
    if (!projectCreationIsLoading && projectCreationData) {
      toast({
        title: 'Project created.',
        description: `${projectCreationData?.data?.identity} is created`,
        status: 'success',
        duration: 3000,
      });
      onClose();
    }
  }, [projectCreationIsLoading, projectCreationData]);

  useEffect(() => {
    if (address) {
      geocodeByAddress(address?.value?.description)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          console.log('Successfully got latitude and longitude', { lat, lng }),
        );
    }
  }, [address]);

  return (
    <form onSubmit={handleFormSubmit}>
      {formFields.map((field) => (
        <>
          <AppInput
            {...field}
            key={field.fieldName}
            formValues={formValues}
            setFormValues={setFormValues}
            fieldError={projectCreationError?.response?.data}
          />
        </>
      ))}

      <GooglePlacesAutocomplete
        apiKey="AIzaSyBm3jAe3XJzja-3Q7dhW5o0pTeV5f7xoSs"
        selectProps={{
          address,
          onChange: setAddress,
          isRequired: true,
          placeholder: 'Type address',
          className: 'app-select',
          noOptionsMessage: ({ inputValue }) =>
            !inputValue ? 'Enter address' : 'No results found',
          styles: {
            control: (provided) => ({
              ...provided,
              marginBottom: '20px',
              height: '50px',
              color: appColors.brandGray['500'],
              backgroundColor: address?.value?.description
                ? appColors.brandGray['selectedField']
                : appColors.brandTeal['300'],
              border: projectCreationError?.response?.data?.data?.address?.[0]
                ? `1px solid ${appColors.brandRed['error']}`
                : 'none',
            }),
            input: (provided) => ({
              ...provided,
              fontSize: fontSizes.sm,
            }),
            option: (provided) => ({
              ...provided,
              color: appColors.brandGray['500'],
            }),
            singleValue: (provided) => ({
              ...provided,
              color: appColors.brandGray['500'],
            }),
          },
        }}
      />

      <AppFileInput
        fieldPlaceholder="Project image"
        fieldName="banner_image"
        id="banner_image"
        formValues={formValues}
        setFormValues={setFormValues}
        icon={AiOutlineCloudUpload}
        uploadEndpoint={UPLOAD_PROJECT_BANNER_IMAGE}
        allowedFileTypes=".jpg,.jpeg,.png"
        fieldError={projectCreationError?.response?.data}
      />

      {formSelectFields?.map((field, i) => (
        <div style={{ marginBottom: '1.25rem' }} key={i}>
          <AppSelect
            variant="filled"
            fieldName={field.fieldName}
            icon={MdArrowDropDown}
            customStyles={{
              fontSize: '14px',
            }}
            formValues={formValues}
            setFormValues={setFormValues}
            fieldError={projectCreationError?.response?.data}
            isRequired={field.isRequired}
          >
            <option value="" hidden>
              {field.fieldPlaceholder}
            </option>
            {formSelectOptions?.[field.fieldOptions] ? (
              formSelectOptions?.[field.fieldOptions]?.map((data) => {
                return (
                  <>
                    <option key={data.id} value={data.id}>
                      {data.identity}
                    </option>
                  </>
                );
              })
            ) : (
              <option disabled>No Options Available</option>
            )}
          </AppSelect>
        </div>
      ))}
      {formDateFields?.map((field) => {
        return (
          <AppInputDate
            {...field}
            key={field.fieldName}
            formValues={formValues}
            setFormValues={setFormValues}
            margin="0px 0px 20px 0px"
            startDate={formValues?.survey_from_date}
            toDate={formValues?.survey_to_date}
            startDateKey="survey_to_date"
            toDateKey="survey_from_date"
          />
        );
      })}

      {formStatusField?.map((field, i) => (
        <div style={{ marginBottom: '1.25rem' }} key={i}>
          <AppSelect
            variant="filled"
            fieldName={field.fieldName}
            icon={MdArrowDropDown}
            customStyles={{
              fontSize: '14px',
            }}
            formValues={formValues}
            setFormValues={setFormValues}
            fieldError={projectCreationError?.response?.data}
            isRequired={field.isRequired}
          >
            <option value="" hidden>
              {field.fieldPlaceholder}
            </option>
            {formSelectOptions?.[field.fieldOptions] ? (
              formSelectOptions?.[field.fieldOptions]?.map((data) => {
                return (
                  <>
                    <option key={data.id} value={data.id}>
                      {data.identity}
                    </option>
                  </>
                );
              })
            ) : (
              <option disabled>No Options Available</option>
            )}
          </AppSelect>
        </div>
      ))}

      <AppButton
        type="submit"
        text="Create Project"
        customStyles={{ margin: '0px 0px 27px 0px' }}
        isLoading={projectCreationIsLoading}
      />
    </form>
  );
};

export default AddNewProject;
