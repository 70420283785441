import { Flex } from '@chakra-ui/react';
import Card from 'components/AppCard/AppCard';
import CustomTable from 'components/AppTable';
import AppHeader from 'components/typography/AppHeader';
import DashboardLayout from 'layout/DashboardLayout';
import { workFlowData } from 'pages/appSettings/project/Data';
import React from 'react';

const WorkFlow = () => {
  const columns = [
    {
      Header: 'Workflow No',
      accessor: 'no',
    },
    {
      Header: 'Workflow order',
      accessor: 'order',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
  ];

  return (
    <DashboardLayout>
      <Flex>
        <AppHeader
          text="Workflow configuration"
          variant="heading"
          customStyles={{ margin: '0px', textAlign: 'start' }}
        />
      </Flex>

      <Card variant="tableCard">
        <CustomTable columns={columns} data={workFlowData} check={false} />
      </Card>
    </DashboardLayout>
  );
};

export default WorkFlow;
