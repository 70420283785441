import { Flex } from '@chakra-ui/react';
import StepperText from 'components/typography/StepperText';
import {
  RoundedButton,
  StepLine,
} from 'pages/Auth/components/StepHelper/StepHelper.styles';
import React from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import appColors from 'theme/appColors';

const totalItems = [
  {
    id: 1,
    text: 'your first project',
    textBold: 'Create',
  },
  {
    id: 2,
    text: 'your project types',
    textBold: 'Customize',
  },
  {
    id: 3,
    text: 'your survey type template',
    textBold: 'Customize',
  },
  {
    id: 4,
    text: 'the project to your surveyor ',
    textBold: 'Assign',
  },
];

const AppStepper = ({ totalCount, currentStep, check }) => {
  let margin;
  let mb;
  let positionTop;
  let positionLeft;
  let width;
  let height;
  let direction;

  if (check === 'dashStepper') {
    margin = '34px';
    mb = 5;
    positionTop = '25px';
    positionLeft = '12px';
    width = '2px';
    height = '34px';
    direction = 'column';
  } else {
    margin = '';
    mb = 0;
    positionTop = '';
    positionLeft = '';
    width = '';
    height = '';
    direction = 'row';
  }

  return (
    <Flex justifyContent="center" mb={mb} flexDirection={direction}>
      {totalItems.map((ele, index) => {
        const currentItem = index + 1;
        const isNumberActive = currentItem <= currentStep;
        const isStepLineActive = currentItem < currentStep;

        return (
          <>
            <div style={{ display: 'flex' }}>
              <RoundedButton
                key={index}
                isActive={isNumberActive}
                style={{ marginBottom: margin, width: '30px' }}
              >
                {currentItem}
                {currentItem !== totalCount && (
                  <StepLine
                    isActive={isStepLineActive}
                    style={{
                      top: positionTop,
                      width: width,
                      height: height,
                      left: positionLeft,
                    }}
                  />
                )}
              </RoundedButton>
              {check === 'dashStepper' && (
                <Link
                  to="#"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '0px 0px 34px 0px',
                    width: '100%',
                  }}
                >
                  <StepperText
                    text={ele.text}
                    textBold={ele.textBold + '\xa0'}
                  />

                  <AiOutlineRight color={appColors.brandBlack['600']} />
                </Link>
              )}
            </div>
          </>
        );
      })}
    </Flex>
  );
};

export default AppStepper;
