import {
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Select,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import AppText from 'components/typography/AppText';
import React from 'react';
import appColors from 'theme/appColors';

styled.div`
  width: 20px !important;
`;

const AppSelect = ({
  variant,
  placeholder,
  icon,
  customStyles,
  formValues,
  setFormValues,
  children,
  fieldName,
  isRequired,
  fieldError,
  label,
  disabled = false,
  inputGroupPadding = 5,
  item,
  ...props
}) => {
  return (
    <FormControl
      isInvalid={Boolean(fieldError?.data?.[fieldName])}
      mb={inputGroupPadding}
    >
      <Select
        {...customStyles}
        icon={
          <Flex width="auto !important" justifyContent="flex-end">
            <AppText
              text={label}
              variant="innerLabelText"
              customStyles={{ width: 'auto' }}
            />
            <Icon as={icon} style={{ color: appColors.brandTeal['900'] }} />
          </Flex>
        }
        variant={variant}
        placeholder={placeholder}
        onChange={(event) => {
          if (item?.question?.field_type === 'regular') {
            setFormValues({
              ...formValues,
              [item?.question?.custom_id]: {
                ...item.answer,
                [fieldName]: event.target.value,
              },
            });
          } else if (item?.question?.field_type === 'cascade') {
            setFormValues({
              ...formValues,
              [item?.question?.custom_id]: {
                ...item.question,
                [fieldName]: event.target.value,
              },
            });
          } else {
            setFormValues({ ...formValues, [fieldName]: event.target.value });
          }
        }}
        // value={formValues?.[fieldName] ? formValues?.[fieldName] : ''}
        required={isRequired}
        disabled={disabled}
        sx={{
          '&': {
            color:
              formValues?.[fieldName] === ''
                ? appColors.brandGray['placeholderColor']
                : '#606060',
          },
          "& option[value='']": { color: '#606060' }, // placeholder *option* should be subtle (firefox)
          "& :not(option[value=''])": { color: '#606060' }, // all other *options* should be default text color
          backgroundColor: formValues?.[fieldName]
            ? appColors.brandGray['selectedField']
            : appColors.brandTeal['300'],
        }}
        {...props}
      >
        {children}
      </Select>
      <FormErrorMessage>{fieldError?.data?.[fieldName]}</FormErrorMessage>
    </FormControl>
  );
};

export default AppSelect;
