import { Flex, useDisclosure } from '@chakra-ui/react';
import { FORM_BUILDER_LIST } from 'api/urls/settings/formBuilder';
import useStore from 'AppZustand/store';
import AppAlertModal from 'components/AppAlertModal/AppAlertModal';
import AppButton from 'components/AppButton/AppButton';
import Card from 'components/AppCard/AppCard';
import AppLoader from 'components/AppLoader/AppLoader';
import AppModal from 'components/AppModal/AppModal';
import CustomTable from 'components/AppTable';
import AppHeader from 'components/typography/AppHeader';
import useGetTableFunctions from 'hooks/tableHelpers/useGetTableFunctions';
import DashboardLayout from 'layout/DashboardLayout';
import AddAndEditSurvey from 'pages/appSettings/surveyTypes/components/AddAndEditSurvey';
import React, { useEffect, useState } from 'react';
import { GrEdit } from 'react-icons/gr';
import { RiDeleteBinLine } from 'react-icons/ri';
import { timeStamp } from 'utils/TimeStampModifier';

import CreateAndEditSurvey from './components/CreateAndEditSurvey';
import SurveyFormDetails from './components/SurveyFormDetails';

const SurveyTemplates = () => {
  const columnsIdentity = {
    id: 'Id',
    identity: 'Name',
    description: 'Description',
    project_type__identity: 'Project Type',
    survey_type__identity: 'Survey Type',
    created_by__name: 'Created By',
    created: 'created',
  };

  const columnsData = [
    // {
    //   Header: 'Form Id',
    //   accessor: 'id',
    // },
    {
      Header: 'Name',
      accessor: 'identity',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Project Type',
      accessor: 'project_type.identity',
    },
    {
      Header: 'Survey Type',
      accessor: 'survey_type.identity',
    },
    {
      Header: 'Created By',
      accessor: 'created_by.name',
    },
    {
      Header: 'Created',
      accessor: 'created',
    },
  ];

  const [currentItem, setCurrentItem] = useState();

  const [surveyType, setSurveyType] = useState('');

  const { pageSize, fetchPageSize } = useStore((state) => state);

  useEffect(() => {
    getTableDataMutation();
    fetchPageSize(25);
  }, []);

  const {
    isOpen: createSurveytypeModalOpen,
    onOpen: createSurveytypeModalOnOpen,
    onClose: createSurveytypeModalOnClose,
  } = useDisclosure();

  const {
    isOpen: editSurveyTypeIsOpen,
    onOpen: editSurveyTypeOnOpen,
    onClose: editSurveyTypeOnClose,
  } = useDisclosure();

  const {
    isOpen: deleteSurveyTypeIsOpen,
    onOpen: deleteSurveyTypeOnOpen,
    onClose: deleteSurveyTypeOnClose,
  } = useDisclosure();

  const {
    isOpen: isPermissionAlertOpen,
    onClose: onPermissionAlertClose,
    onOpen: onPermissionAlertOpen,
  } = useDisclosure();

  const { permissions } = useStore((state) => state);

  const additionalColumns = [
    {
      Header: '',
      accessor: (originalRow) => (
        <Flex>
          <GrEdit
            cursor="pointer"
            style={{ marginRight: '24px' }}
            onClick={() => surveyEditHandler(originalRow)}
          />
          <RiDeleteBinLine
            cursor="pointer"
            onClick={() => surveyDeleteHandler(originalRow)}
          />
        </Flex>
      ),
      id: 'action_columns',
    },
  ];

  const surveyEditHandler = (originalRow) => {
    editSurveyTypeOnOpen();
    setCurrentItem(originalRow.id);
  };

  const surveyDeleteHandler = (originalRow) => {
    deleteSurveyTypeOnOpen();
    setCurrentItem(originalRow.id);
  };

  const { isTableLoading, tableData, getTableDataMutation, setSearchParams } =
    useGetTableFunctions({
      tableDataURL: FORM_BUILDER_LIST,
      columnsIdentity: columnsIdentity,
      additionalColumns: additionalColumns,
      permissionAlert: onPermissionAlertOpen,
    });

  useEffect(() => {
    setSearchParams(`?page-${pageSize}`);
  }, [pageSize]);

  return (
    <DashboardLayout menu={true} type="Settings" menuName="Survey Templates">
      <Flex justifyContent="space-between" alignItems="center">
        <AppHeader
          text="Survey Templates"
          variant="heading"
          customStyles={{ margin: '0px', textAlign: 'start' }}
        />
        {permissions.includes('survey_templates_manage') && (
          <AppButton
            text="+ Add New Template"
            customStyles={{ width: '157px' }}
            onClick={() => {
              setSurveyType('');
              createSurveytypeModalOnOpen();
            }}
            //onClick={() => navigate((APP_NEW_FORM_BUILDER()))}
          />
        )}
      </Flex>

      {permissions.includes('survey_templates_view') && (
        <>
          <Card variant="tableCard">
            <AppLoader isLoading={isTableLoading}>
              {!isTableLoading && (
                <CustomTable
                  columns={columnsData}
                  data={timeStamp(tableData?.data?.results) || []}
                  check={false}
                />
              )}
            </AppLoader>
          </Card>

          {!surveyType ? (
            <AppModal
              isOpen={createSurveytypeModalOpen}
              onClose={createSurveytypeModalOnClose}
              keyName="addOrChooseExistingOne"
              closeCheck={true}
              size={'auto'}
            >
              <CreateAndEditSurvey onSurveySelect={setSurveyType} />
            </AppModal>
          ) : (
            <AppModal
              isOpen={createSurveytypeModalOpen}
              onClose={createSurveytypeModalOnClose}
              keyName="surveyFormDetails"
              closeCheck={true}
              size={'auto'}
            >
              <SurveyFormDetails onClose={createSurveytypeModalOnClose} />
            </AppModal>
          )}
          {currentItem && (
            <AppModal
              isOpen={editSurveyTypeIsOpen}
              onClose={editSurveyTypeOnClose}
              keyName="edit"
              text="Edit Survey Type"
              closeCheck={true}
            >
              <AddAndEditSurvey
                isEdit
                onClose={editSurveyTypeOnClose}
                currentId={currentItem}
                getTableDataMutation={getTableDataMutation}
              />
            </AppModal>
          )}
          {currentItem && (
            <AppModal
              isOpen={deleteSurveyTypeIsOpen}
              onClose={deleteSurveyTypeOnClose}
              keyName="delete"
              text="Delete Survey Type"
              closeCheck={true}
            >
              <AddAndEditSurvey
                isDelete
                onClose={deleteSurveyTypeOnClose}
                currentId={currentItem}
                getTableDataMutation={getTableDataMutation}
              />
            </AppModal>
          )}
        </>
      )}
      <AppAlertModal
        isOpen={isPermissionAlertOpen}
        onClose={onPermissionAlertClose}
      />
    </DashboardLayout>
  );
};

export default SurveyTemplates;
