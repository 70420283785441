const PROFILE_META_DATA = '/api/settings/user/my-profile/';

const CHANGE_PASSWORD = '/api/settings/user/change-password/';

const EDIT_PROFILE_DATA = '/api/settings/user/my-profile/';

const EMAIL_EDIT_VERIFICATION = (userID) =>
  `/api/access/user/email/edit/${userID}/verify/`;

export {
  CHANGE_PASSWORD,
  EDIT_PROFILE_DATA,
  EMAIL_EDIT_VERIFICATION,
  PROFILE_META_DATA,
};
