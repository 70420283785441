import { Flex } from '@chakra-ui/react';
import AppInput from 'components/AppInput/AppInput';
import AppText from 'components/typography/AppText';
import React from 'react';

const ProperitiesRender = ({ properityForm, setProperityForm }) => {
  return (
    <>
      <AppText
        text={'Text'}
        customStyles={{ marginBottom: '0.5rem', display: 'flex' }}
        // isRequired={item.isRequired}
      />
      <AppInput
        fieldType="text"
        fieldName="name"
        formValues={properityForm}
        setFormValues={setProperityForm}
        // fieldError={error}
      />
      <Flex gap={'20px'}>
        <Flex flexDirection={'column'}>
          <AppText
            text={'X-Axis'}
            customStyles={{ marginBottom: '0.5rem', display: 'flex' }}
            // isRequired={item.isRequired}
          />
          <AppInput
            fieldType="text"
            fieldName="x"
            formValues={properityForm}
            setFormValues={setProperityForm}
            // fieldError={error}
          />
        </Flex>
        <Flex flexDirection={'column'}>
          <AppText
            text={'Y-Axis'}
            customStyles={{ marginBottom: '0.5rem', display: 'flex' }}
            // isRequired={item.isRequired}
          />
          <AppInput
            fieldType="text"
            fieldName="y"
            formValues={properityForm}
            setFormValues={setProperityForm}
            // fieldError={error}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default ProperitiesRender;
