import { WORKFLOW_CONFIG } from 'api/urls';
import makeGetRequest from 'api/utilities/makeGetRequest';
import { useQuery } from 'react-query';

const useWorkflowConfig = ({ projectsData, qcMetaData }) => {
  const {
    data: workflowConfig,
    // isLoading: tableDataIsLoading,
  } = useQuery(WORKFLOW_CONFIG, () => makeGetRequest(WORKFLOW_CONFIG));

  const getCurrentWorkflow =
    workflowConfig?.data?.workflow_movements_config[
      projectsData?.[0]?.current_workflow_state
    ];

  const fromState = projectsData?.[0]?.current_workflow_state;

  const nextStates = getCurrentWorkflow?.next_states;

  const getCurrentWorkflow2 =
    workflowConfig?.data?.workflow_movements_config[
      qcMetaData?.data?.current_workflow_state
    ];

  const fromState2 = qcMetaData?.data?.current_workflow_state;

  const nextStates2 = getCurrentWorkflow2?.next_states;

  const actionsList = [];

  Object.values(nextStates || {}).map((data) => actionsList.push(data));

  Object.values(nextStates2 || {}).map((data) => actionsList.push(data));

  return { actionsList, fromState, nextStates, fromState2, nextStates2 };
};

export default useWorkflowConfig;
