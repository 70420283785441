const GetOptionForSingleSelect = ({ data = [], initial }) => {
  return data.map((roleData) => {
    return (
      <option
        key={roleData.id}
        value={roleData.id}
        selected={roleData.id === initial}
      >
        {roleData.identity}
      </option>
    );
  });
};

export default GetOptionForSingleSelect;
