import {
  Flex,
  FormControl,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import {
  GET_USER_ROLES_CREATION_META,
  GET_USER_ROLES_LISTING,
  USER_ROLE_CUD,
} from 'api/urls/settings';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import { Select } from 'chakra-react-select';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import React, { useEffect, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const AddUserRole = ({ setState, option, onClose }) => {
  const [formValues, setFormValues] = useState({
    identity: '',
    permissions: [],
  });

  const [selectedFilters, setSelectedFilters] = useState([]);

  const formFields = [
    {
      fieldPlaceholder: 'Name',
      fieldType: 'text',
      fieldName: 'identity',
      isRequired: true,
    },
  ];

  const formSelectFields = [
    {
      fieldPlaceholder: 'Select permission',
      fieldName: 'permission',
      fieldOptions: 'permissions',
      isRequired: true,
    },
  ];

  const closeHandler = () => {
    onClose();
  };

  const queryClient = useQueryClient();
  const { data: userRolesMetaData, isLoading: userRolesMetaIsLoading } =
    useQuery(GET_USER_ROLES_CREATION_META, () =>
      makeGetRequest(GET_USER_ROLES_CREATION_META),
    );

  const {
    mutate: userRolesCreationMutation,
    error: userRolesCreationError,
    isLoading: userRolesCreationIsLoading,
    data: userRolesCreationData,
  } = useMutation(
    USER_ROLE_CUD(),
    (formBody) => makePostRequest(USER_ROLE_CUD(), formBody),
    {
      onSuccess: () => {
        setState({ ...option, type: 'Roles and permissions' });
        queryClient.invalidateQueries(GET_USER_ROLES_LISTING);
      },
    },
  );
  const [formSelectOptions, setFormSelectOptions] = useState(null);

  const toast = useToast();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    userRolesCreationMutation(formValues);
  };

  useEffect(() => {
    if (!userRolesMetaIsLoading && userRolesMetaData) {
      const modifyData = userRolesMetaData?.data?.meta?.permissions?.map(
        (data) => {
          return { label: data.identity, value: data.id };
        },
      );
      setFormSelectOptions({ permissions: modifyData });
    }
  }, [userRolesMetaIsLoading, userRolesMetaData]);

  useEffect(() => {
    if (!userRolesCreationIsLoading && userRolesCreationData) {
      toast({
        title: 'Created User Roles Successfully',
        status: 'success',
        duration: 3000,
      });
      onClose();
    }
  }, [userRolesCreationIsLoading, userRolesCreationData]);

  useEffect(() => {
    const permissions = [];

    selectedFilters?.permission?.map((data) => {
      return permissions.push(data.value);
    });

    setFormValues({ ...formValues, permissions: permissions });
  }, [selectedFilters]);

  return (
    <form onSubmit={handleFormSubmit}>
      {formFields.map((field) => (
        <>
          <AppInput
            {...field}
            key={field.fieldName}
            formValues={formValues}
            setFormValues={setFormValues}
            fieldError={userRolesCreationError?.response?.data}
          />
        </>
      ))}
      {formSelectFields.map((field, i) => (
        <FormControl
          style={{ marginBottom: '1.25rem' }}
          key={i}
          isInvalid={Boolean(
            userRolesCreationError?.response?.data?.data?.[
              field.fieldOptions
            ]?.[0],
          )}
        >
          <Select
            name={field.fieldName}
            isInvalid={Boolean(
              userRolesCreationError?.response?.data?.data?.[
                field.fieldOptions
              ]?.[0],
            )}
            options={formSelectOptions?.[field.fieldOptions]}
            isMulti
            variant="filled"
            placeholder={field.fieldPlaceholder}
            onChange={(ele, { name }) => {
              setSelectedFilters({
                ...selectedFilters,
                [name]: ele,
              });
            }}
            icon={MdArrowDropDown}
            customStyles={{ fontSize: '14px' }}
          />

          <FormErrorMessage>
            {
              userRolesCreationError?.response?.data?.data?.[
                field.fieldOptions
              ]?.[0]
            }
          </FormErrorMessage>
        </FormControl>
      ))}
      <Flex>
        <AppButton
          text="Cancel"
          variant="cancelBtn"
          customStyles={{ margin: '0px 22px 27px 22px' }}
          onClick={closeHandler}
        />
        <AppButton
          type="submit"
          text="Create"
          customStyles={{ margin: '0px 22px 27px 0px' }}
          isLoading={userRolesCreationIsLoading}
        />
      </Flex>
    </form>
  );
};

export default AddUserRole;
