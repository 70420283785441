const APP_PROJECTS = `/project`;
const APP_BULK_UPLOAD = (type = '') => `/bulk/upload/${type || ':type'}`;
const APP_PROJECTS_TYPES = '/project/types';
const APP_SURVEY_TYPES = '/survey/types';
const APP_SURVEY_TEMPLATES = '/survey/templates';
const APP_WORKFLOW = '/workflow';
const TEAM_MEMBERS = '/team_members';
const COMPANY_SETTING_UPDATE = '/company_setting/update';
const APP_EMAIL_TEMPLATES = '/email_templates';
const APP_EMAIL_TEMPLATES_ADD = '/email_templates/add';
const SURVEY_TYPE_IMAGES = (slug = '') => `/survey/images/${slug || ':slug'}`;
const APP_EMAIL_TEMPLATES_EDIT = (slug = '') =>
  `/email_templates/edit/${slug ? slug : ':slug'}`;
const APP_PROJECTS_PAGE = (slug = '') => `/project/page/${slug || ':slug'}`;
const APP_NEW_FORM_BUILDER = (slug = '') =>
  `/survey/form-builder/${slug || ':slug'}/new`;
const APP_REPORTS = '/reports';

const PDF_Builder_List = '/pdf_builder/list';
const PDF_Builder = (slug = '') => `/pdf_builder/${slug || ':slug'}`;

export {
  APP_BULK_UPLOAD,
  APP_EMAIL_TEMPLATES,
  APP_EMAIL_TEMPLATES_ADD,
  APP_EMAIL_TEMPLATES_EDIT,
  APP_NEW_FORM_BUILDER,
  APP_PROJECTS,
  APP_PROJECTS_PAGE,
  APP_PROJECTS_TYPES,
  APP_REPORTS,
  APP_SURVEY_TEMPLATES,
  APP_SURVEY_TYPES,
  APP_WORKFLOW,
  COMPANY_SETTING_UPDATE,
  PDF_Builder,
  PDF_Builder_List,
  SURVEY_TYPE_IMAGES,
  TEAM_MEMBERS,
};
