// QC screen

export const QC_SCREEN_DATA = (slug) => `api/qc-screen-data/${slug}/`;

export const ANSWER_QC_SCREEN_DATA = (slug) =>
  `api/answers-qc-screen-data/${slug}/form-group/`;

export const ANSWER_QC_SCREEN_DATA_SAVE = (slug) =>
  `api/qc-screen-data/answers/${slug}/bulk-edit/`;

export const QC_SCREEN_DATA_TO_PDF = (endpoint, useDb, slug) =>
  `${endpoint}api/project-module/project/${useDb}/${slug}/data-to-pdf/`;

export const QC_SCREEN_IMAGES_AS_ZIP = (slug) =>
  `api/qc-screen-data/${slug}/images-as-zip/`;

export const IMAGE_UPLOAD = (slug) =>
  `api/qc-screen/survey/${slug}/image/upload/`;

export const FILE_UPLOAD = (slug) =>
  `api/qc-screen/survey/${slug}/file/upload/`;
