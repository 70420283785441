import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import React from 'react';

const AppSwitch = ({
  label,
  customStyles,
  justifyContent = 'flex-end',
  fieldName,
  formValues,
  setFormValues,
  isChecked,
}) => {
  return (
    <FormControl
      display="flex"
      alignItems="center"
      justifyContent={justifyContent}
      mb={5}
    >
      <FormLabel mb="0" {...customStyles}>
        {label}
      </FormLabel>
      <Switch
        isChecked={isChecked}
        colorScheme="teal"
        onChange={(e) => {
          setFormValues({ ...formValues, [fieldName]: e.target.checked });
        }}
      />
    </FormControl>
  );
};

export default AppSwitch;
