import { Flex } from '@chakra-ui/react';
import { CHANGE_PASSWORD } from 'api/urls';
import makePostRequest from 'api/utilities/makePostRequest';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import React, { useState } from 'react';
import { useMutation } from 'react-query';

const AppChangePasswordForm = ({ onClose }) => {
  const [formValues, setFormValues] = useState({
    password: '',
    confirm_password: '',
  });

  const formFields = [
    {
      fieldPlaceholder: 'Password',
      fieldType: 'password',
      fieldName: 'password',
      isRequired: true,
    },
    {
      fieldPlaceholder: 'Confirm Password',
      fieldType: 'password',
      fieldName: 'confirm_password',
      isRequired: true,
    },
  ];

  const {
    mutate: changePasswordMutation,
    error: changePasswordError,
    isLoading: changePasswordIsLoading,
  } = useMutation(
    CHANGE_PASSWORD,
    (formBody) => makePostRequest(CHANGE_PASSWORD, formBody),
    {
      onSuccess: () => {
        onClose();
      },
    },
  );

  const handleFormSubmit = (event) => {
    event.preventDefault();
    changePasswordMutation(formValues);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {formFields.map((field) => {
        return (
          <AppInput
            {...field}
            key={field.fieldName}
            formValues={formValues}
            setFormValues={setFormValues}
            fieldError={changePasswordError?.response?.data}
          />
        );
      })}
      <Flex>
        <AppButton
          text="Cancel"
          variant="cancelBtn"
          customStyles={{ margin: '0px 22px 27px 22px' }}
          onClick={() => onClose()}
        />
        <AppButton
          type="submit"
          text="Save"
          customStyles={{ margin: '0px 22px 27px 0px' }}
          isLoading={changePasswordIsLoading}
        />
      </Flex>
    </form>
  );
};

export default AppChangePasswordForm;
