import { TEAM_MEMBERS } from 'navigation/routes';
import TeamMembers from 'pages/appSettings/teamMembers';
import { Route } from 'react-router-dom';

const TeamMembersRoutes = () => {
  return (
    <>
      <Route path={TEAM_MEMBERS} element={<TeamMembers />} />
    </>
  );
};

export default TeamMembersRoutes;
