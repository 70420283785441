import { Stack } from '@chakra-ui/react';
import AppBox from 'components/AppBox/AppBox';
import AppTextArea from 'components/AppTextArea/AppTextArea';
import AppText from 'components/typography/AppText';
import { memo } from 'react';

const TextAreaField = ({ isEditable, state, setState, error, index, item }) => {
  const placeholder_text = item?.question?.placeholder_text;
  const help_text = item?.question?.help_text;

  return (
    <Stack>
      <AppBox>
        <AppTextArea
          variant={'filled'}
          isRequired={true}
          formValues={state}
          setFormValues={setState}
          fieldPlaceholder={placeholder_text}
          fieldName={'answer_large_text_input'}
          disabled={!isEditable}
          groupPadding={'0px'}
          item={item}
        />
        <AppText
          text={help_text}
          customStyles={{
            fontSize: '12px',
            color: '#757575',
            lineHeight: '15.6px',
            padding: '10px 0 4px',
          }}
        />
      </AppBox>
      <AppText
        text={
          error?.data?.answers_data[index]?.answer_large_text_input ||
          error?.data?.answers_data[index]?.non_field_errors
        }
        variant="errorMessage"
      />
    </Stack>
  );
};

export default memo(TextAreaField);
