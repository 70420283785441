import isUserAuthenticated from 'api/utilities/isUserAuthenticated';
import useStore from 'AppZustand/store';
import AppLoader from 'components/AppLoader/AppLoader';
import { APP_DASHBOARD, APP_LOGIN_SCREEN } from 'navigation/routes';
import AuthRoutes from 'navigation/switch/authRoutes';
import dashboardRoutes from 'navigation/switch/dashboardRoutes';
import projectsRoutes from 'navigation/switch/projectsRoutes';
import TeamMembersRoutes from 'navigation/switch/teamMembersRoutes';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import CompanySettingRoutes from './companySettingRoutes';
import EmailRoutes from './emailRoutes';
import PdfBuilderRoutes from './pdfBuilderRoutes';
import ProfileRoutes from './profileRoutes';
import ReportsRoutes from './reportsRoutes';

const AppSwitch = () => {
  /**
   * Global Switch Handle
   * */

  const { fetchUseDb } = useStore((state) => state);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    isUserAuthenticated()
      .then((data) => {
        fetchUseDb(data.data.data.use_db);

        setIsAuthenticated(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
      });
  }, []);
  return (
    <Routes>
      {AuthRoutes()} {dashboardRoutes()} {projectsRoutes()}
      {TeamMembersRoutes()}
      {CompanySettingRoutes()}
      {EmailRoutes()}
      {ProfileRoutes()}
      {ReportsRoutes()}
      {PdfBuilderRoutes()}
      {isAuthenticated !== null ? (
        <>
          <Route
            path="/"
            element={
              <Navigate
                to={isAuthenticated ? APP_DASHBOARD : APP_LOGIN_SCREEN}
              />
            }
          />
        </>
      ) : (
        <Route path="/" element={<AppLoader />} />
      )}
    </Routes>
  );
};

export default AppSwitch;
