import { Flex, Td, Tr } from '@chakra-ui/react';
import AppButton from 'components/AppButton/AppButton';
import AppLoader from 'components/AppLoader/AppLoader';
import AppText from 'components/typography/AppText';

import AllQuestions from './fields';

const TDStyles = {
  border: 'none',
  paddingLeft: '0',
  boxSizing: 'content-box',
  display: 'block',
};

const TextStyles = {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '17px',
};

const TitleTextStyles = {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '17px',
};

const RoofForm = ({
  isEditable = false,
  slug,
  values,
  setValues,
  submitHandler,
  qcScreenEditIsLoading,
  projectDetails,
  setProjectData,
  error,
  qcAnswerMetaIsLoading,
  qcAnswerMetaRefetch,
  existQcAnswerData,
  qcData,
}) => {
  const data = qcData;

  let showLogicQuestion = {};

  return (
    <AppLoader isLoading={qcAnswerMetaIsLoading}>
      {data?.length > 0 ? (
        <>
          <Flex flexWrap="wrap" gap="30px" alignItems="flex-start">
            {data.map((item, idx) => {
              if (item?.question?.show_logic_field) {
                Object.assign(showLogicQuestion, {
                  [item?.question?.show_logic_field]:
                    values[item?.question?.show_logic_field],
                });
              }

              let showLogicQuestionId =
                showLogicQuestion[item?.question?.show_logic_field]?.question;

              if (
                showLogicQuestionId === item?.question?.show_logic_field &&
                showLogicQuestion[item?.question?.show_logic_field]
                  ?.answer_single_selection_input ===
                  item?.question?.show_logic_value &&
                item?.question?.show_logic_value_type === 'is'
              ) {
                return (
                  <>
                    <Tr key={idx}>
                      <Td style={TDStyles} w="230px">
                        <Flex>
                          <AppText
                            text={item?.question?.identity + ' ' || 'NA'}
                            customStyles={{
                              ...TextStyles,
                              marginBottom: '13px',
                              marginRight: '5px',
                            }}
                          />
                        </Flex>
                      </Td>

                      <AllQuestions
                        item={item}
                        isEditable={isEditable}
                        values={values}
                        setValues={setValues}
                        error={error}
                        idx={idx}
                        slug={slug}
                        customStyles={{ width: '256px' }}
                      />
                      <Flex key={idx} flexDirection="column">
                        <AppText
                          key={item}
                          text={
                            item?.question
                              ?.single_selection_group_input_validation
                              ?.identity
                          }
                          customStyles={{
                            ...TitleTextStyles,
                            marginBottom: '25px',
                            marginRight: '5px',
                          }}
                        />
                        <Flex
                          flexWrap="wrap"
                          gap="30px"
                          alignItems="flex-start"
                        >
                          {item?.answer_single_selection_group_input?.map(
                            (singleList) => {
                              return singleList?.map((cascadeItem, i) => {
                                return (
                                  <Flex key={i}>
                                    <Tr key={idx}>
                                      <Td style={TDStyles} w="230px">
                                        <Flex>
                                          <AppText
                                            text={
                                              cascadeItem?.question?.identity +
                                                ' ' || 'NA'
                                            }
                                            customStyles={{
                                              ...TextStyles,
                                              marginBottom: '13px',
                                              marginRight: '5px',
                                            }}
                                          />
                                        </Flex>
                                      </Td>

                                      <AllQuestions
                                        item={cascadeItem}
                                        isEditable={isEditable}
                                        values={values}
                                        setValues={setValues}
                                        cascadeAns={cascadeItem?.question}
                                        error={error}
                                        idx={idx}
                                        slug={slug}
                                        type="cascaded"
                                      />
                                    </Tr>
                                  </Flex>
                                );
                              });
                            },
                          )}
                        </Flex>
                      </Flex>
                    </Tr>
                  </>
                );
              } else if (
                showLogicQuestionId === item?.question?.show_logic_field &&
                showLogicQuestion[item?.question?.show_logic_field]
                  ?.answer_single_selection_input !==
                  item?.question?.show_logic_value &&
                item?.question?.show_logic_value_type === 'is_not'
              ) {
                return (
                  <>
                    <Tr key={idx}>
                      <Td style={TDStyles} w="230px">
                        <Flex>
                          <AppText
                            text={item?.question?.identity + ' ' || 'NA'}
                            customStyles={{
                              ...TextStyles,
                              marginBottom: '13px',
                              marginRight: '5px',
                            }}
                          />
                        </Flex>
                      </Td>

                      <AllQuestions
                        item={item}
                        isEditable={isEditable}
                        values={values}
                        setValues={setValues}
                        error={error}
                        idx={idx}
                        slug={slug}
                        customStyles={{ width: '256px' }}
                      />
                      <Flex key={idx} flexDirection="column">
                        <AppText
                          key={item}
                          text={
                            item?.question
                              ?.single_selection_group_input_validation
                              ?.identity
                          }
                          customStyles={{
                            ...TitleTextStyles,
                            marginBottom: '25px',
                            marginRight: '5px',
                          }}
                        />
                        <Flex
                          flexWrap="wrap"
                          gap="30px"
                          alignItems="flex-start"
                        >
                          {item?.answer_single_selection_group_input?.map(
                            (singleList) => {
                              return singleList?.map((cascadeItem, i) => {
                                return (
                                  <Flex key={i}>
                                    <Tr key={idx}>
                                      <Td style={TDStyles} w="230px">
                                        <Flex>
                                          <AppText
                                            text={
                                              cascadeItem?.question?.identity +
                                                ' ' || 'NA'
                                            }
                                            customStyles={{
                                              ...TextStyles,
                                              marginBottom: '13px',
                                              marginRight: '5px',
                                            }}
                                          />
                                        </Flex>
                                      </Td>

                                      <AllQuestions
                                        item={cascadeItem}
                                        isEditable={isEditable}
                                        values={values}
                                        setValues={setValues}
                                        cascadeAns={cascadeItem?.question}
                                        error={error}
                                        idx={idx}
                                        slug={slug}
                                        type="cascaded"
                                      />
                                    </Tr>
                                  </Flex>
                                );
                              });
                            },
                          )}
                        </Flex>
                      </Flex>
                    </Tr>
                  </>
                );
              } else if (!item?.question?.show_logic_field) {
                return (
                  <>
                    <Tr key={idx}>
                      <Td style={TDStyles} w="230px">
                        <Flex>
                          <AppText
                            text={item?.question?.identity + ' ' || 'NA'}
                            customStyles={{
                              ...TextStyles,
                              marginBottom: '13px',
                              marginRight: '5px',
                            }}
                          />
                        </Flex>
                      </Td>

                      <AllQuestions
                        item={item}
                        isEditable={isEditable}
                        values={values}
                        setValues={setValues}
                        error={error}
                        idx={idx}
                        slug={slug}
                        customStyles={{ width: '256px' }}
                      />
                      <Flex key={idx} flexDirection="column">
                        <AppText
                          key={item}
                          text={
                            item?.question
                              ?.single_selection_group_input_validation
                              ?.identity
                          }
                          customStyles={{
                            ...TitleTextStyles,
                            marginBottom: '25px',
                            marginRight: '5px',
                          }}
                        />
                        <Flex
                          flexWrap="wrap"
                          gap="30px"
                          alignItems="flex-start"
                        >
                          {item?.answer_single_selection_group_input?.map(
                            (singleList) => {
                              return singleList?.map((cascadeItem, i) => {
                                return (
                                  <Flex key={i}>
                                    <Tr key={idx}>
                                      <Td style={TDStyles} w="230px">
                                        <Flex>
                                          <AppText
                                            text={
                                              cascadeItem?.question?.identity +
                                                ' ' || 'NA'
                                            }
                                            customStyles={{
                                              ...TextStyles,
                                              marginBottom: '13px',
                                              marginRight: '5px',
                                            }}
                                          />
                                        </Flex>
                                      </Td>

                                      <AllQuestions
                                        item={cascadeItem}
                                        isEditable={isEditable}
                                        values={values}
                                        setValues={setValues}
                                        cascadeAns={cascadeItem?.question}
                                        error={error}
                                        idx={idx}
                                        slug={slug}
                                        type="cascaded"
                                      />
                                    </Tr>
                                  </Flex>
                                );
                              });
                            },
                          )}
                        </Flex>
                      </Flex>
                    </Tr>
                  </>
                );
              }
            })}
          </Flex>
        </>
      ) : null}
      {isEditable && (
        <Flex justifyContent="flex-end" gap="16px" mt={5}>
          <AppButton
            onClick={() => {
              qcAnswerMetaRefetch();
              setValues(existQcAnswerData);
              setProjectData({ ...projectDetails, isProjectEditable: false });
            }}
            variant="cancelBtn"
            text="Cancel"
            customStyles={{ width: '120px' }}
          />
          <AppButton
            onClick={submitHandler}
            isLoading={qcScreenEditIsLoading}
            text="Save"
            customStyles={{ width: '120px' }}
          />
        </Flex>
      )}
    </AppLoader>
  );
};

export default RoofForm;
