import { Stack } from '@chakra-ui/react';
import AppBox from 'components/AppBox/AppBox';
import AppInput from 'components/AppInput/AppInput';
import AppText from 'components/typography/AppText';
import { memo } from 'react';

const URLField = ({
  isEditable,
  state,
  setState,
  error,
  index,
  item,
  type,
  fieldName,
}) => {
  const placeholder_text = item?.question?.placeholder_text;
  const help_text = item?.question?.help_text;

  return (
    <Stack>
      <AppBox>
        <AppInput
          disabled={!isEditable}
          fieldPlaceholder={placeholder_text}
          variant={'filled'}
          fieldName={fieldName}
          formValues={state}
          setFormValues={setState}
          inputGroupPadding={'0px'}
          customStyles={{
            height: '40px',
            paddingBottom: '10px',
          }}
          item={item}
          type={type}
        />
        <AppText
          text={help_text}
          customStyles={{
            fontSize: '12px',
            color: '#757575',
            lineHeight: '15.6px',
            padding: '10px 0 4px',
          }}
        />
      </AppBox>

      <AppText
        text={
          error?.data?.answers_data[index]?.answer_url_input ||
          error?.data?.answers_data[index]?.non_field_errors
        }
        variant="errorMessage"
      />
    </Stack>
  );
};

export default memo(URLField);
