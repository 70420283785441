import { Flex } from '@chakra-ui/react';
import AppButton from 'components/AppButton/AppButton';
import Card from 'components/AppCard/AppCard';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import DashboardLayout from 'layout/DashboardLayout';
import {
  APP_EMAIL_TEMPLATES_ADD,
  APP_EMAIL_TEMPLATES_EDIT,
} from 'navigation/routes';
import React from 'react';
import { MdOutlineEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import appColors from 'theme/appColors';

import { emailTemplatesData } from './data';

const EmailTemplates = () => {
  const navigate = useNavigate();

  const editHandler = (id) => {
    navigate(APP_EMAIL_TEMPLATES_EDIT(id));
  };

  return (
    <DashboardLayout menu={true} type="Settings" menuName="Email Templates">
      <Flex justifyContent="space-between" alignItems="center">
        <AppHeader
          text="Email templates"
          variant="heading"
          customStyles={{ margin: '0px' }}
        />
        <AppButton
          text="+ Add New"
          customStyles={{ width: 'auto' }}
          onClick={() => navigate(APP_EMAIL_TEMPLATES_ADD)}
        />
      </Flex>
      <Flex flexDirection="column">
        {emailTemplatesData.map((data) => (
          <Card variant="emailCard" key={data.id}>
            <Flex alignItems="center" justifyContent="space-between">
              <div>
                <AppHeader
                  text={data.title}
                  variant="emailHeader"
                  customStyles={{ textAlign: 'start', margin: '0px' }}
                />
                <AppText text={data.content} variant="emailText" />
              </div>
              <Flex>
                <MdOutlineEdit
                  cursor="pointer"
                  style={{
                    marginRight: '24px',
                    width: '21px',
                    height: '21px',
                    color: appColors.brandGray['iconGrey'],
                  }}
                  onClick={() => editHandler(data.id)}
                />
                <RiDeleteBinLine
                  cursor="pointer"
                  style={{
                    width: '21px',
                    height: '21px',
                    color: appColors.brandGray['iconGrey'],
                  }}
                />
              </Flex>
            </Flex>
          </Card>
        ))}
      </Flex>
    </DashboardLayout>
  );
};

export default EmailTemplates;
