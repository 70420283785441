const POST_BULK_UPLOAD_USERS = '/api/bulk-upload/users/upload/';
const GET_BULK_UPLOADED_USERS = '/api/bulk-upload/users/help-file/';
const POST_BULK_UPLOAD_PROJECTS = '/api/bulk-upload/projects/upload/';
const GET_BULK_UPLOADED_PROJECTS = '/api/bulk-upload/projects/help-file/';

export {
  GET_BULK_UPLOADED_PROJECTS,
  GET_BULK_UPLOADED_USERS,
  POST_BULK_UPLOAD_PROJECTS,
  POST_BULK_UPLOAD_USERS,
};
