import AppSwitch from 'navigation/switch/appSwitch';

function App() {
  return (
    <div className="App">
      <AppSwitch />
    </div>
  );
}

export default App;
