import { Divider, Flex, Stack } from '@chakra-ui/react';
import makeGetRequest from 'api/utilities/makeGetRequest';
import { AddMemberIcon, WatchIcon } from 'assets';
import AppButton from 'components/AppButton/AppButton';
import Card from 'components/AppCard/AppCard';
import AppLoader from 'components/AppLoader/AppLoader';
import CustomTable from 'components/AppTable';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import DashboardLayout from 'layout/DashboardLayout';
import {
  APP_PROJECTS,
  GET_EXISTING_USER_PAGE,
  TEAM_MEMBERS,
} from 'navigation/routes';
import AppStepper from 'pages/appDashboard/components/AppStepper/AppStepper';
import { useEffect, useState } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import appColors from 'theme/appColors';

import AppCard from './components/AppCard/AppCard';

const AppDashboard = () => {
  const navigate = useNavigate();

  const [overView, setOverView] = useState({});
  const [surveyList, setSurveyList] = useState([]);

  const {
    mutate: getUserData,
    data: getUserMetaData,
    isLoading: getUserDataIsLoading,
  } = useMutation(
    GET_EXISTING_USER_PAGE,
    () => makeGetRequest(GET_EXISTING_USER_PAGE),
    {
      onSuccess: (response) => {
        setOverView(response.data.overview);
        setSurveyList(response.data.surveyor_list);
      },
    },
  );

  useEffect(() => {
    getUserData();
  }, []);

  const learnArr = [
    {
      id: 1,
      name: 'Quick start guide',
    },
    {
      id: 2,
      name: 'Form builder',
    },
    {
      id: 3,
      name: 'Adding team member',
    },
    {
      id: 4,
      name: 'PDF layout builder',
    },
    {
      id: 5,
      name: 'Creating new project',
    },
    {
      id: 6,
      name: 'Organisation switch',
    },
    {
      id: 7,
      name: 'Customizing templates',
    },
    {
      id: 8,
      name: 'Cascaded form',
    },
  ];
  const columns = [
    // {
    //   Header: 'User ID',
    //   accessor: 'user_id',
    // },
    {
      Header: 'User Name',
      accessor: 'user_name',
    },
    {
      Header: 'User Email ID',
      accessor: 'user_email',
    },
    {
      Header: 'Role',
      accessor: 'role.identity',
    },
    {
      Header: 'Survey Assigned',
      accessor: 'survey_assigned',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
  ];

  const dashboardData = [
    {
      id: 1,
      name: 'Survey Pending',
      value: overView?.survey_pending || '0',
    },
    {
      id: 2,
      name: 'Your Survey',
      value: overView?.your_survey || '0',
    },
    {
      id: 3,
      name: 'Survey Unassigned',
      value: overView?.survey_unassigned || '0',
    },
    {
      id: 4,
      name: 'Survey Completed',
      value: overView?.survey_completed || '0',
    },
  ];

  return (
    <DashboardLayout menu={false} type="Home">
      <AppLoader isLoading={getUserDataIsLoading}>
        {getUserMetaData?.data?.show_tutorial_for_project_creation ? (
          <>
            <Flex justifyContent="space-between" alignItems="center">
              <AppHeader
                text={`Welcome!`}
                variant="heading"
                customStyles={{ margin: '0px' }}
              />
              <AppButton
                text="+ Add New"
                customStyles={{ width: 'auto' }}
                onClick={() => navigate(APP_PROJECTS)}
              />
            </Flex>
            <Card variant="dashInviteCard">
              <div>
                <AppHeader
                  text="Invite Team Members"
                  variant="dashInviteHeading"
                  customStyles={{ textAlign: 'start' }}
                />
                <AppText text="Collaborate together and make the progress easier" />
              </div>

              <Link
                to={TEAM_MEMBERS}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img
                  src={AddMemberIcon}
                  alt="icon"
                  style={{ marginRight: '13px' }}
                />
                <AppText text="Add Members" variant="dashBtnText" />
              </Link>
            </Card>
            <Card variant="dashTutorialCard">
              <Stack direction="row">
                <Flex
                  flexDirection="column"
                  width="50%"
                  padding="15px 22px 15px 15px"
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom="30px"
                  >
                    <AppHeader
                      text="Your quick start guide"
                      variant="heading"
                      customStyles={{
                        margin: '0px',
                      }}
                    />

                    <AppButton
                      text="Watch tutorials"
                      customStyles={{ width: 'auto' }}
                    >
                      <img
                        src={WatchIcon}
                        alt="icon"
                        style={{ marginRight: '15px' }}
                      />
                    </AppButton>
                  </Flex>
                  <AppStepper
                    totalCount={4}
                    currentStep={1}
                    check="dashStepper"
                  />
                </Flex>
                <Divider orientation="vertical" height="auto" />
                <Flex
                  flexDirection="column"
                  width="50%"
                  padding="15px 15px 15px 22px"
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom="10px"
                  >
                    <AppHeader
                      text="Learn how to use"
                      variant="heading"
                      customStyles={{
                        margin: '0px',
                      }}
                    />
                  </Flex>
                  <Flex flexWrap="wrap" justifyContent="space-between">
                    {learnArr.map((data) => {
                      return (
                        <AppButton
                          key={data.id}
                          text={data.name}
                          check="dash"
                          variant="dashMenuBtn"
                        >
                          <AiOutlineRight color={appColors.brandBlack['600']} />
                        </AppButton>
                      );
                    })}
                  </Flex>
                </Flex>
              </Stack>
            </Card>
          </>
        ) : (
          <>
            <AppHeader
              text="Dashboard"
              variant="modalText"
              customStyles={{ textAlign: 'start', margin: '0px !important' }}
            />
            <Flex flexWrap="wrap" gap="60px">
              {dashboardData.map((data) => (
                <AppCard name={data.name} value={data.value} key={data.id} />
              ))}
            </Flex>
            <AppHeader
              text="Surveyor List"
              variant="modalText"
              customStyles={{
                textAlign: 'start',
                margin: '78px 0px 0px 0px !important',
              }}
            />
            <Card variant="tableCard">
              <AppLoader isLoading={false}>
                {true && (
                  <CustomTable
                    columns={columns}
                    data={surveyList}
                    check={false}
                  />
                )}
              </AppLoader>
            </Card>
          </>
        )}
      </AppLoader>
    </DashboardLayout>
  );
};

export default AppDashboard;
