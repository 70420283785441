import { Flex } from '@chakra-ui/react';
import {
  RoundedButton,
  StepLine,
} from 'pages/Auth/components/StepHelper/StepHelper.styles';

const StepHelper = ({ totalCount, currentStep }) => {
  const totalItems = new Array(totalCount).fill(0);
  return (
    <Flex justifyContent="center" mb={5}>
      {totalItems.map((ele, index) => {
        const currentItem = index + 1;
        const isNumberActive = currentItem <= currentStep;
        const isStepLineActive = currentItem < currentStep;

        return (
          <RoundedButton key={index} isActive={isNumberActive}>
            {currentItem}
            {currentItem !== totalCount && (
              <StepLine isActive={isStepLineActive} />
            )}
          </RoundedButton>
        );
      })}
    </Flex>
  );
};

export default StepHelper;
