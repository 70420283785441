import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { components, Select } from 'chakra-react-select';
import React from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import appColors from 'theme/appColors';

const MultiSelect = ({
  options,
  placeholder,
  setField,
  Field,
  fieldName,
  isRequired,
  margin,
  fieldError,
  isInvalid,
}) => {
  var result = options.map(function (el) {
    var obj = Object.assign({}, el);
    obj.value = el.identity || el.name;
    obj.label = el.identity || el.name;
    return obj;
  });

  var initialData = Field?.[fieldName]?.map(function (el) {
    var obj = Object.assign({}, el);
    obj.value = el.identity || el.name;
    obj.label = el.identity || el.name;
    return obj;
  });

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <MdArrowDropDown style={{ color: appColors.brandTeal['900'] }} />
      </components.DropdownIndicator>
    );
  };
  const chakraStyles = {
    container: (provided) => ({
      ...provided,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: appColors.brandGray['placeholderColor'],
      fontSize: '16px',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '1px',
      borderRadius: '8px',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '0px',
    }),
  };

  return (
    <FormControl margin={margin} isInvalid={isInvalid}>
      <Select
        name={fieldName}
        options={result}
        isMulti
        variant="filled"
        size="lg"
        className={'app-multi-select'}
        components={{ DropdownIndicator }}
        placeholder={placeholder}
        icon={MdArrowDropDown}
        onChange={(ele, { name }) => {
          let arr = ele.map((item) => {
            return { id: item.id, identity: item.identity || item.name };
          });
          setField({
            ...Field,
            [name]: [...arr],
          });
        }}
        value={initialData}
        isRequired={isRequired}
        chakraStyles={chakraStyles}
      />
      <FormErrorMessage>{fieldError?.data?.[fieldName]}</FormErrorMessage>
    </FormControl>
  );
};

export default MultiSelect;
