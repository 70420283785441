import { Text } from '@chakra-ui/react';
import React from 'react';
import appColors from 'theme/appColors';

const StepperText = ({ text, textBold, customStyles }) => {
  return (
    <Text {...customStyles} color={appColors.brandBlack['600']} display="flex">
      <Text fontWeight={700}>{textBold} </Text> {text}
    </Text>
  );
};

export default StepperText;
