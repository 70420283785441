import { Image, Stack } from '@chakra-ui/react';
//import { UPLOAD_PROJECT_BANNER_IMAGE } from 'api/urls/projects';
import AppBox from 'components/AppBox/AppBox';
//import AppFileInput from 'components/AppFileInput/AppFileInput';
import AppText from 'components/typography/AppText';
import { memo } from 'react';
//import { AiOutlineCloudUpload } from 'react-icons/ai';

const SignatureField = ({
  //isEditable,
  state,
  //setState,
  error,
  index,
  item,
}) => {
  return (
    <Stack>
      {/* {isEditable ? (
        <Stack>
          <AppFileInput
            disabled={!isEditable}
            fieldName="answer_image_attachment_input"
            id="attachment"
            formValues={state}
            setFormValues={setState}
            answerId={answers}
            icon={AiOutlineCloudUpload}
            allowedFileTypes=".jpg,.jpeg,.png"
            uploadEndpoint={UPLOAD_PROJECT_BANNER_IMAGE}
          />
        </Stack>
      ) : null} */}
      {state?.[item?.question?.custom_id]?.answer_signature_input?.file ? (
        <AppBox>
          <Image
            style={{
              height: '80px',
              width: '200px',
            }}
            objectFit="cover"
            src={
              state?.[item?.question?.custom_id]?.answer_signature_input?.file
            }
            alt="signature image"
          />
          <AppText
            text={item?.question?.help_text}
            customStyles={{
              fontSize: '12px',
              color: '#757575',
              lineHeight: '15.6px',
              padding: '10px 0 4px',
            }}
          />
        </AppBox>
      ) : null}
      {/* {state?.[answers]?.answer_signature_input?.map((item, index) => {
        if (index <= 1) {
          return (
            <Image
              key={index}
              style={{
                height: '70px',
                width: '80px',
                marginRight: '10px',
              }}
              objectFit="cover"
              src={item.file}
              alt="roof image"
            />
          );
        } else if (index === 2) {
          return (
            <Flex alignItems="center" position="relative">
              <Image
                key={index}
                style={{
                  height: '70px',
                  width: '80px',
                  marginRight: '10px',
                }}
                objectFit="cover"
                src={item.file}
                alt="roof image"
              />
              <Flex
                cursor="pointer"
                position="absolute"
                width="80px"
                height="70px"
                backgroundColor="rgba(0, 0, 0, 0.6)"
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  width="100%"
                >
                  <AppText
                    text={`+ ${
                      state?.[answers]?.answer_signature_input?.length -
                       2
                    }`}
                    customStyles={{
                      color: `${appColors.brandWhite['100']}`,
                      fontWeight: 700,
                    }}
                  />

                  <AppText
                    text="more"
                    customStyles={{
                      color: `${appColors.brandWhite['100']}`,
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          );
        }
      })} */}
      <AppText
        text={
          error?.data?.answers_data[index]?.answer_signature_input ||
          error?.data?.answers_data[index]?.non_field_errors
        }
        variant="errorMessage"
      />
    </Stack>
  );
};

export default memo(SignatureField);
