import { SEARCH_ALL } from 'api/urls';
import makeGetRequest from 'api/utilities/makeGetRequest';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

function useOverallSearch() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState(
    searchParams.get('search') ?? '',
  );

  const setSearchStringToQueryParam = (searchString) => {
    setSearchParams({ search: searchString });
    refetchSearch();
  };

  const { data: overallSearchResult, refetch: refetchSearch } = useQuery(
    SEARCH_ALL(searchInput),
    () => makeGetRequest(SEARCH_ALL(searchInput)),
    {
      enabled: false,
    },
  );

  return {
    overallSearchResult,
    searchInput,
    setSearchInput,
    setSearchStringToQueryParam,
  };
}
export default useOverallSearch;
