import {
  APP_BULK_UPLOAD,
  APP_NEW_FORM_BUILDER,
  APP_PROJECTS,
  APP_PROJECTS_PAGE,
  APP_PROJECTS_TYPES,
  APP_SURVEY_TEMPLATES,
  APP_SURVEY_TYPES,
  APP_WORKFLOW,
  SURVEY_TYPE_IMAGES,
} from 'navigation/routes';
import FormBuilder from 'pages/appSettings/formBuilder/FormBuilder';
import ProjectsList from 'pages/appSettings/project';
import PdfTemplate from 'pages/appSettings/project/components/pdfTemplate/PdfTemplate';
import BulkUpload from 'pages/appSettings/project/components/projectComponents/BulkUpload';
import ViewProject from 'pages/appSettings/project/components/ViewProject';
import ViewProjectImages from 'pages/appSettings/project/components/ViewProjectImages';
import ProjectTypes from 'pages/appSettings/projectTypes/ProjectTypes';
import SurveyTemplates from 'pages/appSettings/surveyTemplates/surveyTemplates';
import SurveyTypes from 'pages/appSettings/surveyTypes/SurveyTypes';
import WorkFlow from 'pages/appSettings/workFlow/WorkFlow';
import { Route } from 'react-router-dom';

const ProjectsRoutes = () => {
  return (
    <>
      <Route path={APP_PROJECTS} element={<ProjectsList />} />
      <Route path={APP_PROJECTS_TYPES} element={<ProjectTypes />} />
      <Route path={APP_SURVEY_TYPES} element={<SurveyTypes />} />
      <Route path={APP_SURVEY_TEMPLATES} element={<SurveyTemplates />} />
      <Route path={APP_WORKFLOW} element={<WorkFlow />} />
      <Route path={APP_BULK_UPLOAD()} element={<BulkUpload />} />
      <Route path={APP_PROJECTS_PAGE()} element={<ViewProject />} />
      <Route path={APP_NEW_FORM_BUILDER()} element={<FormBuilder />} />
      <Route path={SURVEY_TYPE_IMAGES()} element={<ViewProjectImages />} />
      <Route path={'/pdf/template'} element={<PdfTemplate />} />
    </>
  );
};

export default ProjectsRoutes;
