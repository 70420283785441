import { CloseButton, Flex } from '@chakra-ui/react';
import AppSearch from 'components/AppSearch/AppSearch';
import AppText from 'components/typography/AppText';
import React from 'react';
import { BsTable } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import appColors from 'theme/appColors';

const TableSettingHeader = ({
  setState,
  option,
  formValues,
  setFormValues,
  setOptionId,
}) => {
  return (
    <>
      <Flex>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex
            alignItems="center"
            borderBottom="1px solid #E0E6FF"
            paddingBottom="16px"
            width="190px"
          >
            <BsTable color={appColors.brandBlack['600']} />
            <AppText text="Table display order" variant="tableSettingText" />
          </Flex>
          <Flex
            alignItems="center"
            borderBottom="1px solid #E0E6FF"
            paddingBottom="5px"
            width="185px"
          >
            <AppSearch
              fieldPlaceholder="Search..."
              fieldType="text"
              fieldName="search"
              icon={<FiSearch color={appColors.brandGray['iconGrey']} />}
              formValues={formValues}
              setFormValues={setFormValues}
              variant="tableSearch"
              customStyles={{ paddingBottom: '10px' }}
              top="-9px"
            />
          </Flex>
        </Flex>
        <CloseButton
          marginLeft="10px"
          onClick={() => {
            setState({ ...option, setting: false });
            setOptionId('');
          }}
        />
      </Flex>
    </>
  );
};

export default TableSettingHeader;
