import create from 'zustand';

const useStore = create((set) => ({
  isAuthenticated: null,
  projectsCheckedValue: [],
  projectsCheckedId: [],
  projectsData: [],
  formBuilderDetails: {},
  chartTypes: '',
  charts: [],
  widgetMetaData: {},
  widgetOverallConfigData: {},
  useDb: '',
  permissions: [],
  companySettings: {},
  duplicateCompanySettings: {},
  companySettingsData: {},
  pageSize: 25,
  companySettingsAlert: true,
  fetchProjectsCheckedValue: (data) => {
    set({ projectsCheckedValue: data });
  },
  fetchProjectsCheckedId: (data) => {
    set({ projectsCheckedId: data });
  },
  fetchProjectsData: (data) => {
    set({ projectsData: data });
  },
  setIsAuthenticated: (data) => {
    set({ isAuthenticated: data });
  },
  fetchFormBuilderData: (data) => {
    set({ formBuilderDetails: data });
  },
  fetchChartTypes: (data) => {
    set({ chartTypes: data });
  },
  fetchUseDb: (data) => {
    set({ useDb: data });
  },
  fetchPermissions: (data) => {
    set({ permissions: data });
  },
  fetchPageSize: (data) => {
    set({ pageSize: data });
  },
  setCharts: (data) => {
    set({ charts: data });
  },
  setWidgetMetaData: (data) => {
    set({ widgetMetaData: data });
  },
  setWidgetOverallConfigData: (data) => {
    set({ widgetOverallConfigData: data });
  },
  setCompanySettings: (data) => {
    set({ companySettings: data });
  },
  setDuplicateCompanySettings: (data) => {
    set({ duplicateCompanySettings: data });
  },
  setCompanySettingsData: (data) => {
    set({ companySettingsData: data });
  },
  setCompanySettingsAlert: (data) => {
    set({ companySettingsAlert: data });
  },
}));

export default useStore;
