import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import AppText from 'components/typography/AppText';

export default function CustomModal({
  isOpen,
  onClose,
  children,
  text,
  closeCheck = false,
  closeText = false,
  variant = 'modalText',
  size = 'md',
  bodyCustomStyles = {},
}) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={size}>
        <ModalOverlay />

        <ModalContent
          width={size === 'auto' ? 'auto' : '100%'}
          maxWidth={size === 'auto' ? 'none' : '448px'}
        >
          {!closeText && <AppText text={text} variant={variant} />}

          {!closeCheck && (
            <ModalCloseButton marginRight="14px" marginTop="18px" zIndex="99" />
          )}

          <ModalBody style={{ ...bodyCustomStyles }}>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
