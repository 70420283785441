// Project Creation
export const GET_PROJECT_CREATION_META =
  '/api/project-module/project/cud/meta/';
export const PROJECT_CUD = `/api/project-module/project/cud/`;
export const UPLOAD_PROJECT_BANNER_IMAGE = `/api/project-module/project/banner-image/upload/`;

// Project Listing
export const GET_PROJECT_LISTING_META = `/api/project-module/project/list/table-meta/`;
export const GET_PROJECT_LISTING_DATA = (params = '') =>
  `/api/project-module/project/list/?${params}`;

// Project Listing Filter
export const GET_PROJECT_LISTING_FILTER_META = `/api/project-module/project/list/filter-meta/`;

// Project Listing Setting
export const PROJECT_LISTING_SETTING = `/api/project-module/project/list/table-columns-preference/`;

// Project List Export
export const PROJECT_TABLE_EXPORT = () =>
  `/api/project-module/project/list/sync-export/`;

// Project status change

export const PROJECT_STATUS_CHANGE = () =>
  `/api/project-module/project/change/status/`;

export const SEND_PDF_TO_EMAIL = () =>
  `/api/project-module/project/list/email-pdfs/`;

// Project Type Listing
export const GET_PROJECT_TYPE_LISTING_DATA = (params = '') =>
  `/api/project-meta-module/project-type/list/?${params}`;
export const GET_PROJECT_TYPE_LISTING_DATA_META =
  '/api/project-meta-module/project-type/list/table-meta/';

// Project Type Creation
export const PROJECT_TYPE_CUD = '/api/project-meta-module/project-type/cud/';
export const GET_PROJECT_TYPE_CREATION_META =
  '/api/project-meta-module/project-type/cud/meta/';

// Project Type Edit
export const GET_PROJECT_TYPE_EDIT_PREFILL_DATA = (slug) =>
  `/api/project-meta-module/project-type/cud/${slug}/meta/`;
export const GET_PROJECT_TYPE_EDIT_AND_DELETE = (slug) =>
  `/api/project-meta-module/project-type/cud/${slug}/`;

// Workflow

export const WORKFLOW_CONFIG = `/api/workflow/get-config/`;

export const GET_ACTION_DATA = '/api/workflow/movement/action-data/';

export const MOVE_PROJECTS = '/api/workflow/movement/execute/';
