import { Text } from '@chakra-ui/react';
import React from 'react';
import appColors from 'theme/appColors';

const AppText = ({
  text,
  size,
  customStyles,
  variant,
  onClick,
  title = '',
  isRequired,
}) => {
  return (
    <Text
      size={size}
      title={title}
      variant={variant}
      onClick={onClick}
      {...customStyles}
    >
      {text}
      {isRequired && (
        <Text style={{ marginLeft: '3px', color: appColors.brandRed['300'] }}>
          *
        </Text>
      )}
    </Text>
  );
};

export default AppText;
