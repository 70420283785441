import { Flex } from '@chakra-ui/react';
import React from 'react';
import appColors from 'theme/appColors';

const AppStatusText = ({ text, icon, customStyles }) => {
  return (
    <Flex
      border={`1px solid  ${appColors.brandTeal['active']}`}
      borderRadius="50px"
      justifyContent="center"
      alignItems="center"
      padding="4px 5px"
      style={{ ...customStyles }}
    >
      <Flex alignItems="center" justifyContent="center">
        {icon}
        <span style={{ color: appColors.brandTeal['active'] }}>{text}</span>
      </Flex>
    </Flex>
  );
};

export default AppStatusText;
