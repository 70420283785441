import {
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Tr,
} from '@chakra-ui/react';
import useStore from 'AppZustand/store';
import { TD, TH, THead } from 'chakraOverwrite/TableStyles';
import AppText from 'components/typography/AppText';
import {
  APP_NEW_FORM_BUILDER,
  APP_PROJECTS_PAGE,
  PDF_Builder,
} from 'navigation/routes';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePagination, useTable } from 'react-table';

import TableFooter from './components/TableFooter';

export default function CustomTable({ columns, data, check }) {
  const [checkedIds, setCheckedIds] = useState([]);

  const location = useLocation();

  const [projectData, setProjectData] = useState([]);

  const navigate = useNavigate();

  const { fetchProjectsCheckedId, fetchProjectsData, permissions } = useStore(
    (state) => state,
  );

  useEffect(() => {
    fetchProjectsCheckedId(checkedIds);
  }, [checkedIds]);

  useEffect(() => {
    fetchProjectsData(projectData);
  }, [projectData]);

  const onProjectNavigate = (id, row) => {
    navigate(APP_PROJECTS_PAGE(id), {
      state: { ...row.original },
    });
  };

  const onFormNavigate = (row) => {
    if (permissions.includes('survey_templates_manage')) {
      navigate(APP_NEW_FORM_BUILDER(row.original.id), {
        state: { ...row.original },
      });
    }
  };

  const onPDFNavigate = (row) => {
    navigate(PDF_Builder(row.original.id), {
      state: { ...row.original },
    });
  };

  const navigateHandler = (type, id, row) => {
    if (type === '/project') {
      onProjectNavigate(id, row);
    } else if (type === '/survey/templates') {
      onFormNavigate(row);
    } else if (type === '/pdf_builder/list') {
      onPDFNavigate(row);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25 },
    },
    usePagination,
  );
  return (
    <>
      <TableContainer>
        <Table {...getTableProps()} overflowX="scroll">
          <THead>
            {headerGroups.map((headerGroup, i) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {check && (
                  <TH>
                    <Checkbox
                      isChecked={
                        data.map((d) => d.id).length === 0
                          ? false
                          : checkedIds.length === data.map((d) => d.id).length
                      }
                      onChange={() => {
                        const dataIds = data.map((d) => d.id);
                        if (checkedIds.length === dataIds.length) {
                          setCheckedIds([]);
                        } else {
                          setCheckedIds(dataIds);
                        }
                      }}
                    ></Checkbox>
                  </TH>
                )}
                {headerGroup.headers.map((column, i) => (
                  <TH {...column.getHeaderProps()} key={i}>
                    {column.render('Header')}
                  </TH>
                ))}
              </Tr>
            ))}
          </THead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);

              return (
                <Tr {...row.getRowProps()} key={i}>
                  {check && (
                    <TD>
                      <Checkbox
                        isChecked={checkedIds.includes(row.original.id)}
                        onChange={() => {
                          const index = checkedIds.indexOf(row.original.id);

                          if (index > -1) {
                            setCheckedIds([
                              ...checkedIds.slice(0, index),
                              ...checkedIds.slice(index + 1),
                            ]);
                            setProjectData([]);
                          } else {
                            setCheckedIds([...checkedIds, row.original.id]);
                            setProjectData([...projectData, row.original]);
                          }
                        }}
                      ></Checkbox>
                    </TD>
                  )}
                  {row.cells.map((cell, index) => {
                    return (
                      <>
                        <TD
                          {...cell.getCellProps()}
                          key={index}
                          onClick={() =>
                            navigateHandler(
                              location.pathname,
                              cell.row.original.id,
                              cell.row,
                            )
                          }
                          style={{
                            cursor:
                              location.pathname === '/project' ||
                              location.pathname === '/survey/templates'
                                ? 'pointer'
                                : '',
                          }}
                        >
                          {cell.render('Cell')}
                        </TD>
                      </>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {data?.length === 0 && (
        <Flex justifyContent="center" width="100%" margin="16px 0px">
          <AppText text="No Data" variant="innerLabelText" />
        </Flex>
      )}
      <TableFooter
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
      />
    </>
  );
}
