const getHostAPIUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    // return 'https://api-staging.sitescribe.app';
    return 'https://api-staging.sitescribe.us/';
  }
  if (process.env.REACT_APP_NODE_ENV === 'uat') {
    // return 'https://api-uat.sitescribe.app';
    return 'https://api-uat.sitescribe.us/';
  }
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    return 'https://api-production.sitescribe.us/';
  }
  return 'https://api-staging.sitescribe.us/';
};
export { getHostAPIUrl };
