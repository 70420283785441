import appColors from 'theme/appColors';
import { fontSizes, fontWeights } from 'theme/typographyStyles';

const TextStyles = {
  sizes: {
    xs: {
      fontSize: fontSizes.xs,
      lineHeight: '17px',
      fontWeight: fontWeights.normal,
      color: appColors.brandGray['700'],
    },

    sm: {
      fontSize: fontSizes.sm,
      color: appColors.brandGray['700'],
    },

    xl: {
      fontSize: fontSizes.xl,
      lineHeight: '42px',
      fontWeight: fontWeights.bold,
      color: appColors.brandGray['900'],
    },
  },

  variants: {
    auth: {
      color: appColors.brandRed['400'],
      textAlign: 'center',
      marginBottom: 5,
    },
    dashBtnText: {
      fontSize: fontSizes['18'],
      fontWeight: '500',
      color: appColors.brandTeal['500'],
    },
    dashInnerName: {
      fontSize: fontSizes.sm,
      fontWeight: 700,
      color: '#B1B1B1',
      wordSpacing: '9rem',
    },
    dashInnerValue: {
      fontSize: fontSizes['36'],
      fontWeight: 700,
      color: appColors.brandTeal['500'],
    },
    heading: {
      fontSize: fontSizes['22'],
      color: appColors.brandBlack['600'],
    },
    dashInviteHeading: {
      fontSize: fontSizes['24'],
      fontWeight: '600',
      color: appColors.brandTeal['600'],
      marginBottom: '5px',
    },
    modalText: {
      fontWeight: 700,
      fontSize: fontSizes['22'],
      lineHeight: '17px',
      margin: '35px 0px 15px 24px',
      color: appColors.brandBlack['600'],
    },
    alertText: {
      fontSize: fontSizes['18'],
      fontWeight: '500',
      lineHeight: '17px',
      color: appColors.brandBlack['600'],
      margin: '15px 15px 0px 15px',
    },
    centerModalText: {
      fontWeight: 700,
      fontSize: fontSizes['22'],
      lineHeight: '17px',
      margin: '35px 0px',
      textAlign: 'center',
      color: appColors.brandBlack['600'],
    },
    filterText: {
      fontWeight: 500,
      fontSize: fontSizes.sm,
      lineHeight: '17px',
      color: appColors.brandBlack['600'],
    },
    tableSettingText: {
      fontWeight: 400,
      fontSize: fontSizes.sm,
      lineHeight: '17px',
      color: appColors.brandBlack['600'],
      marginLeft: '12px',
    },
    checkBoxText: {
      fontWeight: 400,
      fontSize: fontSizes.sm,
      lineHeight: '17px',
      color: appColors.brandBlack['600'],
    },
    alertMessageText: {
      fontWeight: 400,
      fontSize: fontSizes['16'],
      lineHeight: '17px',
      color: appColors.brandBlack['600'],
    },
    permissionsText: {
      fontWeight: 600,
      fontSize: fontSizes['16'],
      lineHeight: '150%',
      color: appColors.brandBlack['600'],
      marginTop: '30px',
    },
    errorMessage: {
      fontSize: fontSizes.sm,
      color: appColors.brandRed['400'],
      mt: 2,
    },
    removeText: {
      fontWeight: 700,
      fontSize: fontSizes.sm,
      color: appColors.brandTeal['800'],
      cursor: 'pointer',
    },
    emailHeader: {
      fontWeight: 700,
      fontSize: fontSizes.sm,
      color: appColors.brandBlack['600'],
      cursor: 'pointer',
    },
    emailText: {
      fontWeight: 400,
      fontSize: fontSizes.sm,
      lineHeight: '17px',
      color: appColors.brandBlack['600'],
    },
    innerLabelText: {
      fontWeight: 400,
      fontSize: fontSizes.sm,
      color: appColors.brandGray['500'],
    },
    errorText: {
      fontWeight: 400,
      fontSize: fontSizes.sm,
      marginTop: '8px',
      color: appColors.brandRed['error'],
    },
    labelText: {
      fontWeight: 600,
      fontSize: fontSizes.sm,
      color: appColors.brandGray['label'],
      textTransform: 'capitalize',
      marginBottom: '7px',
    },
    ValueText: {
      fontWeight: 500,
      fontSize: fontSizes.sm,
      color: appColors.brandBlack['600'],
    },
    paginationText: {
      fontWeight: 500,
      fontSize: fontSizes.sm,
      color: appColors.brandGray['paginationText'],
    },
    closeText: {
      fontSize: fontSizes['20'],
      fontWeight: 600,
      color: appColors.brandWhite['100'],
      position: 'absolute',
      top: '-40px',
      right: '0px',
      cursor: 'pointer',
    },
    addWidgetText: {
      textAlign: 'center',
      fontWeight: 700,
      fontSize: fontSizes['22'],
      lineHeight: '17px',
      margin: '35px 0px 15px 24px',
      color: appColors.brandBlack['600'],
    },
    addWidgetInnerText: {
      fontWeight: 700,
      fontSize: fontSizes['18'],
      color: appColors.brandWhite['100'],
      marginTop: '5px',
    },
    pieChartLabelText: {
      fontSize: fontSizes.sm,
      fontWeight: 400,
      color: appColors.brandGray['pieLabel'],
    },
    pieChartValueText: {
      fontSize: fontSizes.sm,
      fontWeight: 600,
      color: appColors.brandBlack['600'],
    },
  },
};

export default TextStyles;
