import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DownloadIcon,
} from '@chakra-ui/icons';
import { Flex, IconButton, Image } from '@chakra-ui/react';
import { EyeIcon } from 'assets';
import { TableFooterText } from 'chakraOverwrite/TableStyles';
import AppText from 'components/typography/AppText';
import React, { useState } from 'react';

const GroupImages = ({
  data,
  width = '260px',
  height = '150px',
  isDownload = false,
  itemWidth = '240px',
  itemHeight = '150px',
  isHover,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [pages] = useState(Math.ceil(data?.length / 9));

  const prevPage = () => {
    setCurrentPage((page) => page - 1);
  };

  const nextPage = () => {
    setCurrentPage((page) => page + 1);
  };

  const getpageData = () => {
    let startIndex = currentPage * 9 - 9;
    let endIndex = startIndex + 9;
    return data?.slice(startIndex, endIndex);
  };

  const handleFileDownload = async ({ fileURL }) => {
    let fileName =
      Math.random().toString(36).substring(2, 8) +
      Math.random().toString(36).substring(2, 8);
    const a = document.createElement('a');
    a.href = await toDataURL(fileURL);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const toDataURL = (url) => {
    return fetch(url + '?not-from-cache-please')
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  };

  const viewImageHandler = (url) => {
    window.open(url);
  };

  // let test = Math.ceil(data?.length / 9);

  return (
    <>
      <Flex flexWrap="wrap" gap={5} mt="45px !important" justifyItems="center">
        {getpageData()?.map((item, inx) => {
          if (item !== undefined) {
            return (
              <div key={inx} w={itemWidth} h={itemHeight}>
                <Flex
                  data-group
                  style={{
                    height: height,
                    width: width,
                    position: 'relative',
                  }}
                >
                  <Image
                    height={height}
                    width={width}
                    objectFit="cover"
                    src={item?.file || item?.url || item}
                    alt="roof image"
                  />
                  {isHover ? (
                    <Flex
                      style={{
                        position: 'absolute',
                        display: 'none',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        background: 'rgba(255,255,255, 0.4)',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      _groupHover={{
                        display: 'flex !important',
                      }}
                      onClick={() =>
                        viewImageHandler(item?.file || item?.url || item)
                      }
                    >
                      <img src={EyeIcon} alt="icon" />
                    </Flex>
                  ) : null}
                </Flex>
                {isDownload ? (
                  <Flex justifyContent={'space-between'}>
                    <AppText
                      customStyles={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: 700,
                        marginTop: '10px',
                      }}
                      text={item.title}
                    />
                    <IconButton
                      background={'transparent'}
                      _hover={{ background: 'transparent' }}
                      marginLeft="14px"
                      icon={<DownloadIcon h={6} w={6} />}
                      onClick={() => {
                        handleFileDownload({ fileURL: item.url || item });
                      }}
                    />
                  </Flex>
                ) : null}
              </div>
            );
          }
        })}
      </Flex>

      <Flex
        justifyContent="space-between"
        marginTop="16px"
        alignItems="flex-end"
      >
        <Flex>
          <AppText
            text={`Total Image ${getpageData()?.length}`}
            variant="paginationText"
          />
        </Flex>
        <Flex alignItems="center">
          <TableFooterText flexShrink="0">
            Page <TableFooterText as="span">{currentPage}</TableFooterText> of{' '}
            <TableFooterText as="span">
              {Math.ceil(data?.length / 9)}
            </TableFooterText>
          </TableFooterText>
          <Flex ml={5}>
            <IconButton
              isDisabled={currentPage === 1 ? true : false}
              icon={<ChevronLeftIcon h={6} w={6} />}
              onClick={prevPage}
            />

            <IconButton
              isDisabled={currentPage === pages ? true : false}
              marginLeft="14px"
              icon={<ChevronRightIcon h={6} w={6} />}
              onClick={nextPage}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default GroupImages;
