import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Select } from '@chakra-ui/react';
import useStore from 'AppZustand/store';
import React from 'react';

import { TableFooterText } from '../../../chakraOverwrite/TableStyles';

const TableFooter = ({
  pageIndex,
  pageOptions,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
}) => {
  const { pageSize, fetchPageSize } = useStore((state) => state);

  return (
    <Flex
      justifyContent="space-between"
      margin="16px 16px 0 16px"
      alignItems="center"
    >
      <Flex justifyContent="flex-start">
        <TableFooterText flexShrink="0" mr={8}>
          Page <TableFooterText as="span">{pageIndex + 1}</TableFooterText> of{' '}
          <TableFooterText as="span">{pageOptions.length}</TableFooterText>
        </TableFooterText>
      </Flex>

      <Flex>
        <Select
          w={32}
          marginRight="15px"
          value={pageSize}
          onChange={(e) => {
            fetchPageSize(Number(e.target.value));
          }}
        >
          {[25, 50, 75, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>

        <IconButton
          onClick={previousPage}
          isDisabled={!canPreviousPage}
          icon={<ChevronLeftIcon h={6} w={6} />}
        />

        <IconButton
          onClick={nextPage}
          isDisabled={!canNextPage}
          marginLeft="14px"
          icon={<ChevronRightIcon h={6} w={6} />}
        />
      </Flex>
    </Flex>
  );
};

export default TableFooter;
