import { Box } from '@chakra-ui/react';
import AppLink from 'components/AppLink/AppLink';
import AppText from 'components/typography/AppText';
import React from 'react';

const TermsAndConditionsText = ({ customStyles }) => {
  const footerLinkStyles = {
    size: 'xs',
    textDecoration: 'underline',
    textUnderlineOffset: 2,
  };

  return (
    <Box mt={5} style={{ ...customStyles }}>
      <AppText
        text="By signing up, I agree to the SiteScribe "
        textType="footer"
        customStyles={{
          as: 'span',
        }}
        size="xs"
      />
      <AppLink text="Privacy Policy" customStyles={footerLinkStyles} to="#" />
      <AppText
        size="xs"
        text=" and "
        customStyles={{ as: 'span' }}
        textType="footer"
      />
      <AppLink
        text=" Terms of Service."
        to="#"
        customStyles={footerLinkStyles}
      />
    </Box>
  );
};

export default TermsAndConditionsText;
