import appColors from 'theme/appColors';

const CardStyles = {
  baseStyle: {
    backgroundColor: appColors.brandWhite['100'],
    marginTop: '24px',
  },
  sizes: {},

  variants: {
    dashInviteCard: {
      padding: '30px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '6px',
    },
    dashTutorialCard: {
      padding: '15px',
      borderRadius: '6px',
    },
    dashCard: {
      padding: '20px',
      boxShadow: '0px 0px 48px rgba(27, 178, 147, 0.05)',
      borderRadius: '12px 12px 9px 9px',
      borderBottom: `10px solid ${appColors.brandTeal['500']}`,
      width: '200px',
      height: '136px',
    },
    tableCard: {
      padding: '30px',
      borderRadius: '12px',
    },
    defaultCard: {
      padding: '30px',
      borderRadius: '12px',
      boxShadow: '0px 0px 48px rgba(27, 178, 147, 0.1)',
    },
    tableHeaderCard: {
      padding: '12px 30px',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    },
    iconCard: {
      padding: '15px',
      background: `${appColors.brandGray['background']}`,
      border: `0.5px solid ${appColors.brandTeal['500']}`,
      borderRadius: '8px',
    },
    activeIconCard: {
      padding: '15px',
      background: `${appColors.brandTeal['500']}`,
      border: `0.5px solid ${appColors.brandTeal['500']}`,
      borderRadius: '8px',
    },
    settingCard: {
      padding: '20px',
      position: 'absolute',
      zIndex: 99,
      right: '30px',
      borderRadius: '12px',
      boxShadow: '-1px 0px 2px rgba(21, 38, 38, 0.13)',
    },
    settingInnerCard: {
      overflow: 'auto',
      padding: '20px',
      background: '#F3F7F9',
      border: '1px solid #E0E6FF',
      borderRadius: '4px',
      width: '250px',
      height: '300px',
    },
    sortInnerCard: {
      overflow: 'auto',
      padding: '20px',
      background: '#F3F7F9',
      border: '1px solid #E0E6FF',
      borderRadius: '4px',
      width: '100%',
      height: '300px',
    },
    searchIconCard: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '35px',
      height: '35px',
      background: `${appColors.brandWhite['100']}`,
      borderRadius: '6px',
    },
    emailCard: {
      width: '100%',
      padding: '28px 36px',
      borderRadius: '12px',
    },
    formBuilderMenuCard: {
      padding: '0px 10px 0px 0px',
      boxShadow: '0px 0px 48px rgba(27, 178, 147, 0.1)',
      borderRadius: '8px',
      height: '80px',
    },
    pdfBuilderMenuCard: {
      padding: '10px',
      borderRadius: '8px',
    },
    activeWidgetCard: {
      width: '189px',
      height: '142px',
      boxShadow: '0px 0px 11px rgba(76, 138, 127, 0.2)',
      borderRadius: '12px',
      background: appColors.brandTeal['activeWidget'],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    widgetCard: {
      width: '189px',
      height: '142px',
      boxShadow: ' 0px 0px 11px rgba(76, 138, 127, 0.2)',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    chartsCard: {
      boxShadow: '0px 0px 48px rgba(27, 178, 147, 0.05)',
      borderRadius: '12px',
      padding: '13px',
    },
  },
};

export default CardStyles;
