import { PDF_Builder, PDF_Builder_List } from 'navigation/routes';
import PdfBuilderList from 'pages/appSettings/pdfBuilder';
import PdfBuilder from 'pages/appSettings/pdfBuilder/PdfBuilder';
import { Route } from 'react-router-dom';

const PdfBuilderRoutes = () => {
  return (
    <>
      <Route path={PDF_Builder()} element={<PdfBuilder />} />
      <Route path={PDF_Builder_List} element={<PdfBuilderList />} />
    </>
  );
};

export default PdfBuilderRoutes;
