import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  Spinner,
} from '@chakra-ui/react';
import makePostRequest from 'api/utilities/makePostRequest';
import useStore from 'AppZustand/store';
import React, { useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import appColors from 'theme/appColors';

const AppFileIcon = ({
  icon,
  fieldName,
  fieldError,
  formValues,
  setFormValues,
  id,
  uploadEndpoint,
  allowedFileTypes,
  customStyles,
  color,
}) => {
  /**
   * A common wrapper for all inputs in the application
   * */

  const { setCompanySettingsAlert } = useStore((state) => state);

  const {
    mutate: fileUploadMutate,
    isLoading: isFileUploadLoading,
    data: fileUploadData,
  } = useMutation(uploadEndpoint, (formBody) =>
    makePostRequest(uploadEndpoint, formBody),
  );

  const handleFileUpload = (e) => {
    const [file] = e.target.files;

    const formData = new FormData();
    formData.append('file', file);
    fileUploadMutate(formData);
    setCompanySettingsAlert(true);
  };

  const inputRef = useRef();

  useEffect(() => {
    if (!isFileUploadLoading && fileUploadData) {
      setFormValues({
        ...formValues,
        [fieldName]: fileUploadData?.data?.id,
        file: fileUploadData?.data?.file,
      });
    }
  }, [isFileUploadLoading, fileUploadData]);
  return (
    <FormControl
      isInvalid={Boolean(fieldError?.data?.[fieldName])}
      marginBottom={5}
      width="auto"
      position="relative"
    >
      <InputGroup>
        <Input
          ref={inputRef}
          type="file"
          display="none"
          id={id}
          name={fieldName}
          accept={allowedFileTypes}
          onChange={handleFileUpload}
        />

        <label htmlFor={id}>
          <Flex
            width="32px"
            height="32px"
            background={appColors.brandTeal['500']}
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
            {...customStyles}
          >
            {isFileUploadLoading ? <Spinner size="sm" color={color} /> : icon}
          </Flex>
        </label>
      </InputGroup>
      <FormErrorMessage>{fieldError?.data?.[fieldName]}</FormErrorMessage>
    </FormControl>
  );
};

export default AppFileIcon;
