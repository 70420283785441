import {
  APP_EMAIL_EDIT_MAGIC_LINK_VERIFICATION,
  APP_PROFILE,
} from 'navigation/routes';
import AppEmailVerification from 'pages/profile/components/AppEmailVerification/AppEmailVerification';
import Profile from 'pages/profile/Profile';
import { Route } from 'react-router-dom';

const ProfileRoutes = () => {
  return (
    <>
      <Route path={APP_PROFILE} element={<Profile />} />
      <Route
        path={APP_EMAIL_EDIT_MAGIC_LINK_VERIFICATION}
        element={<AppEmailVerification />}
      />
    </>
  );
};

export default ProfileRoutes;
