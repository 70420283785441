import { Box, Flex } from '@chakra-ui/react';
import { SiteLoader } from 'assets';
// import { AppLoaderWrapper } from 'components/AppLoader/AppLoader.styles';

function AppLoader({ isLoading, children, height = '90vh' }) {
  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" height={height}>
        {/* <AppLoaderWrapper /> */}
        <Box width="80px" height="80px">
          <img src={SiteLoader} alt="icon" />
        </Box>
      </Flex>
    );
  }
  return children;
}

export default AppLoader;
