/* eslint-disable no-unsafe-optional-chaining */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  FormLabel,
  Grid,
  Icon,
  Select,
  Stack,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import {
  FORM_BUILDER_GROUP_CUD,
  FORM_BUILDER_GROUP_UPDATE_META,
  FORM_BUILDER_INITIAL_DATA,
  FORM_CREATION_META,
  FORM_CREATION_UPDATE,
  FORM_FIELD_CREATION,
  FORM_FIELD_CUD,
  FORM_GROUP_CREATION,
  GET_CASCADE_META_LIST,
  GET_FORM_BUILDER_SHOW_LOGIC_DATA,
  POST_DUPLICATE_FORM_FIELD,
  SCRATCH_FORM_BUILDER,
  //FORM_FIELD_CREATION,
} from 'api/urls/settings/formBuilder';
import makeDeleteRequest from 'api/utilities/makeDeleteRequest';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import makePutRequest from 'api/utilities/makePutRequest';
import { CascadeGroup, FormGroup } from 'assets';
import AppBox from 'components/AppBox/AppBox';
import AppButton from 'components/AppButton/AppButton';
import AppCard from 'components/AppCard/AppCard';
import AppInput from 'components/AppInput/AppInput';
import AppInputDate from 'components/AppInputDate/AppInputDate';
import AppLoader from 'components/AppLoader/AppLoader';
import AppModal from 'components/AppModal/AppModal';
import AppRightDrawer from 'components/AppRightDrawer/AppRightDrawer';
import AppSelect from 'components/AppSelect/AppSelect';
import AppSwitch from 'components/AppSwitch/AppSwitch';
import GetOptionForSingleSelect from 'components/GetOptionsForSingleSelect/GetOptionsForSingleSelect';
import AppText from 'components/typography/AppText';
import DashboardLayout from 'layout/DashboardLayout';
import { APP_SURVEY_TEMPLATES } from 'navigation/routes';
import React, { useEffect, useRef, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { BsChevronLeft, BsPlusCircle } from 'react-icons/bs';
import { IoIosCopy } from 'react-icons/io';
import {
  MdAdd,
  MdArrowDropDown,
  MdOutlineDeleteOutline,
  MdSettings,
} from 'react-icons/md';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import appColors from 'theme/appColors';

import CaseCade from './components/CaseCade';
import FormConstant from './components/Constant';
import FormWidgets from './components/FormWidgets';
import { FormWidgetsMeta } from './DataSource';

const TabItems = [
  {
    name: 'Form Builder',
    icon: FormGroup,
  },
  {
    name: 'Cascade Groups',
    icon: CascadeGroup,
  },
];

const RenderAccordion = ({
  data,
  index,
  onShowEditor,
  formGroupDeleteHandler,
  drop,
  dragOver,
  selectedComponent,
  componentHandler,
  fieldDragStart,
  settingsHandler,
  copyHandler,
  menu,
  setToIndex,
  accordionIndex,
  setAccordionIndex,
  parentRef,
  type,
}) => {
  const item = data;

  const getAccordionIndex = (inx) => {
    if (accordionIndex.includes(inx)) {
      setAccordionIndex([]);
    } else {
      setAccordionIndex([inx]);
    }
  };
  function getPosition(e) {
    if (e.clientY < 200) {
      parentRef.scrollTop -= 25;
    } else if (e.clientY > 700) {
      parentRef.scrollTop += 25;
    }
  }

  return (
    <AccordionItem border="none" data-group>
      <h2 style={{ margin: '0 23px 0 15px' }}>
        <AccordionButton
          h="56px"
          _focus={{
            boxShadow: 'none',
          }}
          onClick={() => {
            getAccordionIndex(index);
          }}
        >
          <Box flex="1" textAlign="left">
            <Flex alignItems={'center'}>
              <AppText
                text={item?.identity || 'Group Name'}
                customStyles={{
                  marginRight: '10px',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
              <Icon
                _groupHover={{ display: 'inline-block' }}
                as={BiEditAlt}
                alt="icon"
                onClick={() => onShowEditor(item.id)}
                style={{
                  cursor: 'pointer',
                  marginRight: '10px',
                }}
              />

              <Icon
                as={MdOutlineDeleteOutline}
                onClick={() => formGroupDeleteHandler(item.id)}
                style={{
                  cursor: 'pointer',
                }}
              />
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel
        p="10px 0 0 0"
        onDrop={(e) => drop(e, item.id, type)}
        onDragOver={(e) => {
          dragOver(e);
          getPosition(e);
        }}
      >
        <>
          {item.related_form_fields.map((item, inx) => {
            let selectedIndex = item?.id === selectedComponent?.id ? inx : -1;

            return (
              <Stack
                cursor="move"
                draggable
                onDragStart={(e) => fieldDragStart(item, e, inx)}
                onDragOver={() => {
                  setToIndex(inx - 1);
                }}
                key={inx}
                style={{
                  margin: '4px 23px 20px 15px',
                  border:
                    inx === selectedIndex
                      ? '1px solid #EAEAEA'
                      : '1px solid transparent',
                  borderLeft:
                    inx === selectedIndex
                      ? `7px solid ${appColors.brandTeal[500]}`
                      : '7px solid transparent',
                  borderRadius: '4px',
                  borderBottom: '1px solid #EAEAEA',
                  padding: '16px 0',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
                onClick={() => componentHandler(item, index)}
              >
                {item?.type !== FormConstant.heading ? (
                  <Box display="flex" alignItems="center">
                    <AppText
                      customStyles={{
                        fontSize: '14px',
                        lineHeight: '18.2px',
                        marginLeft: '4px',
                        fontWeight: 500,
                        display: 'flex',
                      }}
                      text={
                        item.identity
                          ? ++inx + '. ' + item?.identity
                          : ++inx + '. ' + FormWidgetsMeta[item.type]?.name
                      }
                      isRequired={item.is_required}
                    />
                    <AppText
                      text={`Field type - ${item?.type} `}
                      customStyles={{
                        marginLeft: '40px',
                        color: appColors.brandGray['paginationText'],
                      }}
                    />
                  </Box>
                ) : null}

                {item?.id === selectedComponent?.id && (
                  <Flex>
                    <AppCard
                      variant="formBuilderMenuCard"
                      customStyles={{ marginTop: '0px' }}
                    >
                      <Flex flexDirection="column" gap="18px">
                        <MdSettings
                          onClick={() =>
                            settingsHandler('settings', item, data.id)
                          }
                          style={{
                            cursor: 'pointer',
                            color:
                              Object.keys(selectedComponent).length > 0
                                ? appColors.brandTeal['activeMenu']
                                : appColors.brandGray['iconGrey'],
                          }}
                        />

                        <IoIosCopy
                          style={{
                            cursor: 'pointer',
                            color:
                              menu === 'delete'
                                ? appColors.brandTeal['activeMenu']
                                : appColors.brandGray['iconGrey'],
                          }}
                          onClick={() => {
                            copyHandler(item, data.id);
                          }}
                        />

                        <AiFillDelete
                          onClick={() => settingsHandler('delete', item)}
                          style={{
                            cursor: 'pointer',
                            color:
                              menu === 'delete'
                                ? appColors.brandTeal['activeMenu']
                                : appColors.brandGray['iconGrey'],
                          }}
                        />
                      </Flex>
                    </AppCard>
                  </Flex>
                )}
              </Stack>
            );
          })}
          <Center
            h="76px"
            w="685px"
            m="0 23px 0 25px"
            width="95%"
            border={`1px dashed ${appColors.brandGray.iconGrey}`}
          >
            <AppText
              text="Drag and Drop fields here"
              customStyles={{
                fontSize: '14px',
                lineHeight: '18.2px',
              }}
            />
          </Center>
        </>
      </AccordionPanel>
    </AccordionItem>
  );
};

const FormBuilder = () => {
  const [formDetails, setFormDetails] = useState({
    formId: '',
    status: '',
    statusMeta: [],
    identity: '',
  });

  const [initialState, setInitialState] = useState({
    activeTabIndex: 0,
    isActive: true,
    fieldConfig: [],
    groupList: [],
    isEdit: false,
    fieldIsEdit: false,
  });

  const [properityForm, setProperityForm] = useState({
    identity: '',
    help_text: '',
    placeholder_text: '',
    is_required: false,
    is_cascaded: false,
  });

  const params = useParams();

  const navigate = useNavigate();

  const { state } = useLocation();

  const [menu, setMenu] = useState('');

  const [initialProperityData, setInitialProperityData] = useState({});

  const [draggedComponent, setDraggedComponent] = useState({});

  const [selectedComponent, setSelectedComponent] = useState({});
  const [copyItem, setCopyItem] = useState({});
  const [formGroupId, setFormGroupId] = useState('');

  const [error, setError] = useState({});

  const [choiceCount, setChoiceCount] = useState([0]);

  const [fromIndex, setFromIndex] = useState();

  const [toIndex, setToIndex] = useState();

  const [showLogicOption, setShowLogicOption] = useState([]);

  const formbuilderRef = useRef();
  const formFields = [
    {
      fieldPlaceholder: '',
      fieldName: 'identity',
      label: 'Label',
      isRequired: true,
    },
    {
      fieldPlaceholder: '',
      fieldName: 'help_text',
      label: 'Help Text',
    },
    {
      fieldPlaceholder: '',
      fieldName: 'placeholder_text',
      label: 'Placeholder Text',
    },
  ];

  const showValueTypeOptions = [
    {
      id: 'is',
      identity: 'Is Equal To',
    },
    {
      id: 'is_not',
      identity: 'Is Not Equal To',
    },
  ];

  // show logic meta api

  const {
    mutate: getShowLogic,
    data: getShowLogicData,
    // isLoading: getShowLogicDataIsLoading,
  } = useMutation(GET_FORM_BUILDER_SHOW_LOGIC_DATA(), (groupId) =>
    makeGetRequest(GET_FORM_BUILDER_SHOW_LOGIC_DATA(groupId)),
  );

  useEffect(() => {
    const filterShowLogicData =
      getShowLogicData?.data?.show_logic_fields?.filter((data) => {
        return data.id === properityForm?.show_logic_field;
      });
    const modifyData =
      filterShowLogicData?.[0]?.single_selection_input_choices_validation?.map(
        (data) => {
          return { id: data, identity: data };
        },
      );

    setShowLogicOption(modifyData);
  }, [properityForm, getShowLogicData]);

  const showLogicFields = [
    {
      fieldPlaceholder: 'Select The Question',
      fieldName: 'show_logic_field',
      label: 'Show Logic Field',
      fieldType: 'select',
      fieldOptions: getShowLogicData?.data?.show_logic_fields,
    },
    {
      fieldPlaceholder: '',
      fieldName: 'show_logic_value_type',
      label: 'Show Logic Value Type',
      fieldType: 'select',
      fieldOptions: showValueTypeOptions,
    },
    {
      fieldPlaceholder: 'Select The Value',
      fieldName: 'show_logic_value',
      label: 'Show Logic Value',
      fieldType: 'select',
      fieldOptions: showLogicOption,
    },
    // {
    //   fieldPlaceholder: '',
    //   fieldName: 'show_logic_value',
    //   label: 'Show Logic Value',
    //   fieldType: 'number',
    // },
  ];

  const { activeTabIndex, groupList } = initialState;

  const [accordionIndex, setAccordionIndex] = useState([]);

  const {
    mutate: getFormBuilderInitialMutation,
    data: getFormBuilderInitialData,
    isLoading: getFormBuilderInitialDataIsLoading,
  } = useMutation(FORM_BUILDER_INITIAL_DATA(), () =>
    makeGetRequest(FORM_BUILDER_INITIAL_DATA(params.slug)),
  );

  useEffect(() => {
    getFormBuilderInitialMutation();
  }, []);

  const onFormBuilderSave = () => {
    //Write the code to save the designed form details.
    navigate(APP_SURVEY_TEMPLATES);
  };

  const {
    mutate: getFormMetaData,
    data: getMetaFormData,
    // isLoading: getMetaIsLoading,
  } = useMutation(FORM_CREATION_META(), (slug) =>
    makeGetRequest(FORM_CREATION_META(slug)),
  );

  const {
    data: getCascadeMetaData,
    refetch: getCascadeMetaRefetch,
    // isLoading: getMetaIsLoading,
  } = useQuery(GET_CASCADE_META_LIST(params.slug), () =>
    makeGetRequest(GET_CASCADE_META_LIST(params.slug)),
  );

  useEffect(() => {
    getFormMetaData(params.slug);
  }, []);

  const [activeState, setActiveState] = useState();

  useEffect(() => {
    setActiveState(getMetaFormData?.data?.initial?.status === 'active');
  }, [getMetaFormData]);

  const {
    isOpen: propertiesIsOpen,
    onOpen: propertiesOnOpen,
    onClose: propertiesOnClose,
  } = useDisclosure();

  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  const {
    isOpen: formGroupModalOpen,
    onOpen: formGroupModalOnOpen,
    onClose: formGroupModalOnClose,
  } = useDisclosure();

  const {
    isOpen: isCopyOpen,
    onOpen: copyOnOpen,
    onClose: copyOnClose,
  } = useDisclosure();

  const {
    isOpen: isCascadeCopyOpen,
    onOpen: cascadeCopyOnOpen,
    onClose: cascadeCopyOnClose,
  } = useDisclosure();

  // Form group creation API call

  const {
    mutate: getFormGroup,
    error: formGroupError,
    data: getFormGroupData,
    isLoading: getFormGroupIsLoading,
  } = useMutation(
    FORM_GROUP_CREATION,
    (formGroupData) => makePostRequest(FORM_GROUP_CREATION, formGroupData),
    {
      onSuccess: () => {
        formGroupModalOnClose();
        getFormBuilderInitialMutation();
        setFormDetails({});
        getCascadeMetaRefetch();
      },
    },
  );
  const { mutate: postDuplicate } = useMutation(
    FORM_GROUP_CREATION,
    (formGroupData) =>
      makePostRequest(POST_DUPLICATE_FORM_FIELD(copyItem?.id), formGroupData),
    {
      onSuccess: () => {
        formGroupModalOnClose();
        getFormBuilderInitialMutation();
        setFormDetails({});
        getCascadeMetaRefetch();
        setCopyItem({});
        setFormGroupId('');
        copyOnClose();
        cascadeCopyOnClose();
      },
    },
  );

  // Form group updation

  const {
    mutate: formGroupUpdate,
    // error: formGroupUpdateError,
    // isLoading: getFormGroupIsLoading,
  } = useMutation(
    FORM_CREATION_UPDATE(),
    (formGroupData) =>
      makePutRequest(FORM_CREATION_UPDATE(params.slug), formGroupData),
    {
      onSuccess: () => {},
    },
  );

  const {
    mutate: getFormGroupUpdateMutation,
    // data: getFormGroupUpdateData,
    isLoading: getFormGroupUpdateIsLoading,
  } = useMutation(
    FORM_BUILDER_GROUP_CUD(),
    (formData) =>
      makePutRequest(
        FORM_BUILDER_GROUP_CUD(getFormGroupUpdateMetaData?.data?.initial?.id),
        formData,
      ),
    {
      onSuccess: () => {
        formGroupModalOnClose();
        getFormBuilderInitialMutation();
        setFormDetails({});
        setInitialState({ ...initialState, isEdit: false });
        getCascadeMetaRefetch();
      },
    },
  );

  const {
    mutate: getFormFieldDeletionMutation,
    // data: getFormGroupUpdateData,
    isLoading: getFormFieldDeletionIsLoading,
  } = useMutation(
    FORM_FIELD_CUD(),
    (slug) => makeDeleteRequest(FORM_FIELD_CUD(slug)),
    {
      onSuccess: () => {
        modalOnClose();
        getFormBuilderInitialMutation();
      },
    },
  );

  // From group meta creation API call
  const {
    mutate: getFormFieldUpdationMutation,
    // data: getFormGroupUpdateData,
    isLoading: getFormFieldUpdationIsLoading,
  } = useMutation(
    FORM_FIELD_CUD(),
    (updateFieldData) =>
      makePutRequest(FORM_FIELD_CUD(updateFieldData?.ID), updateFieldData),
    {
      onSuccess: () => {
        setChoiceArr([]);
        setChoiceCount([0]);
        setInitialFields({});
        setProperityForm({});
        setInitialProperityData({});
        setDraggedComponent({});
        setInitialState({ ...initialState, fieldIsEdit: false });
        propertiesOnClose();
        getFormBuilderInitialMutation();
      },
      onError: (data) => {
        setError(data.response.data);
      },
    },
  );

  const {
    mutate: getFormGroupDeletionMutation,
    // data: getFormGroupUpdateData,
    isLoading: getFormGroupDeletionIsLoading,
  } = useMutation(
    FORM_BUILDER_GROUP_CUD(),
    (slug) => makeDeleteRequest(FORM_BUILDER_GROUP_CUD(slug)),
    {
      onSuccess: () => {
        modalOnClose();
        getFormBuilderInitialMutation();
        setFormDetails({});
        setChoiceCount([0]);
        setChoiceArr([]);
        getCascadeMetaRefetch();
      },
    },
  );

  let data = {};

  useEffect(() => {
    if (getFormGroupData) {
      data = {
        id: getFormGroupData?.data?.id,
        identity: getFormGroupData?.data?.identity,
        status: getFormGroupData?.data?.status,
        isEdit: false,
        related_form_fields: [],
      };

      setInitialState((prevState) => ({
        ...prevState,
        groupList: groupList?.concat(data),
      }));
    }
  }, [getFormGroupData]);

  const onAddAccordion = () => {
    if (initialState.isEdit) {
      getFormGroupUpdateMutation({
        identity: formDetails.identity,
        type: activeTabIndex === 0 ? 'regular' : 'cascaded',
        status: formDetails.status,
        form: params.slug,
      });
    } else {
      getFormGroup({
        identity: formDetails.identity,
        type: activeTabIndex === 0 ? 'regular' : 'cascaded',
        status: formDetails.status,
        form: params.slug,
      });
    }
  };

  // const { data: getFormGroupMetaData, isLoading: getFormGroupMetaIsLoading } =
  //   useQuery(FORM_GROUP_CREATION_META, () =>
  //     makeGetRequest(FORM_GROUP_CREATION_META),
  //   );

  const onNewGroup = () => {
    setFormDetails({});
    setInitialState({ ...initialState, isEdit: false });
    formGroupModalOnOpen();
  };

  // Form Fields overall config API call

  const {
    mutate: getFormBuilderData,
    data: getMetaData,
    isLoading: getMetaIsLoading,
  } = useMutation(SCRATCH_FORM_BUILDER, () =>
    makeGetRequest(SCRATCH_FORM_BUILDER),
  );

  const {
    mutate: getFormGroupUpdateMetaMutation,
    data: getFormGroupUpdateMetaData,
    isLoading: getFormGroupUpdateMetaIsLoading,
  } = useMutation(FORM_BUILDER_GROUP_UPDATE_META(), (slug) =>
    makeGetRequest(FORM_BUILDER_GROUP_UPDATE_META(slug)),
  );

  useEffect(() => {
    if (!getFormGroupUpdateMetaIsLoading && getFormGroupUpdateMetaData) {
      setFormDetails({
        ...formDetails,
        identity: getFormGroupUpdateMetaData?.data?.initial?.identity,
        status: getFormGroupUpdateMetaData?.data?.initial?.status,
        id: getFormGroupUpdateMetaData?.data?.initial?.id,
        form: getFormGroupUpdateMetaData?.data?.initial?.form,
      });
    }
  }, [getFormGroupUpdateMetaData, getFormGroupUpdateMetaIsLoading]);

  const { mutate: getFormField, isLoading: getFormFieldIsLoading } =
    useMutation(
      FORM_FIELD_CREATION,
      (formData) => makePostRequest(FORM_FIELD_CREATION, formData),
      {
        onSuccess: () => {
          setChoiceArr([]);
          setChoiceCount([0]);
          propertiesOnClose();
          getFormBuilderInitialMutation();
          // changed
          setProperityForm({});
          setInitialProperityData({});
          setDraggedComponent({});
          setCopyItem({});
          setFormGroupId('');
          copyOnClose();
        },
        onError: (data) => {
          setError(data.response.data);
        },
      },
    );

  useEffect(() => {
    getFormBuilderData();
  }, []);

  // const getFieldValidation = () => {
  //   if (getMetaData?.data?.field_types_config) {
  //     let fields = Object.entries(getMetaData.data.field_types_config);
  //     if (fields.length > 0) {
  //       let fieldObj = {};
  //       fields.map((item) => {
  //         fieldObj[item[0]] = item[1];
  //       });
  //       return fieldObj;
  //     }
  //   }
  // };

  // Form field creation meta API call

  // const {
  //   mutate: getFormFieldData,
  //   //data: getFormFieldMetaData,
  //   //isLoading: getFormFieldMetaIsLoading,
  // } = useMutation(FORM_FIELD_CREATION_META, () =>
  //   makeGetRequest(FORM_FIELD_CREATION_META),
  // );

  const onShowEditor = (id) => {
    formGroupModalOnOpen();
    setInitialState({ ...initialState, isEdit: true });
    getFormGroupUpdateMetaMutation(id);
  };
  const [initialFields, setInitialFields] = useState({});

  useEffect(() => {
    let data = [];

    let validationField = {};

    let formField = {};

    if (getFormBuilderInitialData) {
      getFormBuilderInitialData?.data?.map((item) => {
        item?.related_form_fields?.map((field) => {
          if (field.type === initialProperityData.type) {
            Object.entries(
              getMetaData?.data?.field_types_config[initialProperityData.type]
                ?.field_specific_validations || {},
            ).map((item) => {
              Object.assign(validationField, {
                ...validationField,
                [item[0]]: initialProperityData[item[0]],
              });
            });

            setProperityForm({
              ...properityForm,
              ...validationField,
              id: item.id,
              form_group: initialProperityData.form_group,
              identity: initialProperityData.identity,
              help_text: initialProperityData.help_text,
              placeholder_text: initialProperityData.placeholder_text,
              is_required: initialProperityData.is_required,
              is_cascaded: initialProperityData?.is_cascaded,
              type: initialProperityData.type,
            });
          } else {
            Object.entries(
              getMetaData?.data?.field_types_config[draggedComponent.type]
                ?.field_specific_validations || {},
            ).map((item) => {
              if (item[0] === 'single_selection_input_choices_validation') {
                if (item[1] === null) {
                  setProperityForm({
                    ...properityForm,
                    file_attachment_input_min_files_validation: 1,
                    [item[0]]: [],
                    short_text_input_min_length_validation: 0,
                  });
                }
              } else {
                setProperityForm({
                  ...properityForm,
                  file_attachment_input_min_files_validation: 1,
                  [item[0]]: item[1],
                });
              }
            });
          }
          Object.assign(formField, {
            help_text: field.help_text,
            placeholder_text: field.placeholder_text,
            is_required: field.is_required,
          });
        });
        data.push({
          ...item,
          ...formField,
        });
      });
    }

    setInitialFields({
      ...validationField,
    });

    setInitialState({ ...initialState, groupList: data });
  }, [
    getFormBuilderInitialData,
    initialProperityData,
    getMetaData,
    draggedComponent,
  ]);

  useEffect(() => {
    if (initialProperityData || draggedComponent) {
      getShowLogic(initialProperityData.form_group || properityForm.groupId);
    }
  }, [draggedComponent, initialProperityData]);

  const drop = (args, id, type) => {
    let widgetName = args.dataTransfer.getData('widget');
    let fieldName = args.dataTransfer.getData('field');
    let data = groupList;
    groupList[0].related_form_fields.filter((item) => {
      return item.id === fieldName;
    });
    let index = groupList.filter((item) => {
      return item.type === type;
    });
    let mainIndex = groupList.findIndex(
      (object) => object.id === index[accordionIndex[0]].id,
    );

    if (widgetName) {
      data.map((item) => {
        if (item.id === id) {
          // item.related_form_fields.push({
          //   type: widgetName,
          //   id: Math.random().toString(36).substr(2, 9),
          //   name: FormWidgets[widgetName]?.name,
          // });
          setDraggedComponent({
            type: widgetName,
            id: Math.random().toString(36).substr(2, 9),
            name: FormWidgetsMeta[widgetName]?.name,
          });
        }
      });

      // setInitialState((prevState) => ({
      //   ...prevState,
      //   groupList: data,
      // }));
      setError({});
      setChoiceCount([0]);
      setChoiceArr([]);
      setInitialFields({});
      setInitialProperityData({});
      // changed
      setProperityForm({ groupId: id });
      setInitialState({ ...initialState, fieldIsEdit: false });
      propertiesOnOpen();
    } else {
      let element = groupList[mainIndex].related_form_fields.splice(
        fromIndex,
        1,
      )[0];
      groupList[mainIndex].related_form_fields.splice(toIndex, 0, element);
      setInitialState({ ...initialState, groupList: groupList });
    }
  };

  const dragOver = (args) => {
    args.stopPropagation();
    args.preventDefault();
  };

  const widgetDragStart = (item, args) => {
    args.dataTransfer.setData('widget', item[0]);
  };

  const fieldDragStart = (item, args, index) => {
    setFromIndex(index - 1);
    args.dataTransfer.setData('field', item.id);
  };

  const settingsHandler = (type, item) => {
    if (Object.keys(selectedComponent).length > 0) {
      setMenu(type);
      if (type === 'settings') {
        setInitialState({ ...initialState, fieldIsEdit: true });
        setInitialProperityData(item);
        propertiesOnOpen();
        setError({});
      } else if (type === 'delete') {
        modalOnOpen();
        setInitialProperityData(item);
      }
    } else {
      alert('Select any one field first and try again');
    }
  };

  const copyHandler = (item) => {
    setCopyItem(item);
    copyOnOpen();
  };

  const cascadeCopyHandler = (item) => {
    setCopyItem(item);
    cascadeCopyOnOpen();
  };

  const submitCopy = () => {
    postDuplicate({
      form_group: formGroupId,
    });
  };

  const onRemoveField = () => {
    if (typeof initialProperityData !== 'string') {
      getFormFieldDeletionMutation(initialProperityData.id);
    } else {
      getFormGroupDeletionMutation(initialProperityData);
    }
  };

  const formGroupDeleteHandler = (item) => {
    setInitialProperityData(item);
    modalOnOpen();
  };

  const componentHandler = (item) => {
    setSelectedComponent(item);
  };

  const [choiceArr, setChoiceArr] = useState([]);
  useEffect(() => {
    let single_selection_input_choices_validation_obj = {};

    let multi_selection_input_choices_validation_obj = {};

    if (initialProperityData.type === 'single_selection') {
      initialProperityData?.['single_selection_input_choices_validation']?.map(
        (item) => {
          Object.assign(single_selection_input_choices_validation_obj, {
            [`single_selection_input_choices_validation_${item}`]: item,
          });
        },
        setChoiceArr(
          initialProperityData['single_selection_input_choices_validation'],
        ),
      );
    } else if (initialProperityData.type === 'multi_selection') {
      initialProperityData?.['multi_selection_input_choices_validation']?.map(
        (item) => {
          Object.assign(multi_selection_input_choices_validation_obj, {
            [`multi_selection_input_choices_validation_${item}`]: item,
          });
        },
        setChoiceArr(
          initialProperityData['multi_selection_input_choices_validation'],
        ),
      );
    }

    setProperityForm({
      ...properityForm,
      ...initialFields,
      form_group: initialProperityData.form_group,
      identity: initialProperityData.identity,
      help_text:
        initialProperityData.help_text || initialFields.help_text || '',
      placeholder_text:
        initialProperityData.placeholder_text ||
        initialFields.placeholder_text ||
        '',
      is_required:
        initialProperityData.is_required || initialFields.is_required || false,
      is_cascaded:
        initialProperityData?.is_cascaded ||
        initialFields?.is_cascaded ||
        false,
      type: initialProperityData.type,
      single_selection_input_choices_validation_obj,
      multi_selection_input_choices_validation_obj,
      short_text_input_min_length_validation:
        initialFields?.short_text_input_min_length_validation || 1,
      large_text_input_min_length_validation:
        initialFields?.large_text_input_min_length_validation || 1,
      image_attachment_input_max_images_validation:
        initialFields?.image_attachment_input_max_images_validation || 1,
      file_attachment_input_max_files_validation:
        initialFields?.file_attachment_input_max_files_validation || 1,
      large_text_input_max_length_validation:
        initialFields?.large_text_input_max_length_validation || 100,
      numeric_input_max_value_validation:
        initialFields?.numeric_input_max_value_validation || 100,
      numeric_input_min_value_validation:
        initialFields?.numeric_input_min_value_validation || 0,
      image_attachment_input_compression_choices_validation:
        initialFields?.image_attachment_input_compression_choices_validation,
      show_logic_field: initialProperityData?.show_logic_field || null,
      show_logic_value: initialProperityData?.show_logic_value || null,
      show_logic_value_type:
        initialProperityData?.show_logic_value_type || null,
      single_selection_group_input_validation:
        initialProperityData?.single_selection_group_input_validation || null,
      single_selection_group_input_title_validation:
        initialProperityData?.single_selection_group_input_title_validation ||
        null,
      datetime_input_from_datetime_validation:
        initialFields?.datetime_input_from_datetime_validation || null,
      datetime_input_to_datetime_validation:
        initialFields?.datetime_input_to_datetime_validation || null,
      date_input_from_date_validation:
        initialFields?.date_input_from_date_validation || null,
      date_input_to_date_validation:
        initialFields?.date_input_to_date_validation || null,
    });
  }, [initialProperityData, initialFields]);

  const choiceDeleteHandler = (index, key, obj) => {
    const findindex = choiceCount.indexOf(index);

    delete properityForm[obj][key];

    choiceCount.splice(findindex, 1);

    setChoiceCount([...choiceCount]);
  };

  const choiceArrDeleteHandler = (index, key, obj) => {
    const findindex = choiceArr.indexOf(index);

    choiceArr.splice(findindex, 1);

    delete properityForm[obj]?.[key];

    setChoiceArr([...choiceArr]);
  };

  const reg = new RegExp('^[0-9]+$');

  const isCascededShow = Object.entries(
    properityForm?.single_selection_input_choices_validation_obj || {},
  )?.every((data) => {
    return reg.test(data[1]);
  });

  const saveHandler = () => {
    let single_selection_input_choices_validation = [];
    let multi_selection_input_choices_validation = [];
    if (
      draggedComponent.type === 'single_selection' ||
      properityForm.type === 'single_selection'
    ) {
      Object.entries(
        properityForm?.['single_selection_input_choices_validation_obj'] || {},
      ).map((item) => {
        single_selection_input_choices_validation.push(item[1].trim());
      });
    } else {
      Object.entries(
        properityForm?.['multi_selection_input_choices_validation_obj'] || {},
      ).map((item) => {
        multi_selection_input_choices_validation.push(item[1].trim());
      });
    }

    if (initialState.fieldIsEdit) {
      getFormFieldUpdationMutation({
        ID: initialProperityData.id,
        ...properityForm,
        is_cascaded: isCascededShow ? properityForm?.is_cascaded : false,
        form_group: properityForm.form_group || properityForm.groupId,
        type: draggedComponent?.type || properityForm.type,
        single_selection_input_choices_validation: [
          ...new Set(single_selection_input_choices_validation),
        ],
        multi_selection_input_choices_validation: [
          ...new Set(multi_selection_input_choices_validation),
        ],
        show_logic_field:
          properityForm?.show_logic_field !== ''
            ? properityForm?.show_logic_field
            : null,
        show_logic_value:
          properityForm?.show_logic_value !== ''
            ? properityForm?.show_logic_value
            : null,
        show_logic_value_type:
          properityForm?.show_logic_value_type !== ''
            ? properityForm?.show_logic_value_type
            : null,
        single_selection_group_input_title_validation:
          properityForm?.single_selection_group_input_title_validation !== ''
            ? properityForm?.single_selection_group_input_title_validation
            : null,
      });
    } else {
      getFormField({
        ...properityForm,
        is_cascaded: isCascededShow ? properityForm?.is_cascaded : false,
        form_group: properityForm.form_group || properityForm.groupId,
        type: draggedComponent?.type || properityForm.type,
        single_selection_input_choices_validation: [
          ...new Set(single_selection_input_choices_validation),
        ],
        multi_selection_input_choices_validation: [
          ...new Set(multi_selection_input_choices_validation),
        ],
        show_logic_field:
          properityForm?.show_logic_field !== ''
            ? properityForm?.show_logic_field
            : null,
        show_logic_value:
          properityForm?.show_logic_value !== ''
            ? properityForm?.show_logic_value
            : null,
        show_logic_value_type:
          properityForm?.show_logic_value_type !== ''
            ? properityForm?.show_logic_value_type
            : null,
      });
    }
  };
  return (
    <DashboardLayout
      menu={true}
      type="Settings"
      menuName="Survey Templates"
      customStyles={{ padding: '0px', overflowY: 'hidden' }}
      isSideBar={false}
    >
      <AppLoader height="100px" isLoading={getMetaIsLoading}>
        {!getMetaIsLoading && getMetaData && (
          <>
            <Flex
              h={'65px'}
              backgroundColor={appColors.brandTeal.tableHead}
              p="0 33px"
              justifyContent={'space-between'}
              alignItems="center"
            >
              <Flex onClick={() => navigate(APP_SURVEY_TEMPLATES)}>
                <Icon
                  color={'#F8F8F8'}
                  fontSize="18px"
                  cursor={'pointer'}
                  as={BsChevronLeft}
                />
                <AppText
                  customStyles={{
                    marginLeft: '10px',
                    fontSize: '16px',
                    lineHeight: '19.36px',
                    color: appColors.brandWhite[100],
                  }}
                  text="Back"
                />
              </Flex>
              <Flex>
                {TabItems.map((item, inx) => {
                  return (
                    <Box
                      key={inx}
                      w="201px"
                      h="65px"
                      cursor={'pointer'}
                      backgroundColor={
                        inx === activeTabIndex
                          ? appColors.brandTeal[500]
                          : 'transparent'
                      }
                      onClick={() =>
                        setInitialState({
                          ...initialState,
                          activeTabIndex: inx,
                        })
                      }
                    >
                      <Center h="100%">
                        <img src={item.icon} alt="icon" />
                        <AppText
                          customStyles={{
                            color: appColors.brandWhite[100],
                            marginLeft: '15px',
                            fontSize: '16px',
                            lineHeight: '19.36px',
                          }}
                          text={item.name}
                        />
                      </Center>
                    </Box>
                  );
                })}
              </Flex>
              <Box>
                <AppButton
                  customStyles={{
                    height: '44px',
                    width: '112px',
                  }}
                  text="Done"
                  onClick={() => onFormBuilderSave()}
                />
              </Box>
            </Flex>
            {activeTabIndex === 0 ? (
              <Flex
                gap="15px"
                overflowY="auto"
                height="79vh"
                position="relative"
                ref={formbuilderRef}
              >
                <Stack
                  w="415px"
                  h="790px"
                  borderRight="0.5px solid #C3C4CC"
                  borderBottom={'0.5px solid #C3C4CC'}
                  background={appColors.brandWhite[100]}
                  position="sticky"
                  height="79vh"
                  top="0px"
                >
                  <AppText
                    customStyles={{
                      padding: '25px 0 19px 20px',
                      borderBottom: '0.5px solid #CDCDCD',
                    }}
                    text="Field Types"
                  />
                  <Box p="10px 20px 0 20px">
                    <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                      {Object.entries(
                        getMetaData?.data?.field_types_config || {},
                      ).map((item, inx) => {
                        let widget = FormWidgetsMeta[item[0]];
                        return (
                          <FormWidgets
                            key={inx}
                            item={item}
                            widget={widget}
                            widgetDragStart={widgetDragStart}
                          />
                        );
                      })}
                    </Grid>
                  </Box>
                </Stack>
                <Stack
                  w="60%"
                  border="1px solid #E0E6FF"
                  backgroundColor={appColors.brandWhite[100]}
                  boxShadow="0px -3px 9px rgba(21, 38, 38, 0.12)"
                  minHeight="500px"
                  height="max-content"
                  p="30px 0"
                  mt="30px"
                  mb="30px"
                >
                  <Flex
                    justifyContent={'space-between'}
                    pb="30px"
                    borderBottom="1px solid #CDCDCD"
                  >
                    <AppText
                      customStyles={{
                        fontSize: '20px',
                        lineHeight: '26px',
                        fontWeight: 600,
                        marginLeft: '50px',
                      }}
                      text={
                        getMetaFormData?.data?.initial?.identity ||
                        state?.identity ||
                        'Residential'
                      }
                    />
                    <Center mr="21px" w="150px" justifyContent={'flex-start'}>
                      <Switch
                        isChecked={activeState}
                        colorScheme="teal"
                        id="status"
                        size="md"
                        style={{
                          marginRight: '10px',
                        }}
                        onChange={(args) => {
                          formGroupUpdate({
                            ...getMetaFormData?.data?.initial,
                            status:
                              args?.target?.checked === true
                                ? 'active'
                                : 'inactive',
                          });
                          setActiveState(args?.target?.checked);
                        }}
                      />
                      <FormLabel mb={'0px !important'} htmlFor="status">
                        {activeState ? 'Active' : 'InActive'}
                      </FormLabel>
                    </Center>
                  </Flex>
                  <AppLoader
                    height="100px"
                    isLoading={
                      getFormGroupIsLoading ||
                      getFormBuilderInitialDataIsLoading
                    }
                  >
                    <Accordion allowMultiple index={accordionIndex}>
                      {(!getFormGroupIsLoading ||
                        !getFormBuilderInitialDataIsLoading) &&
                        (getFormGroupData || getFormBuilderInitialData) &&
                        groupList
                          ?.filter((i) => {
                            return i.type === 'regular';
                          })
                          .map((item, inx) => {
                            return (
                              <AppBox key={inx}>
                                <RenderAccordion
                                  data={item}
                                  index={inx}
                                  menu={menu}
                                  onShowEditor={onShowEditor}
                                  drop={drop}
                                  dragOver={dragOver}
                                  fieldDragStart={fieldDragStart}
                                  settingsHandler={settingsHandler}
                                  copyHandler={copyHandler}
                                  selectedComponent={selectedComponent}
                                  formGroupDeleteHandler={
                                    formGroupDeleteHandler
                                  }
                                  componentHandler={componentHandler}
                                  accordionIndex={accordionIndex}
                                  setToIndex={setToIndex}
                                  setAccordionIndex={setAccordionIndex}
                                  parentRef={formbuilderRef.current}
                                  type="regular"
                                />
                              </AppBox>
                            );
                          })}
                    </Accordion>
                  </AppLoader>
                  <AppModal
                    isOpen={isCopyOpen}
                    onClose={copyOnClose}
                    closeCheck={true}
                    size="xl"
                    text={`Copied: ${copyItem?.identity}`}
                  >
                    <AppText text={'Choose a Group to Paste'} />
                    <Select
                      onChange={(e) => {
                        setFormGroupId(e.target.value);
                      }}
                      marginTop="10px"
                    >
                      <option value="" hidden>
                        Select Group
                      </option>
                      {groupList?.map((item) => {
                        return (
                          <option key={item?.id} value={item?.id}>
                            {item?.identity}
                          </option>
                        );
                      })}
                    </Select>
                    <Flex marginTop="20px" paddingBottom="20px" gap="10px">
                      <AppButton
                        text="Cancel"
                        variant="cancelBtn"
                        onClick={() => copyOnClose()}
                      />
                      <AppButton
                        text="Save"
                        type="submit"
                        onClick={() => submitCopy()}
                      />
                    </Flex>
                  </AppModal>

                  <Center>
                    <AppButton
                      text="New Group"
                      check={' '}
                      variant="outline"
                      customStyles={{
                        width: '685px',
                        marginTop: '30px',
                      }}
                      onClick={() => onNewGroup()}
                    >
                      <Icon as={BsPlusCircle} alt="icon" mr="15px" />
                    </AppButton>
                  </Center>
                </Stack>
              </Flex>
            ) : (
              <CaseCade
                getMetaData={getMetaData}
                getMetaFormData={getMetaFormData}
                formGroupUpdate={formGroupUpdate}
                setActiveState={setActiveState}
                activeState={activeState}
                onNewGroup={onNewGroup}
                widgetDragStart={widgetDragStart}
                getFormGroupUpdateMetaIsLoading={
                  getFormGroupUpdateMetaIsLoading
                }
                formDetails={formDetails}
                setFormDetails={setFormDetails}
                formGroupError={formGroupError}
                getFormGroupUpdateIsLoading={getFormGroupUpdateIsLoading}
                getFormGroupUpdateMutation={getFormGroupUpdateMutation}
                getFormGroup={getFormGroup}
                initialState={initialState}
                params={params}
                getFormGroupIsLoading={getFormGroupIsLoading}
                getFormBuilderInitialDataIsLoading={
                  getFormBuilderInitialDataIsLoading
                }
                accordionIndex={accordionIndex}
                getFormGroupData={getFormGroupData}
                getFormBuilderInitialData={getFormBuilderInitialData}
                menu={menu}
                onShowEditor={onShowEditor}
                drop={drop}
                dragOver={dragOver}
                fieldDragStart={fieldDragStart}
                settingsHandler={settingsHandler}
                selectedComponent={selectedComponent}
                formGroupDeleteHandler={formGroupDeleteHandler}
                componentHandler={componentHandler}
                setToIndex={setToIndex}
                setAccordionIndex={setAccordionIndex}
                groupList={groupList}
                RenderAccordion={RenderAccordion}
                activeTabIndex={activeTabIndex}
                copyHandler={cascadeCopyHandler}
              />
            )}
            <AppModal
              isOpen={isCascadeCopyOpen}
              onClose={cascadeCopyOnClose}
              closeCheck={true}
              size="xl"
              text={`Copied: ${copyItem?.identity}`}
            >
              <AppText text={'Choose a Group to Paste'} />
              <Select
                onChange={(e) => {
                  setFormGroupId(e.target.value);
                }}
                marginTop="10px"
              >
                <option value="" hidden>
                  Select Group
                </option>
                {groupList?.map((item) => {
                  return (
                    <option key={item?.id} value={item?.id}>
                      {item?.identity}
                    </option>
                  );
                })}
              </Select>
              <Flex marginTop="20px" paddingBottom="20px" gap="10px">
                <AppButton
                  text="Cancel"
                  variant="cancelBtn"
                  onClick={() => cascadeCopyOnClose()}
                />
                <AppButton
                  text="Save"
                  type="submit"
                  onClick={() => submitCopy()}
                />
              </Flex>
            </AppModal>
          </>
        )}
      </AppLoader>
      <AppRightDrawer
        isOpen={propertiesIsOpen}
        onClose={propertiesOnClose}
        properityForm={properityForm}
        setError={setError}
        setProperityForm={setProperityForm}
        setInitialProperityData={setInitialProperityData}
        setDraggedComponent={setDraggedComponent}
        text="Properties"
        saveHandler={saveHandler}
        isLoading={getFormFieldIsLoading || getFormFieldUpdationIsLoading}
      >
        <>
          {formFields.map((item) => {
            return (
              <>
                <AppText
                  text={item.label}
                  customStyles={{ marginBottom: '0.5rem', display: 'flex' }}
                  isRequired={item.isRequired}
                />
                <AppInput
                  fieldName={item.fieldName}
                  formValues={properityForm}
                  setFormValues={setProperityForm}
                  fieldError={error}
                />
              </>
            );
          })}
          <AppSwitch
            isChecked={properityForm.is_required}
            label="Is Required"
            justifyContent="flex-start"
            fieldName="is_required"
            formValues={properityForm}
            setFormValues={setProperityForm}
          />

          {!getMetaIsLoading &&
            getMetaData &&
            Object.entries(
              getMetaData?.data?.field_types_config[
                initialProperityData.type || draggedComponent.type
              ]?.field_specific_validations || {},
            ).map((item) => {
              if (
                item[0] === 'single_selection_group_input_validation' &&
                activeTabIndex === 0 &&
                isCascededShow
              ) {
                return (
                  <>
                    <AppSwitch
                      isChecked={properityForm.is_cascaded}
                      label="Is Cascaded"
                      justifyContent="flex-start"
                      fieldName="is_cascaded"
                      formValues={properityForm}
                      setFormValues={setProperityForm}
                    />
                    {properityForm.is_cascaded && (
                      <>
                        <AppText
                          text={'Cascaded Title'}
                          customStyles={{ marginBottom: '0.5rem' }}
                        />

                        <AppInput
                          fieldType="text"
                          fieldName={
                            'single_selection_group_input_title_validation'
                          }
                          formValues={properityForm}
                          setFormValues={setProperityForm}
                        />

                        <AppText
                          text={
                            getMetaData?.data?.field_types_config[
                              initialProperityData.type || draggedComponent.type
                            ]?.field_specific_display?.[item[0]]
                          }
                          customStyles={{ marginBottom: '0.5rem' }}
                        />
                        <AppSelect
                          formValues={properityForm}
                          variant="filled"
                          fieldName={item[0]}
                          icon={MdArrowDropDown}
                          setFormValues={setProperityForm}
                          fieldError={error}
                          placeholder="select"
                        >
                          <GetOptionForSingleSelect
                            data={
                              getCascadeMetaData?.data?.cascaded_groups_data
                            }
                            initial={properityForm[item[0]]}
                          />
                        </AppSelect>
                      </>
                    )}
                  </>
                );
              } else if (
                item[1] === null &&
                item[0] !== 'single_selection_group_input_validation' &&
                item[0] !== 'single_selection_group_input_title_validation'
              ) {
                const textArr = item[0].split('_');

                let label = [];

                let type = [];

                textArr.map((txt) => {
                  if (txt === 'min') {
                    type.push('number');
                  } else if (txt === 'max') {
                    type.push('number');
                  }
                });

                if (item[0] === 'heading_sub_text_input') {
                  type.push('text');
                } else if (item[0] === 'heading_text_input') {
                  type.push('text');
                } else if (
                  item[0] === 'single_selection_input_choices_validation' ||
                  item[0] === 'multi_selection_input_choices_validation'
                ) {
                  type.push('text');
                } else if (
                  item[0] === 'date_input_from_date_validation' ||
                  item[0] === 'datetime_input_from_datetime_validation'
                ) {
                  label.push('From Date');
                } else if (
                  item[0] === 'date_input_to_date_validation' ||
                  item[0] === 'datetime_input_to_datetime_validation'
                ) {
                  label.push('To Date');
                }

                return (
                  <>
                    <AppText
                      text={
                        getMetaData?.data?.field_types_config[
                          initialProperityData.type || draggedComponent.type
                        ]?.field_specific_display?.[item[0]]
                      }
                      customStyles={{ marginBottom: '0.5rem' }}
                    />

                    {item[0] === 'date_input_from_date_validation' ||
                    item[0] === 'date_input_to_date_validation' ? (
                      <AppInputDate
                        fieldPlaceholder={initialProperityData[item[0]]}
                        margin="0px 0px 20px 0px"
                        fieldName={item[0]}
                        formValues={properityForm}
                        setFormValues={setProperityForm}
                      />
                    ) : item[0] ===
                        'single_selection_input_choices_validation' ||
                      item[0] === 'multi_selection_input_choices_validation' ? (
                      <Flex flexDirection="column">
                        {choiceCount[0] === 0 && draggedComponent.type && (
                          <Flex alignItems="center">
                            <AppInput
                              componentType={`${item[0]}_obj`}
                              fieldName={`${item[0]}_0`}
                              formValues={properityForm}
                              setFormValues={setProperityForm}
                              fieldError={error}
                            />

                            {choiceCount.length >= 2 ? (
                              <Flex marginBottom={5}>
                                <Icon
                                  as={MdOutlineDeleteOutline}
                                  onClick={() =>
                                    choiceDeleteHandler(
                                      0,
                                      `${item[0]}_${0}`,
                                      `${item[0]}_obj`,
                                    )
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    margin: '10px',
                                  }}
                                />
                              </Flex>
                            ) : (
                              <Flex marginBottom={5}>
                                <Icon
                                  as={MdAdd}
                                  onClick={() => {
                                    let lastNum =
                                      choiceCount[choiceCount.length - 1];

                                    setChoiceCount([
                                      ...choiceCount,
                                      lastNum + 1,
                                    ]);
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    margin: '10px',
                                  }}
                                />
                              </Flex>
                            )}
                          </Flex>
                        )}
                        {choiceArr.length > 0
                          ? choiceArr.map((index, idx) => {
                              let lastNum = choiceArr[choiceArr.length - 1];
                              let lastIndex = choiceArr.lastIndexOf(lastNum);
                              if (idx === lastIndex) {
                                return (
                                  <Flex alignItems="center" key={idx}>
                                    <AppInput
                                      componentType={`${item[0]}_obj`}
                                      fieldName={`${item[0]}_${index}`}
                                      formValues={properityForm}
                                      setFormValues={setProperityForm}
                                      fieldError={error}
                                      InputStyles={{ width: '351px' }}
                                    />

                                    {choiceArr.length === 1 ? (
                                      <Flex marginBottom={5}>
                                        <Icon
                                          as={MdAdd}
                                          onClick={() => {
                                            setChoiceArr([
                                              ...choiceArr,
                                              `${Math.random()
                                                .toString(16)
                                                .slice(2)}`,
                                            ]);
                                          }}
                                          style={{
                                            cursor: 'pointer',
                                            margin: '10px',
                                          }}
                                        />
                                      </Flex>
                                    ) : (
                                      <Flex marginBottom={5}>
                                        <Icon
                                          as={MdAdd}
                                          onClick={() => {
                                            setChoiceArr([
                                              ...choiceArr,
                                              `${Math.random()
                                                .toString(16)
                                                .slice(2)}`,
                                            ]);
                                          }}
                                          style={{
                                            cursor: 'pointer',
                                            margin: '10px',
                                          }}
                                        />
                                        <Icon
                                          as={MdOutlineDeleteOutline}
                                          onClick={() =>
                                            choiceArrDeleteHandler(
                                              index,
                                              `${item[0]}_${index}`,
                                              `${item[0]}_obj`,
                                            )
                                          }
                                          style={{
                                            cursor: 'pointer',
                                            margin: '10px 0px',
                                          }}
                                        />
                                      </Flex>
                                    )}
                                  </Flex>
                                );
                              } else {
                                return (
                                  <Flex alignItems="center" key={idx}>
                                    <AppInput
                                      componentType={`${item[0]}_obj`}
                                      fieldName={`${item[0]}_${index}`}
                                      formValues={properityForm}
                                      setFormValues={setProperityForm}
                                      fieldError={error}
                                    />

                                    <Flex marginBottom={5}>
                                      <Icon
                                        as={MdOutlineDeleteOutline}
                                        onClick={() =>
                                          choiceArrDeleteHandler(
                                            index,
                                            `${item[0]}_${index}`,
                                            `${item[0]}_obj`,
                                          )
                                        }
                                        style={{
                                          cursor: 'pointer',
                                          margin: '10px',
                                        }}
                                      />
                                    </Flex>
                                  </Flex>
                                );
                              }
                            })
                          : choiceCount
                              .filter((n) => n >= 1)
                              .map((num) => {
                                let lastNum =
                                  choiceCount[choiceCount.length - 1];

                                if (num === lastNum && choiceCount.length > 1) {
                                  return (
                                    <Flex alignItems="center" key={num}>
                                      <AppInput
                                        componentType={`${item[0]}_obj`}
                                        fieldName={`${item[0]}_${num}`}
                                        formValues={properityForm}
                                        setFormValues={setProperityForm}
                                        fieldError={error}
                                        InputStyles={{ width: '351px' }}
                                      />

                                      <Flex marginBottom={5}>
                                        <Icon
                                          as={MdAdd}
                                          onClick={() => {
                                            let lastNum =
                                              choiceCount[
                                                choiceCount.length - 1
                                              ];

                                            setChoiceCount([
                                              ...choiceCount,
                                              lastNum + 1,
                                            ]);
                                          }}
                                          style={{
                                            cursor: 'pointer',
                                            margin: '10px',
                                          }}
                                        />
                                        <Icon
                                          as={MdOutlineDeleteOutline}
                                          onClick={() =>
                                            choiceDeleteHandler(
                                              num,
                                              `${item[0]}_${num}`,
                                              `${item[0]}_obj`,
                                            )
                                          }
                                          style={{
                                            cursor: 'pointer',
                                            margin: '10px 0px',
                                          }}
                                        />
                                      </Flex>
                                    </Flex>
                                  );
                                } else {
                                  return (
                                    <Flex alignItems="center" key={num}>
                                      <AppInput
                                        componentType={`${item[0]}_obj`}
                                        fieldName={`${item[0]}_${num}`}
                                        formValues={properityForm}
                                        setFormValues={setProperityForm}
                                        fieldError={error}
                                      />

                                      {choiceCount.length === 1 ? (
                                        <Flex marginBottom={5}>
                                          <Icon
                                            as={MdAdd}
                                            onClick={() => {
                                              let lastNum =
                                                choiceCount[
                                                  choiceCount.length - 1
                                                ];

                                              setChoiceCount([
                                                ...choiceCount,
                                                lastNum + 1,
                                              ]);
                                            }}
                                            style={{
                                              cursor: 'pointer',
                                              margin: '10px',
                                            }}
                                          />
                                        </Flex>
                                      ) : (
                                        <Flex marginBottom={5}>
                                          <Icon
                                            as={MdOutlineDeleteOutline}
                                            onClick={() =>
                                              choiceDeleteHandler(
                                                num,
                                                `${item[0]}_${num}`,
                                                `${item[0]}_obj`,
                                              )
                                            }
                                            style={{
                                              cursor: 'pointer',
                                              margin: '10px',
                                            }}
                                          />
                                        </Flex>
                                      )}
                                    </Flex>
                                  );
                                }
                              })}
                      </Flex>
                    ) : (
                      type.map((inputType, idx) => {
                        return (
                          <AppInput
                            key={idx}
                            isFloat={
                              properityForm?.numeric_input_type_choices_validation ===
                              'decimal'
                                ? true
                                : false
                            }
                            fieldType={inputType}
                            fieldName={item[0]}
                            formValues={properityForm}
                            setFormValues={setProperityForm}
                            fieldError={error}
                          />
                        );
                      })
                    )}
                  </>
                );
              } else if (Array.isArray(item[1])) {
                return (
                  <>
                    <AppText
                      text={
                        getMetaData?.data?.field_types_config[
                          initialProperityData.type || draggedComponent.type
                        ]?.field_specific_display?.[item[0]]
                      }
                      customStyles={{ marginBottom: '0.5rem', display: 'flex' }}
                      isRequired={true}
                    />
                    <AppSelect
                      formValues={properityForm}
                      variant="filled"
                      fieldName={item[0]}
                      icon={MdArrowDropDown}
                      setFormValues={setProperityForm}
                      fieldError={error}
                    >
                      {Array.isArray(item[1]) && (
                        <>
                          <option>select option</option>
                          {item[1]?.map((data) => (
                            <option
                              key={data}
                              value={data}
                              selected={properityForm[item[0]] === data}
                            >
                              {data}
                            </option>
                          ))}
                        </>
                      )}
                    </AppSelect>
                  </>
                );
              } else if (item[1]) {
                const textArr = item[0].split('_');

                let label = [];

                textArr.map((txt) => {
                  if (txt === 'min') {
                    label.push('Min Value');
                  } else if (txt === 'max') {
                    label.push('Max Value');
                  }
                });

                return (
                  <>
                    <AppText
                      text={
                        getMetaData?.data?.field_types_config[
                          initialProperityData.type || draggedComponent.type
                        ]?.field_specific_display?.[item[0]]
                      }
                      customStyles={{ marginBottom: '0.5rem' }}
                    />

                    <AppInput
                      fieldName={item[0]}
                      formValues={properityForm}
                      setFormValues={setProperityForm}
                      defaultValue={item[1]}
                      disabled={true}
                      fieldError={error}
                    />
                  </>
                );
              }
            })}

          {showLogicFields.map((item) => {
            if (item.fieldType === 'select') {
              return (
                <>
                  <AppText
                    text={item.label}
                    customStyles={{ marginBottom: '0.5rem' }}
                    isRequired={item.isRequired}
                  />
                  <AppSelect
                    formValues={properityForm}
                    variant="filled"
                    fieldName={item.fieldName}
                    icon={MdArrowDropDown}
                    setFormValues={setProperityForm}
                    placeholder="select option"
                  >
                    {item.fieldOptions && (
                      <>
                        {item.fieldOptions
                          .filter((item) => item?.id !== selectedComponent?.id)
                          ?.map((data) => {
                            return (
                              <option
                                key={data}
                                value={data.id}
                                selected={
                                  properityForm[item.fieldName] === data.id
                                }
                              >
                                {data.identity}
                              </option>
                            );
                          })}
                      </>
                    )}
                  </AppSelect>
                </>
              );
            } else if (item.fieldType === 'number') {
              return (
                <>
                  <AppText
                    text={item.label}
                    customStyles={{ marginBottom: '0.5rem' }}
                    isRequired={item.isRequired}
                  />
                  <AppInput
                    fieldName={item.fieldName}
                    formValues={properityForm}
                    setFormValues={setProperityForm}
                  />
                </>
              );
            } else if (item.fieldType === 'toggle') {
              return (
                <AppSwitch
                  isChecked={properityForm.is_cascaded}
                  label={item.label}
                  justifyContent="flex-start"
                  fieldName={item.fieldName}
                  formValues={properityForm}
                  setFormValues={setProperityForm}
                />
              );
            }
          })}

          <AppText
            text={
              error.data?.['non_field_errors'] ||
              error?.data?.['show_logic_value_type']
            }
            variant="errorText"
          />
        </>
      </AppRightDrawer>
      <AppModal
        isOpen={modalIsOpen}
        onClose={modalOnClose}
        closeCheck={true}
        size="xl"
      >
        <AppText
          text="Are you sure you want to delete?"
          customStyles={{ mb: 5, textAlign: 'center' }}
        />
        <Flex>
          <AppButton
            text="Cancel"
            variant="cancelBtn"
            customStyles={{ margin: '0px 22px 27px 22px' }}
            onClick={modalOnClose}
          />
          <AppButton
            isLoading={
              getFormFieldDeletionIsLoading || getFormGroupDeletionIsLoading
            }
            text="Delete"
            variant="deleteBtn"
            onClick={() => onRemoveField()}
            customStyles={{ margin: '0px 22px 27px 0px' }}
          />
        </Flex>
      </AppModal>

      <AppModal
        isOpen={formGroupModalOpen}
        onClose={formGroupModalOnClose}
        closeCheck={true}
        size="xl"
      >
        <AppLoader height="100px" isLoading={getFormGroupUpdateMetaIsLoading}>
          <Stack textAlign={'center'}>
            <AppInput
              formValues={formDetails}
              variant="filled"
              isRequired={true}
              fieldName={'identity'}
              setFormValues={setFormDetails}
              fieldPlaceholder={'Name'}
              fieldError={formGroupError?.response?.data}
            />
            {/* <AppSelect
                formValues={formDetails}
                variant="filled"
                isRequired={true}
                fieldName={'status'}
                icon={MdArrowDropDown}
                setFormValues={setFormDetails}
                placeholder={formDetails.status || 'Select Status'}
                fieldError={formGroupError?.response?.data}
              >
                {getFormGroupMetaData?.data?.meta?.statuses?.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.identity}
                  </option>
                ))}
              </AppSelect> */}
            <Flex justifyContent={'center'}>
              <AppButton
                text={'Cancel'}
                variant="outline"
                customStyles={{
                  marginRight: '20px',
                  marginBottom: '20px',
                  width: '100px',
                }}
                onClick={() => formGroupModalOnClose()}
              />
              <AppButton
                isLoading={getFormGroupUpdateIsLoading || getFormGroupIsLoading}
                onClick={() => onAddAccordion()}
                text={'OK'}
                customStyles={{
                  width: '100px',
                  marginBottom: '20px',
                }}
              />
            </Flex>
          </Stack>
        </AppLoader>
      </AppModal>
    </DashboardLayout>
  );
};

export default FormBuilder;
