import {
  Accordion,
  Box,
  Center,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Stack,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import AppBox from 'components/AppBox/AppBox';
import AppButton from 'components/AppButton/AppButton';
import AppCard from 'components/AppCard/AppCard';
import AppInput from 'components/AppInput/AppInput';
import AppLoader from 'components/AppLoader/AppLoader';
import AppModal from 'components/AppModal/AppModal';
import AppText from 'components/typography/AppText';
import React, { useRef } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import appColors from 'theme/appColors';

import { FormWidgetsMeta } from '../DataSource';

const CaseCade = ({
  getMetaFormData,
  getMetaData,
  formGroupUpdate,
  setActiveState,
  activeState,
  widgetDragStart,
  getFormGroupUpdateMetaIsLoading,
  formDetails,
  setFormDetails,
  formGroupError,
  getFormGroupUpdateIsLoading,
  getFormGroupUpdateMutation,
  getFormGroup,
  initialState,
  params,
  getFormGroupIsLoading,
  getFormBuilderInitialDataIsLoading,
  accordionIndex,
  getFormGroupData,
  getFormBuilderInitialData,
  menu,
  onShowEditor,
  drop,
  dragOver,
  fieldDragStart,
  settingsHandler,
  selectedComponent,
  formGroupDeleteHandler,
  componentHandler,
  setToIndex,
  setAccordionIndex,
  groupList,
  RenderAccordion,
  activeTabIndex,
  copyHandler,
}) => {
  const {
    isOpen: cascadeGroupModalOpen,
    onOpen: cascadeGroupModalOnOpen,
    onClose: cascadeGroupModalOnClose,
  } = useDisclosure();
  const cascadeRef = useRef();
  const onAddAccordion = () => {
    if (initialState.isEdit) {
      getFormGroupUpdateMutation({
        identity: formDetails.identity,
        type: activeTabIndex === 0 ? 'regular' : 'cascaded',
        status: formDetails.status,
        form: params.slug,
      });
      cascadeGroupModalOnClose();
    } else {
      getFormGroup({
        identity: formDetails.identity,
        type: activeTabIndex === 0 ? 'regular' : 'cascaded',
        status: formDetails.status,
        form: params.slug,
      });
      cascadeGroupModalOnClose();
    }
  };

  return (
    <>
      <Flex
        gap="15px"
        overflowY="auto"
        height="79vh"
        position="relative"
        ref={cascadeRef}
      >
        <Stack
          w="415px"
          h="790px"
          borderRight="0.5px solid #C3C4CC"
          borderBottom={'0.5px solid #C3C4CC'}
          background={appColors.brandWhite[100]}
          position="sticky"
          height="79vh"
          top="0px"
        >
          <AppText
            customStyles={{
              padding: '25px 0 19px 20px',
              borderBottom: '0.5px solid #CDCDCD',
            }}
            text="Field Types"
          />
          <Box p="10px 20px 0 20px">
            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              {Object.entries(getMetaData?.data?.field_types_config || {}).map(
                (item, inx) => {
                  if (item[0] !== 'cascaded') {
                    let widget = FormWidgetsMeta[item[0]];
                    return (
                      <GridItem
                        data-group
                        key={inx}
                        w="175px"
                        h="39px"
                        mb="3px"
                        draggable
                        style={{
                          border: '1px solid #CDCDCD',
                          borderRadius: '4px',
                        }}
                        _hover={{
                          borderColor: `${appColors.brandTeal[500]} !important`,
                          color: appColors.brandTeal[500],
                        }}
                        onDragStart={(e) => widgetDragStart(item, e)}
                      >
                        <AppCard
                          customStyles={{
                            fontSize: '14px',
                            lineHeight: '21px',
                            paddingLeft: '14px',
                            justifyContent: 'flex-start',
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            marginTop: 0,
                            cursor: 'move',
                            background: 'transparent',
                          }}
                        >
                          <Box
                            _groupHover={{
                              filter:
                                'invert(65%) sepia(12%) saturate(2587%) hue-rotate(119deg) brightness(100%) contrast(84%)',
                            }}
                          >
                            <img
                              src={widget?.icon}
                              alt="icon"
                              style={{
                                marginRight: '10px',
                                fontSize: '16px',
                              }}
                            />
                          </Box>
                          <AppText
                            text={widget?.name}
                            customStyles={{
                              fontSize: '14px',
                              lineHeight: '21px',
                            }}
                          />
                        </AppCard>
                      </GridItem>
                    );
                  }
                },
              )}
            </Grid>
          </Box>
        </Stack>
        <Stack
          w="60%"
          border="1px solid #E0E6FF"
          backgroundColor={appColors.brandWhite[100]}
          boxShadow="0px -3px 9px rgba(21, 38, 38, 0.12)"
          minHeight="500px"
          height="max-content"
          p="30px 0"
          mt="30px"
          mb="30px"
        >
          <Flex
            justifyContent={'space-between'}
            pb="30px"
            borderBottom="1px solid #CDCDCD"
          >
            <AppText
              customStyles={{
                fontSize: '20px',
                lineHeight: '26px',
                fontWeight: 600,
                marginLeft: '50px',
              }}
              text={getMetaFormData?.data?.initial?.identity || 'Residential'}
            />
            <Center mr="21px" w="150px" justifyContent={'flex-start'}>
              <Switch
                isChecked={activeState}
                colorScheme="teal"
                id="status"
                size="md"
                style={{
                  marginRight: '10px',
                }}
                onChange={(args) => {
                  formGroupUpdate({
                    ...getMetaFormData?.data?.initial,
                    status:
                      args?.target?.checked === true ? 'active' : 'inactive',
                  });
                  setActiveState(args?.target?.checked);
                }}
              />
              <FormLabel mb={'0px !important'} htmlFor="status">
                {activeState ? 'Active' : 'InActive'}
              </FormLabel>
            </Center>
          </Flex>
          <AppLoader
            height="100px"
            isLoading={
              getFormGroupIsLoading || getFormBuilderInitialDataIsLoading
            }
          >
            <Accordion allowMultiple index={accordionIndex}>
              {(!getFormGroupIsLoading ||
                !getFormBuilderInitialDataIsLoading) &&
                (getFormGroupData || getFormBuilderInitialData) &&
                groupList
                  ?.filter((i) => {
                    return i.type === 'cascaded';
                  })
                  .map((item, inx) => {
                    return (
                      <AppBox key={item.id}>
                        <RenderAccordion
                          data={item}
                          index={inx}
                          menu={menu}
                          onShowEditor={onShowEditor}
                          drop={drop}
                          dragOver={dragOver}
                          fieldDragStart={fieldDragStart}
                          settingsHandler={settingsHandler}
                          selectedComponent={selectedComponent}
                          formGroupDeleteHandler={formGroupDeleteHandler}
                          componentHandler={componentHandler}
                          accordionIndex={accordionIndex}
                          setToIndex={setToIndex}
                          setAccordionIndex={setAccordionIndex}
                          parentRef={cascadeRef.current}
                          copyHandler={copyHandler}
                          type="cascaded"
                        />
                      </AppBox>
                    );
                  })}
            </Accordion>
          </AppLoader>

          <Center>
            <AppButton
              text="New Cascade Group"
              check={' '}
              variant="outline"
              customStyles={{
                width: '685px',
                marginTop: '30px',
              }}
              onClick={() => cascadeGroupModalOnOpen()}
            >
              <Icon as={BsPlusCircle} alt="icon" mr="15px" />
            </AppButton>
          </Center>
        </Stack>
      </Flex>

      <AppModal
        isOpen={cascadeGroupModalOpen}
        onClose={cascadeGroupModalOnClose}
        closeCheck={true}
        size="xl"
      >
        <AppLoader height="100px" isLoading={getFormGroupUpdateMetaIsLoading}>
          <Stack textAlign={'center'}>
            <AppInput
              formValues={formDetails}
              variant="filled"
              isRequired={true}
              fieldName={'identity'}
              setFormValues={setFormDetails}
              fieldPlaceholder={'Name'}
              fieldError={formGroupError?.response?.data}
            />

            <Flex justifyContent={'center'}>
              <AppButton
                text={'Cancel'}
                variant="outline"
                customStyles={{
                  marginRight: '20px',
                  marginBottom: '20px',
                  width: '100px',
                }}
                onClick={() => cascadeGroupModalOnClose()}
              />
              <AppButton
                isLoading={getFormGroupUpdateIsLoading || getFormGroupIsLoading}
                onClick={() => onAddAccordion()}
                text={'OK'}
                customStyles={{
                  width: '100px',
                  marginBottom: '20px',
                }}
              />
            </Flex>
          </Stack>
        </AppLoader>
      </AppModal>
    </>
  );
};

export default CaseCade;
