import { theme as baseTheme } from '@chakra-ui/react';
import appColors from 'theme/appColors';
import { fontSizes } from 'theme/typographyStyles';

const InputStyles = {
  baseStyle: {},
  variants: {
    filled: {
      field: {
        fontSize: fontSizes.sm,
        height: '50px',
        color: appColors.brandGray['500'],
        backgroundColor: appColors.brandTeal['300'],

        _disabled: {
          backgroundColor: appColors.brandTeal['300'],
          opacity: 0.8,
        },
      },

      _placeholder: {
        fontSize: fontSizes.sm,
      },
    },
    fileUploadFilled: {
      field: {
        ...baseTheme.components.Input.variants.filled,

        fontSize: fontSizes.sm,

        height: '50px',

        color: appColors.brandGray['500'],
        backgroundColor: appColors.brandTeal['300'],
      },
    },
    searchField: {
      field: {
        fontSize: fontSizes.sm,
        height: '50px',
        color: appColors.brandGray['iconGrey'],
        backgroundColor: appColors.brandWhite['100'],
      },

      _placeholder: {
        fontSize: fontSizes.sm,
      },
    },
    tableSearch: {
      field: {
        fontSize: fontSizes.sm,
        height: '30px',
        width: '154px',
        color: appColors.brandGray['iconGrey'],
        backgroundColor: appColors.brandWhite['100'],
      },

      _placeholder: {
        fontSize: fontSizes.sm,
      },
    },
  },
};

export default InputStyles;
