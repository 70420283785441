/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
import 'react-dropzone-uploader/dist/styles.css';

import { Flex } from '@chakra-ui/react';
import Card from 'components/AppCard/AppCard';
import AppText from 'components/typography/AppText';
import React from 'react';
import Dropzone from 'react-dropzone-uploader';
import { RiUploadCloudFill } from 'react-icons/ri';
import appColors from 'theme/appColors';
import { fonts } from 'theme/typographyStyles';

const AppDragAndDropUploader = ({ onChange, onSubmit, errors }) => {
  return (
    <Card
      customStyles={{ padding: '30px 30px 18px 30px', borderRadius: '12px' }}
    >
      <Dropzone
        accept=".xlsx"
        onChangeStatus={onChange}
        onSubmit={onSubmit}
        multiple={false}
        maxFiles={1}
        inputContent={
          <Flex alignItems="center">
            <RiUploadCloudFill
              style={{
                width: '41.67px',
                height: '29.17px',
                color: appColors.brandGray['iconGrey'],
              }}
            />
            <AppText text="Drag & Drop files here or " variant="filterText" />
            <AppText
              text="Choose file"
              variant="filterText"
              customStyles={{
                color: appColors.brandTeal[800],
                marginLeft: '4px',
              }}
            />
          </Flex>
        }
        styles={{
          dropzone: {
            overflow: 'hidden',
            border: '1px dashed #8F8F8F',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          inputLabelWithFiles: {
            margin: '20px 3%',
            background: appColors.brandTeal[500],
            color: '#ffffff',
            fontFamily: fonts.body,
            padding: '15px 24px',
          },
          preview: {
            borderBottom: 'hidden',
            padding: '20px 3%',
          },
          submitButtonContainer: {
            textAlign: 'end',
            width: '100%',
            margin: '0px',
            padding: '0px 10px',
          },
          submitButton: {
            fontFamily: 'Inter, sans-serif',
            fontWeight: 500,
            background: appColors.brandTeal['500'],
          },
        }}
      />
      <Flex justifyContent="space-between" marginTop="10px">
        <AppText
          text="File supported: XLS"
          variant="innerLabelText"
          customStyles={{ color: appColors.brandGray['iconGrey'] }}
        />
        <AppText
          text="Maxium file size: 50 MB"
          variant="innerLabelText"
          customStyles={{ color: appColors.brandGray['iconGrey'] }}
        />
      </Flex>
    </Card>
  );
};

export default AppDragAndDropUploader;
