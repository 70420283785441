import { Flex } from '@chakra-ui/react';
import AppButton from 'components/AppButton/AppButton';
import Card from 'components/AppCard/AppCard';
import AppInput from 'components/AppInput/AppInput';
import AppLabel from 'components/AppLabel/AppLabel';
import AppTextArea from 'components/AppTextArea/AppTextArea';
import AppHeader from 'components/typography/AppHeader';
import DashboardLayout from 'layout/DashboardLayout';
import { APP_EMAIL_TEMPLATES } from 'navigation/routes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import appColors from 'theme/appColors';
import { fontSizes } from 'theme/typographyStyles';

const EmailTemplatesAdd = () => {
  const [formValues, setFormValues] = useState({});
  const navigate = useNavigate();
  const formFields = [
    {
      fieldPlaceholder: '',
      fieldType: 'email',
      fieldName: 'from',
      label: 'From',
    },
    {
      fieldPlaceholder: '',
      fieldType: 'email',
      fieldName: 'to',
      label: 'To',
    },
    {
      fieldPlaceholder: '',
      fieldType: 'text',
      fieldName: 'subject',
      label: 'Subject',
    },
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    navigate(APP_EMAIL_TEMPLATES);
  };

  return (
    <DashboardLayout menu={true} type="Settings" menuName="Email Templates">
      <Flex>
        <AppHeader
          text="Add email template"
          variant="heading"
          customStyles={{ margin: '0px' }}
        />
      </Flex>
      <Card variant={'tableCard'}>
        <form onSubmit={handleFormSubmit}>
          <Flex direction="column">
            {formFields.map((field, i) => (
              <Flex mt={4} key={i} width="100%" alignItems="center">
                <AppLabel
                  fieldName={field.label}
                  customStyles={{
                    fontSize: fontSizes.sm,
                    fontWeight: 400,
                    color: appColors.brandBlack['600'],
                    width: '8%',
                  }}
                />
                <AppInput
                  variant="outline"
                  {...field}
                  key={field.fieldName}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Flex>
            ))}
            <Flex width="100%" justifyContent="flex-end" mt={4}>
              <AppTextArea
                variant="outline"
                fieldName="content"
                formValues={formValues}
                setFormValues={setFormValues}
                customStyles={{ width: '91.5%', height: '250px' }}
              />
            </Flex>
            <Flex justifyContent="flex-end">
              <AppButton
                type="submit"
                text="Create"
                customStyles={{
                  margin: '27px 0px 0px 0px',
                  width: 'auto',
                }}
                isLoading=""
              />
            </Flex>
          </Flex>
        </form>
      </Card>
    </DashboardLayout>
  );
};

export default EmailTemplatesAdd;
