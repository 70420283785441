import AppText from 'components/typography/AppText';
import React from 'react';

const AppDetail = ({ label, value, customStyles }) => {
  return (
    <div style={{ ...customStyles }}>
      <AppText text={label} variant="labelText" />
      <AppText text={value} variant="valueText" />
    </div>
  );
};

export default AppDetail;
