import { Box, Flex, Input, Text } from '@chakra-ui/react';
import _ from 'lodash';
import {
  APP_DASHBOARD,
  APP_PROFILE,
  APP_PROJECTS,
  APP_PROJECTS_PAGE,
  APP_PROJECTS_TYPES,
  APP_REPORTS,
  APP_SURVEY_TEMPLATES,
  APP_SURVEY_TYPES,
  COMPANY_SETTING_UPDATE,
  TEAM_MEMBERS,
} from 'navigation/routes';
import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import appColors from 'theme/appColors';

function AppOverallSearch({
  listOfResult,
  searchInput,
  setSearchInput,
  setSearchStringToQueryParam,
  rightIcon,
  customStyles,
}) {
  const navigate = useNavigate();
  const handleSearchResutlClick = (id) => {
    const sideBarTabData = [
      {
        id: 'company_settings',
        display: 'Company Settings',
        path: COMPANY_SETTING_UPDATE,
      },
      {
        id: 'project_types',
        display: 'Project Types',
        path: APP_PROJECTS_TYPES,
      },
      {
        id: 'survey_types',
        display: 'Survey Types',
        path: APP_SURVEY_TYPES,
      },
      {
        id: 'survey_templates',
        display: 'Survey Templates',
        path: APP_SURVEY_TEMPLATES,
      },
      {
        id: 'team_management',
        display: 'Team Management',
        path: TEAM_MEMBERS,
      },
      {
        id: 'reports',
        display: 'Reports',
        path: APP_REPORTS,
      },
      {
        id: 'my_profile',
        display: 'My Profile',
        path: APP_PROFILE,
      },
      {
        id: 'home',
        display: 'Home',
        path: APP_DASHBOARD,
      },
      {
        id: 'projects',
        display: 'Projects',
        path: APP_PROJECTS,
      },
    ];
    const side = sideBarTabData.filter((item) => item.id === id);
    const exist = listOfResult?.data?.filter((item) => item.id == id);
    if (side[0]) {
      navigate(side[0].path);
    } else if (exist[0].type === 'project_object') {
      navigate(APP_PROJECTS_PAGE(id));
    }
  };
  // const debounceResults = useCallback(() => {
  //   _.debounce(() => setSearchStringToQueryParam(searchInput), 2000);
  // }, []);
  return (
    <Flex alignItems="center" style={customStyles}>
      <Box pos="relative" left="1.90em" zIndex="1">
        <FiSearch color={appColors.brandGray['iconGrey']} />
      </Box>
      <Box className="dropdown" rounded="md">
        <Input
          type="text"
          variant="filled"
          paddingLeft="2.5em"
          placeholder="Search any customer name, etc..."
          outline="none"
          border="hidden"
          rounded="md"
          background="inherit"
          _placeholder={{
            fontSize: '14px',
          }}
          onChange={({ currentTarget }) => {
            setSearchInput(currentTarget.value);
            _.debounce(() => {
              setSearchStringToQueryParam(currentTarget.value);
            }, 600)();
          }}
          value={searchInput}
          id="search"
        />
        {searchInput && (
          <>
            {listOfResult?.data && (
              <div className="dropdown-content">
                {listOfResult?.data?.map((eachResult) => (
                  <Text
                    key={eachResult?.id}
                    onClick={() => handleSearchResutlClick(eachResult?.id)}
                    value={eachResult?.id}
                    style={{ color: 'black' }}
                  >
                    {eachResult?.display
                      ? eachResult?.display
                      : eachResult?.identity}
                  </Text>
                ))}
                {listOfResult?.data?.length === 0 && <Text>Not Available</Text>}
              </div>
            )}
          </>
        )}
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            right: '0px',
            transform: 'translateY(-50%)',
          }}
        >
          {rightIcon}
        </Box>
      </Box>

      <style jsx="true">
        {`
          .dropdown {
            position: relative;
            display: inline-block;
            width: 50%;
            background-color: rgb(240, 240, 240);
          }
          .dropdown-content {
            position: absolute;
            background-color: white;
            min-width: 160px;
            box-shadow: -1px 0px 2px rgba(21, 38, 38, 0.13);
            z-index: 1;
            cursor: pointer;
            width: 100%;
            max-height: 500px;
            overflow-y: auto;
            padding: 5px 0px;
            border-radius: 5px;
            margin-top: 8px;
          }
          .dropdown-content p {
            padding: 0.35em 0.75em;
          }
          // .dropdown:hover .dropdown-content {
          //   display: block;
          // }
          .dropdown-content p:hover {
            background-color: #edf2f7;
          }
        `}
      </style>
    </Flex>
  );
}
export default AppOverallSearch;
