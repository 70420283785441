import { Box, Center, Flex, Stack } from '@chakra-ui/react';
import {
  QC_SCREEN_DATA,
  QC_SCREEN_IMAGES_AS_ZIP,
} from 'api/urls/settings/qcScreen';
import makeGetRequest from 'api/utilities/makeGetRequest';
import AppBox from 'components/AppBox/AppBox';
import AppButton from 'components/AppButton/AppButton';
import AppLoader from 'components/AppLoader/AppLoader';
import AppText from 'components/typography/AppText';
import { useEffect, useState } from 'react';
import { MdDownload, MdOutlineContentCopy } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import appColors from 'theme/appColors';

import GroupImages from './groupImages/GroupImages';

const TextStyle = {
  fontSize: '18px',
  lineHeight: '40px',
  whiteSpace: 'normal',
  maxWidth: '400px',
};

const ViewProjectImages = () => {
  const params = useParams();

  useEffect(() => {
    getQcMetadata();
  }, []);

  const {
    mutate: getQcMetadata,
    data: qcMetaData,
    isLoading: qcMetaIsLoading,
  } = useMutation(QC_SCREEN_DATA(params?.slug), () =>
    makeGetRequest(QC_SCREEN_DATA(params.slug)),
  );

  const {
    mutate: getImagedata,
    data: qcImageMetaData,
    isLoading: qcImageIsLoading,
  } = useMutation(
    QC_SCREEN_IMAGES_AS_ZIP(params?.slug),
    () => makeGetRequest(QC_SCREEN_IMAGES_AS_ZIP(params.slug)),
    {
      onSuccess: () => {
        // window.open(response?.data?.download_url, '_blank');
      },
    },
  );

  const onDownloadAsZip = () => {
    getImagedata();
  };

  const [copied, setCopied] = useState(false);

  const copyUrlHandler = () => {
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };

  useEffect(() => {
    let url = qcImageMetaData?.data?.download_url;

    if (url) {
      let modify;

      if (url.split(':')[0] === 'http') {
        modify = 'https:' + url.split(':')[1];
      }

      const a = document.createElement('a');
      a.href = modify;
      a.download = 'download';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // window.open(url);
    }
    document.body.style.overflow = 'auto';
  }, [qcImageMetaData]);

  let imageContent = qcMetaData?.data?.images || [];

  return (
    <Box m={'0 auto'} padding={'55px 0px'} width="80vw">
      <AppLoader height="100px" isLoading={qcMetaIsLoading}>
        {qcMetaData ? (
          <>
            <Center>
              <AppText
                text={'Image Gallery'}
                customStyles={{
                  fontSize: '22px',
                  lineHeight: '27px',
                  fontWeight: 700,
                }}
              />
            </Center>
            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              p={'35px 30px 40px'}
            >
              <Stack>
                <Flex>
                  <AppText text={'Survey ID : '} customStyles={TextStyle} />
                  <AppText
                    text={params?.slug}
                    customStyles={{
                      ...TextStyle,
                      marginLeft: '10px',
                    }}
                  />
                </Flex>
                <Flex>
                  <AppText
                    text={'Address : '}
                    customStyles={{
                      ...TextStyle,
                      minWidth: '85px',
                    }}
                  />
                  <AppText
                    text={qcMetaData?.data?.address || ''}
                    customStyles={{
                      ...TextStyle,
                      marginLeft: '10px',
                    }}
                  />
                </Flex>
              </Stack>
              <Flex>
                <AppButton
                  text="Download All Images"
                  variant="downloadBtn"
                  onClick={() => onDownloadAsZip()}
                  isLoading={qcImageIsLoading}
                  customStyles={{
                    width: '220px',
                    height: '46px',
                    background: appColors.brandGray[400],
                  }}
                >
                  <MdDownload
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '10px',
                    }}
                  />
                </AppButton>

                <AppButton
                  text={copied ? 'Copied!' : 'Copy URL'}
                  onClick={() => copyUrlHandler()}
                  customStyles={{ width: '140px', marginLeft: '15px' }}
                >
                  {!copied && (
                    <MdOutlineContentCopy
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '10px',
                        color: appColors.brandWhite['100'],
                      }}
                    />
                  )}
                </AppButton>
              </Flex>
            </Flex>
            <AppBox
              customStyles={{
                background: appColors.brandWhite[100],
                width: '80vw',
                margin: 'auto',
                height: 'auto',
                borderRadius: '12px',
                padding: '30px',
                boxShadow: '0px 0px 48px rgba(27, 178, 147, 0.1)',
              }}
            >
              <GroupImages
                data={imageContent}
                width={'295px'}
                height={'190px'}
                isDownload={true}
                itemHeight={'240px'}
                itemWidth={'295px'}
                isHover={true}
              />
            </AppBox>
          </>
        ) : null}
      </AppLoader>
    </Box>
  );
};

export default ViewProjectImages;
