import { Box, Flex } from '@chakra-ui/react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { capitalize } from 'constants';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      min: 0,
      max: 100,
      ticks: {
        stepSize: 20,
      },
      grid: { borderDash: [4, 4], drawBorder: false },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
  maintainAspectRatio: false,
};

const colors = ['#FF5E5E', '#27B1FF', '#F2C962', '#FF8042'];
export function BarGraph({ reportdata }) {
  const labels = reportdata.map((item) => capitalize(item.display));

  const data = {
    labels,
    datasets: [
      {
        label: 'Value',
        data: reportdata.map((item) => item.value),
        backgroundColor: colors,
        barThickness: 30,
        borderRadius: 3,
      },
    ],
  };
  return (
    <Box>
      {reportdata.length !== 0 && (
        <Box height={280} padding="32px 10px 10px 10px">
          <Bar options={options} data={data} />
        </Box>
      )}
      {reportdata.length === 0 && (
        <Flex alignItems="center" padding="40px" justifyContent="center">
          Data Not Available
        </Flex>
      )}
    </Box>
  );
}
