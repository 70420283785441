const APP_LOGIN_SCREEN = '/login';
const APP_SIGNUP_SCREEN = '/signup';
const APP_FORGOT_PASSWORD_SCREEN = '/forgot-password';
const APP_CREATE_NEW_PASSWORD_SCREEN = '/create-new-password';
const APP_EMAIL_VERIFICATION_SCREEN = '/email-verification';
const ADD_TEAM_MEMBERS_SCREEN = '/add-team-members';
const DOWNLOAD_MOBILE_APP_SCREEN = '/download-mobile-app';

// Magic Link Verification - Forgot Password
const APP_USER_FORGOT_PASSWORD_MAGIC_LINK =
  '/user/forgot-password/:verificationToken/link-verification/set-password';
const GET_APP_USER_FORGOT_PASSWORD_MAGIC_LINK = (verifyToken) =>
  `/user/forgot-password/${verifyToken}/link-verification/set-password`;

// Magic Link Verification - Email Verification
const APP_USER_SIGNUP_MAGIC_LINK_VERIFICATION =
  '/organisation/sign-up/:verificationToken/verify/';
const GET_APP_USER_SIGNUP_MAGIC_LINK_VERIFICATION = (verifyToken) =>
  `/organisation/sign-up/${verifyToken}/verify`;

// Magic Link Verification - Invite Team Member
const APP_TEAM_MEMBER_INVITE_MAGIC_LINK_VERIFICATION =
  '/organisation/:userDatabaseID/access/user/invite/:userID/accept/';

const APP_TEAM_MEMBER_ONBOARDING_MAGIC_LINK_VERIFICATION =
  '/organisation/:userDatabaseID/onboarding/user/invite/:userID/accept/';

const APP_TEAM_MEMBER_PASSWORD_MAGIC_LINK =
  '/organisation/admin-invite/:verificationToken/set-password/';

export {
  ADD_TEAM_MEMBERS_SCREEN,
  APP_CREATE_NEW_PASSWORD_SCREEN,
  APP_EMAIL_VERIFICATION_SCREEN,
  APP_FORGOT_PASSWORD_SCREEN,
  APP_LOGIN_SCREEN,
  APP_SIGNUP_SCREEN,
  APP_TEAM_MEMBER_INVITE_MAGIC_LINK_VERIFICATION,
  APP_TEAM_MEMBER_ONBOARDING_MAGIC_LINK_VERIFICATION,
  APP_TEAM_MEMBER_PASSWORD_MAGIC_LINK,
  APP_USER_FORGOT_PASSWORD_MAGIC_LINK,
  APP_USER_SIGNUP_MAGIC_LINK_VERIFICATION,
  DOWNLOAD_MOBILE_APP_SCREEN,
  GET_APP_USER_FORGOT_PASSWORD_MAGIC_LINK,
  GET_APP_USER_SIGNUP_MAGIC_LINK_VERIFICATION,
};
