import { Flex, Icon, Image, Stack } from '@chakra-ui/react';
import { FILE_UPLOAD } from 'api/urls/settings/qcScreen';
import AppFileInput from 'components/AppFileInput/AppFileInput';
import AppText from 'components/typography/AppText';
import { memo } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BiFileBlank } from 'react-icons/bi';
import appColors from 'theme/appColors';

const AttachmentField = ({
  isEditable,
  state,
  setState,
  slug,
  error,
  index,
  type,
  item,
  fieldName,
}) => {
  let images = [];

  if (item?.question?.field_type === 'cascade') {
    images = JSON.parse(
      state?.[item?.question?.custom_id]?.[`${fieldName}_base64`],
    );
  } else {
    images = state?.[item?.question?.custom_id]?.[fieldName];
  }

  return (
    <Stack>
      {isEditable || Object.entries(images || {}).length === 0 ? (
        <Stack>
          <AppFileInput
            disabled={!isEditable}
            fieldName={fieldName}
            id="attachment"
            formValues={state}
            setFormValues={setState}
            icon={AiOutlineCloudUpload}
            uploadEndpoint={FILE_UPLOAD}
            urlSlug={slug}
            type={type}
            item={item}
          />
        </Stack>
      ) : null}
      {images?.map((item, index) => {
        let fileName = item?.file?.split('/');
        if (index <= 1) {
          return (
            <Stack>
              <Icon
                key={index}
                style={{
                  height: '40px',
                  width: '40px',
                  marginRight: '10px',
                }}
                as={BiFileBlank}
              />
              {fileName?.length > 0 ? (
                <AppText
                  title={fileName[fileName.length - 1]}
                  customStyles={{
                    width: '60px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  text={fileName[fileName.length - 1]}
                />
              ) : null}
            </Stack>
          );
        } else if (index === 2) {
          return (
            <Flex alignItems="center" position="relative">
              <Image
                key={index}
                style={{
                  height: '70px',
                  width: '80px',
                  marginRight: '10px',
                }}
                objectFit="cover"
                src={item.file}
                alt="roof image"
              />
              <Flex
                cursor="pointer"
                position="absolute"
                width="80px"
                height="70px"
                backgroundColor="rgba(0, 0, 0, 0.6)"
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  width="100%"
                >
                  <AppText
                    text={`+ ${
                      state?.[item?.question?.custom_id]?.[fieldName]?.length -
                      2
                    }`}
                    customStyles={{
                      color: `${appColors.brandWhite['100']}`,
                      fontWeight: 700,
                    }}
                  />

                  <AppText
                    text="more"
                    customStyles={{
                      color: `${appColors.brandWhite['100']}`,
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          );
        }
      })}
      <AppText
        text={
          error?.data?.answers_data[index]?.answer_file_attachment_input ||
          error?.data?.answers_data[index]?.non_field_errors
        }
        variant="errorMessage"
      />
    </Stack>
  );
};

export default memo(AttachmentField);
