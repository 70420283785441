export const PreprocessorData = (values, qcData) => {
  let finalObj = [];

  Object.entries(values).map((item) => {
    if (item[1]?.answer_single_selection_group_input?.length > 0) {
      let getCascadeData = [];
      Object.entries(values).map((cascade) => {
        qcData?.map((data) => {
          if (cascade[1]?.parent_question === data.question.id) {
            if (cascade[1]?.type === 'signature') {
              getCascadeData.push({
                ...cascade[1],
                question: cascade[1]?.id,
                answer_signature_input: cascade[1]?.answer_signature_input?.id,
              });
            } else if (cascade[1]?.type === 'image_attachment') {
              // let ids = [];
              // rest?.answer_image_attachment_input_base64?.map((img) => {
              //   return ids.push(img.id);
              // });

              getCascadeData.push({
                ...cascade[1],
                question: cascade[1]?.id,
                answer_image_attachment_input_base64:
                  cascade[1]?.answer_image_attachment_input_base64,
              });
            } else if (cascade[1]?.type === 'file_attachment') {
              let ids = [];
              cascade[1]?.answer_file_attachment_input?.map((img) => {
                return ids.push(img.id);
              });

              getCascadeData.push({
                ...cascade[1],
                question: cascade[1]?.id,
                answer_file_attachment_input: ids,
              });
            } else if (cascade[1]?.type === 'annotation') {
              getCascadeData.push({
                ...cascade[1],
                question: cascade[1]?.id,
                answer_annotation_input: [
                  cascade[1]?.answer_annotation_input?.id,
                ],
              });
            } else {
              getCascadeData.push({
                ...cascade[1],
                question: cascade[1]?.id,
              });
            }
          }
        });
      });

      const perChunk =
        item[1]?.answer_single_selection_group_input?.[0]?.length; // items per chunk

      const inputArray = getCascadeData;

      const result = inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

      finalObj.push({
        ...item[1],
        answer_single_selection_group_input: result,
      });
    } else if (
      item[1]?.field_type === 'regular' &&
      item[1]?.type === 'signature'
    ) {
      finalObj.push({
        ...item[1],
        answer_signature_input: item[1]?.answer_signature_input?.id,
      });
    } else if (
      item[1]?.field_type === 'regular' &&
      item[1]?.type === 'image_attachment'
    ) {
      let ids = [];
      item[1]?.answer_image_attachment_input?.map((img) => {
        return ids.push(img.id);
      });

      finalObj.push({
        ...item[1],
        answer_image_attachment_input: ids,
      });
    } else if (
      item[1]?.field_type === 'regular' &&
      item[1]?.type === 'file_attachment'
    ) {
      let ids = [];
      item[1]?.answer_file_attachment_input?.map((img) => {
        return ids.push(img.id);
      });

      finalObj.push({
        ...item[1],
        answer_file_attachment_input: ids,
      });
    } else if (
      item[1]?.field_type === 'regular' &&
      item[1]?.type === 'annotation'
    ) {
      finalObj.push({
        ...item[1],
        answer_annotation_input: [item[1]?.answer_annotation_input?.id],
      });
    } else if (
      item[1]?.field_type === 'regular' &&
      (item[1]?.type === 'heading' ||
        item[1]?.type === 'horizontal_divider' ||
        item[1]?.type === 'short_text' ||
        item[1]?.type === 'large_text' ||
        item[1]?.type === 'email_address' ||
        item[1]?.type === 'phone_number' ||
        item[1]?.type === 'numeric' ||
        item[1]?.type === 'address' ||
        item[1]?.type === 'google_address' ||
        item[1]?.type === 'single_selection' ||
        item[1]?.type === 'multi_selection' ||
        item[1]?.type === 'checkbox' ||
        item[1]?.type === 'url' ||
        item[1]?.type === 'date' ||
        item[1]?.type === 'datetime' ||
        item[1]?.type === 'pitch')
    ) {
      finalObj.push({
        ...item[1],
      });
    }
  });

  return finalObj;
};
