import { Box, Flex, Image, useColorModeValue } from '@chakra-ui/react';
import useStore from 'AppZustand/store';
import { SiteLogoSm, SiteScribeLogo } from 'assets';
import NavItem from 'components/AppDrawer/components/NavItem';
import AppText from 'components/typography/AppText';
import {
  APP_DASHBOARD,
  APP_PROJECTS,
  APP_PROJECTS_TYPES,
  APP_REPORTS,
  APP_SURVEY_TEMPLATES,
  APP_SURVEY_TYPES,
  COMPANY_SETTING_UPDATE,
  // PDF_Builder_List,
  TEAM_MEMBERS,
} from 'navigation/routes';
import { useEffect, useState } from 'react';
import { BsBuilding } from 'react-icons/bs';
// import { FaFilePdf } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { HiDocumentReport } from 'react-icons/hi';
import { MdHomeFilled, MdStickyNote2 } from 'react-icons/md';
import { RiPlayList2Fill, RiSurveyFill, RiTeamFill } from 'react-icons/ri';
import appColors from 'theme/appColors';
import { fontSizes } from 'theme/typographyStyles';

const LinkItems = [
  {
    id: 1,
    name: 'Home',
    link: APP_DASHBOARD,
    icon: MdHomeFilled,
    isMenu: false,
    subMenu: [],
  },
  {
    id: 2,
    name: 'Projects',
    icon: MdStickyNote2,
    link: APP_PROJECTS,
    isMenu: false,
    subMenu: [],
    permissionKey: ['all_projects_view', 'only_assigned_projects_view'],
  },
  // { id: 3, name: 'Reports', icon: BiListCheck, isMenu: false, subMenu: [] },
  {
    id: 4,
    name: 'Settings',
    icon: FiSettings,
    link: COMPANY_SETTING_UPDATE,
    isMenu: true,
    subMenu: [
      {
        subid: 1,
        name: 'Company Settings',
        link: COMPANY_SETTING_UPDATE,
        icon: BsBuilding,
        isMenu: false,
        permissionKey: 'company_setting_view',
      },
      {
        subid: 2,
        name: 'Project Types',
        link: APP_PROJECTS_TYPES,
        icon: MdStickyNote2,
        isMenu: false,
        permissionKey: 'project_types_view',
      },
      {
        subid: 3,
        name: 'Survey Types',
        link: APP_SURVEY_TYPES,
        icon: RiSurveyFill,
        isMenu: false,
        permissionKey: 'survey_types_view',
      },
      {
        subid: 4,
        name: 'Survey Templates',
        link: APP_SURVEY_TEMPLATES,
        icon: RiSurveyFill,
        isMenu: false,
        permissionKey: 'survey_templates_view',
      },
      {
        subid: 5,
        name: 'Team Management',
        link: TEAM_MEMBERS,
        icon: RiTeamFill,
        isMenu: false,
        permissionKey: 'team_management_view',
      },
      {
        subid: 6,
        name: 'Reports',
        link: APP_REPORTS,
        icon: HiDocumentReport,
        isMenu: false,
        permissionKey: 'reports_dashboard_view',
      },
      // {
      //   subid: 5,
      //   name: 'Email Templates',
      //   link: APP_EMAIL_TEMPLATES,
      //   icon: MdEmail,
      //   isMenu: false,
      // },
      // {
      //   subid: 7,
      //   name: 'PDF Builder',
      //   link: PDF_Builder_List,
      //   icon: FaFilePdf,
      //   isMenu: false,
      // },
    ],
  },
];

const SideBarContent = ({
  isMenu,
  type,
  menuName,
  setIsExpand,
  isExpand,
  ...rest
}) => {
  const [menu, setMenu] = useState(isMenu ? isMenu : false);
  const [subData, setSubData] = useState([]);
  const [menuId, setMenuId] = useState();
  const [subId, setSubId] = useState();
  const { permissions } = useStore((state) => state);

  useEffect(() => {
    const filter = LinkItems.filter((menu) => menu.name === type);
    const subData = filter.map((data) => data.subMenu);
    const filterSubMenu = subData?.[0]?.filter(
      (data) => data.name === menuName,
    );
    setMenuId(filter?.[0]?.id);
    setSubId(filterSubMenu?.[0]?.subid);
    setSubData(...subData);
  }, [type, menuName]);

  const menuHandler = (data) => {
    if (data.isMenu === true) {
      setMenu(true);
      setSubData(data.subMenu);
      setMenuId(data.id);
    } else {
      setMenu(false);
      setMenuId(data.id);
    }
  };

  return (
    <>
      <Box bg={useColorModeValue('#FFFFFF', 'gray.900')} h="full" {...rest}>
        {!menu ? (
          <Flex h="75px" w="80%" mb={1} p={5} alignItems="center">
            {!isExpand && <Image src={SiteScribeLogo} />}

            <RiPlayList2Fill
              style={{
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                position: 'absolute',
                right: !isExpand ? '10px' : '25px',
                transform: !isExpand ? 'rotateY(180deg)' : '',
              }}
              onClick={() => setIsExpand(!isExpand)}
            />
          </Flex>
        ) : (
          <Flex h="50px" w="100%" alignItems="center">
            <Flex width="28%" justifyContent="center">
              {!isExpand && (
                <Image src={SiteLogoSm} width="40px" height="40px" />
              )}
            </Flex>
            <AppText
              text={type}
              customStyles={{
                fontSize: fontSizes.sm,
                fontWeight: 700,
                padding: '25px 16px 5px 16px',
              }}
            />
          </Flex>
        )}

        <Flex>
          <div style={{ width: '100%' }}>
            {LinkItems?.map((link) => {
              if (
                permissions.includes(link?.permissionKey) ||
                (Array.isArray(link?.permissionKey) &&
                  permissions.some((data) => {
                    return link?.permissionKey?.some((d) => d === data);
                  })) ||
                link.name === 'Home' ||
                (link.name === 'Settings' &&
                  (permissions.includes('company_setting_view') ||
                    permissions.includes('project_types_view') ||
                    permissions.includes('survey_types_view') ||
                    permissions.includes('survey_templates_view') ||
                    permissions.includes('team_management_view') ||
                    permissions.includes('reports_dashboard_view')))
              )
                return (
                  <NavItem
                    key={link.name}
                    link={link.link}
                    params={link.name === 'Projects' && '?status=active'}
                    icon={link.icon}
                    color={
                      menuId === link.id
                        ? appColors.brandTeal['activeMenu']
                        : appColors.brandBlack['400']
                    }
                    onClick={() => menuHandler(link)}
                    menuState={menu}
                    width="100%"
                    customStyles={{
                      fontSize: '16px',
                      fontWeight: 500,
                      margin: '0px',
                      justifyContent: isExpand ? 'center' : 'flex-start',
                      marginLeft: !isExpand ? '15px' : '0px',
                    }}
                    iconStyles={{
                      width: '20px',
                      height: '20px',
                      color:
                        menuId === link.id
                          ? appColors.brandTeal['activeMenu']
                          : menu
                          ? appColors.brandGray['graymenuIcon']
                          : appColors.brandBlack['400'],
                    }}
                    isExpand={isExpand}
                  >
                    {link.name}
                  </NavItem>
                );
            })}
          </div>
          {menu && (
            <Flex>
              <div
                style={{
                  borderLeft: '1px solid #CDCDCD',
                  height: '100vh',
                  width: '220px',
                  background: '#ffffff',
                }}
              >
                {subData?.map((link) => {
                  if (
                    permissions.includes(link?.permissionKey) ||
                    link.name === 'PDF Builder'
                  ) {
                    return (
                      <NavItem
                        onClick={() => {
                          setMenu(true);
                          setSubId(link.subid);
                        }}
                        link={link.link}
                        key={link.name}
                        icon={link.icon}
                        color={
                          subId === link.subid
                            ? appColors.brandTeal['activeMenu']
                            : appColors.brandBlack['400']
                        }
                        customStyles={{
                          fontSize: '14px',
                          fontWeight: 400,
                          padding: '16px 16px 16px 0px',
                        }}
                        width="100%"
                      >
                        {link.name}
                      </NavItem>
                    );
                  }
                })}
              </div>
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default SideBarContent;
