/* eslint-disable react/no-unescaped-entities */
import { Flex, useDisclosure, useToast } from '@chakra-ui/react';
import {
  GET_ACCESS_MANAGEMENT_LISTING,
  GET_ACCESS_MANAGEMENT_LISTING_META,
  TEAM_MEMBER_DELETING,
} from 'api/urls/settings';
import makeDeleteRequest from 'api/utilities/makeDeleteRequest';
import useStore from 'AppZustand/store';
import AppAlertModal from 'components/AppAlertModal/AppAlertModal';
import Card from 'components/AppCard/AppCard';
import AppLoader from 'components/AppLoader/AppLoader';
import AppModal from 'components/AppModal/AppModal';
import CustomTable from 'components/AppTable';
import useGetTableFunctions from 'hooks/tableHelpers/useGetTableFunctions';
import React, { useEffect, useState } from 'react';
import { GrEdit } from 'react-icons/gr';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useMutation, useQueryClient } from 'react-query';

import DeleteUser from './components/DeleteUser';
import EditUserAccess from './components/EditUserAccess';

const AccessManagement = ({ setOption, option }) => {
  const [slug, setSlug] = useState('');

  const {
    isOpen: isEditTeamMemberOpen,
    onClose: onEditTeamMemberClose,
    onOpen: onEditTeamMemberOpen,
  } = useDisclosure();

  const {
    isOpen: isDeleteTeamMemberOpen,
    onClose: onDeleteTeamMemberClose,
    onOpen: onDeleteTeamMemberOpen,
  } = useDisclosure();

  const {
    isOpen: isPermissionAlertOpen,
    onClose: onPermissionAlertClose,
    onOpen: onPermissionAlertOpen,
  } = useDisclosure();

  const { permissions } = useStore((state) => state);

  const columnsIdentity = {
    name: 'Name',
    status: 'Status',
    email: 'Email',
    role__identity: 'Role',
  };

  const additionalColumns = [
    {
      Header: '',
      accessor: (originalRow) => (
        <Flex
          display={
            permissions.includes('team_management_manage') ? 'flex' : 'none'
          }
        >
          <GrEdit
            cursor="pointer"
            style={{ marginRight: '24px' }}
            onClick={() => editHandler(originalRow)}
          />
          <RiDeleteBinLine
            cursor="pointer"
            onClick={() => deleteHandler(originalRow)}
          />
        </Flex>
      ),
      id: 'action_columns',
    },
  ];

  useEffect(() => {
    getTableDataMutation();
    getTableMetaMutation();
  }, [option]);

  const {
    isTableLoading,
    tableData,
    columnsData,
    getTableDataMutation,
    getTableMetaMutation,
  } = useGetTableFunctions({
    tableDataURL: GET_ACCESS_MANAGEMENT_LISTING,
    tableMetaURL: GET_ACCESS_MANAGEMENT_LISTING_META,
    columnsIdentity: columnsIdentity,
    additionalColumns: additionalColumns,
    permissionAlert: onPermissionAlertOpen,
  });

  const toast = useToast();

  const queryClient = useQueryClient();
  const {
    mutate: teamMemberDeletionMutation,
    isLoading: teamMemberDeletionIsLoading,
  } = useMutation(
    TEAM_MEMBER_DELETING(slug),
    () => makeDeleteRequest(TEAM_MEMBER_DELETING()),
    {
      onSuccess: () => {
        getTableDataMutation();
        queryClient.invalidateQueries(GET_ACCESS_MANAGEMENT_LISTING);
        onDeleteTeamMemberClose();
      },
      onError: (data) => {
        toast({
          title: data?.response?.data?.data?.detail,
          status: 'error',
          duration: 3000,
        });
      },
    },
  );

  const editHandler = (originalRow) => {
    setSlug(originalRow.id);
    onEditTeamMemberOpen();
  };

  const deleteHandler = (originalRow) => {
    setSlug(originalRow.id);
    onDeleteTeamMemberOpen();
  };

  const confirmDelete = () => {
    teamMemberDeletionMutation(slug);
  };

  return (
    <>
      {permissions.includes('team_management_view') && (
        <>
          <Card variant="tableCard">
            <AppLoader isLoading={isTableLoading}>
              {!isTableLoading && (
                <CustomTable
                  columns={columnsData}
                  data={tableData?.data?.results}
                  check={false}
                />
              )}
            </AppLoader>
          </Card>
          {permissions.includes('team_management_manage') && (
            <>
              <AppModal
                isOpen={isEditTeamMemberOpen}
                onClose={onEditTeamMemberClose}
                keyName="accessEdit"
                text="Edit user access"
                closeCheck={true}
              >
                <EditUserAccess
                  slug={slug}
                  setState={setOption}
                  option={option}
                  onClose={onEditTeamMemberClose}
                />
              </AppModal>
              <AppModal
                isOpen={isDeleteTeamMemberOpen}
                onClose={onDeleteTeamMemberClose}
                keyName="delete"
                text="Delete Team Member"
                closeCheck={true}
              >
                <DeleteUser
                  isDelete
                  onClose={onDeleteTeamMemberClose}
                  handleDelete={confirmDelete}
                  isSubmitLoading={teamMemberDeletionIsLoading}
                />
              </AppModal>
            </>
          )}
        </>
      )}
      {option?.type === 'Access management'}
      <AppAlertModal
        isOpen={isPermissionAlertOpen}
        onClose={onPermissionAlertClose}
      />
    </>
  );
};

export default AccessManagement;
