import { Flex } from '@chakra-ui/react';
import {
  CREATE_WIDGET,
  GET_DASHBOARD_DATA,
  GET_WIDGET_DATA,
  UPDATE_WIDGET_DATA,
} from 'api/urls/settings/Reports';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import makePutRequest from 'api/utilities/makePutRequest';
import useStore from 'AppZustand/store';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AppInputDate from 'components/AppInputDate/AppInputDate';
import AppLoader from 'components/AppLoader/AppLoader';
import AppSelect from 'components/AppSelect/AppSelect';
import { capitalize } from 'constants';
import React, { useEffect, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';

import MultiSelect from './charts/MultiSelect';

export function getArrayOfIds(array) {
  let a = array?.map((item) => {
    return item?.id;
  });
  return a;
}

const AddModal = ({ onClose, chartData }) => {
  const [formValues, setFormValues] = useState({
    report_type: '',
    identity: '',
    from_date: '',
    to_date: '',
    metric: '',
    project_types: [],
    survey_types: [],
    extra_data_single_user: '',
    extra_data_multi_users: [],
    extra_data_multi_workflow_states: [],
    extra_data_single_workflow_state: '',
  });
  const [error, setError] = useState({});
  const [loaded, setLoaded] = useState(true);
  const {
    widgetMetaData,
    chartTypes,
    fetchChartTypes,
    widgetOverallConfigData,
  } = useStore((state) => state);

  useEffect(() => {
    if (chartData?.id) {
      fetchSingleWidgetData({ id: chartData.id });
    } else {
      setLoaded(false);
    }
  }, [chartData]);

  function singleValueArrayReturned(name, singleData) {
    return widgetMetaData?.[name]?.filter((item) => {
      return singleData[name]?.some((id) => {
        return id === item.id;
      });
    });
  }
  function multiValueArrayReturned(name, extra, singleData) {
    return widgetMetaData?.[name]?.filter((item) => {
      return singleData[extra]?.some((id) => {
        return id === item.id;
      });
    });
  }

  const queryClient = useQueryClient();

  //add new widget
  const { mutate: getNewWidgetData, isLoading: getNewWidgetDataLoading } =
    useMutation(
      CREATE_WIDGET(),
      (body) => makePostRequest(CREATE_WIDGET(), body),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(GET_DASHBOARD_DATA());
          onClose();
        },
        onError: (data) => {
          setError(data.response.data);
        },
      },
    );

  //fetch single widget details
  const { mutate: fetchSingleWidgetData } = useMutation(
    GET_WIDGET_DATA(),
    (data) => makeGetRequest(GET_WIDGET_DATA(data.id)),
    {
      onSuccess: (data) => {
        let singleData = data.data.initial;
        fetchChartTypes(singleData.type);
        setFormValues({
          ...formValues,
          identity: singleData.identity,
          type: chartTypes,
          report_type: singleData.report_type,
          from_date: singleData.from_date,
          to_date: singleData.to_date,
          metric: singleData.metric,
          project_types:
            singleValueArrayReturned('project_types', singleData) || [],
          survey_types:
            singleValueArrayReturned('survey_types', singleData) || [],
          extra_data_single_user:
            singleData.report_type ===
            'single_user_multi_workflow_states_report'
              ? singleData.extra_data_single_user
              : null,
          extra_data_multi_workflow_states:
            singleData.report_type ===
              'single_user_multi_workflow_states_report' ||
            singleData.report_type === 'overall_workflow_states_report'
              ? multiValueArrayReturned(
                  'workflow_states',
                  'extra_data_multi_workflow_states',
                  singleData,
                )
              : [],
          extra_data_multi_users:
            singleData.report_type ===
            'multi_users_single_workflow_state_report'
              ? multiValueArrayReturned(
                  'users',
                  'extra_data_multi_users',
                  singleData,
                )
              : [],
          extra_data_single_workflow_state:
            singleData.report_type ===
            'multi_users_single_workflow_state_report'
              ? singleData.extra_data_single_workflow_state
              : null,
        });
      },
      onSettled: () => {
        setLoaded(false);
      },
    },
  );

  //update widget
  const { mutate: updateWidgetData, isLoading: updateWidgetDataLoading } =
    useMutation(
      UPDATE_WIDGET_DATA(),
      (body) => makePutRequest(UPDATE_WIDGET_DATA(chartData.id), body),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(GET_DASHBOARD_DATA());
          onClose();
        },
        onError: (data) => {
          setError(data.response.data);
        },
      },
    );

  const handleSubmit = () => {
    if (chartData) {
      updateWidgetData({
        identity: formValues.identity,
        report_type: formValues.report_type,
        type: chartTypes,
        from_date: formValues.from_date,
        to_date: formValues.to_date,
        metric: formValues.metric,
        project_types: formValues.project_types
          ? getArrayOfIds(formValues.project_types)
          : [],
        survey_types: formValues.survey_types
          ? getArrayOfIds(formValues.survey_types)
          : [],
        extra_data_single_user:
          formValues.report_type === 'single_user_multi_workflow_states_report'
            ? formValues.extra_data_single_user
            : null,
        extra_data_multi_workflow_states:
          formValues.report_type ===
            'single_user_multi_workflow_states_report' ||
          formValues.report_type === 'overall_workflow_states_report'
            ? getArrayOfIds(formValues.extra_data_multi_workflow_states)
            : null,
        extra_data_multi_users:
          formValues.report_type === 'multi_users_single_workflow_state_report'
            ? getArrayOfIds(formValues.extra_data_multi_users)
            : [],
        extra_data_single_workflow_state:
          formValues.report_type === 'multi_users_single_workflow_state_report'
            ? formValues.extra_data_single_workflow_state
            : null,
      });
    } else {
      getNewWidgetData({
        identity: formValues.identity,
        report_type: formValues.report_type,
        type: chartTypes,
        from_date: formValues.from_date,
        to_date: formValues.to_date,
        metric: formValues.metric,
        project_types: getArrayOfIds(formValues.project_types) || [],
        survey_types: getArrayOfIds(formValues.survey_types) || [],
        extra_data_single_user:
          formValues.report_type === 'single_user_multi_workflow_states_report'
            ? formValues.extra_data_single_user
            : null,
        extra_data_multi_workflow_states:
          formValues.report_type ===
            'single_user_multi_workflow_states_report' ||
          formValues.report_type === 'overall_workflow_states_report'
            ? getArrayOfIds(formValues.extra_data_multi_workflow_states)
            : [],
        extra_data_multi_users:
          formValues.report_type === 'multi_users_single_workflow_state_report'
            ? getArrayOfIds(formValues.extra_data_multi_users)
            : [],
        extra_data_single_workflow_state:
          formValues.report_type === 'multi_users_single_workflow_state_report'
            ? formValues.extra_data_single_workflow_state
            : null,
      });
    }
  };

  const formFields = [
    {
      fieldPlaceholder: 'Start Date',
      fieldType: 'date',
      fieldName: 'from_date',
    },
    {
      fieldPlaceholder: 'End Date',
      fieldType: 'date',
      fieldName: 'to_date',
    },
  ];

  return (
    <AppLoader isLoading={loaded}>
      <form>
        <Flex flexDirection="column">
          <AppInput
            fieldPlaceholder="Enter Widget Name"
            setFormValues={setFormValues}
            fieldName={'identity'}
            formValues={formValues}
            isRequired={true}
            fieldError={error}
          />
          <AppSelect
            variant="filled"
            icon={MdArrowDropDown}
            setFormValues={setFormValues}
            formValues={formValues}
            fieldName={'report_type'}
            required={true}
            fieldError={error}
          >
            <option value="" hidden>
              Select Report Type
            </option>
            {widgetMetaData.report_type.map((item) => {
              let result =
                widgetOverallConfigData[item.id]?.applicable_widget_types;
              let exist = chartTypes in result;

              if (exist) {
                return (
                  <option
                    key={item.id}
                    value={item.id}
                    selected={formValues.report_type === item.id}
                  >
                    {item.identity}
                  </option>
                );
              } else {
                return null;
              }
            })}
          </AppSelect>

          {formValues.report_type && (
            <AppSelect
              variant="filled"
              key={'metric'}
              icon={MdArrowDropDown}
              setFormValues={setFormValues}
              formValues={formValues}
              fieldName={'metric'}
              isRequired={true}
              fieldError={error}
            >
              <option value="" hidden>
                Select Metric
              </option>
              {widgetMetaData.metric.map((item) => {
                let exist = widgetOverallConfigData[
                  formValues?.report_type
                ]?.applicable_widget_types[
                  chartTypes
                ]?.applicable_metrics.includes(item.id);
                if (exist) {
                  return (
                    <option
                      key={item.id}
                      value={item.id}
                      selected={formValues.metric === item.id}
                    >
                      {item.identity}
                    </option>
                  );
                } else {
                  return null;
                }
              })}
            </AppSelect>
          )}

          <MultiSelect
            setField={setFormValues}
            Field={formValues}
            fieldName="project_types"
            options={widgetMetaData.project_types}
            placeholder="Select Project Types"
            fieldError={error}
            isInvalid={Boolean(error?.data?.['project_types'])}
            margin="0 0 1.25rem 0"
          />
          <MultiSelect
            setField={setFormValues}
            Field={formValues}
            fieldName="survey_types"
            options={widgetMetaData.survey_types}
            isInvalid={Boolean(error?.data?.['survey_types'])}
            placeholder="Select Survey Types"
            margin="0 0 1.25rem 0"
          />
          {formValues.report_type &&
            Object.values(
              widgetOverallConfigData[formValues.report_type]?.extra_data,
            )?.map((extraitem) => {
              let value = Object.keys(
                widgetOverallConfigData[formValues.report_type]?.extra_data,
              ).find(
                (key) =>
                  widgetOverallConfigData[formValues.report_type]?.extra_data[
                    key
                  ] === extraitem,
              );

              if (value.includes('single_user')) {
                return (
                  <AppSelect
                    variant="filled"
                    icon={MdArrowDropDown}
                    setFormValues={setFormValues}
                    formValues={formValues}
                    fieldName={'extra_data_single_user'}
                    isRequired={extraitem.required}
                    key={'single_user'}
                    fieldError={error}
                  >
                    <option value="" hidden>
                      Select User
                    </option>
                    {widgetMetaData.users.map((item) => {
                      return (
                        <option
                          key={item.id}
                          value={item.id}
                          selected={
                            formValues.extra_data_single_user === item.id
                          }
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </AppSelect>
                );
              } else if (value.includes('multi_workflow')) {
                return (
                  <MultiSelect
                    setField={setFormValues}
                    Field={formValues}
                    fieldName={'extra_data_multi_workflow_states'}
                    options={widgetMetaData.workflow_states?.map((data) => {
                      return { ...data, identity: capitalize(data.identity) };
                    })}
                    placeholder={extraitem.display}
                    key={'multi_workflow'}
                    isInvalid={Boolean(
                      error?.data?.['extra_data_multi_workflow_states'],
                    )}
                    margin="0 0 1.25rem 0"
                    fieldError={error}
                  />
                );
              } else if (value.includes('single_workflow')) {
                return (
                  <AppSelect
                    variant="filled"
                    icon={MdArrowDropDown}
                    setFormValues={setFormValues}
                    formValues={formValues}
                    fieldName={'extra_data_single_workflow_state'}
                    key={'single_workflow'}
                    isRequired={extraitem.required}
                    fieldError={error}
                  >
                    <option value="" hidden>
                      {extraitem.display}
                    </option>
                    {widgetMetaData.workflow_states.map((item) => {
                      return (
                        <option
                          key={item.id}
                          value={item.id}
                          selected={
                            formValues.extra_data_single_workflow_state ===
                            item.id
                          }
                        >
                          {capitalize(item.identity)}
                        </option>
                      );
                    })}
                  </AppSelect>
                );
              } else if (value.includes('multi_users')) {
                return (
                  <MultiSelect
                    setField={setFormValues}
                    Field={formValues}
                    fieldName={'extra_data_multi_users'}
                    key={'multi_users'}
                    options={widgetMetaData.users}
                    placeholder="Select multi users"
                    isRequired={extraitem.required}
                    isInvalid={Boolean(error?.data?.['extra_data_multi_users'])}
                    margin="0 0 1.25rem 0"
                    fieldError={error}
                  />
                );
              }
            })}
          {formFields?.map((data) => {
            return (
              <AppInputDate
                key={data.fieldName}
                fieldPlaceholder={data.fieldPlaceholder}
                fieldName={data.fieldName}
                formValues={formValues}
                setFormValues={setFormValues}
                margin="0px 0px 1.25rem 0px"
                fieldError={error}
                startDate={formValues?.from_date}
                toDate={formValues?.to_date}
                startDateKey="to_date"
                toDateKey="from_date"
              />
            );
          })}

          {/* <AppInputDate
            fieldPlaceholder="To Date"
            fieldName="to_date"
            formValues={formValues}
            setFormValues={setFormValues}
            margin="0px 0px 1.25rem 0px"
            fieldError={error}
            toDate={formValues?.to_date}
            toDateKey="to_date"
          /> */}
          <Flex gap="10px" margin="0px 0px 1.25rem 0px">
            <AppButton
              text="Cancel"
              variant="cancelBtn"
              onClick={() => onClose()}
            />
            <AppButton
              text="Submit"
              isLoading={updateWidgetDataLoading || getNewWidgetDataLoading}
              onClick={() => {
                if (!updateWidgetDataLoading || !getNewWidgetDataLoading) {
                  handleSubmit();
                }
              }}
            />
          </Flex>
        </Flex>
      </form>
    </AppLoader>
  );
};

export default AddModal;
