import { Flex, useDisclosure } from '@chakra-ui/react';
import AppAlertModal from 'components/AppAlertModal/AppAlertModal';
import AppButton from 'components/AppButton/AppButton';
import Card from 'components/AppCard/AppCard';
import AppModal from 'components/AppModal/AppModal';
import CustomTable from 'components/AppTable';
import AppHeader from 'components/typography/AppHeader';
import DashboardLayout from 'layout/DashboardLayout';
import AddAndEditProject from 'pages/appSettings/projectTypes/components/AddAndEditProject';
import React, { useState } from 'react';
import { GrEdit } from 'react-icons/gr';
import { RiDeleteBinLine } from 'react-icons/ri';

import { pdfTableData } from './Constant';

const PdfBuilderList = () => {
  //   const columnsIdentity = {
  //     identity: 'Name',
  //     created_by__name: 'Created By',
  //     created: 'Created',
  //     modified: 'Last Modified',
  //   };

  //   const { pageSize, fetchPageSize } = useStore((state) => state);

  //   useEffect(() => {
  //     getTableDataMutation();
  //     getTableMetaMutation();
  //     fetchPageSize(25);
  //   }, []);

  const columnsData = [
    {
      Header: 'Name',
      accessor: 'identity',
    },
    {
      Header: 'Created By',
      accessor: 'created_by__name',
    },
    {
      Header: 'Created',
      accessor: 'created',
    },
    {
      Header: 'Last Modified',
      accessor: 'modified',
    },
    {
      Header: '',
      accessor: (originalRow) => (
        <Flex>
          <GrEdit
            cursor="pointer"
            style={{ marginRight: '24px' }}
            onClick={() => projectEditHandler(originalRow)}
          />
          <RiDeleteBinLine
            cursor="pointer"
            onClick={() => projectDeleteHandler(originalRow)}
          />
        </Flex>
      ),
      id: 'action_columns',
    },
  ];
  const {
    isOpen: createProjectNewModalIsOpen,
    onOpen: createProjectNewModalOnOpen,
    onClose: createProjectNewModalOnClose,
  } = useDisclosure();

  const {
    isOpen: editProjectModalIsOpen,
    onOpen: editProjectModalOnOpen,
    onClose: editProjectModalOnClose,
  } = useDisclosure();

  const {
    isOpen: deleteProjectModelIsOpen,
    onOpen: deleteProjectModalOnOpen,
    onClose: deleteProjectModalOnClose,
  } = useDisclosure();

  const {
    isOpen: isPermissionAlertOpen,
    onClose: onPermissionAlertClose,
    // onOpen: onPermissionAlertOpen,
  } = useDisclosure();

  //   const { permissions } = useStore((state) => state);

  const [currentType, setCurrentType] = useState();

  //   const {
  //     isTableLoading,
  //     tableData,
  //     columnsData,
  //     getTableDataMutation,
  //     getTableMetaMutation,
  //     setSearchParams,
  //   } = useGetTableFunctions({
  //     tableDataURL: GET_PROJECT_TYPE_LISTING_DATA,
  //     tableMetaURL: GET_PROJECT_TYPE_LISTING_DATA_META,
  //     columnsIdentity: columnsIdentity,
  //     additionalColumns: additionalColumns,
  //     permissionAlert: onPermissionAlertOpen,
  //   });

  //   useEffect(() => {
  //     setSearchParams(`?page-${pageSize}`);
  //   }, [pageSize]);

  const projectEditHandler = (rowData) => {
    setCurrentType(rowData.id);
    editProjectModalOnOpen();
  };

  const projectDeleteHandler = (rowData) => {
    setCurrentType(rowData.id);
    deleteProjectModalOnOpen();
  };

  const addModalHandler = () => {
    createProjectNewModalOnOpen();
  };

  return (
    <DashboardLayout menu={true} type="Settings" menuName="PDF Builder">
      <Flex justifyContent="space-between" alignItems="center">
        <AppHeader
          text="PDF Builder"
          variant="heading"
          customStyles={{ margin: '0px', textAlign: 'start' }}
        />

        <AppButton
          text="+ Add New PDF"
          customStyles={{ width: '207px' }}
          onClick={addModalHandler}
        />
      </Flex>

      <>
        <Card variant="tableCard">
          {/* <AppLoader isLoading={isTableLoading}>
            {!isTableLoading && (
              <CustomTable
                columns={columnsData}
                data={pdfTableData}
                check={false}
              />
            )}
          </AppLoader> */}
          <CustomTable
            columns={columnsData}
            data={pdfTableData}
            check={false}
          />
        </Card>
        <AppModal
          isOpen={createProjectNewModalIsOpen}
          onClose={createProjectNewModalOnClose}
          keyName="addNew"
          text="New Project Type"
          closeCheck={true}
        >
          <AddAndEditProject
            onClose={createProjectNewModalOnClose}
            // getTableDataMutation={getTableDataMutation}
          />
        </AppModal>
        {currentType && (
          <AppModal
            isOpen={editProjectModalIsOpen}
            onClose={editProjectModalOnClose}
            keyName="edit"
            text="Edit Project Type"
            closeCheck={true}
          >
            <AddAndEditProject
              isEdit
              onClose={editProjectModalOnClose}
              currentId={currentType}
              //   getTableDataMutation={getTableDataMutation}
            />
          </AppModal>
        )}
        {currentType && (
          <AppModal
            isOpen={deleteProjectModelIsOpen}
            onClose={deleteProjectModalOnClose}
            keyName="delete"
            text="Delete Project Type"
            closeCheck={true}
          >
            <AddAndEditProject
              isDelete
              onClose={deleteProjectModalOnClose}
              currentId={currentType}
              //   getTableDataMutation={getTableDataMutation}
            />
          </AppModal>
        )}
      </>

      <AppAlertModal
        isOpen={isPermissionAlertOpen}
        onClose={onPermissionAlertClose}
      />
    </DashboardLayout>
  );
};

export default PdfBuilderList;
