import 'antd/lib/date-picker/style/index.css';
import 'antd/lib/date-picker/style/css.js';

import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom';
import appColors from 'theme/appColors';
import { fontSizes } from 'theme/typographyStyles';

const AppInputDate = ({
  fieldName,
  fieldPlaceholder,
  fieldError,
  fieldErrorMessage,
  formValues,
  setFormValues,
  disabled,
  margin,
  fieldKey,
  index,
  questionId,
  startDate,
  toDate,
  startDateKey,
  toDateKey,
}) => {
  /**
   * A common wrapper for all inputs in the application
   * */

  const getValidation = (fieldName, name, value) => {
    return fieldName === name && value;
  };

  const dateFormat = 'YYYY-MM-DD';
  const location = useLocation();
  return (
    <FormControl
      isInvalid={Boolean(fieldError?.data?.[fieldName]) || fieldErrorMessage}
      margin={margin}
    >
      <DatePicker
        placeholder={
          questionId
            ? formValues[index]?.[questionId]?.answer
            : fieldPlaceholder
        }
        onChange={(e, str) => {
          if (questionId) {
            setFormValues({
              ...formValues,

              [index]: {
                [questionId]: {
                  ...formValues[index]?.[questionId],
                  answer: str,
                  [fieldKey]: str,
                  isEdit: true,
                },
              },
            });
          } else {
            if (location.pathname.includes('project')) {
              if (!str) {
                const newItems = { ...formValues };
                delete newItems?.[fieldName];
                setFormValues(newItems);
              } else {
                setFormValues({
                  ...formValues,
                  [fieldName]: str,
                });
              }
            } else {
              setFormValues({ ...formValues, [fieldName]: str });
            }
          }
        }}
        value={
          formValues[fieldName] && moment(formValues[fieldName], dateFormat)
        }
        disabled={disabled}
        defaultValue={
          formValues[fieldName] ? moment(formValues[fieldName], dateFormat) : ''
        }
        style={{
          fontSize: fontSizes.sm,
          height: '50px',
          backgroundColor: formValues?.[fieldName]
            ? appColors.brandGray['selectedField']
            : appColors.brandTeal['300'],
          border: 'none',
          borderRadius: '0.375rem',
          width: '100%',
          padding: '4px 16px 4px 16px',
        }}
        disabledDate={
          getValidation(fieldName, startDateKey, startDate)
            ? (d) => d.isBefore(startDate)
            : getValidation(fieldName, toDateKey, toDate)
            ? (d) => d.isAfter(toDate)
            : () => {}
        }
      />

      <FormErrorMessage>
        {fieldError?.data?.[fieldName]}
        {fieldErrorMessage}
      </FormErrorMessage>
    </FormControl>
  );
};

export default AppInputDate;
