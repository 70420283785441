import { Stack } from '@chakra-ui/react';
import AppBox from 'components/AppBox/AppBox';
import AppInput from 'components/AppInput/AppInput';
import AppText from 'components/typography/AppText';
import { memo } from 'react';

const addressFieldMapping = {
  answer_address_line_1_input: 'Street Address',
  answer_address_line_2_input: 'Street Address Line 2',
  answer_address_country_input: 'Country',
  answer_address_state_input: 'State',
  answer_address_city_input: 'City',
  answer_address_pin_code_input: 'Postal Code',
};

const AddressField = ({
  isEditable,
  state,
  setState,
  error,
  index,
  item,
  type,
}) => {
  return (
    <Stack>
      <AppBox>
        {Object.keys(addressFieldMapping)?.map((data, inx) => {
          return (
            <AppInput
              key={inx}
              customStyles={{
                margin: '10px 0',
              }}
              formValues={state}
              setFormValues={setState}
              fieldPlaceholder={addressFieldMapping[data]}
              fieldName={data}
              variant="filled"
              isRequired={true}
              disabled={!isEditable}
              item={item}
              type={type}
            />
          );
        })}
      </AppBox>
      <AppText
        text={
          error?.data?.answers_data[index]?.non_field_errors ||
          error?.data?.answers_data[index]?.non_field_errors
        }
        variant="errorMessage"
      />
    </Stack>
  );
};

export default memo(AddressField);
