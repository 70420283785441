import { Flex, Image } from '@chakra-ui/react';
import { EMAIL_EDIT_VERIFICATION } from 'api/urls';
import makePostRequest from 'api/utilities/makePostRequest';
import { SuccessIcon, WrongIcon } from 'assets';
import AppButton from 'components/AppButton/AppButton';
import AppLoader from 'components/AppLoader/AppLoader';
import AuthCard from 'components/AuthCard/AuthCard';
import AppHeader from 'components/typography/AppHeader';
import AuthLayout from 'layout/AuthLayout';
import { APP_DASHBOARD } from 'navigation/routes';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const AppEmailVerification = () => {
  const params = useParams();

  const navigate = useNavigate();

  const userId = params?.userID;

  const { status, isLoading } = useQuery(EMAIL_EDIT_VERIFICATION(userId), () =>
    makePostRequest(EMAIL_EDIT_VERIFICATION(userId)),
  );

  return (
    <AuthLayout>
      <AuthCard>
        <AppLoader isLoading={isLoading}>
          {status === 'success' && (
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Image
                src={SuccessIcon}
                alt="verified"
                width="100px"
                height="100px"
                objectFit="cover"
              />
              <AppHeader
                text="Verified!"
                variant="heading"
                customStyles={{ margin: '20px 0px 0px 0px' }}
              />
              <AppButton
                text={'Proceed to appDashboard'}
                onClick={() => navigate(APP_DASHBOARD)}
                customStyles={{ margin: '20px 0px', width: '300px' }}
              />
            </Flex>
          )}
          {status === 'error' && (
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Image
                src={WrongIcon}
                alt="The requested URL has expired"
                width="90px"
                height="90px"
                objectFit="cover"
              />
              <AppHeader
                text="The requested URL has expired"
                variant="heading"
                customStyles={{ margin: '20px 0px 0px 0px' }}
              />
            </Flex>
          )}
        </AppLoader>
      </AuthCard>
    </AuthLayout>
  );
};

export default AppEmailVerification;
