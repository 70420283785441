import { Divider, Flex } from '@chakra-ui/react';
import AppButton from 'components/AppButton/AppButton';
import AppModal from 'components/AppModal/AppModal';
import AppText from 'components/typography/AppText';
import React from 'react';

const AppAlertModal = ({
  isOpen,
  onClose,
  content = 'You do not have permission to see.',
  isShowCancel = false,
  handleSubmit,
  onCancel,
}) => {
  return (
    <AppModal
      isOpen={isOpen}
      text={'Alert'}
      closeCheck={true}
      variant={'alertText'}
      bodyCustomStyles={{ padding: '0px' }}
    >
      <>
        <Divider mb={5} mt={3} />
        <AppText
          text={content}
          variant="alertMessageText"
          customStyles={{ padding: '0px 15px' }}
        />
        <Divider mt={5} mb={3} />
        {!isShowCancel && (
          <Flex justifyContent="flex-end" m={3}>
            <AppButton
              text="Done"
              onClick={onClose}
              customStyles={{ width: '90px', margin: '0px' }}
            />
          </Flex>
        )}
        {isShowCancel && (
          <Flex gap="20px" m={3}>
            <AppButton text="No" variant="cancelBtn" onClick={onCancel} />
            <AppButton text="Yes" onClick={handleSubmit} />
          </Flex>
        )}
      </>
    </AppModal>
  );
};

export default AppAlertModal;
