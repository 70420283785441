import { Flex, TabPanel, TabPanels, useDisclosure } from '@chakra-ui/react';
import useStore from 'AppZustand/store';
import AppBox from 'components/AppBox/AppBox';
import AppButton from 'components/AppButton/AppButton';
import AppModal from 'components/AppModal/AppModal';
import AppTabs from 'components/AppTabs/AppTabs';
import AppHeader from 'components/typography/AppHeader';
import DashboardLayout from 'layout/DashboardLayout';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import AccessManagement from './AccessManagement';
import AddNewUser from './components/AddUser';
import AddUserRole from './components/AddUserRole';
import { tabsHeading } from './Data';
import RolesAndPermission from './RolesAndPermission';

const TeamMembers = () => {
  const [option, setOption] = useState({
    permissions: false,
    type: 'Access management',
    id: 0,
  });

  const { permissions } = useStore((state) => state);

  const {
    isOpen: isAddNewTeamMemberOpen,
    onClose: onAddNewTeamMemberClose,
    onOpen: onAddNewTeamMemberOpen,
  } = useDisclosure();

  const {
    isOpen: isAddUserRolesOpen,
    onClose: onAddUserRolesClose,
    onOpen: onAddUserRolesOpen,
  } = useDisclosure();

  const addModalHandler = () => {
    onAddNewTeamMemberOpen();
  };

  const addRolesModalHandler = () => {
    onAddUserRolesOpen();
  };

  return (
    <DashboardLayout menu={true} type="Settings" menuName="Team Management">
      <Flex justifyContent="space-between" alignItems="center">
        <AppHeader
          text="Team Management"
          variant="heading"
          customStyles={{ margin: '0px', textAlign: 'start' }}
        />
        {option.type === 'Access management' &&
          permissions.includes('team_management_manage') && (
            <AppBox customStyles={{ display: 'flex', gap: '10px' }}>
              <AppButton
                text="+ Add New User"
                customStyles={{ width: '157px' }}
                onClick={addModalHandler}
              />
              <Link to={'/bulk/upload/Users'}>
                <AppButton>Bulk Upload</AppButton>
              </Link>
            </AppBox>
          )}
        {option.type === 'Roles and permissions' &&
          permissions.includes('role_management_manage') && (
            <AppButton
              text="+ Add New Role"
              customStyles={{ width: '157px' }}
              onClick={addRolesModalHandler}
            />
          )}
      </Flex>
      <Flex>
        <AppTabs tabsHeading={tabsHeading} setState={setOption}>
          <TabPanels>
            <TabPanel>
              <AccessManagement option={option} setOption={setOption} />
            </TabPanel>
            <TabPanel>
              <RolesAndPermission option={option} setOption={setOption} />
            </TabPanel>
          </TabPanels>
        </AppTabs>
      </Flex>
      <AppModal
        isOpen={isAddNewTeamMemberOpen}
        onClose={onAddNewTeamMemberClose}
        keyName="accessNew"
        text="Add New user"
        closeCheck={true}
      >
        <AddNewUser
          setState={setOption}
          option={option}
          onClose={onAddNewTeamMemberClose}
        />
      </AppModal>

      <AppModal
        isOpen={isAddUserRolesOpen}
        onClose={onAddUserRolesClose}
        keyName="accessNew"
        text="Add User Roles"
        closeCheck={true}
      >
        <AddUserRole
          setState={setOption}
          option={option}
          onClose={onAddUserRolesClose}
        />
      </AppModal>
    </DashboardLayout>
  );
};

export default TeamMembers;
