import { extendTheme, theme as baseTheme } from '@chakra-ui/react';
import appColors from 'theme/appColors';
import components from 'theme/components';
import styles from 'theme/styles';
import { fonts, fontSizes } from 'theme/typographyStyles';

/**
 * Base theme file for the entire application
 * */
const theme = extendTheme({
  styles,
  colors: appColors,
  fonts,
  fontSizes,
  baseTheme,
  components,
});

export default theme;
