import {
  Badge,
  Box,
  Collapse,
  Divider,
  Flex,
  Icon,
  Image,
  List,
  ListItem,
  MenuItem,
  Stack,
  TabPanel,
  TabPanels,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  ANSWER_QC_SCREEN_DATA,
  ANSWER_QC_SCREEN_DATA_SAVE,
  QC_SCREEN_DATA,
  QC_SCREEN_DATA_TO_PDF,
} from 'api/urls/settings/qcScreen';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import { getHostAPIUrl } from 'AppConfig';
import useStore from 'AppZustand/store';
import AppButton from 'components/AppButton/AppButton';
//import AppFileInput from 'components/AppFileInput/AppFileInput';
import AppLoader from 'components/AppLoader/AppLoader';
import AppMenu from 'components/AppMenu/AppMenu';
import AppModal from 'components/AppModal/AppModal';
import AppTabs from 'components/AppTabs/AppTabs';
import AppText from 'components/typography/AppText';
import { getDateTimeString } from 'constants';
import useWorkflowConfig from 'hooks/workflow/useWorkflowConfig';
import DashboardLayout from 'layout/DashboardLayout';
// import { isArray } from 'lodash';
import { SURVEY_TYPE_IMAGES } from 'navigation/routes';
import ActionProject from 'pages/appSettings/project/components/projectComponents/ActionProject';
import React, { useEffect, useState } from 'react';
import { FaListUl } from 'react-icons/fa';
import { GoPrimitiveDot } from 'react-icons/go';
import { IoImages } from 'react-icons/io5';
import { MdDownload, MdModeEditOutline } from 'react-icons/md';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import appColors from 'theme/appColors';
import { fontSizes } from 'theme/typographyStyles';
import uuidGenerator from 'utils/uuid';

import { PreprocessorData } from '../preprocessor';
import GroupImages from './groupImages/GroupImages';
import ModalImages from './ModalImages/ModalImages';
import RoofForm from './RoofForm';

const resultTextStyle = {
  fontSize: '14px',
  lineHeight: '16.92px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginLeft: '5px',
};

const ViewProject = () => {
  const [projectDetails, setProjectData] = useState({
    isDetailsExpand: false,
    activeListIndex: 0,
    isProjectEditable: false,
    roofDetails: {
      roofType: '',
      age: 0,
      type: '',
      length: 0,
      breadth: 0,
      damagedProof: '',
      damagedProofPhotos: [], // assign photo URL in this state
    },
  });

  const location = useLocation();

  const [tabIndex, setTabIndex] = useState(0);

  const navigate = useNavigate();

  const [selectedData, setSelectedData] = useState([]);

  const [values, setValues] = useState();

  const [logsShow, setLogsShow] = useState(false);

  useEffect(() => {
    if (selectedData.length > 0) {
      onModalOpen();
    }
  }, [selectedData]);

  const params = useParams();

  const [tabOption, setTabOption] = useState({
    name: '',
  });

  const { useDb } = useStore((state) => state);

  const { isDetailsExpand, activeListIndex, isProjectEditable } =
    projectDetails;

  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
  } = useDisclosure();

  // eslint-disable-next-line
  const onImagesOpen = () => {
    navigate(SURVEY_TYPE_IMAGES(params?.slug));
  };

  const {
    isOpen: isViewImagesOpen,
    onClose: onViewImagesClose,
    onOpen: onViewImagesOpen,
  } = useDisclosure();

  const { data: qcMetaData, isLoading: qcMetaIsLoading } = useQuery(
    QC_SCREEN_DATA(params.slug),
    () => makeGetRequest(QC_SCREEN_DATA(params.slug)),
  );

  const { actionsList, fromState2, nextStates2 } = useWorkflowConfig({
    qcMetaData,
  });

  // let imageContent = [];

  const listItem = qcMetaData?.data?.question_groups?.map(
    (item) => item?.identity,
  );

  const tabsHeading = [
    {
      name: `${listItem?.[activeListIndex]}`,
    },
    {
      name: `Images for ${listItem?.[activeListIndex]}`,
    },
  ];

  const {
    data: qcAnswerMetaData,
    isLoading: qcAnswerMetaIsLoading,
    refetch: qcAnswerMetaRefetch,
  } = useQuery(
    ANSWER_QC_SCREEN_DATA(
      qcMetaData?.data?.question_groups[activeListIndex]?.id,
    ),
    () =>
      qcMetaData?.data?.question_groups[activeListIndex]?.id &&
      makeGetRequest(
        ANSWER_QC_SCREEN_DATA(
          qcMetaData?.data?.question_groups[activeListIndex]?.id,
        ),
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const [qcData, setQcData] = useState({});

  const [existQcAnswerData, setExistQcAnswerData] = useState({});

  const [groupImages, setGroupImages] = useState([]);

  const surveyDate = qcMetaData?.data?.pending_audit_to_qc_pending_datetime;

  const startDate = qcMetaData?.data?.pending_audit_survey_start_datetime;

  const endDate = qcMetaData?.data?.pending_audit_survey_end_datetime;

  useEffect(() => {
    if (qcAnswerMetaData || !qcAnswerMetaIsLoading) {
      const preprocessorQuestionList = qcAnswerMetaData?.data?.map((item) => {
        const preprocessorCascade =
          item?.answer_single_selection_group_input?.map((cascadeGroup) => {
            return cascadeGroup?.map((cascadeItem) => {
              let custom_id = uuidGenerator();
              const { question, ...rest } = cascadeItem;

              return {
                ...cascadeItem,
                question: {
                  ...rest,
                  ...question,
                  custom_id: custom_id,
                  field_type: 'cascade',
                  parent_question: item?.question?.id,
                },
              };
            });
          });

        return {
          ...item,
          question: {
            ...item?.question,
            custom_id: item?.question?.id,
            field_type: 'regular',
          },
          answer: {
            ...item?.answer,
            field_type: 'regular',
            type: item?.question?.type,
          },
          answer_single_selection_group_input: preprocessorCascade,
        };
      });

      setQcData(preprocessorQuestionList);

      let questionList = {};

      preprocessorQuestionList?.map((item) => {
        item?.answer_single_selection_group_input?.map((cascadeGroup) => {
          cascadeGroup?.map((cascadeItem) => {
            const { question, ...rest } = cascadeItem;

            Object.assign(questionList, {
              [question?.custom_id]: {
                ...question,
                parent_question: item?.question?.id,
                custom_id: question?.custom_id,
                ...rest,
              },
            });
          });
        });

        Object.assign(questionList, {
          [item?.question?.custom_id]: {
            ...item?.answer,
            // answer:
            //   item?.question?.type === 'date' ||
            //   item?.question?.type === 'datetime'
            //     ? new Date(item?.answer?.answer).toLocaleDateString()
            //     : item?.answer?.answer,
            show_logic_field: item?.question?.show_logic_field,
            show_logic_value: item?.question?.show_logic_value,
            show_logic_value_type: item?.question?.show_logic_value_type,
            field_type: item?.question?.field_type,
            custom_id: item?.question?.custom_id,
            type: item?.question?.type,
          },
        });
      });

      // let obj = {},
      //   questionList = {};
      // qcAnswerMetaData?.data?.map((item) => {
      //   let fieldName = item?.question?.type;
      //   obj = { ...item?.answer };
      //   obj['id'] = item?.answer?.id;
      //   obj['question'] = item?.question?.id;
      //   obj['type'] = fieldName;
      //   // if (fieldName === 'numeric') {
      //   //   obj['numeric_input_type_choices_validation'] =
      //   //     item?.question?.numeric_input_type_choices_validation;
      //   // } else {

      //   // }

      //   questionList[item?.answer?.id] = obj;

      //   obj = {};
      // });

      setValues(questionList);
      setExistQcAnswerData(questionList);

      // setQcAnswerData({ ...modify });

      let images = [];
      qcAnswerMetaData?.data?.map((item) => {
        if (item?.question?.type === 'signature') {
          images.push(item?.answer?.answer_signature_input);
        } else if (item?.question?.type === 'image_attachment') {
          item?.answer?.answer_image_attachment_input?.map((img) => {
            if (img?.id) {
              images.push(img);
            }
          });
        } else if (item?.question?.type === 'annotation') {
          images.push(item?.answer?.answer_annotation_input);
        } else if (
          item?.answer?.answer_single_selection_group_input?.length > 0
        ) {
          item?.answer_single_selection_group_input?.map((data) => {
            data?.map((cascadeQuestion) => {
              if (cascadeQuestion?.question?.type === 'image_attachment') {
                let img = JSON.parse(
                  cascadeQuestion?.answer_image_attachment_input_base64,
                );
                images.push(...img);
              } else if (cascadeQuestion?.question?.type === 'signature') {
                let img = JSON.parse(cascadeQuestion?.answer_signature_input);
                images.push(...img);
              } else if (cascadeQuestion?.question?.type === 'annotation') {
                let img = JSON.parse(cascadeQuestion?.answer_annotation_input);
                images.push(...img);
              }
            });
          });
        }
      });

      const validImages = images?.filter((data) => data !== null);

      setGroupImages([...groupImages, ...validImages]);
    }
  }, [qcAnswerMetaData, qcAnswerMetaIsLoading]);

  const toast = useToast();

  const {
    mutate: qcScreenEditMutation,
    error: qcScreenEditError,
    isLoading: qcScreenEditIsLoading,
  } = useMutation(
    ANSWER_QC_SCREEN_DATA_SAVE(
      qcMetaData?.data?.question_groups[activeListIndex]?.id,
    ),
    (formBody) =>
      makePostRequest(
        ANSWER_QC_SCREEN_DATA_SAVE(
          qcMetaData?.data?.question_groups[activeListIndex]?.id,
        ),
        formBody,
      ),
    {
      onSuccess: () => {
        setProjectData({
          ...projectDetails,
          isProjectEditable: false,
        });
        toast({
          title: 'Successfully Updated.',
          status: 'success',
          duration: 3000,
        });
      },
    },
  );

  // const [cascadeObj, setcascadeObj] = useState([]);

  const submitHandler = (e) => {
    e.preventDefault();

    const finalObj = PreprocessorData(values, qcData);

    console.log('check answer', { answers_data: finalObj });

    qcScreenEditMutation({ answers_data: finalObj });
  };

  const handleFormSubmit = () => {
    tabOption('tab');
  };

  const onSurveyTypeChange = (inx) => {
    setProjectData((prevState) => ({ ...prevState, activeListIndex: inx }));
    setGroupImages([]);
  };

  const actionsHandler = (value) => {
    Object.entries(nextStates2).map((d) => {
      if (value.name === d[1].name) {
        setSelectedData(d);
      }
    });
  };

  let status = qcMetaData?.data?.current_workflow_state;

  let statusText = '';
  status &&
    status
      .split('_')
      .map(
        (item) => (statusText += item[0].toUpperCase() + item.slice(1) + ' '),
      );

  const getSlicedString = (value, count = 10) => {
    let tmpString = '';
    if (typeof value === 'string') {
      tmpString = value.slice(0, count);
      return `${tmpString}...`;
    }
    return value;
  };

  const downloadHandler = () => {
    window.open(QC_SCREEN_DATA_TO_PDF(getHostAPIUrl(), useDb, params.slug));
  };

  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    if (qcAnswerMetaData?.data?.length >= 1) {
      setIsLoader(false);
    } else {
      setIsLoader(true);
    }
  }, [qcAnswerMetaData]);

  return (
    <DashboardLayout menu={false} type="Projects">
      <form onSubmit={handleFormSubmit}>
        <AppLoader height="100px" isLoading={qcMetaIsLoading}>
          {!qcMetaIsLoading && qcMetaData && (
            <Stack>
              {/* Project Details Information */}
              <Collapse
                startingHeight={isDetailsExpand ? '404px' : '240px'}
                in={!isDetailsExpand}
              >
                <Flex
                  p={'30px 26px'}
                  height={!isDetailsExpand ? '205px' : '440px'}
                  backgroundColor="#fff"
                  position={'relative'}
                  boxShadow="0px 0px 48px rgba(27, 178, 147, 0.1)"
                >
                  <Flex
                    position={'absolute'}
                    top="13px"
                    right={'16px'}
                    alignItems="center"
                  >
                    {/* <img
                      src={ListIcon}
                      style={{ fontSize: '14px', marginRight: '4px' }}
                    />
                    <img
                      src={ExpandIcon}
                      onClick={() =>
                        setProjectData((prevState) => ({
                          ...prevState,
                          isDetailsExpand: !isDetailsExpand,
                        }))
                      }
                      style={{
                        cursor: 'pointer',
                        transform: isDetailsExpand
                          ? 'rotate(180deg)'
                          : 'rotate(0)',
                        fontSize: '8px',
                      }}
                    /> */}
                  </Flex>
                  {!isDetailsExpand ? (
                    qcMetaData?.data?.banner_image ? (
                      <Box>
                        <Image
                          boxSize="148px"
                          objectFit="cover"
                          src={qcMetaData.data.banner_image}
                          alt="project image"
                        />
                      </Box>
                    ) : null
                  ) : null}
                  {/* Project Details */}
                  <Stack
                    borderRight="1px solid #B7C2D7"
                    w={isDetailsExpand ? '404px' : '388px'}
                    pl={isDetailsExpand ? 0 : '41px'}
                  >
                    <AppText
                      customStyles={{
                        color: appColors.brandTeal[500],
                        fontWeight: 700,
                        lineHeight: '19.36px',
                        fontSize: '16px',
                      }}
                      text="Project Details"
                    />
                    <Flex m="0 0 14px !important" mt="22px !important">
                      <AppText
                        customStyles={{
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '16.92px',
                          minWidth: '75px',
                        }}
                        text="Project Number : "
                      />
                      <AppText
                        customStyles={resultTextStyle}
                        text={
                          getSlicedString(
                            qcMetaData?.data?.generate_id ||
                              qcMetaData?.data?.id,
                          ) || 'NA'
                        }
                      />
                    </Flex>
                    <Flex m="0 0 14px !important">
                      <AppText
                        customStyles={{
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '16.92px',
                        }}
                        text="Project Name : "
                      />
                      <AppText
                        customStyles={resultTextStyle}
                        text={qcMetaData?.data?.identity || 'NA'}
                      />
                    </Flex>
                    <Flex m="0 0 14px !important">
                      <AppText
                        customStyles={{
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '16.92px',
                          minWidth: '120px',
                        }}
                        text="Project Address : "
                      />
                      <AppText
                        customStyles={resultTextStyle}
                        text={qcMetaData?.data?.address || 'NA'}
                      />
                    </Flex>
                    <Flex m="0 0 14px !important">
                      <AppText
                        customStyles={{
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '16.92px',
                        }}
                        text="Project Type : "
                      />
                      <AppText
                        customStyles={resultTextStyle}
                        text={qcMetaData?.data?.project_type?.identity || 'NA'}
                      />
                    </Flex>
                  </Stack>
                  {/* Project Details End */}

                  {/* Survey Date */}
                  <Stack
                    borderRight="1px solid #B7C2D7"
                    w="222px"
                    pl={isDetailsExpand ? '25px' : '35px'}
                  >
                    <AppText
                      customStyles={{
                        color: appColors.brandTeal[500],
                        fontWeight: 700,
                        lineHeight: '19.36px',
                        fontSize: '16px',
                      }}
                      text="Survey Date"
                    />
                    <Flex m="0 0 14px !important" mt="22px !important">
                      <AppText
                        customStyles={{
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '16.92px',
                          minWidth: '40px',
                        }}
                        text="Start Date : "
                      />
                      <AppText
                        customStyles={resultTextStyle}
                        text={
                          startDate
                            ? new Date(startDate).toLocaleDateString()
                            : 'NA'
                        }
                      />
                    </Flex>
                    <Flex m="0 0 14px !important">
                      <AppText
                        customStyles={{
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '16.92px',
                          minWidth: '40px',
                        }}
                        text=" Start Time : "
                      />
                      <AppText
                        customStyles={resultTextStyle}
                        text={
                          startDate
                            ? new Date(startDate).toLocaleTimeString()
                            : 'NA'
                        }
                      />
                    </Flex>
                    <Flex m="0 0 14px !important">
                      <AppText
                        customStyles={{
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '16.92px',
                          minWidth: '40px',
                        }}
                        text="End Date : "
                      />
                      <AppText
                        customStyles={resultTextStyle}
                        text={
                          endDate
                            ? new Date(endDate).toLocaleDateString()
                            : 'NA'
                        }
                      />
                    </Flex>
                    <Flex m="0 0 14px !important">
                      <AppText
                        customStyles={{
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '16.92px',
                          minWidth: '40px',
                        }}
                        text=" End Time : "
                      />
                      <AppText
                        customStyles={resultTextStyle}
                        text={
                          endDate
                            ? new Date(endDate).toLocaleTimeString()
                            : 'NA'
                        }
                      />
                    </Flex>
                  </Stack>
                  {/* Survey Date End*/}

                  {/* Completion Date */}
                  {isDetailsExpand ? (
                    <Stack borderRight="1px solid #B7C2D7" w="250px" pl="25px">
                      <AppText
                        customStyles={{
                          color: appColors.brandTeal[500],
                          fontWeight: 700,
                          lineHeight: '19.36px',
                          fontSize: '16px',
                        }}
                        text="Completion Date"
                      />
                      <Flex m="0 0 14px !important" mt="22px !important">
                        <AppText
                          customStyles={{
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '16.92px',
                          }}
                          text="Date : "
                        />
                        <AppText
                          customStyles={resultTextStyle}
                          text={
                            surveyDate
                              ? new Date(surveyDate).toLocaleDateString()
                              : 'NA'
                          }
                        />
                      </Flex>
                      <Flex m="0 0 14px !important">
                        <AppText
                          customStyles={{
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '16.92px',
                          }}
                          text="Time : "
                        />
                        <AppText
                          customStyles={resultTextStyle}
                          text={
                            surveyDate
                              ? new Date(surveyDate).toLocaleTimeString()
                              : 'NA'
                          }
                        />
                      </Flex>
                    </Stack>
                  ) : null}
                  {/* Completion Date End*/}

                  {/* Surveyor Details */}
                  <Stack w="281px" pl="25px">
                    <AppText
                      customStyles={{
                        color: appColors.brandTeal[500],
                        fontWeight: 700,
                        lineHeight: '19.36px',
                        fontSize: '16px',
                      }}
                      text="Surveyor Details"
                    />

                    <Flex m="0 0 14px !important" mt="22px !important">
                      <AppText
                        customStyles={{
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '16.92px',
                        }}
                        text="Surveyor Name : "
                      />
                      <AppText
                        customStyles={resultTextStyle}
                        text={
                          qcMetaData?.data?.assigned_field_auditor?.identity ||
                          'NA'
                        }
                      />
                    </Flex>
                    <Flex m="0 0 14px !important">
                      <AppText
                        customStyles={{
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '16.92px',
                          minWidth: '90px',
                        }}
                        text="Surveyor ID : "
                      />
                      <AppText
                        customStyles={resultTextStyle}
                        text={
                          getSlicedString(
                            qcMetaData?.data?.assigned_field_auditor?.id,
                          ) || 'NA'
                        }
                      />
                    </Flex>
                  </Stack>
                  {/* Surveyor Details End*/}

                  {/* Status */}
                  <Stack
                    display={'flex'}
                    alignItems="center"
                    mt={isDetailsExpand ? '46px' : '36px'}
                  >
                    <AppMenu
                      label="Change Status"
                      iconStyles={{
                        width: '20px',
                        height: '20px',
                        color: appColors.brandTeal['900'],
                      }}
                      buttonStyles={{
                        fontSize: fontSizes.sm,
                        fontWeight: 400,
                        color: appColors.brandGray[500],
                        width: '200px',
                        textAlign: 'start',
                      }}
                    >
                      {actionsList.length === 0 ||
                      location?.state?.status === 'inactive' ? (
                        <MenuItem
                          color={appColors.brandBlack[600]}
                          key="noData"
                        >
                          No Action
                        </MenuItem>
                      ) : (
                        actionsList.map((data) => {
                          return (
                            <MenuItem
                              color={appColors.brandBlack[600]}
                              key={data.name}
                              onClick={() => actionsHandler(data)}
                            >
                              {data.name}
                            </MenuItem>
                          );
                        })
                      )}
                    </AppMenu>

                    <Badge
                      h="24px"
                      w="150px"
                      mt="17px !important"
                      bgColor={'#152626'}
                      color="#fff"
                      borderRadius={'12px'}
                      display="flex"
                      alignItems={'center'}
                      justifyContent="center"
                    >
                      <Icon
                        as={GoPrimitiveDot}
                        color={statusText === 'Completed' ? '#34EB7D' : 'red'}
                        fontSize={'14px'}
                      ></Icon>
                      <AppText
                        text={statusText}
                        customStyles={{
                          marginRight: '5.5px',
                          textTransform: 'initial',
                          fontSize: '12px',
                          fontWeight: 700,
                          lineHeight: '14.5px',
                        }}
                      />
                    </Badge>
                  </Stack>
                  {/* Status End*/}
                </Flex>
              </Collapse>
              {/* Project Details Information End*/}

              <Flex justifyContent="flex-end">
                {status === 'qc_pending' && (
                  <AppButton
                    text="Edit"
                    customStyles={{
                      width: '100px',
                      margin: '20px 0px 5px 0px',
                    }}
                    onClick={() =>
                      setProjectData({
                        ...projectDetails,
                        isProjectEditable: true,
                      })
                    }
                  >
                    <MdModeEditOutline
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '8px',
                      }}
                    />
                  </AppButton>
                )}
              </Flex>

              {/* Edit button section end*/}

              {/* Project details entries section */}
              <Flex
                pl={'41px'}
                height="max-content"
                backgroundColor="#fff"
                position={'relative'}
                boxShadow="0px 0px 48px rgba(27, 178, 147, 0.1)"
              >
                {!logsShow && (
                  <Stack
                    style={{
                      borderRight: '1px solid #CDCDCD',
                    }}
                    pt={'15px !important'}
                    pb={'36px !important'}
                  >
                    <Flex alignItems="center" mt="17px !important">
                      <AppText
                        text={'Survey Details'}
                        customStyles={{
                          color: appColors.brandTeal[500],
                          pl: '17px',
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '20.8px',
                        }}
                      />
                    </Flex>
                    <List
                      spacing={3}
                      style={{
                        overflow: 'auto',
                        height: '279px',
                        width: '242px',
                        padding: '29px 0 0 17px',
                        marginTop: '10px',
                      }}
                    >
                      {listItem.map((item, inx) => {
                        return (
                          <ListItem
                            key={inx}
                            onClick={() => onSurveyTypeChange(inx)}
                            style={{
                              marginBottom: '25px',
                              cursor: 'pointer',
                              borderRight:
                                activeListIndex === inx
                                  ? `3px solid ${appColors.brandTeal[500]}`
                                  : '3px solid transparent',
                            }}
                          >
                            <AppText
                              text={item}
                              customStyles={{
                                fontSize: '14px',
                                lineHeight: '17px',
                                color: appColors.brandTeal[500],
                                width: 'max-content',
                                paddingBottom: '4px',
                                fontWeight: 700,
                              }}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Stack>
                )}

                <Stack
                  style={{
                    position: 'relative',
                    width: '100%',
                    marginTop: 0,
                  }}
                  padding="15px 30px 30px 30px"
                >
                  <AppLoader isLoader={isLoader}>
                    <Flex justifyContent="flex-end">
                      {!logsShow && (
                        <>
                          <AppButton
                            text="Download"
                            variant="downloadBtn"
                            customStyles={{
                              marginRight: '14px',
                              width: '150px',
                            }}
                            disabled={isProjectEditable}
                            onClick={downloadHandler}
                          >
                            <MdDownload
                              style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '10px',
                              }}
                            />
                          </AppButton>
                          <AppButton
                            text="View All Image"
                            variant="cancelBtn"
                            customStyles={{
                              color: appColors.brandBlack['400'],
                              width: '180px',
                            }}
                            onClick={() => onViewImagesOpen()}
                            // onClick={() => onImagesOpen()}
                          >
                            <IoImages
                              style={{
                                width: '22px',
                                height: '22px',
                                marginRight: '10px',
                                color: appColors.brandTeal['500'],
                              }}
                            />
                          </AppButton>
                          <AppButton
                            text="Gallery"
                            variant="cancelBtn"
                            customStyles={{
                              color: appColors.brandBlack['400'],
                              marginLeft: '14px',
                              width: '150px',
                            }}
                            onClick={() =>
                              window.open(SURVEY_TYPE_IMAGES(params.slug))
                            }
                          >
                            <IoImages
                              style={{
                                width: '22px',
                                height: '22px',
                                marginRight: '10px',
                                color: appColors.brandTeal['500'],
                              }}
                            />
                          </AppButton>
                        </>
                      )}
                      <AppButton
                        text={!logsShow ? 'View Log' : 'View Survey'}
                        variant="cancelBtn"
                        customStyles={{
                          color: appColors.brandBlack['400'],
                          marginLeft: '14px',
                          width: '180px',
                        }}
                        onClick={() => setLogsShow(!logsShow)}
                      >
                        <FaListUl
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '10px',
                            color: appColors.brandTeal['500'],
                          }}
                        />
                      </AppButton>
                    </Flex>
                    {logsShow && (
                      <>
                        <AppText
                          customStyles={{
                            color: appColors.brandTeal[500],
                            fontWeight: 700,
                            lineHeight: '19.36px',
                            fontSize: '16px',
                            marginBottom: '10px !important',
                          }}
                          text="Log History"
                        />
                        {qcMetaData?.data?.logs?.map((logdata) => {
                          return (
                            <>
                              <Flex
                                alignItems="center"
                                padding="10px 0px"
                                justifyContent="space-between"
                              >
                                <AppText
                                  variant="emailText"
                                  text={logdata?.display_message}
                                  customStyles={{
                                    width: '75%',
                                    lineHeight: '20px',
                                  }}
                                />
                                <AppText
                                  variant="labelText"
                                  text={`${
                                    getDateTimeString(logdata?.created)?.split(
                                      ',',
                                    )?.[0]
                                  } | ${
                                    getDateTimeString(logdata?.created)?.split(
                                      ',',
                                    )?.[1]
                                  }`}
                                />
                              </Flex>
                              <Divider />
                            </>
                          );
                        })}
                      </>
                    )}
                    {(status === 'qc_pending' ||
                      status === 'project_completed') &&
                    !logsShow ? (
                      <div>
                        <AppTabs
                          tabsHeading={tabsHeading}
                          setState={setTabOption}
                          variant={'enclosed'}
                          tabIndex={tabIndex}
                          setTabIndex={setTabIndex}
                          marginTop={0}
                        >
                          <AppLoader
                            height="100px"
                            isLoading={qcAnswerMetaIsLoading}
                          >
                            <TabPanels style={{ padding: '20px 0 0 1px' }}>
                              <TabPanel>
                                <Flex flexDirection="column">
                                  {!qcAnswerMetaIsLoading && (
                                    <RoofForm
                                      slug={
                                        qcMetaData?.data?.question_groups[
                                          activeListIndex
                                        ]?.id
                                      }
                                      isEditable={isProjectEditable}
                                      roofDetails={qcAnswerMetaData}
                                      groupImages={groupImages}
                                      setGroupImages={setGroupImages}
                                      qcData={qcData}
                                      values={values}
                                      setValues={setValues}
                                      submitHandler={submitHandler}
                                      qcScreenEditIsLoading={
                                        qcScreenEditIsLoading
                                      }
                                      projectDetails={projectDetails}
                                      setProjectData={setProjectData}
                                      error={qcScreenEditError?.response?.data}
                                      qcAnswerMetaIsLoading={
                                        qcAnswerMetaIsLoading
                                      }
                                      qcAnswerMetaRefetch={qcAnswerMetaRefetch}
                                      existQcAnswerData={existQcAnswerData}
                                    />
                                  )}
                                </Flex>
                              </TabPanel>
                              <TabPanel>
                                {groupImages[0] !== undefined ? (
                                  <GroupImages data={groupImages} />
                                ) : (
                                  <>No Preview Available</>
                                )}
                              </TabPanel>
                            </TabPanels>
                          </AppLoader>
                        </AppTabs>
                      </div>
                    ) : status !== 'qc_pending' && !logsShow ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        No Data To Preview
                      </div>
                    ) : null}
                  </AppLoader>
                </Stack>
              </Flex>

              {/* Project details entries section end*/}

              <AppModal
                isOpen={isModalOpen}
                onClose={onModalClose}
                text={selectedData && selectedData?.[1]?.name}
                closeCheck={true}
              >
                <ActionProject
                  onClose={onModalClose}
                  projectsCheckedId={[qcMetaData?.data?.id]}
                  selectedData={selectedData}
                  fromState={fromState2}
                />
              </AppModal>

              <AppModal
                isOpen={isViewImagesOpen}
                onClose={onViewImagesClose}
                text=""
                closeCheck={false}
                closeText={true}
                size="auto"
              >
                <ModalImages
                  onClose={onViewImagesClose}
                  listItem={listItem}
                  qcAnswerMetaData={qcAnswerMetaData}
                />
              </AppModal>
            </Stack>
          )}
        </AppLoader>
      </form>
    </DashboardLayout>
  );
};

export default ViewProject;
