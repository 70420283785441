import { Box, Flex, useToast } from '@chakra-ui/react';
import { ADMIN_TEAM_MEMBER_SETPASSWORD, USER_SET_PASSWORD } from 'api/urls';
import makePostRequest from 'api/utilities/makePostRequest';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AuthCard from 'components/AuthCard/AuthCard';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import useHandleShowPassword from 'hooks/formHelpers/useHandleShowPassword';
import AuthLayout from 'layout/AuthLayout';
import {
  ADD_TEAM_MEMBERS_SCREEN,
  APP_LOGIN_SCREEN,
} from 'navigation/routes/auth';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const InitialFormErrors = {
  data: {
    non_field_errors: [''],
  },
};
const AppCreateNewPassword = () => {
  const { passwordProps } = useHandleShowPassword();
  const { passwordProps: confirmPasswordProps } = useHandleShowPassword();

  const location = useLocation();

  const admin_invite = location?.pathname.includes(
    '/organisation/admin-invite',
  );

  const [error, setError] = useState(InitialFormErrors);
  const [formValues, setFormValues] = useState({});

  const params = useParams();
  const toast = useToast();

  const navigate = useNavigate();

  let API_HANDLER;

  const verificationToken = params?.verificationToken;

  if (admin_invite) {
    API_HANDLER = ADMIN_TEAM_MEMBER_SETPASSWORD(verificationToken);
  } else {
    API_HANDLER = USER_SET_PASSWORD(verificationToken);
  }

  const {
    mutate,
    isLoading,
    error: passwordResetError,
    isSuccess: passwordResetSuccess,
  } = useMutation(
    API_HANDLER,
    (formVal) => makePostRequest(API_HANDLER, formVal),
    {
      onError: (formError) => {
        setError(formError.response.data);
      },
    },
  );

  const formFields = [
    {
      fieldName: 'password',
      fieldPlaceholder: 'New Password',
      ...passwordProps,
    },
    {
      fieldPlaceholder: 'Confirm New Password',
      fieldName: 'confirm_password',
      ...confirmPasswordProps,
    },
  ];

  useEffect(() => {
    if (passwordResetSuccess && !isLoading) {
      if (admin_invite) {
        navigate(ADD_TEAM_MEMBERS_SCREEN);
      } else {
        toast({
          title: 'Password has been reset successfully',
          status: 'success',
          duration: 3000,
        });
        navigate(APP_LOGIN_SCREEN);
      }
    }
  }, [isLoading, passwordResetSuccess]);

  return (
    <AuthLayout>
      <AuthCard>
        <AppHeader text="Create New Password" />

        <Flex flexFlow="column" minH={300} justifyContent={'space-between'}>
          <Box textAlign="center">
            <AppText text="Email Verified Successfully" size="sm" />
            <AppText text="Please enter your new password" size="sm" />
            <AppText
              text={passwordResetError?.response?.data?.data?.detail}
              variant="errorMessage"
            />
          </Box>

          <Box>
            {formFields.map((field) => (
              <AppInput
                {...field}
                key={field.fieldName}
                formValues={formValues}
                setFormValues={setFormValues}
                fieldError={error}
              />
            ))}
          </Box>

          <AppButton
            isLoading={isLoading}
            text={admin_invite ? 'Submit' : 'Reset my password'}
            onClick={() =>
              admin_invite
                ? mutate({
                    user_password: formValues?.password,
                    confirm_password: formValues?.confirm_password,
                  })
                : mutate(formValues)
            }
          />
        </Flex>
      </AuthCard>
    </AuthLayout>
  );
};

export default AppCreateNewPassword;
