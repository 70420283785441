import { Flex, Image } from '@chakra-ui/react';
import useStore from 'AppZustand/store';
import { BarImage, LineBarImage, PieChartIcon } from 'assets';
import AppButton from 'components/AppButton/AppButton';
import AppCard from 'components/AppCard/AppCard';
import AppText from 'components/typography/AppText';
import React, { useState } from 'react';
import appColors from 'theme/appColors';

const AddWidget = ({ onClose, openNextModal }) => {
  const [chartTypes, setChartTypes] = useState('');
  const { fetchChartTypes, widgetMetaData } = useStore((state) => state);

  const chartHandler = (type) => {
    setChartTypes(type);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    fetchChartTypes(chartTypes);
    onClose();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Flex gap="27px" padding="20px">
        {widgetMetaData?.type?.map((item) => {
          let imageName =
            item.identity === 'Line'
              ? LineBarImage
              : item.identity === 'Bar'
              ? BarImage
              : PieChartIcon;
          return (
            <AppCard
              key={item.id}
              variant="widgetCard"
              onClick={() => chartHandler(`${item.id}`)}
              hoverStyles={{
                border: `1px solid ${appColors.brandTeal['500']}`,
              }}
              customStyles={{
                border: `${
                  chartTypes.toLocaleLowerCase() === item.id
                    ? `1px solid ${appColors.brandTeal['500']}`
                    : ''
                }`,
              }}
            >
              <Image src={imageName} width="72px" height="72px" />
              <AppText
                text={`${item.identity} Graph`}
                variant="addWidgetInnerText"
                customStyles={{ color: appColors.brandBlack['600'] }}
              />
            </AppCard>
          );
        })}
      </Flex>
      <Flex margin="40px 0px" width="100%" justifyContent="center">
        <Flex width="70%">
          <AppButton
            text="Cancel"
            variant="cancelBtn"
            customStyles={{
              marginRight: '20px',
            }}
            onClick={() => onClose()}
          />
          <AppButton
            type="submit"
            text="Continue"
            disabled={chartTypes === ''}
            onClick={() => {
              openNextModal();
            }}
          />
        </Flex>
      </Flex>
    </form>
  );
};

export default AddWidget;
