import { Flex, useToast } from '@chakra-ui/react';
import {
  GET_BULK_UPLOADED_PROJECTS,
  GET_BULK_UPLOADED_USERS,
  POST_BULK_UPLOAD_PROJECTS,
  POST_BULK_UPLOAD_USERS,
} from 'api/urls/bulkupload';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import AppButton from 'components/AppButton/AppButton';
import AppDragAndDropUploader from 'components/AppDragAndDropUploader/AppDragAndDropUploader';
import AppLoader from 'components/AppLoader/AppLoader';
import AppHeader from 'components/typography/AppHeader';
import DashboardLayout from 'layout/DashboardLayout';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

const BulkUpload = () => {
  const [type, setType] = useState('');
  const [errors] = useState([]);
  const toast = useToast();
  const location = useLocation();

  function successful() {
    toast({
      title: `Successfuly Uploaded`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }
  useEffect(() => {
    setType(location.pathname.split('/').pop());
  }, []);
  const changeHandler = (file, status) => {
    if (status === 'done') {
      toast({
        title: `${file.meta.name}`,
        description: 'Your file has been uploaded.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } else if (status === 'error_file_size') {
      toast({
        title: `Maximum file size is 50 Mb`,
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const { mutate: projectsData, isLoading: isUploading } = useMutation(
    POST_BULK_UPLOAD_PROJECTS,
    (body) =>
      makePostRequest(POST_BULK_UPLOAD_PROJECTS, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      onSuccess: () => {
        successful();
      },
      onError: () => {
        toast({
          title: `All Fields are Required.
          Fill in all the empty fields`,
          status: 'error',
          duration: 9000,
          position: 'top',
          isClosable: true,
        });
      },
    },
  );
  const { mutate: usersData } = useMutation(
    POST_BULK_UPLOAD_USERS,
    (body) =>
      makePostRequest(POST_BULK_UPLOAD_USERS, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      onSuccess: () => {
        successful();
      },
      onError: () => {
        toast({
          title: `All Fields are Required`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const submitHandler = (files) => {
    let formData = new FormData();
    if (type === 'Projects') {
      formData.append('file', files[0].file);
      projectsData(formData);
    } else {
      formData.append('file', files[0].file);
      usersData(formData);
    }
    files.map((f) => f.remove());
  };

  return (
    <DashboardLayout menu={false} type={type}>
      <Flex justifyContent="space-between" alignItems="center">
        <AppHeader
          text="File Upload"
          variant="modalText"
          customStyles={{ textAlign: 'start', margin: '0px !important' }}
        />
        <AppButton
          text="Download Template"
          onClick={() => {
            makeGetRequest(
              type === 'Projects'
                ? GET_BULK_UPLOADED_PROJECTS
                : GET_BULK_UPLOADED_USERS,
            ).then((res) => {
              let modify;

              if (res.data.file.split(':')[0] === 'http') {
                modify = 'https:' + res.data.file.split(':')[1];
              }

              let alink = document.createElement('a');
              alink.href = modify;
              let fileName = modify.split('/').pop();
              alink.download = fileName;
              alink.click();
            });
          }}
          customStyles={{ width: 'auto' }}
        />
      </Flex>

      <AppLoader isLoading={isUploading}>
        <AppDragAndDropUploader
          onChange={changeHandler}
          onSubmit={submitHandler}
          errors={errors}
        />
      </AppLoader>

      {/* <AppHeader
        text="History"
        variant="modalText"
        customStyles={{ textAlign: 'start', margin: '24px 0px' }}
      />
      <Card
        customStyles={{ height: '300px', borderTopLeftRadius: '12px' }}
        variant="tableCard"
      ></Card> */}
    </DashboardLayout>
  );
};

export default BulkUpload;
