import { Text } from '@chakra-ui/react';
import React from 'react';

const AppHeader = ({ text, customStyles = {}, variant }) => {
  const baseHeaderStyles = {
    size: 'xl',
  };

  return (
    <Text
      align="center"
      mb={5}
      {...baseHeaderStyles}
      {...customStyles}
      variant={variant}
    >
      {text}
    </Text>
  );
};

export default AppHeader;
