import { COMPANY_SETTING_UPDATE } from 'navigation/routes';
import EditCompanySetting from 'pages/appSettings/company/EditCompanySetting';
import { Route } from 'react-router-dom';

const CompanySettingRoutes = () => {
  return (
    <>
      <Route path={COMPANY_SETTING_UPDATE} element={<EditCompanySetting />} />
    </>
  );
};

export default CompanySettingRoutes;
