import { Box, useStyleConfig } from '@chakra-ui/react';
import React from 'react';

const Card = ({
  children,
  customStyles = {},
  variant,
  onClick,
  hoverStyles = '',
}) => {
  const styles = useStyleConfig('BoxStyles', { variant });
  return (
    <Box
      __css={styles}
      {...customStyles}
      onClick={onClick}
      _hover={hoverStyles}
    >
      {children}
    </Box>
  );
};

export default Card;
