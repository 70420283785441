import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
} from '@chakra-ui/react';
import AppBox from 'components/AppBox/AppBox';
import AppText from 'components/typography/AppText';
import { memo } from 'react';

const PitchField = ({
  isEditable,
  state,
  setState,
  error,
  index,
  item,
  fieldName,
}) => {
  const placeholder_text = item?.question?.placeholder_text;
  const help_text = item?.question?.help_text;

  const onChange = (num) => {
    if (item?.question?.field_type === 'regular') {
      setState({
        ...state,
        [item?.question?.custom_id]: { ...item?.answer, [fieldName]: num },
      });
    } else if (item?.question?.field_type === 'cascade') {
      setState({
        ...state,
        [item?.question?.custom_id]: { ...item?.question, [fieldName]: num },
      });
    }
  };

  let fieldValue;

  if (state?.[item?.question?.custom_id]?.[fieldName] === null) {
    fieldValue = 0;
  } else {
    fieldValue = state?.[item?.question?.custom_id]?.[fieldName];
  }

  return (
    <Stack>
      <AppBox>
        <NumberInput
          placeholder={placeholder_text}
          variant={'filled'}
          step={0.2}
          precision={2}
          value={fieldValue}
          onChange={(str, num) => onChange(num)}
          disabled={!isEditable}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <AppText
          text={help_text}
          customStyles={{
            fontSize: '12px',
            color: '#757575',
            lineHeight: '15.6px',
            padding: '10px 0 4px',
          }}
        />
      </AppBox>
      <AppText
        text={
          error?.data?.answers_data[index]?.answer_pitch_input ||
          error?.data?.answers_data[index]?.non_field_errors
        }
        variant="errorMessage"
      />
    </Stack>
  );
};

export default memo(PitchField);
