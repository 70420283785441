import { Image, Stack } from '@chakra-ui/react';
import { IMAGE_UPLOAD } from 'api/urls/settings/qcScreen';
import AppFileInput from 'components/AppFileInput/AppFileInput';
import AppText from 'components/typography/AppText';
import { memo } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';

const AnnotationField = ({
  isEditable,
  state,
  setState,
  slug,
  error,
  index,
  type,
  item,
  fieldName,
}) => {
  let image = {};

  if (item?.question?.field_type === 'cascade') {
    image = JSON.parse(
      state?.[item?.question?.custom_id]?.answer_annotation_input_base64,
    );
  } else {
    image = state?.[item?.question?.custom_id]?.[fieldName];
  }

  return (
    <Stack>
      {isEditable || Object.entries(image || {}).length === 0 ? (
        <Stack>
          <AppFileInput
            disabled={!isEditable}
            fieldName={fieldName}
            id="attachment"
            formValues={state}
            setFormValues={setState}
            icon={AiOutlineCloudUpload}
            allowedFileTypes=".jpg,.jpeg,.png"
            uploadEndpoint={IMAGE_UPLOAD}
            urlSlug={slug}
            type={type}
            item={item}
          />
        </Stack>
      ) : null}

      {image && (
        <Image
          key={index}
          style={{
            height: '70px',
            width: '80px',
            marginRight: '10px',
          }}
          objectFit="cover"
          src={image.file || image}
          alt="roof image"
        />
      )}
      <AppText
        text={
          error?.data?.answers_data[index]?.answer_annotation_input ||
          error?.data?.answers_data[index]?.non_field_errors
        }
        variant="errorMessage"
      />
    </Stack>
  );
};

export default memo(AnnotationField);
