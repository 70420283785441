import { Button } from '@chakra-ui/react';
import React from 'react';

const AppButton = ({
  text,
  customStyles,
  children,
  onClick,
  isLoading,
  disabled,
  check,
  variant = 'auth',
  type,
  size = 'lg',
  draggable,
  onDragStart,
  onDragOver,
}) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      isLoading={isLoading}
      disabled={disabled}
      size={size}
      variant={variant}
      {...customStyles}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
    >
      {check !== 'dash' && children}
      {text}
      {check === 'dash' && children}
    </Button>
  );
};

export default AppButton;
