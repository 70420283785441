import { useState } from 'react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

const useHandleShowPassword = () => {
  /**
   * Global hook to handle show password props and method
   * */
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  let passwordProps = { fieldType: 'password' };

  if (showPassword) {
    passwordProps = {
      fieldType: 'text',
      icon: BsEyeSlashFill,
      iconClick: togglePasswordVisibility,
    };
  } else {
    passwordProps = {
      fieldType: 'password',
      icon: BsEyeFill,
      iconClick: togglePasswordVisibility,
    };
  }

  return { showPassword, togglePasswordVisibility, passwordProps };
};

export default useHandleShowPassword;
