import { Link } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const AppLink = ({ text, to, customStyles, size }) => {
  const linkStyles = {};

  return (
    <Link as="span" {...linkStyles} {...customStyles} size={size}>
      <RouterLink to={to}>{text}</RouterLink>
    </Link>
  );
};

export default AppLink;
