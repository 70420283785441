// Access Management Listing

export const GET_ACCESS_MANAGEMENT_LISTING_META = `/api/access/team-member/list/table-meta/`;

export const GET_ACCESS_MANAGEMENT_LISTING = (params = '') =>
  `/api/access/team-member/list/?${params}`;

// Team Member Creation
export const TEAM_MEMBER_CUD = (slug = '') =>
  `/api/access/team-member/cud/${slug && `${slug}/`}`;
export const GET_TEAM_MEMBER_CREATION_META =
  '/api/access/team-member/cud/meta/';

// Team Member Updating
export const GET_TEAM_MEMBER_UPDATING_META = (slug) =>
  `/api/access/team-member/cud/${slug}/meta/`;

// Team Member Deleting
export const TEAM_MEMBER_DELETING = (slug) =>
  `/api/access/team-member/cud/${slug}/`;

// Team Member Accept Invite
export const TEAM_MEMBER_ACCEPT_INVITE = (userDatabaseId, userId) =>
  `/api/user/invite/${userId}/organisation/${userDatabaseId}/access/accept/`;
