import appAxios from 'api/appAxios';
import { setHeadersInStorage } from 'api/utilities/headersHandler';

const makeGetRequest = async (endpoint) => {
  const { data } = await appAxios.get(endpoint);
  setHeadersInStorage(data);
  return data;
};

export default makeGetRequest;
