import { Flex } from '@chakra-ui/react';
import Card from 'components/AppCard/AppCard';
import AppText from 'components/typography/AppText';
import React from 'react';

const AppCard = ({ name, value }) => {
  return (
    <Card variant="dashCard">
      <Flex justifyContent="space-between">
        <AppText text={name} variant="dashInnerName" />
        <AppText text={value} variant="dashInnerValue" />
      </Flex>
    </Card>
  );
};

export default AppCard;
