import appColors from 'theme/appColors';
import { fontSizes } from 'theme/typographyStyles';

const ButtonStyles = {
  baseStyle: {
    _hover: {
      opacity: '0.8',
    },

    _disabled: {
      opacity: '0.7',
    },
  },
  sizes: {
    lg: {
      color: appColors.brandWhite['100'],
      paddingY: 5,
      width: '100%',
    },
  },

  variants: {
    auth: {
      backgroundColor: appColors.brandTeal['500'],
      fontSize: fontSizes.sm,

      _hover: {
        backgroundColor: appColors.brandTeal['500'],

        _disabled: {
          backgroundColor: appColors.brandTeal['500'],
          opacity: 0.4,
        },
      },

      _loading: {
        backgroundColor: appColors.brandTeal['500'],
      },

      _disabled: {
        backgroundColor: appColors.brandTeal['500'],
      },
    },

    dashMenuBtn: {
      background: appColors.brandGray['100'],
      _hover: appColors.brandGray['100'],
      color: appColors.brandBlack['400'],
      fontSize: '14px',
      fontWeight: 400,
      justifyContent: 'space-between',
      padding: '20px',
      flex: '0 48%',
      margin: '10px 0px',
      width: 'auto',
    },

    tableMenuBtn: {
      backgroundColor: '#D9DFEA',
      borderRadius: '6px',
      padding: '10px',
      fontWeight: 400,
      fontSize: '12px',
      color: '#2E3445',
      height: '26px',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '13px',
      _focus: {
        boxShadow: 'none',
      },
    },

    cancelBtn: {
      backgroundColor: appColors.brandWhite['100'],
      border: `0.5px solid ${appColors.brandTeal['500']}`,
      color: appColors.brandTeal['500'],
      fontSize: fontSizes.sm,
    },
    downloadBtn: {
      backgroundColor: appColors.brandWhite['100'],
      border: `0.5px solid ${appColors.brandBlack['400']}`,
      color: appColors.brandBlack['400'],
      fontSize: fontSizes.sm,
    },

    deleteBtn: {
      backgroundColor: appColors.brandRed['300'],
    },
  },
};

export default ButtonStyles;
