import AppButton from 'components/AppButton/AppButton';
import AppText from 'components/typography/AppText';
import React from 'react';
import { IoMdClose } from 'react-icons/io';
import appColors from 'theme/appColors';

const {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerFooter,
  Flex,
} = require('@chakra-ui/react');

export default function RightDrawer({
  isOpen,
  onClose,
  keyName,
  children,
  text,
  option,
  saveHandler,
  isLoading,
  setProperityForm,
  properityForm,
  setError,
  setInitialProperityData,
  setDraggedComponent,
}) {
  const closeHandler = () => {
    onClose({ ...option, [keyName]: false });
    setProperityForm({ ...properityForm, is_required: false });
    setInitialProperityData({});
    setError({});
    setDraggedComponent({});
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={closeHandler}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            padding="12px 25px"
            background={appColors.brandTeal['tableHead']}
            color={appColors.brandWhite['100']}
          >
            <AppText text={text} />
            <IoMdClose onClick={closeHandler} cursor="pointer" />
          </Flex>

          <DrawerBody mt={5}>{children}</DrawerBody>

          <DrawerFooter>
            <AppButton
              text="Cancel"
              variant="cancelBtn"
              onClick={closeHandler}
              customStyles={{ marginRight: '25px' }}
            />
            <AppButton
              text="Save"
              onClick={saveHandler}
              isLoading={isLoading}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
