import { Tab, TabList, Tabs } from '@chakra-ui/react';
import React from 'react';
import appColors from 'theme/appColors';
import { fontSizes } from 'theme/typographyStyles';

const AppTabs = ({
  children,
  tabsHeading,
  setState,
  marginTop = 6,
  variant,
  tabIndex,
  setTabIndex,
}) => {
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  return (
    <Tabs
      width="100%"
      mt={marginTop}
      variant={variant}
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <TabList
        borderColor={
          variant === 'enclosed' ? appColors.brandTeal['500'] : 'inherit'
        }
      >
        {tabsHeading.map((d, i) => {
          return (
            <Tab
              key={i}
              color={appColors.brandBlack['600']}
              fontSize={fontSizes.sm}
              fontWeight={400}
              mr={10}
              _selected={{
                boxShadow: 'none',

                border:
                  variant === 'enclosed' &&
                  `1px solid ${appColors.brandTeal['500']}`,
                borderBottom:
                  variant !== 'enclosed'
                    ? `3px solid ${appColors.brandTeal['500']}`
                    : '1px solid white',
              }}
              onClick={() => setState({ type: d.name })}
            >
              {d.name}
            </Tab>
          );
        })}
      </TabList>
      {children}
    </Tabs>
  );
};

export default AppTabs;
