import { Box, Flex, Stack } from '@chakra-ui/react';
import { CreateSurvey } from 'assets';
// import { UseSurvey } from 'assets';
import AppCard from 'components/AppCard/AppCard';
import AppText from 'components/typography/AppText';
// import { APP_NEW_FORM_BUILDER } from 'navigation/routes';
import React from 'react';
// import { useNavigate } from 'react-router-dom';
import appColors from 'theme/appColors';

const BoxStyles = {
  height: '221px',
  width: '252px',
  background: appColors.brandGray.background,
  borderRadius: '12px',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
};

const CreateAndEditSurvey = ({ onSurveySelect }) => {
  // const navigate = useNavigate();

  return (
    <>
      <Stack alignItems={'center'} mb="80px" textAlign={'center'}>
        <AppText
          customStyles={{
            fontSize: '32px',
            lineHeight: '41.6px',
            fontWeight: 700,
          }}
          text="Create a Form"
        />
        <AppText
          customStyles={{
            fontSize: '16px',
            lineHeight: '20.8px',
          }}
          text="Start building the form to make the survey easier"
        />
        <Flex mt={'56px !important'}>
          <Box>
            <AppCard
              customStyles={BoxStyles}
              hoverStyles={{
                border: `1px solid ${appColors.brandTeal[500]}`,
                boxShadow: '0px 0px 24px rgba(76, 138, 127, 0.12)',
              }}
              onClick={() => onSurveySelect('scratch')}
            >
              <img src={CreateSurvey} alt="icon" />
            </AppCard>
            <AppText
              customStyles={{
                marginTop: '20px',
                fontSize: '18px',
                lineHeight: '23.4px',
                fontWeight: '500',
                justifyContent: 'center',
              }}
              text="Create from scratch"
            />
          </Box>
          {/* <Box ml={'68px'}>
            <AppCard
              customStyles={BoxStyles}
              hoverStyles={{
                border: `1px solid ${appColors.brandTeal[500]}`,
                boxShadow: '0px 0px 24px rgba(76, 138, 127, 0.12)',
              }}
              onClick={() => navigate(APP_NEW_FORM_BUILDER())}
            >
              <img src={UseSurvey} alt="icon" />
            </AppCard>
            <AppText
              customStyles={{
                marginTop: '20px',
                fontSize: '18px',
                lineHeight: '23.4px',
                fontWeight: '500',
              }}
              text="Use a template"
            />
          </Box> */}
        </Flex>
      </Stack>
    </>
  );
};

export default CreateAndEditSurvey;
