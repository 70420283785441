import { getHeadersFromStorage } from 'api/utilities/headersHandler';
import { getHostAPIUrl } from 'AppConfig';
import axios from 'axios';

const appAxios = axios.create({
  baseURL: getHostAPIUrl(),
  timeout: 30000,
  responseType: 'json',
});

appAxios.interceptors.request.use(async (config) => {
  if (getHeadersFromStorage()) {
    config.headers = getHeadersFromStorage();
  }
  return config;
});

export default appAxios;
