export const GET_WIDGET_OVERALL_CONFIG = () =>
  `api/report-widgets/overall-config/`;

export const GET_DASHBOARD_DATA = () => 'api/report-widgets/dashboard-data/';

export const GET_WIDGET_META_DATA = () =>
  'api/report-widgets/report-widget/cud/meta/';

export const CREATE_WIDGET = () => 'api/report-widgets/report-widget/cud/';

export const GET_WIDGET_DATA = (slug) =>
  `api/report-widgets/report-widget/cud/${slug}/meta/`;

export const UPDATE_WIDGET_DATA = (slug) =>
  `api/report-widgets/report-widget/cud/${slug}/`;

export const DELETE_WIDGET_DATA = (slug) =>
  `api/report-widgets/report-widget/cud/${slug}/`;
