import { Flex, Icon } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const NavItem = ({
  icon,
  children,
  menuState,
  link = '#',
  onClick,
  customStyles,
  iconStyles,
  isExpand,
  params,
  ...rest
}) => {
  return (
    <Link to={{ pathname: link, search: params }} onClick={onClick}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        marginRight="0px"
        {...rest}
        style={{ ...customStyles }}
      >
        {icon && (
          <Icon
            mr={!isExpand ? '4' : '0'}
            fontSize="18px"
            as={icon}
            style={{ ...iconStyles }}
          />
        )}
        {!menuState && !isExpand && children}
      </Flex>
    </Link>
  );
};

export default NavItem;
