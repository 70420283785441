import { APP_HEADERS } from 'constants';

const setHeadersInStorage = (data) => {
  if (data?.headers) {
    localStorage.setItem(APP_HEADERS, JSON.stringify(data.headers));
  }
};

const getHeadersFromStorage = () => {
  return JSON.parse(localStorage.getItem(APP_HEADERS));
};

export { getHeadersFromStorage, setHeadersInStorage };
