import { AUTH_SIGNUP } from 'api/urls';
import makePostRequest from 'api/utilities/makePostRequest';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AuthCard from 'components/AuthCard/AuthCard';
import AppHeader from 'components/typography/AppHeader';
import useHandleShowPassword from 'hooks/formHelpers/useHandleShowPassword';
import AuthLayout from 'layout/AuthLayout';
import {
  APP_EMAIL_VERIFICATION_SCREEN,
  APP_LOGIN_SCREEN,
} from 'navigation/routes/auth';
import RedirectionCTA from 'pages/Auth/components/RedirectionCTA/RedirectionCTA';
import TermsAndConditionsText from 'pages/Auth/components/TermsAndConditionsText/TermsAndConditionsText';
import React, { useState } from 'react';
import { CgOrganisation } from 'react-icons/cg';
import { MdEmail } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

/**
 * Signup Screen - Test
 * */
const AppSignupScreen = () => {
  const InitialFormErrors = {
    data: {
      non_field_errors: [''],
    },
  };
  // React Hooks - Data
  const [formValues, setFormValues] = useState({
    organization_name: '',
    user_email: '',
    password: '',
  });

  const [formError, setFormErrors] = useState(InitialFormErrors);

  // Custom Hooks
  const { passwordProps } = useHandleShowPassword();

  // Route
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    AUTH_SIGNUP,
    (formVal) => makePostRequest(AUTH_SIGNUP, formVal),
    {
      onSuccess: (data) =>
        navigate(APP_EMAIL_VERIFICATION_SCREEN, {
          state: {
            user_email: formValues.user_email,
            tracker_id: data?.data?.tracker_id,
          },
        }),
      onError: (error) => setFormErrors(error?.response?.data),
    },
  );

  const handleFormSumbit = (event) => {
    event.preventDefault();
    setFormErrors(InitialFormErrors);
    mutate(formValues);
  };

  // Form Fields
  const formFields = [
    {
      fieldPlaceholder: 'Organisation Name',
      fieldType: 'text',
      fieldName: 'organisation_name',
      isRequired: true,
      icon: CgOrganisation,
    },
    {
      fieldPlaceholder: 'Email Address',
      fieldType: 'email',
      fieldName: 'user_email',
      isRequired: true,
      icon: MdEmail,
    },
    {
      fieldPlaceholder: 'Password',
      fieldName: 'user_password',
      isRequired: true,
      ...passwordProps,
    },
  ];
  // API
  return (
    <AuthLayout>
      <AuthCard>
        <AppHeader text="Sign Up - Getting Started" customStyles={{ mb: 10 }} />
        <form onSubmit={handleFormSumbit}>
          {formFields.map((fieldProps) => (
            <AppInput
              {...fieldProps}
              key={fieldProps.fieldName}
              formValues={formValues}
              setFormValues={setFormValues}
              fieldError={formError}
            />
          ))}
          <AppButton
            text={'Create Account'}
            customStyles={{
              type: 'submit',
            }}
            isLoading={isLoading}
          />
        </form>

        <TermsAndConditionsText
          customStyles={{ display: 'flex', gap: '5px' }}
        />

        <RedirectionCTA
          titleText={'Have an account? '}
          ctaText={'Login'}
          ctaLink={APP_LOGIN_SCREEN}
        />
      </AuthCard>
    </AuthLayout>
  );
};

export default AppSignupScreen;
