import { Stack } from '@chakra-ui/react';
import AppBox from 'components/AppBox/AppBox';
import AppSelect from 'components/AppSelect/AppSelect';
import GetOptionForSingleSelect from 'components/GetOptionsForSingleSelect/GetOptionsForSingleSelect';
import AppText from 'components/typography/AppText';
import { memo, useEffect, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';

const SingleSelectionField = ({
  isEditable,
  state,
  setState,
  error,
  index,
  item,
  type,
  customStyles,
}) => {
  const [option, setOption] = useState([]);

  useEffect(() => {
    let arr = [];
    item?.question?.single_selection_input_choices_validation?.map((data) => {
      arr.push({ id: data, identity: data });
    });
    setOption(arr);
  }, [item?.question?.single_selection_input_choices_validation]);

  return (
    <Stack>
      <AppBox>
        <AppSelect
          variant="filled"
          icon={MdArrowDropDown}
          customStyles={{ fontSize: '14px', ...customStyles }}
          formValues={state}
          setFormValues={setState}
          fieldName={'answer_single_selection_input'}
          disabled={!isEditable}
          type={type}
          item={item}
        >
          {option && (
            <GetOptionForSingleSelect
              data={option}
              initial={
                state?.[item?.question?.custom_id]
                  ?.answer_single_selection_input
              }
            />
          )}
        </AppSelect>

        {/* <RadioGroup
          onChange={(e) => onRadioChange(e)}
          value={state?.[answers]?.answer_single_selection_input}
          disabled={!isEditable}
          style={{
            pointerEvents: isEditable ? 'auto' : 'none',
          }}
        >
          <Stack>
            {questions?.single_selection_input_choices_validation?.map(
              (item, inx) => {
                return (
                  <Radio key={inx} value={item} disabled={!isEditable}>
                    {item}
                  </Radio>
                );
              },
            )}
          </Stack>
        </RadioGroup> */}
      </AppBox>
      <AppText
        text={
          error?.data?.answers_data[index]?.answer_single_selection_input ||
          error?.data?.answers_data[index]?.non_field_errors
        }
        variant="errorMessage"
      />
    </Stack>
  );
};

export default memo(SingleSelectionField);
