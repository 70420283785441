import {
  Address,
  Attachment,
  Cascade,
  Checkbox,
  Date,
  DateTime,
  Divider,
  Email,
  GmapIcon,
  GoogleAddress,
  Heading,
  Image,
  MultiSelection,
  Numeric,
  PhoneNumber,
  ShortText,
  Signature,
  SingleSelection,
  TextArea,
  URLField,
} from 'assets';

export const FormWidgetsMeta = {
  heading: {
    name: 'Heading',
    icon: Heading,
  },
  annotation: {
    name: 'Annotation',
    icon: MultiSelection,
  },
  gmap_address: {
    name: 'Gmap',
    icon: GmapIcon,
  },
  pitch: {
    name: 'Pitch',
    icon: ShortText,
  },
  horizontal_divider: {
    name: 'Divider',
    icon: Divider,
  },
  short_text: {
    name: 'Short Text',
    icon: ShortText,
  },
  large_text: {
    name: 'Text Area',
    icon: TextArea,
  },
  email_address: {
    name: 'Email',
    icon: Email,
  },
  phone_number: {
    name: 'Phone Number',
    icon: PhoneNumber,
  },
  numeric: {
    name: 'Numeric',
    icon: Numeric,
  },
  address: {
    name: 'Address',
    icon: Address,
  },
  google_address: {
    name: 'Google Address',
    icon: GoogleAddress,
  },
  image_attachment: {
    name: 'Image',
    icon: Image,
  },
  single_selection: {
    name: 'Single Selection',
    icon: SingleSelection,
  },
  multi_selection: {
    name: 'Multi Selection',
    icon: MultiSelection,
  },
  checkbox: {
    name: 'Check Box',
    icon: Checkbox,
  },
  file_attachment: {
    name: 'Attachment',
    icon: Attachment,
  },
  url: {
    name: 'URL Field',
    icon: URLField,
  },
  date: {
    name: 'Date',
    icon: Date,
  },
  datetime: {
    name: 'Date Time',
    icon: DateTime,
  },
  signature: {
    name: 'Signature',
    icon: Signature,
  },
  cascaded: {
    name: 'Cascaded Field',
    icon: Cascade,
  },
};
