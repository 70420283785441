import { Box, Flex } from '@chakra-ui/react';
import { capitalize } from 'constants';
import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export default function Linechart({ reportdata }) {
  const data = reportdata?.map((item) => {
    return { name: capitalize(item.display), pv: item.value };
  });
  return (
    <Box>
      {reportdata.length !== 0 && (
        <LineChart
          width={520}
          height={300}
          data={data}
          margin={{
            top: 30,
            right: 20,
            left: 0,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="pv"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      )}
      {reportdata.length === 0 && (
        <Flex alignItems="center" padding="40px" justifyContent="center">
          Data Not Available
        </Flex>
      )}
    </Box>
  );
}
