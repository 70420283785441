import { Flex, useToast } from '@chakra-ui/react';
import { EDIT_PROFILE_DATA, PROFILE_META_DATA } from 'api/urls';
import makePatchRequest from 'api/utilities/makePatchRequest';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

const AppEditProfile = ({ onClose, metaData }) => {
  const [formValues, setFormValues] = useState({
    name: metaData?.name,
    email: metaData?.email,
  });

  const [error, setError] = useState(true);

  const formFields = [
    {
      fieldPlaceholder: 'Name',
      fieldType: 'text',
      fieldName: 'name',
    },
    {
      fieldPlaceholder: 'Email',
      fieldType: 'email',
      fieldName: 'email',
    },
  ];

  const toast = useToast();

  const queryClient = useQueryClient();

  const {
    mutate: editProfileMutation,
    error: editProfileError,
    isLoading: editProfileIsLoading,
  } = useMutation(
    EDIT_PROFILE_DATA,
    (formBody) => makePatchRequest(EDIT_PROFILE_DATA, formBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROFILE_META_DATA);

        if (formValues?.email !== metaData?.email) {
          toast({
            title: 'Verification link has been sent to your email successfully',
            status: 'success',
            duration: 3000,
          });
        }

        onClose();
      },
    },
  );

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (formValues.name) {
      editProfileMutation(formValues);

      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {formFields.map((field) => {
        return (
          <AppInput
            {...field}
            key={field.fieldName}
            formValues={formValues}
            setFormValues={setFormValues}
            fieldError={editProfileError?.response?.data}
            isRequired={error}
          />
        );
      })}

      <Flex>
        <AppButton
          text="Cancel"
          variant="cancelBtn"
          customStyles={{ margin: '0px 22px 27px 22px' }}
          onClick={() => onClose()}
        />
        <AppButton
          type="submit"
          text="Save"
          customStyles={{ margin: '0px 22px 27px 0px' }}
          isLoading={editProfileIsLoading}
        />
      </Flex>
    </form>
  );
};

export default AppEditProfile;
