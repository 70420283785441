import { Flex } from '@chakra-ui/react';
import { DummyQR } from 'assets';
import AppButton from 'components/AppButton/AppButton';
import AuthCard from 'components/AuthCard/AuthCard';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import AuthLayout from 'layout/AuthLayout';
import { APP_DASHBOARD } from 'navigation/routes/dashboard';
import TermsAndConditionsText from 'pages/Auth/components/TermsAndConditionsText/TermsAndConditionsText';
import { useNavigate } from 'react-router-dom';

const DownloadMobileAppScreen = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <AuthCard>
        <AppHeader text={'Download mobile app'} />
        <Flex
          flexFlow="column"
          minH={270}
          justifyContent="space-between"
          alignItems={'center'}
        >
          <AppText
            text={
              'Mobile App is used for taking surveys and enabling better productivity'
            }
            size="sm"
            customStyles={{
              textAlign: 'center',
            }}
          />

          <img src={DummyQR} alt="" />
          <AppButton
            text={'Proceed to appDashboard'}
            onClick={() => navigate(APP_DASHBOARD)}
          />
        </Flex>

        <TermsAndConditionsText
          customStyles={{ display: 'flex', gap: '5px' }}
        />

        {/*<RedirectionCTA titleText={''} ctaText={'Skip For Now'} ctaLink={'#'} />*/}
      </AuthCard>
    </AuthLayout>
  );
};

export default DownloadMobileAppScreen;
