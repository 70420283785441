import { Flex, List, ListItem, Stack } from '@chakra-ui/react';
import {
  ANSWER_QC_SCREEN_DATA,
  QC_SCREEN_DATA,
} from 'api/urls/settings/qcScreen';
import makeGetRequest from 'api/utilities/makeGetRequest';
import AppLoader from 'components/AppLoader/AppLoader';
import AppText from 'components/typography/AppText';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import appColors from 'theme/appColors';

import GroupImages from '../groupImages/GroupImages';

const ModalImages = ({ listItem }) => {
  const [activeListIndex, setActiveListIndex] = useState(0);

  const [groupImages, setGroupImages] = useState([]);

  const params = useParams();

  const {
    mutate: getQcMetadata,
    data: qcMetaData,
    isLoading: qcMetaIsLoading,
  } = useMutation(QC_SCREEN_DATA(params.slug), () =>
    makeGetRequest(QC_SCREEN_DATA(params.slug)),
  );

  useEffect(() => {
    getQcMetadata();
  }, [getQcMetadata]);

  const { data: qcAnswerMetaData, isLoading: qcAnswerMetaIsLoading } = useQuery(
    ANSWER_QC_SCREEN_DATA(
      qcMetaData?.data?.question_groups[activeListIndex]?.id,
    ),
    () =>
      qcMetaData?.data?.question_groups[activeListIndex]?.id &&
      makeGetRequest(
        ANSWER_QC_SCREEN_DATA(
          qcMetaData?.data?.question_groups[activeListIndex]?.id,
        ),
      ),
  );

  useEffect(() => {
    let images = [];
    qcAnswerMetaData?.data?.map((item) => {
      if (item?.question?.type === 'signature') {
        images.push(item?.answer?.answer_signature_input);
      } else if (item?.question?.type === 'image_attachment') {
        item?.answer?.answer_image_attachment_input?.map((img) => {
          if (img?.id) {
            images.push(img);
          }
        });
      } else if (item?.question?.type === 'annotation') {
        images.push(item?.answer?.answer_annotation_input);
      } else if (
        item?.answer?.answer_single_selection_group_input?.length > 0
      ) {
        item?.answer_single_selection_group_input?.map((data) => {
          data?.map((cascadeQuestion) => {
            if (cascadeQuestion?.question?.type === 'image_attachment') {
              let img = JSON.parse(
                cascadeQuestion?.answer_image_attachment_input_base64,
              );
              images.push(...img);
            }
          });
        });
      }
    });

    setGroupImages([...groupImages, ...images]);
  }, [qcAnswerMetaData]);

  const onSurveyTypeChange = (inx) => {
    setActiveListIndex(inx);
    setGroupImages([]);
  };

  return (
    <Flex>
      <Stack
        style={{
          borderRight: '1px solid #CDCDCD',
        }}
        pt={'15px !important'}
        pb={'36px !important'}
      >
        <Flex alignItems="center" mt="17px !important">
          <AppText
            text={'Survey Images'}
            customStyles={{
              color: appColors.brandTeal[500],
              pl: '17px',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '20.8px',
            }}
          />
        </Flex>
        <List
          spacing={3}
          style={{
            overflow: 'auto',
            height: '279px',
            width: '242px',
            padding: '29px 0 0 17px',
            marginTop: '10px',
          }}
        >
          {listItem.map((item, inx) => {
            return (
              <ListItem
                key={inx}
                onClick={() => onSurveyTypeChange(inx)}
                style={{
                  marginBottom: '25px',
                  cursor: 'pointer',
                  borderRight:
                    activeListIndex === inx
                      ? `3px solid ${appColors.brandTeal[500]}`
                      : '3px solid transparent',
                }}
              >
                <AppText
                  text={item}
                  customStyles={{
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: appColors.brandTeal[500],
                    width: 'max-content',
                    paddingBottom: '4px',
                    fontWeight: 700,
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      </Stack>

      <Stack
        style={{
          position: 'relative',
          width: '800px',
          marginTop: 0,
        }}
        padding="15px 30px 30px 30px !important"
      >
        <AppLoader isLoading={qcMetaIsLoading || qcAnswerMetaIsLoading}>
          {groupImages[0] !== undefined ? (
            <GroupImages data={groupImages} />
          ) : (
            <>No Preview Available</>
          )}
        </AppLoader>
      </Stack>
    </Flex>
  );
};

export default ModalImages;
