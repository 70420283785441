import { APP_REPORTS } from 'navigation/routes';
import Reports from 'pages/appSettings/reports/Reports';
import { Route } from 'react-router-dom';

const ReportsRoutes = () => {
  return (
    <>
      <Route path={APP_REPORTS} element={<Reports />} />
    </>
  );
};

export default ReportsRoutes;
