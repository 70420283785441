import { CheckboxGroup, Stack } from '@chakra-ui/react';
import React from 'react';

const AppCheckBox = ({ children, customStyles }) => {
  return (
    <CheckboxGroup colorScheme="teal">
      <Stack spacing={[1, 5]} direction={['column']} {...customStyles}>
        {children}
      </Stack>
    </CheckboxGroup>
  );
};

export default AppCheckBox;
