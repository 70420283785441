import appColors from 'theme/appColors';

const SelectStyles = {
  div: {
    width: '20px !important',
  },
  variants: {
    filled: {
      field: {
        height: '50px',
        color: appColors.brandGray['500'],
        backgroundColor: appColors.brandTeal['300'],
      },
    },
    unstyled: {
      field: {
        fontSize: '22px',
        fontWeight: 700,
        color: appColors.brandBlack['600'],
      },
    },
  },
};

export default SelectStyles;
