import { Box, GridItem } from '@chakra-ui/react';
import AppCard from 'components/AppCard/AppCard';
import AppText from 'components/typography/AppText';
import React from 'react';
import appColors from 'theme/appColors';

const FormWidgets = ({ widget, widgetDragStart, item }) => {
  return (
    <GridItem
      data-group
      w="175px"
      h="39px"
      mb="3px"
      draggable
      style={{
        border: '1px solid #CDCDCD',
        borderRadius: '4px',
      }}
      _hover={{
        borderColor: `${appColors.brandTeal[500]} !important`,
        color: appColors.brandTeal[500],
      }}
      onDragStart={(e) => widgetDragStart(item, e)}
    >
      <AppCard
        customStyles={{
          fontSize: '14px',
          lineHeight: '21px',
          paddingLeft: '14px',
          justifyContent: 'flex-start',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          marginTop: 0,
          cursor: 'move',
          background: 'transparent',
        }}
      >
        <Box
          _groupHover={{
            filter:
              'invert(65%) sepia(12%) saturate(2587%) hue-rotate(119deg) brightness(100%) contrast(84%)',
          }}
        >
          <img
            src={widget?.icon}
            alt="icon"
            style={{
              marginRight: '10px',
              fontSize: '16px',
            }}
          />
        </Box>
        <AppText
          text={widget?.name}
          customStyles={{
            fontSize: '14px',
            lineHeight: '21px',
          }}
        />
      </AppCard>
    </GridItem>
  );
};

export default FormWidgets;
