import { Flex } from '@chakra-ui/react';
import {
  GET_SURVEY_TYPE_CREATION_META,
  GET_SURVEY_TYPE_EDIT_AND_DELETE,
  GET_SURVEY_TYPE_EDIT_PREFILLED_DATA,
  GET_SURVEY_TYPE_LISTING,
  SURVEY_TYPE_CREATION,
} from 'api/urls/settings';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AppLoader from 'components/AppLoader/AppLoader';
import AppSelect from 'components/AppSelect/AppSelect';
import GetOptionForSingleSelect from 'components/GetOptionsForSingleSelect/GetOptionsForSingleSelect';
import AppText from 'components/typography/AppText';
import useHandleAddAndEdit from 'hooks/formHelpers/useHandleAddAndEdit';
import React, { useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useQueryClient } from 'react-query';

const AddAndEditSurvey = ({
  onClose,
  isDelete,
  isEdit,
  currentId,
  getTableDataMutation,
}) => {
  const [formValues, setFormValues] = useState({
    identity: '',
    status: '',
    project_type: '',
  });

  const formFields = [
    {
      fieldPlaceholder: 'Survey Name',
      fieldType: 'text',
      fieldName: 'identity',
      isRequired: true,
    },
  ];

  const formSelectFields = [
    {
      fieldPlaceholder: 'Status',
      fieldName: 'status',
      fieldMetaName: 'statuses',
    },
    {
      fieldPlaceholder: 'Project Type',
      fieldName: 'project_type',
      fieldMetaName: 'project_types',
    },
  ];

  const queryClient = useQueryClient();
  const {
    metaData,
    initialValues,
    formError,
    isLoading,
    isSubmitLoading,
    deleteMutation,
    handleFormSubmit,
  } = useHandleAddAndEdit({
    baseMetaUrl: GET_SURVEY_TYPE_CREATION_META,
    prefillMetaUrl: GET_SURVEY_TYPE_EDIT_PREFILLED_DATA(currentId),
    createUrl: SURVEY_TYPE_CREATION,
    editAndDeleteUrl: GET_SURVEY_TYPE_EDIT_AND_DELETE(currentId),
    isEdit: isEdit,
    formValues: formValues,
    setFormValues: setFormValues,
    onClose: onClose,
    toastText: 'survey type',
    onCreateEditSuccess: () => {
      getTableDataMutation();
      queryClient.invalidateQueries(GET_SURVEY_TYPE_LISTING);
    },
  });
  const closeHandler = () => {
    onClose();
  };

  if (isDelete) {
    return (
      <>
        <AppText
          text="Are you sure you wanna delete this project type"
          customStyles={{ mb: 5, textAlign: 'center' }}
        />
        <Flex>
          <AppButton
            text="Cancel"
            variant="cancelBtn"
            customStyles={{ margin: '0px 22px 27px 22px' }}
            onClick={closeHandler}
          />
          <AppButton
            isLoading={isSubmitLoading}
            text="Delete"
            variant="deleteBtn"
            onClick={() => {
              onClose();
              deleteMutation();
            }}
            customStyles={{ margin: '0px 22px 27px 0px' }}
          />
        </Flex>
      </>
    );
  } else {
    return (
      <form onSubmit={handleFormSubmit}>
        <AppLoader height="100px" isLoading={isLoading}>
          {!isLoading && (
            <>
              {formFields.map((field) => (
                <>
                  <AppInput
                    {...field}
                    key={field.fieldName}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    fieldError={formError}
                    label={isEdit ? field.fieldPlaceholder : ''}
                    innerLabel={isEdit ? true : false}
                    isRequired={field.isRequired}
                  />
                </>
              ))}

              {formSelectFields.map((field, i) => (
                <div style={{ marginBottom: '1.25rem' }} key={i}>
                  <AppSelect
                    variant="filled"
                    icon={MdArrowDropDown}
                    customStyles={{ fontSize: '14px' }}
                    formValues={formValues}
                    fieldName={field.fieldName}
                    setFormValues={setFormValues}
                    fieldError={formError}
                    label={isEdit ? field.fieldPlaceholder : ''}
                  >
                    <option value="" hidden>
                      {field.fieldPlaceholder}
                    </option>
                    <GetOptionForSingleSelect
                      data={metaData?.[field.fieldMetaName]}
                      initial={initialValues?.[field.fieldName]}
                    />
                  </AppSelect>
                </div>
              ))}
              <Flex>
                <AppButton
                  text="Cancel"
                  variant="cancelBtn"
                  customStyles={{ margin: '0px 22px 27px 22px' }}
                  onClick={closeHandler}
                />
                <AppButton
                  type="submit"
                  text={isEdit ? 'Save' : 'Create'}
                  isLoading={isSubmitLoading}
                  customStyles={{ margin: '0px 22px 27px 0px' }}
                />
              </Flex>
            </>
          )}
        </AppLoader>
      </form>
    );
  }
};

export default AddAndEditSurvey;
