import { Flex, useDisclosure } from '@chakra-ui/react';
import isUserAuthenticated from 'api/utilities/isUserAuthenticated';
import useStore from 'AppZustand/store';
import AppAlertModal from 'components/AppAlertModal/AppAlertModal';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppNavBar from 'components/AppNavBar/AppNavBar';
import { APP_LOGIN_SCREEN, COMPANY_SETTING_UPDATE } from 'navigation/routes';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const DashboardLayout = ({
  children,
  menu,
  type,
  menuName,
  customStyles,
  isSideBar = true,
}) => {
  const {
    fetchPermissions,
    companySettings,
    duplicateCompanySettings,
    setCompanySettings,
    setDuplicateCompanySettings,
    setCompanySettingsData,
    companySettingsAlert,
  } = useStore((state) => state);
  const navigate = useNavigate();
  const location = useLocation();

  const [render, setRender] = useState(false);

  const {
    isOpen: isAlertOpen,
    onClose: onAlertClose,
    onOpen: onAlertOpen,
  } = useDisclosure();

  useEffect(() => {
    isUserAuthenticated()
      .then((data) => {
        fetchPermissions(data?.data?.data?.permissions);
      })
      .catch(() => navigate(APP_LOGIN_SCREEN));

    if (
      companySettingsAlert &&
      JSON.stringify(companySettings) !==
        JSON.stringify(duplicateCompanySettings) &&
      !location.pathname.includes('company_settings')
    ) {
      // toast({
      //   title: toastMsg,
      //   status: 'error',
      //   duration: 3000,
      // });

      onAlertOpen();
      setRender(true);
      setDuplicateCompanySettings({});
      setCompanySettings({});
    }
    document.body.style.overflow = 'hidden';
  }, []);

  window.addEventListener('keydown', function (e) {
    if (e.ctrlKey && e.keyCode === 73) {
      document.getElementById('search')?.focus();
    }
  });

  const handleSubmit = () => {
    navigate(location.pathname);
    onAlertClose();
    setRender(false);
    setCompanySettingsData({});
  };

  const onCancel = () => {
    navigate(COMPANY_SETTING_UPDATE);
    setRender(false);
  };

  return (
    <>
      <AppNavBar />

      <Flex
        style={{
          width: '100%',
        }}
      >
        {isSideBar && <AppDrawer menu={menu} type={type} menuName={menuName} />}
        <div
          style={{
            padding: '30px',
            position: 'relative',
            height: '89vh',
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            ...customStyles,
          }}
        >
          {!render && children}
        </div>
      </Flex>
      <AppAlertModal
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        content="Your changes are not saved. Do you want to exit?"
        isShowCancel={true}
        handleSubmit={handleSubmit}
        onCancel={onCancel}
        setCompanySettingsData={setCompanySettingsData}
      />
    </>
  );
};

export default DashboardLayout;
