import { Flex, Stack } from '@chakra-ui/react';
import {
  FORM_CREATION,
  FORM_CREATION_META,
} from 'api/urls/settings/formBuilder';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import useStore from 'AppZustand/store';
import AppBox from 'components/AppBox/AppBox';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AppLoader from 'components/AppLoader/AppLoader';
import AppSelect from 'components/AppSelect/AppSelect';
import AppTextArea from 'components/AppTextArea/AppTextArea';
import AppText from 'components/typography/AppText';
import { APP_NEW_FORM_BUILDER } from 'navigation/routes';
import React, { useEffect, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const SurveyFormDetails = ({ onClose }) => {
  const { fetchFormBuilderData } = useStore((state) => state);
  const [formDetails, setFormDetails] = useState({
    name: '',
    description: '',
    projectTypes: [],
    survey_types: [],
    statuses: [],
    status: '',
    selectedSurvey: '',
    selectedProject: '',
  });

  const navigate = useNavigate();

  const {
    mutate: getFormCreationData,
    isLoading: getFormIsLoading,
    data: getFormData,
  } = useMutation(FORM_CREATION, (formData) =>
    makePostRequest(FORM_CREATION, formData),
  );

  useEffect(() => {
    if (getFormData) {
      navigate(APP_NEW_FORM_BUILDER(getFormData.data.id));
    }
  }, [getFormData]);

  const {
    mutate: getFormMetaData,
    data: getMetaData,
    isLoading: getMetaIsLoading,
  } = useMutation(FORM_CREATION_META(), () =>
    makeGetRequest(FORM_CREATION_META()),
  );

  useEffect(() => {
    getFormMetaData();
  }, []);

  useEffect(() => {
    if (getMetaData?.data?.meta?.project_types.length > 0) {
      setFormDetails((prevState) => ({
        ...prevState,
        projectTypes: getMetaData.data.meta.project_types,
      }));
    }
    if (getMetaData?.data?.meta?.statuses.length > 0) {
      setFormDetails((prevState) => ({
        ...prevState,
        statuses: getMetaData.data.meta.statuses,
      }));
    }
    if (formDetails.selectedProject) {
      setFormDetails((prevState) => ({
        ...prevState,
        survey_types: getMetaData?.data.meta.survey_types.filter(
          (data) => data.project_type === formDetails.selectedProject,
        ),
      }));
    } else {
      setFormDetails((prevState) => ({
        ...prevState,
        survey_types: [],
      }));
    }
  }, [getMetaData, formDetails.selectedProject]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    getFormCreationData({
      identity: formDetails.name,
      description: formDetails.description,
      project_type: formDetails.selectedProject,
      survey_type: formDetails.selectedSurvey,
      status: formDetails.status,
    });
    fetchFormBuilderData(formDetails);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <AppLoader height={'100px'} isLoading={getMetaIsLoading}>
        {getMetaData && !getMetaIsLoading ? (
          <Stack alignItems={'center'} mb="45px" textAlign={'center'}>
            <AppText
              customStyles={{
                fontSize: '32px',
                lineHeight: '41.6px',
                fontWeight: 700,
              }}
              text="Form Details"
            />
            <AppBox variant="inputBox">
              <AppInput
                formValues={formDetails}
                fieldName={'name'}
                setFormValues={setFormDetails}
                fieldPlaceholder={'Form Name'}
                fieldError={formDetails?.name}
              />
            </AppBox>
            <AppBox variant="inputBox">
              <AppSelect
                formValues={formDetails}
                variant="filled"
                isRequired={true}
                fieldName={'selectedProject'}
                setFormValues={setFormDetails}
                icon={MdArrowDropDown}
                fieldError={formDetails?.name}
              >
                <option value="" hidden>
                  Select Project Type
                </option>
                {formDetails?.projectTypes.length > 0 ? (
                  formDetails?.projectTypes?.map((data) => {
                    return (
                      <option key={data.id} value={data.id}>
                        {data.identity}
                      </option>
                    );
                  })
                ) : (
                  <option disabled>No options available</option>
                )}
              </AppSelect>
            </AppBox>
            <AppBox variant="inputBox">
              <AppSelect
                formValues={formDetails}
                variant="filled"
                isRequired={true}
                fieldName={'selectedSurvey'}
                icon={MdArrowDropDown}
                setFormValues={setFormDetails}
                fieldError={formDetails?.name}
              >
                <option value="" hidden>
                  Select Survey Type
                </option>
                {formDetails?.survey_types.length > 0 ? (
                  formDetails?.survey_types?.map((data) => {
                    return (
                      <option key={data.id} value={data.id}>
                        {data.identity}
                      </option>
                    );
                  })
                ) : (
                  <option disabled>No options available</option>
                )}
              </AppSelect>
            </AppBox>
            <AppBox variant="inputBox">
              <AppSelect
                formValues={formDetails}
                variant="filled"
                isRequired={true}
                fieldName={'status'}
                icon={MdArrowDropDown}
                setFormValues={setFormDetails}
                fieldError={formDetails?.status}
              >
                <option value="" hidden>
                  Select Status
                </option>
                {formDetails?.statuses?.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.identity}
                  </option>
                ))}
              </AppSelect>
            </AppBox>
            <AppBox variant="inputBox">
              <AppTextArea
                formValues={formDetails}
                variant={'filled'}
                setFormValues={setFormDetails}
                fieldError={formDetails?.description}
                fieldName={'description'}
                customStyles={{
                  fontSize: '14px',
                  height: '177px',
                }}
                fieldPlaceholder={'Description (Optional)'}
              />
            </AppBox>
            <Flex mt={'26px !important'}>
              <AppButton
                text="Cancel"
                variant="cancelBtn"
                customStyles={{
                  marginRight: '20px',
                }}
                onClick={() => onClose()}
              />
              <AppButton
                type="submit"
                text="Create Form"
                isLoading={getFormIsLoading}
              />
            </Flex>
          </Stack>
        ) : null}
      </AppLoader>
    </form>
  );
};

export default SurveyFormDetails;
