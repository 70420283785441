import { Flex, Image, Stack } from '@chakra-ui/react';
import { IMAGE_UPLOAD } from 'api/urls/settings/qcScreen';
import AppFileInput from 'components/AppFileInput/AppFileInput';
import AppText from 'components/typography/AppText';
import { memo } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import appColors from 'theme/appColors';

const ImageField = ({
  isEditable,
  state,
  setState,
  slug,
  error,
  index,
  type,
  item,
}) => {
  let images = [];

  let fieldName = 'answer_image_attachment_input';

  if (item?.question?.field_type === 'cascade') {
    images = JSON.parse(
      state?.[item?.question?.custom_id]?.answer_image_attachment_input_base64,
    );
    // images = state?.[item?.question?.custom_id]?.answer_image_attachment_input;
    // fieldName = 'answer_image_attachment_input_base64';
  } else {
    images = state?.[item?.question?.custom_id]?.answer_image_attachment_input;
    fieldName = 'answer_image_attachment_input';
  }

  return (
    <Stack>
      {isEditable || images?.length === 0 ? (
        <Stack>
          <AppFileInput
            disabled={!isEditable}
            fieldName={fieldName}
            id="attachment"
            formValues={state}
            setFormValues={setState}
            icon={AiOutlineCloudUpload}
            allowedFileTypes=".jpg,.jpeg,.png"
            uploadEndpoint={IMAGE_UPLOAD}
            urlSlug={slug}
            type={type}
            item={item}
          />
        </Stack>
      ) : null}

      {images?.map((item, index) => {
        if (index <= 1) {
          return (
            <Image
              key={index}
              style={{
                height: '70px',
                width: '80px',
                marginRight: '10px',
              }}
              objectFit="cover"
              src={item?.file || item}
              alt="roof image"
            />
          );
        } else if (index <= 2) {
          return (
            <Flex alignItems="center" position="relative">
              <Image
                key={index}
                style={{
                  height: '70px',
                  width: '80px',
                  marginRight: '10px',
                }}
                objectFit="cover"
                src={item?.file || item}
                alt="roof image"
              />
              <Flex
                cursor="pointer"
                position="absolute"
                width="80px"
                height="70px"
                backgroundColor="rgba(0, 0, 0, 0.6)"
              >
                {images?.length > 3 && (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    width="100%"
                  >
                    <AppText
                      text={`+ ${images?.length - 3}`}
                      customStyles={{
                        color: `${appColors.brandWhite['100']}`,
                        fontWeight: 700,
                      }}
                    />

                    <AppText
                      text="more"
                      customStyles={{
                        color: `${appColors.brandWhite['100']}`,
                      }}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          );
        }
      })}
      <AppText
        text={
          error?.data?.answers_data[index]?.answer_image_attachment_input ||
          error?.data?.answers_data[index]?.non_field_errors
        }
        variant="errorMessage"
      />
    </Stack>
  );
};

export default memo(ImageField);
