const FormConstant = {
  heading: 'heading',
  divider: 'horizontal_divider',
  input: 'short_text',
  textArea: 'large_text',
  email: 'email_address',
  phoneNumber: 'phone_number',
  numeric: 'numeric',
  address: 'address',
  googleAddress: 'google_address',
  images: 'image_attachment',
  singleSelection: 'single_selection',
  multiSelection: 'multi_selection',
  checkbox: 'checkbox',
  attachment: 'file_attachment',
  url: 'url',
  date: 'date',
  datetime: 'datetime',
  signature: 'signature',
  cascadeField: 'cascade_field',
  annotation: 'annotation',
  pitch: 'pitch',
};

export default FormConstant;
