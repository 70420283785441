import { Flex } from '@chakra-ui/react';
import {
  BottomLeft,
  BottomRight,
  MidLeft,
  MidRight,
  TopLeft,
  TopRight,
} from 'assets';
import React from 'react';

const AuthLayout = ({ children }) => {
  /**
   * Base Layout for the entire application
   * */
  return (
    <>
      <Flex
        height="100vh"
        alignItems="end"
        justifyContent="center"
        flexFlow="column"
        background="#ffffff"
        backgroundSize="100% 100%"
        position="relative"
      >
        <Flex position="absolute" width="100%">
          <img
            src={TopLeft}
            style={{ position: 'absolute', left: '280px', bottom: '130px' }}
          />
          <img
            src={TopRight}
            style={{
              position: 'absolute',
              right: '260px',
              bottom: '130px',
            }}
          />
        </Flex>
        <Flex position="absolute" width="100%">
          <img
            src={MidLeft}
            style={{ position: 'absolute', left: '20px', bottom: '-50px' }}
          />
          <img
            src={MidRight}
            style={{
              position: 'absolute',
              right: '130px',
              bottom: '-50px',
            }}
          />
        </Flex>
        <Flex position="absolute" width="100%">
          <img
            src={BottomLeft}
            style={{ position: 'absolute', left: '200px', bottom: '-300px' }}
          />
          <img
            src={BottomRight}
            style={{
              position: 'absolute',
              right: '130px',
              bottom: '-300px',
            }}
          />
        </Flex>
        {children}
      </Flex>
    </>
  );
};

export default AuthLayout;
