export const APP_HEADERS = 'app_headers';

export const getDateTimeString = (date) => {
  if (date) {
    return new Intl.DateTimeFormat('en-IN', {
      timeStyle: 'short',
      dateStyle: 'medium',
    }).format(new Date(date));
  }
  return null;
};

export function capitalize(string) {
  let modified = string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase(),
  );

  let final;

  if (modified === 'Qc Pending') {
    final = 'QC Pending';
  } else {
    final = modified;
  }

  return final;
}

// string.charAt(0).toUpperCase() + string.slice(1);

// const finalSentence = mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
