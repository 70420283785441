export const GET_SURVEY_TYPE_LISTING = (params = '') =>
  `/api/project-meta-module/survey-type/list/?${params}`;
export const GET_SURVEY_TYPE_LISTING_META = `/api/project-meta-module/survey-type/list/table-meta/`;

export const GET_SURVEY_TYPE_CREATION_META = `/api/project-meta-module/survey-type/cud/meta/`;
export const SURVEY_TYPE_CREATION = '/api/project-meta-module/survey-type/cud/';

export const GET_SURVEY_TYPE_EDIT_PREFILLED_DATA = (slug) =>
  `/api/project-meta-module/survey-type/cud/${slug}/meta/`;
export const GET_SURVEY_TYPE_EDIT_AND_DELETE = (slug) =>
  `/api/project-meta-module/survey-type/cud/${slug}/`;
