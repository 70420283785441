import 'react-phone-input-2/lib/style.css';

import { Flex, Image, useDisclosure, useToast } from '@chakra-ui/react';
import {
  COMPANY_LOGO_UPLOADING,
  COMPANY_SETTING_UPDATING,
  COMPANY_SETTING_UPDATING_META,
} from 'api/urls/settings/company';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePutRequest from 'api/utilities/makePutRequest';
import useStore from 'AppZustand/store';
import AppAlertModal from 'components/AppAlertModal/AppAlertModal';
import AppButton from 'components/AppButton/AppButton';
import Card from 'components/AppCard/AppCard';
import AppFileIcon from 'components/AppFileIcon/AppFileIcon';
import AppInput from 'components/AppInput/AppInput';
import AppLabel from 'components/AppLabel/AppLabel';
import AppLoader from 'components/AppLoader/AppLoader';
import AppTextArea from 'components/AppTextArea/AppTextArea';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import DashboardLayout from 'layout/DashboardLayout';
import { APP_PROJECTS_TYPES } from 'navigation/routes';
import React, { useEffect, useState } from 'react';
import { VscEdit } from 'react-icons/vsc';
import PhoneInput from 'react-phone-input-2';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import appColors from 'theme/appColors';
import { fontSizes } from 'theme/typographyStyles';

const EditCompanySetting = () => {
  const [formValues, setFormValues] = useState({});
  const { onClose } = useDisclosure();

  const {
    isOpen: isPermissionAlertOpen,
    onClose: onPermissionAlertClose,
    onOpen: onPermissionAlertOpen,
  } = useDisclosure();

  const {
    permissions,
    companySettingsData,
    setCompanySettings,
    setDuplicateCompanySettings,
    setCompanySettingsData,
    setCompanySettingsAlert,
  } = useStore((state) => state);

  const getValidPhno = (phone_number) => {
    let phno;

    if (!phone_number?.includes('+')) {
      phno = `+${phone_number}`;
    } else if (phone_number?.includes('+') && phone_number?.length <= 2) {
      phno = null;
    } else {
      phno = phone_number;
    }

    return phno;
  };

  const navigate = useNavigate();

  const {
    data: teamMemberMetaData,
    isLoading: teamMemberMetaIsLoading,
    refetch,
  } = useQuery(
    COMPANY_SETTING_UPDATING_META,
    () => makeGetRequest(COMPANY_SETTING_UPDATING_META),
    {
      onError: (data) => {
        if (data.response.status === 403) {
          onPermissionAlertOpen();
        }
      },
      enabled: false,
    },
  );

  useEffect(() => {
    if (!Object.keys(companySettingsData).length > 0) {
      refetch();
    } else {
      setFormValues(companySettingsData);
    }
  }, [companySettingsData]);

  useEffect(() => {
    if (
      !teamMemberMetaIsLoading &&
      teamMemberMetaData &&
      !Object.keys(companySettingsData).length > 0
    ) {
      setFormValues({
        ...formValues,
        ...teamMemberMetaData?.data?.initial,
        file: teamMemberMetaData?.data?.urls?.logo,
        phone_number:
          teamMemberMetaData?.data?.initial?.phone_number === null
            ? '+1'
            : teamMemberMetaData?.data?.initial?.phone_number,
      });
      setCompanySettings({
        ...formValues,
        ...teamMemberMetaData?.data?.initial,
        file: teamMemberMetaData?.data?.urls?.logo,
        phone_number:
          teamMemberMetaData?.data?.initial?.phone_number === null
            ? '+1'
            : teamMemberMetaData?.data?.initial?.phone_number,
      });
    }
  }, [teamMemberMetaIsLoading, teamMemberMetaData]);

  useEffect(() => {
    if (Object.keys(formValues).length > 0) {
      setCompanySettingsData(formValues);
    }
    setDuplicateCompanySettings(formValues);
  }, [formValues]);
  const {
    mutate: companySettingUpdationMutation,
    error: companySettingUpdationError,
    isLoading: companySettingUpdationIsLoading,
    data: companySettingUpdationData,
  } = useMutation(
    COMPANY_SETTING_UPDATING,
    (formBody) => makePutRequest(COMPANY_SETTING_UPDATING, formBody),
    {
      onSuccess: () => {
        refetch();
        setCompanySettingsAlert(false);
      },
    },
  );

  const toast = useToast();

  useEffect(() => {
    if (!companySettingUpdationIsLoading && companySettingUpdationData) {
      toast({
        title: 'Company settings updated.',
        status: 'success',
        duration: 3000,
      });
      onClose();
    }
  }, [companySettingUpdationIsLoading, companySettingUpdationData]);

  const formFields = [
    {
      fieldPlaceholder: formValues.identity,
      fieldType: 'text',
      fieldName: 'identity',
      label: 'Name',
    },
    {
      fieldPlaceholder: formValues.email_address,
      fieldType: 'email',
      fieldName: 'email_address',
      label: 'Email address',
    },
  ];

  const ProjectIdFields = [
    {
      fieldPlaceholder: formValues.prefix,
      fieldType: 'text',
      fieldName: 'prefix',
      label: 'Project Id Prefix',
    },
    {
      fieldPlaceholder: formValues.suffix,
      fieldType: 'text',
      fieldName: 'suffix',
      label: 'Project Id Suffix',
    },
  ];

  const textarea = [
    {
      fieldPlaceholder: formValues.address,
      fieldName: 'address',
      label: 'Address',
    },
    {
      fieldPlaceholder: formValues.description,
      fieldName: 'description',
      label: 'Description',
    },
  ];

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const {
      identity,
      phone_number,
      email_address,
      description,
      address,
      logo,
      preferred_timezone,
      prefix,
      suffix,
    } = formValues;

    let phno = getValidPhno(phone_number);

    const finalData = {
      identity,
      phone_number: phno ? phno : null,
      email_address,
      description,
      address,
      logo,
      preferred_timezone,
      prefix: prefix || null,
      suffix: suffix || null,
    };
    companySettingUpdationMutation(finalData);
  };

  const getValid = (initialData, formData, fieldName) => {
    let valid = false;

    let phno = getValidPhno(formData?.[fieldName]);

    if (
      fieldName === 'phone_number' &&
      initialData?.[fieldName] !== phno &&
      formData?.[fieldName]?.length > 2
    ) {
      valid = true;
    } else if (
      initialData?.[fieldName] !== formData?.[fieldName] &&
      formData?.[fieldName] &&
      fieldName !== 'phone_number'
    ) {
      valid = true;
    } else {
      valid = false;
    }

    return valid;
  };

  const alertHandler = () => {
    onPermissionAlertClose();
    navigate(APP_PROJECTS_TYPES);
  };

  return (
    <DashboardLayout menu={true} type="Settings" menuName="Company Settings">
      <AppLoader isLoading={teamMemberMetaIsLoading}>
        <Flex justifyContent="space-between" alignItems="center">
          <AppHeader
            text="Company Settings"
            variant="heading"
            customStyles={{ margin: '0px', textAlign: 'start' }}
          />
          {permissions.includes('company_setting_manage') && (
            <AppButton
              type="submit"
              text="Save"
              customStyles={{
                margin: '0px',
                width: '120px',
              }}
              onClick={handleFormSubmit}
              isLoading={companySettingUpdationIsLoading}
            />
          )}
        </Flex>

        {permissions.includes('company_setting_view') && (
          <form>
            <Card variant="tableCard">
              <Flex justifyContent="center">
                <Image
                  width="118px"
                  height="118px"
                  objectFit="contain"
                  src={
                    formValues.file
                      ? formValues.file
                      : 'https://www.divesupply.com/wp-content/uploads/2020/04/No-image.jpg'
                  }
                  alt="logo"
                  border="1px solid #A6B5D6"
                  borderRadius="50%"
                />
                {permissions.includes('company_setting_manage') && (
                  <AppFileIcon
                    fieldName="logo"
                    uploadEndpoint={COMPANY_LOGO_UPLOADING}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    icon={
                      <VscEdit style={{ color: appColors.brandWhite['100'] }} />
                    }
                    customStyles={{
                      position: 'absolute',
                      top: '80px',
                      right: '0px',
                    }}
                    id="logo"
                    color="white"
                    allowedFileTypes=".jpg,.jpeg,.png"
                  />
                )}
              </Flex>
              <Flex justifyContent="center">
                <AppText
                  text={companySettingUpdationError?.response?.data?.data?.logo}
                  variant="errorText"
                />
              </Flex>

              <Flex flexWrap="wrap" columnGap="53px">
                {formFields.map((field, i) => (
                  <Flex direction="column" mt={10} key={i} width="47%">
                    <AppLabel
                      fieldName={field.label}
                      customStyles={{
                        fontSize: fontSizes.sm,
                        fontWeight: 400,
                        color: appColors.brandBlack['600'],
                      }}
                    />
                    <AppInput
                      variant={
                        getValid(
                          teamMemberMetaData?.data?.initial,
                          formValues,
                          field.fieldName,
                        )
                          ? 'filled'
                          : 'outline'
                      }
                      {...field}
                      key={field.fieldName}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      innerLabel={false}
                      fieldError={companySettingUpdationError?.response?.data}
                      disabled={!permissions.includes('company_setting_manage')}
                    />
                  </Flex>
                ))}
                <Flex direction="column" mt={10} width="47%">
                  <AppLabel
                    fieldName={'Phone Number'}
                    customStyles={{
                      fontSize: fontSizes.sm,
                      fontWeight: 400,
                      color: appColors.brandBlack['600'],
                    }}
                  />

                  <PhoneInput
                    value={formValues?.phone_number}
                    country={'us'}
                    onChange={(phone) => {
                      setFormValues({ ...formValues, phone_number: phone });
                      setCompanySettingsAlert(true);
                    }}
                    inputStyle={{
                      width: '100%',
                      height: '40px',
                      border: '1px solid #CBD5E0',
                      background: getValid(
                        teamMemberMetaData?.data?.initial,
                        formValues,
                        'phone_number',
                      )
                        ? appColors.brandGray['selectedField']
                        : appColors.brandWhite['100'],
                    }}
                    disabled={!permissions.includes('company_setting_manage')}
                  />
                  <AppText
                    text={
                      companySettingUpdationError?.response?.data?.data?.[
                        'phone_number'
                      ]
                    }
                    variant="errorMessage"
                  />
                </Flex>

                {textarea.map((field, i) => (
                  <Flex direction="column" mt={10} key={i} width="47%">
                    <AppLabel
                      fieldName={field.label}
                      customStyles={{
                        fontSize: fontSizes.sm,
                        fontWeight: 400,
                        color: appColors.brandBlack['600'],
                      }}
                    />
                    <AppTextArea
                      variant={
                        getValid(
                          teamMemberMetaData?.data?.initial,
                          formValues,
                          field.fieldName,
                        )
                          ? 'filled'
                          : 'outline'
                      }
                      {...field}
                      key={field.fieldName}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      fieldError={companySettingUpdationError?.response?.data}
                      disabled={!permissions.includes('company_setting_manage')}
                    />
                  </Flex>
                ))}

                <Flex direction="column" mt={10} width="47%">
                  <AppLabel
                    fieldName={'Time Zone'}
                    customStyles={{
                      fontSize: fontSizes.sm,
                      fontWeight: 400,
                      color: appColors.brandBlack['600'],
                    }}
                  />
                  <TimezoneSelect
                    value={formValues?.preferred_timezone || {}}
                    onChange={(tz) => {
                      setFormValues({
                        ...formValues,
                        preferred_timezone: tz.value,
                      });
                      setCompanySettingsAlert(true);
                    }}
                    timezones={{
                      ...allTimezones,
                      'America/Lima': 'Pittsburgh',
                      'Europe/Berlin': 'Frankfurt',
                    }}
                    isDisabled={!permissions.includes('company_setting_manage')}
                    styles={{
                      control: (props) => {
                        return {
                          ...props,
                          background:
                            getValid(
                              teamMemberMetaData?.data?.initial,
                              formValues,
                              'preferred_timezone',
                            ) && appColors.brandGray['selectedField'],
                        };
                      },
                    }}
                  />
                </Flex>
                {ProjectIdFields.map((field, i) => (
                  <Flex direction="column" mt={10} key={i} width="47%">
                    <AppLabel
                      fieldName={field.label}
                      customStyles={{
                        fontSize: fontSizes.sm,
                        fontWeight: 400,
                        color: appColors.brandBlack['600'],
                      }}
                    />
                    <AppInput
                      variant={
                        getValid(
                          teamMemberMetaData?.data?.initial,
                          formValues,
                          field.fieldName,
                        )
                          ? 'filled'
                          : 'outline'
                      }
                      {...field}
                      key={field.fieldName}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      innerLabel={false}
                      fieldError={companySettingUpdationError?.response?.data}
                      disabled={!permissions.includes('company_setting_manage')}
                      zIndex={'0'}
                    />
                  </Flex>
                ))}
              </Flex>
            </Card>
          </form>
        )}
        <AppAlertModal isOpen={isPermissionAlertOpen} onClose={alertHandler} />
      </AppLoader>
    </DashboardLayout>
  );
};

export default EditCompanySetting;
