export const emailTemplatesData = [
  {
    id: 1,
    title: 'User invite template',
    content: 'This will be sent when the new user is invited to the team',
  },
  {
    id: 2,
    title: 'Survey incomplete',
    content:
      'This email will be sent if a particular survey has been left incomplete for a long time',
  },
  {
    id: 3,
    title: 'Survey reassign',
    content:
      'This email will be sent if the survey has been reassigned to a user',
  },
];
