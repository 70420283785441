import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Flex,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { PROFILE_META_DATA } from 'api/urls';
import makeGetRequest from 'api/utilities/makeGetRequest';
import { Security } from 'assets';
import AppButton from 'components/AppButton/AppButton';
import Card from 'components/AppCard/AppCard';
import AppLoader from 'components/AppLoader/AppLoader';
import AppModal from 'components/AppModal/AppModal';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import DashboardLayout from 'layout/DashboardLayout';
import moment from 'moment';
import AppStatusText from 'pages/appDashboard/components/AppStatusText/AppStatusText';
import React from 'react';
import { GoPrimitiveDot } from 'react-icons/go';
import { MdModeEditOutline } from 'react-icons/md';
import { useQuery } from 'react-query';
import appColors from 'theme/appColors';

import AppChangePasswordForm from './components/AppChangePassword/AppChangePasswordForm';
import AppDetail from './components/AppDetail/AppDetail';
import AppEditProfile from './components/AppEditProfile/AppEditProfile';
import AppLoginSessionDetail from './components/AppLoginSessionDetail/AppLoginSessionDetail';

const Profile = () => {
  const { data: profileMetaData, isLoading: profileMetaIsLoading } = useQuery(
    PROFILE_META_DATA,
    () => makeGetRequest(PROFILE_META_DATA),
  );

  const profileData = [
    {
      id: 1,
      label: 'User Name',
      value: profileMetaData?.data?.user?.name,
    },
    {
      id: 2,
      label: 'Organization',
      value: profileMetaData?.data?.organisation?.identity,
    },
    {
      id: 3,
      label: 'Email ID',
      value: profileMetaData?.data?.user?.email,
    },
    {
      id: 5,
      label: 'Role',
      value: profileMetaData?.data?.role?.identity,
    },
    {
      id: 6,
      label: 'no of assigned Projects',
      value: profileMetaData?.data?.projects?.number_of_assigned_projects,
    },
  ];

  const {
    isOpen: isChangePasswordOpen,
    onClose: onChangePasswordClose,
    onOpen: onChangePasswordOpen,
  } = useDisclosure();

  const {
    isOpen: isProfileEditOpen,
    onClose: onProfileEditClose,
    onOpen: onProfileEditOpen,
  } = useDisclosure();

  // function timeSince(date) {
  //   var seconds = Math.floor((new Date() - date) / 1000);

  //   var interval = seconds / 31536000;

  //   if (interval > 1) {
  //     return Math.floor(interval) + ' years';
  //   }
  //   interval = seconds / 2592000;
  //   if (interval > 1) {
  //     return Math.floor(interval) + ' months';
  //   }
  //   interval = seconds / 86400;
  //   if (interval > 1) {
  //     return Math.floor(interval) + ' days';
  //   }
  //   interval = seconds / 3600;
  //   if (interval > 1) {
  //     return Math.floor(interval) + ' hours';
  //   }
  //   interval = seconds / 60;
  //   if (interval > 1) {
  //     return Math.floor(interval) + ' minutes';
  //   }
  //   return Math.floor(seconds) + ' seconds';
  // }

  function time(date) {
    return moment(new Date(date)).fromNow();
  }
  const changePasswordHandler = () => {
    onChangePasswordOpen();
  };

  return (
    <DashboardLayout>
      <AppLoader isLoading={profileMetaIsLoading}>
        <Flex justifyContent="space-between" alignItems="center">
          <AppHeader
            text="Profile Settings"
            variant="modalText"
            customStyles={{ margin: '0px !important' }}
          />
        </Flex>
        <Card variant="defaultCard" customStyles={{ marginBottom: '50px' }}>
          <Flex justifyContent="space-between">
            <Flex width="70%" alignItems="center">
              <Avatar
                name={profileMetaData?.data?.user?.name}
                width="100px"
                height="100px"
                objectFit="cover"
                borderRadius="50px"
                mr={8}
              />

              <Flex flexWrap="wrap" gap="20px">
                <div style={{ width: '200px' }}>
                  <AppText text={profileData[0]?.label} variant="labelText" />
                  <Flex>
                    <AppText text={profileData[0]?.value} variant="valueText" />
                    <MdModeEditOutline
                      style={{
                        width: '16px',
                        height: '18px',
                        marginLeft: '8px',
                      }}
                      onClick={() => {
                        onProfileEditOpen();
                      }}
                    />
                  </Flex>
                </div>
                {profileData
                  .filter((item) => item.id !== 1)
                  .map((data) => (
                    <AppDetail
                      key={data.id}
                      label={data.label}
                      value={data.value}
                      customStyles={{ width: '200px' }}
                    />
                  ))}
              </Flex>
            </Flex>
            <Flex width="20%" flexDirection="column">
              <Flex justifyContent="flex-end" marginBottom="auto">
                <AppStatusText
                  customStyles={{ padding: '4px 10px' }}
                  text="Active"
                  icon={
                    <GoPrimitiveDot
                      style={{
                        color: appColors.brandTeal['active'],
                        marginRight: '6px',
                      }}
                    />
                  }
                />
              </Flex>

              <AppButton
                text="Change Password"
                variant="cancelBtn"
                onClick={changePasswordHandler}
              >
                <Image src={Security} mr={4} />
              </AppButton>
            </Flex>
          </Flex>
        </Card>
        <AppHeader
          text="Login Sessions"
          variant="modalText"
          customStyles={{ textAlign: 'start', margin: '24px 0px' }}
        />
        <Card variant="defaultCard">
          {profileMetaData?.data?.sessions?.map((data, index) => {
            if (index === 0) {
              return (
                <AppLoginSessionDetail
                  key={index}
                  label=""
                  value={data?.agent}
                  time={` - ${time(data.created)}`}
                  customStyles={{ marginBottom: '15px' }}
                />
              );
            }
          })}
          <Accordion allowMultiple>
            <AccordionItem>
              <AccordionButton>
                <AppText
                  text="List All sessions"
                  variant="labelText"
                  customStyles={{ fontSize: '16px', margin: '0px' }}
                />
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                {profileMetaData?.data?.sessions?.map((data, index) => {
                  if (index <= 9) {
                    return (
                      <AppLoginSessionDetail
                        key={index}
                        label=""
                        value={data?.agent}
                        time={` - ${time(data.created)}`}
                        customStyles={{ marginBottom: '15px' }}
                      />
                    );
                  }
                })}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Card>
      </AppLoader>
      <AppModal
        isOpen={isChangePasswordOpen}
        onClose={onChangePasswordClose}
        keyName="change passowrd"
        text="Change Password"
        closeCheck={false}
      >
        <AppChangePasswordForm onClose={onChangePasswordClose} />
      </AppModal>

      <AppModal
        isOpen={isProfileEditOpen}
        onClose={onProfileEditClose}
        keyName="profile edit"
        text="Edit profile"
        closeCheck={false}
      >
        <AppEditProfile
          onClose={onProfileEditClose}
          metaData={profileMetaData?.data?.user}
        />
      </AppModal>
    </DashboardLayout>
  );
};

export default Profile;
