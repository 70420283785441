const AUTH_LOGIN = '/api/access/auth/login/';
const AUTH_SIGNUP = '/api/access/organisation/sign-up/init/';
const GET_ORGANSATION_SCHEMA = '/api/user/invite/organisation/sign-up/';
const AUTH_STATUS = '/api/access/auth/status/verify/';

const FORGOT_PASSWORD_INIT =
  '/api/recovery/user/forgot-password/link-verification/init/';
const FORGOT_PASSWORD_RESEND = (trackerId) =>
  `/api/recovery/user/forgot-password/link-verification/${trackerId}/resend/`;

const USER_SET_PASSWORD = (trackerId) =>
  `/api/recovery/user/forgot-password/link-verification/${trackerId}/set-password/`;

const ADMIN_TEAM_MEMBER_SETPASSWORD = (trackerId) =>
  `/api/access/organisation/admin-invite/${trackerId}/set-password/`;

const INVITE_TEAM_MEMBERS = `/api/user/invite/organisation/sign-up/`;
const SIGNUP_TEAM_MEMBERS = (userDatabaseId, userId) =>
  `/api/user/invite/${userId}/organisation/${userDatabaseId}/sign-up/accept/`;

// Magic Link
const AUTH_SIGNUP_MAGIC_LINK_RESEND = (tracker) =>
  `/api/access/organisation/sign-up/${tracker}/resend/`;
const AUTH_SIGNUP_MAGIC_LINK_VERIFY = (verificationId) =>
  `/api/access/organisation/sign-up/${verificationId}/verify/`;

export {
  ADMIN_TEAM_MEMBER_SETPASSWORD,
  AUTH_LOGIN,
  AUTH_SIGNUP,
  AUTH_SIGNUP_MAGIC_LINK_RESEND,
  AUTH_SIGNUP_MAGIC_LINK_VERIFY,
  AUTH_STATUS,
  FORGOT_PASSWORD_INIT,
  FORGOT_PASSWORD_RESEND,
  GET_ORGANSATION_SCHEMA,
  INVITE_TEAM_MEMBERS,
  SIGNUP_TEAM_MEMBERS,
  USER_SET_PASSWORD,
};
