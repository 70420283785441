const BoxStyles = {
  baseStyle: {},
  sizes: {},

  variants: {
    inputBox: {
      width: '462px',
    },
    formGroupBox: {
      padding: '30px 0px',
      borderBottom: '1px solid #CDCDCD',
    },
  },
};

export default BoxStyles;
