import { Box, Flex, useConst } from '@chakra-ui/react';
import { SiteScribeLogo } from 'assets';
import AppLoader from 'components/AppLoader/AppLoader';
import { AppLandingLogo, Card } from 'components/AuthCard/AuthCard.styles';
import AppText from 'components/typography/AppText';
import React from 'react';

const AuthCard = ({ children, isLoading = false }) => {
  const boxWidth = 400;
  const currentYear = useConst(() => new Date().getFullYear());

  return (
    <Flex justifyContent="center" width="100%">
      <Card>
        <AppLoader isLoading={isLoading}>
          {!isLoading && (
            <>
              <AppLandingLogo src={SiteScribeLogo} alt="" />
              <Box maxW={boxWidth} minW={boxWidth} mt={10} mb={5}>
                {children}
              </Box>
              <AppText
                size="xs"
                text={`@${currentYear} All Rights Reserved. IllumineIndustries`}
              />
            </>
          )}
        </AppLoader>
      </Card>
    </Flex>
  );
};

export default AuthCard;
