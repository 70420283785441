import { Flex } from '@chakra-ui/react';
import { FORGOT_PASSWORD_INIT, FORGOT_PASSWORD_RESEND } from 'api/urls';
import makePostRequest from 'api/utilities/makePostRequest';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AuthCard from 'components/AuthCard/AuthCard';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import AuthLayout from 'layout/AuthLayout';
import { APP_LOGIN_SCREEN } from 'navigation/routes/auth';
import RedirectionCTA from 'pages/Auth/components/RedirectionCTA/RedirectionCTA';
import TermsAndConditionsText from 'pages/Auth/components/TermsAndConditionsText/TermsAndConditionsText';
import { useState } from 'react';
import { MdEmail } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useInterval } from 'usehooks-ts';

const InitialFormErrors = {
  data: {
    non_field_errors: [''],
  },
};
const AppForgotPasswordScreen = () => {
  const [formValues, setFormValues] = useState({});
  const [trackerId, setTrackerId] = useState(0);
  const [formError, setFormError] = useState(InitialFormErrors);
  const [forgotPasswordInitiated, setForgotPasswordInitiated] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const [interval, setInterval] = useState(null);

  useInterval(() => {
    setResendTimer((timer) => {
      if (timer === 0) {
        setInterval(null);
        return timer;
      }
      return timer - 1;
    });
  }, interval);

  const { mutate: forgotPasswordInitMutate, isLoading: initIsLoading } =
    useMutation(
      FORGOT_PASSWORD_INIT,
      (formVal) => makePostRequest(FORGOT_PASSWORD_INIT, formVal),
      {
        onSuccess: (data) => {
          const respTrackerID = data.data.tracker_id;
          setTrackerId(respTrackerID);
          setForgotPasswordInitiated(true);
        },
        onError: (error) => setFormError(error.response.data),
      },
    );

  const { mutate: forgotPasswordResendMutate, isLoading: resendIsLoading } =
    useMutation(FORGOT_PASSWORD_RESEND(trackerId), () =>
      makePostRequest(FORGOT_PASSWORD_RESEND(trackerId)),
    );

  const handleForgotPassword = (event) => {
    event.preventDefault();
    setFormError(InitialFormErrors);
    setInterval(1000);
    if (!forgotPasswordInitiated) {
      forgotPasswordInitMutate(formValues);
    } else {
      forgotPasswordResendMutate();
    }
  };

  return (
    <AuthLayout>
      <AuthCard>
        <AppHeader text="Forgot Password" />

        <form onSubmit={handleForgotPassword}>
          <Flex flexFlow="column" minH={270} justifyContent={'space-between'}>
            <AppText
              text="Enter your registered mail address to get a verification link to reset password"
              size="sm"
              customStyles={{
                textAlign: 'center',
              }}
            />
            <AppInput
              fieldPlaceholder={'Email Address'}
              fieldType={'email'}
              fieldName={'email'}
              icon={MdEmail}
              formValues={formValues}
              setFormValues={setFormValues}
              fieldError={formError}
              disabled={forgotPasswordInitiated}
            />

            <AppButton
              text={`${forgotPasswordInitiated ? 'Resend' : 'Send'} Link ${
                forgotPasswordInitiated && resendTimer > 0
                  ? `(${resendTimer})`
                  : ''
              } `}
              disabled={resendTimer != 0 && forgotPasswordInitiated}
              isLoading={initIsLoading || resendIsLoading}
              type="submit"
            />
          </Flex>
        </form>

        <TermsAndConditionsText
          customStyles={{ display: 'flex', gap: '5px' }}
        />

        <RedirectionCTA
          titleText={'Return to'}
          ctaText={'Login page'}
          ctaLink={APP_LOGIN_SCREEN}
        />
      </AuthCard>
    </AuthLayout>
  );
};

export default AppForgotPasswordScreen;
