function createRequiredTimestamp(value) {
  let timestamp = value?.split('T');
  let date = timestamp[0];
  let time = timestamp[1].split(':');
  let result = date + ' ' + time[0] + ':' + time[1];
  return result;
}
function timeStamp(arr) {
  const results = (arr || [])?.map((item) => {
    if (item.modified) {
      return {
        ...item,
        modified: createRequiredTimestamp(item?.modified),
        created: createRequiredTimestamp(item?.created),
      };
    } else {
      return {
        ...item,
        created: createRequiredTimestamp(item?.created),
      };
    }
  });
  return results;
}

export { createRequiredTimestamp, timeStamp };
