import makeGetRequest from 'api/utilities/makeGetRequest';
import { capitalize } from 'constants';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import getTableAccessorKey from 'utils/getTableAccessorKey';

const useGetTableFunctions = ({
  tableMetaURL,
  tableDataURL,
  additionalColumns = [],
  permissionAlert,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    mutate: getTableDataMutation,
    data: tableData,
    isLoading: tableDataIsLoading,
  } = useMutation(
    tableDataURL(searchParams),
    (urlParams) => makeGetRequest(tableDataURL(urlParams)),
    {
      onError: (data) => {
        if (data.response.status === 403) {
          permissionAlert();
        }
      },
    },
  );

  const {
    mutate: getTableMetaMutation,
    data: tableMeta,
    isLoading: tableMetaIsLoading,
  } = useMutation(tableMetaURL, () => makeGetRequest(tableMetaURL), {
    onSuccess: () => {
      // getTableDataMutation();
    },
    onError: (data) => {
      if (data.response.status === 403) {
        permissionAlert();
      }
    },
  });

  const [columnsData, setColumnsData] = useState([]);

  const handleApplyFilter = (filterData) => {
    const filterObj = {};

    Object.keys(filterData).map((key) => {
      let urlString = '';

      if (Array.isArray(filterData[key]) && filterData[key]?.length > 0) {
        filterData[key].map(
          (val) => (urlString = urlString + val?.value + ','),
        );

        filterObj[key] = urlString.slice(0, -1);
      } else if (filterData[key]?.length > 0) {
        filterObj[key] = filterData[key];
      }

      setSearchParams(new URLSearchParams(filterObj).toString());
    });
  };

  useEffect(() => {
    if (tableMeta && !tableMetaIsLoading) {
      const d = Object.entries(tableMeta.data.columns).map((key) => {
        if (key[0] == 'has_accepted') {
          return {
            Header: key[1],
            accessor: key[0] ? getTableAccessorKey(key[0]) : '',
            Cell: ({ cell: { value } }) => <p>{value ? 'Yes' : 'No'}</p>,
            id: 'has_accepted',
          };
        } else if (key[0] == 'previous_workflow_state') {
          return {
            Header: key[1],
            accessor: key[0] ? getTableAccessorKey(key[0]) : '',

            Cell: ({ cell: { value } }) =>
              capitalize(value?.replace(/_/g, ' ')),

            id: 'previous_workflow_state',
          };
        } else if (key[0] == 'current_workflow_state') {
          return {
            Header: key[1],
            accessor: key[0] ? getTableAccessorKey(key[0]) : '',

            Cell: ({ cell: { value } }) =>
              capitalize(value?.replace(/_/g, ' ')),

            id: 'current_workflow_state',
          };
        } else {
          return {
            Header: key[1],
            accessor: key[0] ? getTableAccessorKey(key[0]) : '',
          };
        }
      });

      setColumnsData([...d, ...additionalColumns]);
    }
  }, [tableMeta, tableMetaIsLoading]);

  useEffect(() => {
    const handleApplyFilter = () => {
      getTableDataMutation(searchParams);
    };

    handleApplyFilter();
  }, [searchParams]);

  const isTableLoading = tableMetaIsLoading || tableDataIsLoading;
  return {
    columnsData,
    handleApplyFilter,
    isTableLoading,
    tableData,
    getTableDataMutation,
    getTableMetaMutation,
    setSearchParams,
  };
};

export default useGetTableFunctions;
