import { useToast } from '@chakra-ui/react';
import makeDeleteRequest from 'api/utilities/makeDeleteRequest';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import makePutRequest from 'api/utilities/makePutRequest';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

const useHandleAddAndEdit = ({
  baseMetaUrl,
  prefillMetaUrl,
  createUrl,
  editAndDeleteUrl,
  isEdit,
  formValues,
  setFormValues,
  onClose,
  toastText,
  onCreateEditSuccess = () => {},
}) => {
  /**
   * This hook is used to handle `create`, `edit` and `delete` for all listing
   * The aim of the hook is to abstract all functionality and allow loosely typed UI
   * */
  // values
  const [metaData, setMetaData] = useState();
  const [initialValues, setInitialValues] = useState();

  const toast = useToast();

  // Creation - Mutations
  const {
    mutate: baseMetaDataMutation,
    data: baseMetaData,
    isLoading: baseMetaDataIsLoading,
  } = useMutation(baseMetaUrl, () => makeGetRequest(baseMetaUrl));

  const {
    mutate: createMutation,
    data: createMutationData,
    isLoading: createMutationIsLoading,
    error: createMutationError,
  } = useMutation(
    createUrl,
    (formBody) => makePostRequest(createUrl, formBody),
    {
      onSuccess: () => onCreateEditSuccess(),
    },
  );

  // Edit - Mutations
  const {
    mutate: baseEditMetaDataMutation,
    data: baseEditMetaData,
    isLoading: baseEditMetaDataIsLoading,
  } = useMutation(prefillMetaUrl, () => makeGetRequest(prefillMetaUrl));

  const {
    mutate: editMutation,
    data: editMutationData,
    isLoading: editMutationIsLoading,
    error: editMutationErrror,
  } = useMutation(
    editAndDeleteUrl,
    (formBody) => makePutRequest(editAndDeleteUrl, formBody),
    {
      onSuccess: () => onCreateEditSuccess(),
    },
  );

  // Delete Mutation
  const {
    mutate: deleteMutation,
    isLoading: deleteMutationIsLoading,
    isSuccess: deleteMutationIsSuccess,
  } = useMutation(editAndDeleteUrl, () => makeDeleteRequest(editAndDeleteUrl), {
    onSuccess: () => onCreateEditSuccess(),
  });

  useEffect(() => {
    if (isEdit) {
      baseEditMetaDataMutation();
    } else {
      baseMetaDataMutation();
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit) {
      setMetaData(baseEditMetaData?.data?.meta);
      setInitialValues(baseEditMetaData?.data?.initial);
    } else {
      setMetaData(baseMetaData?.data?.meta);
    }
  }, [isEdit, baseMetaDataIsLoading, baseEditMetaDataIsLoading]);

  useEffect(() => {
    if (!createMutationIsLoading && createMutationData) {
      const toastMsg = `Created ${toastText} successfully`;
      toast({
        title: toastMsg,
        status: 'success',
        duration: 3000,
      });
      onClose();
    }
  }, [createMutationIsLoading, createMutationData]);

  useEffect(() => {
    if (!editMutationIsLoading && editMutationData) {
      const toastMsg = `Edited ${toastText} successfully`;
      toast({
        title: toastMsg,
        status: 'success',
        duration: 3000,
      });
      onClose();
    }
  }, [editMutationIsLoading, editMutationData]);

  useEffect(() => {
    if (!deleteMutationIsLoading && deleteMutationIsSuccess) {
      const toastMsg = `Deleted ${toastText} successfully`;
      toast({
        title: toastMsg,
        status: 'error',
        duration: 3000,
      });
      onClose();
    }
  }, [deleteMutationIsLoading, deleteMutationIsSuccess]);

  useEffect(() => {
    if (!baseEditMetaDataIsLoading && baseEditMetaData) {
      setFormValues({
        ...formValues,
        ...baseEditMetaData?.data?.initial,
      });
    }
  }, [baseEditMetaData, baseEditMetaDataIsLoading]);

  const isLoading = baseMetaDataIsLoading || baseEditMetaDataIsLoading;
  const isSubmitLoading =
    createMutationIsLoading || editMutationIsLoading || deleteMutationIsLoading;
  const formError =
    editMutationErrror?.response?.data || createMutationError?.response?.data;

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (isEdit) {
      editMutation(formValues);
    } else {
      createMutation(formValues);
    }
  };
  return {
    metaData,
    initialValues,
    isLoading,
    isSubmitLoading,
    handleFormSubmit,
    deleteMutation,
    formError,
  };
};

export default useHandleAddAndEdit;
