/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import { CloseButton, Flex, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { GET_PROJECT_LISTING_META } from 'api/urls';
import makeGetRequest from 'api/utilities/makeGetRequest';
import useStore from 'AppZustand/store';
import AppButton from 'components/AppButton/AppButton';
import Card from 'components/AppCard/AppCard';
import AppText from 'components/typography/AppText';
import React from 'react';
import { useQuery } from 'react-query';

const Sort = ({
  setState,
  option,
  setOption,
  sortBy,
  setSortBy,
  columnsIdentity,
  order,
  setOrder,
}) => {
  const { fetchProjectsCheckedValue, projectsCheckedValue } = useStore(
    (state) => state,
  );
  const {
    data: projectListMetaData,
    // isLoading: tableDataIsLoading,
  } = useQuery(
    GET_PROJECT_LISTING_META,
    () => makeGetRequest(GET_PROJECT_LISTING_META),
    {
      onSuccess: (res) => {
        fetchProjectsCheckedValue(
          Object.keys(res.data.columns).map((item) => item),
        );
      },
    },
  );
  const addToSortHandler = (e) => {
    setSortBy(e);
  };

  const saveHandler = () => {
    setState({ sort: !option.sort, filter: false, setting: false, id: 0 });
  };

  return (
    <Flex flexDirection="column" justifyContent="center">
      <Flex justifyContent="space-between" alignItems="center">
        <AppText text="Sort Based on:" variant="filterText" />
        <Flex gap="10px">
          <AppButton
            text="Asc"
            size="sm"
            customStyles={{
              color: 'white',
              opacity: order === 'asc' ? '1' : '0.6',
            }}
            onClick={() => setOrder('asc')}
          />
          <AppButton
            text="Desc"
            size="sm"
            customStyles={{
              color: 'white',
              opacity: order === 'desc' ? '1' : '0.6',
            }}
            onClick={() => setOrder('desc')}
          />
        </Flex>
        <CloseButton
          marginLeft="10px"
          onClick={() => {
            setState({
              sort: !option.sort,
              filter: false,
              setting: false,
              id: 0,
            });
          }}
        />
      </Flex>
      <Card variant="sortInnerCard">
        <RadioGroup onChange={(e) => addToSortHandler(e)} value={sortBy}>
          <Stack direction="column" spacing={4}>
            {projectsCheckedValue?.map((columns, i) => {
              return (
                <Radio
                  key={i}
                  value={columns}
                  colorScheme="teal"
                  borderWidth="4px"
                  fontSize="14px"
                >
                  {columnsIdentity?.[columns]}
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>
      </Card>
      <Flex justifyContent="flex-end">
        <AppButton
          text="Sort"
          customStyles={{ width: '130px', marginTop: '20px' }}
          onClick={saveHandler}
        />
      </Flex>
    </Flex>
  );
};

export default Sort;
