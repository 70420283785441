import { Checkbox, CheckboxGroup, Flex } from '@chakra-ui/react';
import { GET_PROJECT_LISTING_META, PROJECT_LISTING_SETTING } from 'api/urls';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePostRequest from 'api/utilities/makePostRequest';
import useStore from 'AppZustand/store';
import AppBox from 'components/AppBox/AppBox';
import AppButton from 'components/AppButton/AppButton';
import Card from 'components/AppCard/AppCard';
import AppCheckBoxGroup from 'components/AppCheckBox/AppCheckBox';
import TableSettingHeader from 'components/AppTable/components/TableSettingHeader';
import AppText from 'components/typography/AppText';
import React, { useEffect, useState } from 'react';
import { AiFillCloseSquare } from 'react-icons/ai';
import { GrTransaction } from 'react-icons/gr';
import { useMutation, useQuery } from 'react-query';
import appColors from 'theme/appColors';

const Settings = ({
  setState,
  getTableMetaMutation,
  getTableDataMutation,
  option,
  columnsIdentity,
  optionId,
  setOptionId,
}) => {
  const { fetchProjectsCheckedValue, projectsCheckedValue } = useStore(
    (state) => state,
  );

  const [checkboxColumns, setCheckboxColumns] = useState([]);

  const [formValues, setFormValues] = useState({
    search: '',
  });

  // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
  const [fromIndex, setFromIndex] = useState(null);

  // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
  const [toIndex, setToIndex] = useState(null);
  const {
    data: projectListMetaData,
    // isLoading: tableDataIsLoading,
  } = useQuery(
    GET_PROJECT_LISTING_META,
    () => makeGetRequest(GET_PROJECT_LISTING_META),
    {
      onSuccess: (res) => {
        let col = [];
        Object.entries(res.data.all_columns).map((data) => {
          if (data[0] !== 'id') {
            return col.push({ label: data[1], value: data[0] });
          }
        });
        fetchProjectsCheckedValue(
          Object.keys(res.data.columns).map((item) => item),
        );
        setCheckboxColumns(col);
      },
    },
  );

  const { mutate: columnsMutate, isLoading: isColumnsLoading } = useMutation(
    PROJECT_LISTING_SETTING,
    (formBody) => makePostRequest(PROJECT_LISTING_SETTING, formBody),
    {
      onSuccess: () => {
        getTableDataMutation();
        getTableMetaMutation();
      },
    },
  );

  useEffect(() => {
    if (formValues.search) {
      const filter = Object.entries(
        projectListMetaData?.data?.all_columns,
      ).filter((data) => {
        return data[1].includes(formValues.search.toLowerCase());
      });
      setCheckboxColumns(filter);
    } else {
      let col = [];
      Object.entries(projectListMetaData?.data?.all_columns || {}).map(
        (data) => {
          if (data[0] !== 'id') {
            return col.push({ label: data[1], value: data[0] });
          }
        },
      );

      setCheckboxColumns(col);
    }
  }, [formValues]);

  const addToSortHandler = (e) => {
    const { value } = e.target;
    const index = projectsCheckedValue.indexOf(value);
    if (index > -1) {
      fetchProjectsCheckedValue([
        ...projectsCheckedValue.slice(0, index),
        ...projectsCheckedValue.slice(index + 1),
      ]);
    } else {
      fetchProjectsCheckedValue([...projectsCheckedValue, value]);
    }
  };

  const saveHandler = () => {
    columnsMutate({ columns: projectsCheckedValue });
    setState({ ...option, setting: false });
    setOptionId('');
  };

  // const fieldDragStart = (item, args, index) => {
  //   setFromIndex(index);
  //   args.dataTransfer.setData('column', item.id);
  // };

  const drop = () => {
    // let columnName = args.dataTransfer.getData('column');
    let element = projectsCheckedValue.splice(fromIndex, 1)[0];
    projectsCheckedValue.splice(toIndex, 0, element);
    fetchProjectsCheckedValue(projectsCheckedValue);
  };

  const dragOver = (args) => {
    args.stopPropagation();
    args.preventDefault();
  };

  const removeItem = (value) => {
    fetchProjectsCheckedValue(
      projectsCheckedValue.filter((item) => item !== value),
    );
  };

  return (
    <Flex flexDirection="column">
      <TableSettingHeader
        setState={setState}
        option={option}
        optionId={optionId}
        setOptionId={setOptionId}
        formValues={formValues}
        setFormValues={setFormValues}
      />
      <Flex justifyContent="space-between" alignItems="center">
        <Card
          variant="settingInnerCard"
          customStyles={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
          onDrop={(e) => drop(e)}
          onDragOver={(e) => dragOver(e)}
        >
          {projectsCheckedValue?.map((data) => {
            return (
              <AppBox
                customStyles={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                key={data}
              >
                <AppButton
                  key={data}
                  text={columnsIdentity?.[data]}
                  check="dash"
                  variant="tableMenuBtn"
                  customStyles={{ marginBottom: '0px' }}
                  // draggable
                  // onDragStart={(e) => fieldDragStart(data, e, inx)}
                  // onDragOver={() => {
                  //   setToIndex(inx);
                  // }}
                ></AppButton>
                <AiFillCloseSquare
                  size={34}
                  fill={appColors['brandTeal'][500]}
                  onClick={() => removeItem(data)}
                />
              </AppBox>
            );
          })}
        </Card>
        <GrTransaction color="#2E3445" />
        <Card variant="settingInnerCard">
          <AppCheckBoxGroup>
            {checkboxColumns?.map((columns, i) => {
              const checkedValue = projectsCheckedValue?.filter(
                (data) => data === columns.value,
              );

              return (
                <CheckboxGroup
                  key={i}
                  name={columns.label}
                  value={checkedValue}
                  colorScheme="teal"
                >
                  <Checkbox
                    value={columns.value}
                    fontSize="14px"
                    onChange={addToSortHandler}
                  >
                    <AppText text={columns.label} variant="checkBoxText" />
                  </Checkbox>
                </CheckboxGroup>
              );
            })}
          </AppCheckBoxGroup>
        </Card>
      </Flex>
      <Flex justifyContent="flex-end">
        <AppButton
          text="Save"
          customStyles={{ width: '130px', marginTop: '20px' }}
          onClick={saveHandler}
          isLoading={isColumnsLoading}
        />
      </Flex>
    </Flex>
  );
};

export default Settings;
