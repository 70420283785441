import { Flex } from '@chakra-ui/react';
import AppLink from 'components/AppLink/AppLink';
import AppText from 'components/typography/AppText';
import React from 'react';
import appColors from 'theme/appColors';

const RedirectionCTA = ({ titleText, ctaText, ctaLink }) => {
  return (
    <Flex alignItems="center" mt={5} justifyContent="center">
      <AppText
        text={titleText}
        size="xs"
        customStyles={{
          color: appColors.brandGray['900'],
        }}
      />
      <AppLink
        text={ctaText}
        size="xs"
        customStyles={{
          marginLeft: 1,
          color: 'brandTeal.800',
          fontWeight: '700',
        }}
        to={ctaLink}
      />
    </Flex>
  );
};

export default RedirectionCTA;
