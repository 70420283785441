import FormConstant from 'pages/appSettings/formBuilder/components/Constant';
import React from 'react';

import AddressField from './AddressField';
import AnnotationField from './Annotation';
import AttachmentField from './AttachmentField';
import DateField from './DateField';
import DateTimeField from './DateTimeField';
import EmailField from './EmailField';
import ImageField from './ImageField';
import MultiSelectionField from './MultiSelectionField';
import NumberField from './NumberField';
import PhoneNumberField from './PhoneNumberField';
import PitchField from './PitchField';
import ShortTextField from './ShortTextField';
import SignatureField from './SignatureField';
import SingleSelectionField from './SingleSelectionField';
import TextAreaField from './TextAreaField';
import URLField from './URLField';

const AllQuestions = ({
  item,
  isEditable,
  values,
  setValues,
  error,
  idx,
  slug,
  type,
  customStyles,
}) => {
  return (
    <>
      {(() => {
        switch (item?.question?.type) {
          case FormConstant.address:
            return (
              <AddressField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                index={idx}
                item={item}
                type={type}
                customStyles={customStyles}
              />
            );
          case FormConstant.input:
            return (
              <ShortTextField
                index={idx}
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                item={item}
                type={type}
                fieldName="answer_short_text_input"
              />
            );
          case FormConstant.phoneNumber:
            return (
              <PhoneNumberField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                index={idx}
                item={item}
                type={type}
                fieldName="answer_phone_number_input"
              />
            );
          case FormConstant.textArea:
            return (
              <TextAreaField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                index={idx}
                item={item}
                type={type}
                fieldName="answer_large_text_input"
              />
            );
          case FormConstant.email:
            return (
              <EmailField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                index={idx}
                item={item}
                type={type}
                fieldName="answer_email_address_input"
              />
            );
          case FormConstant.numeric:
            return (
              <NumberField
                item={item}
                fieldName="answer_numeric_input"
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                index={idx}
                type={type}
              />
            );
          case FormConstant.pitch:
            return (
              <PitchField
                item={item}
                fieldName="answer_pitch_input"
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                index={idx}
                type={type}
              />
            );
          case FormConstant.date:
            return (
              <DateField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                index={idx}
                item={item}
                type={type}
                fieldName="answer_date_input"
              />
            );
          case FormConstant.datetime:
            return (
              <DateTimeField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                index={idx}
                item={item}
                type={type}
                fieldName="answer_datetime_input"
              />
            );
          case FormConstant.signature:
            return (
              <SignatureField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                index={idx}
                item={item}
                type={type}
                fieldName="answer_signature_input"
              />
            );
          case FormConstant.url:
            return (
              <URLField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                index={idx}
                item={item}
                type={type}
                fieldName="answer_url_input"
              />
            );
          case FormConstant.images:
            return (
              <ImageField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                slug={slug}
                error={error}
                index={idx}
                item={item}
                fieldName="answer_image_attachment_input_base64"
                type={type}
              />
            );
          case FormConstant.annotation:
            return (
              <AnnotationField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                slug={slug}
                error={error}
                index={idx}
                item={item}
                fieldName="answer_annotation_input"
                type={type}
              />
            );
          case FormConstant.attachment:
            return (
              <AttachmentField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                slug={slug}
                error={error}
                index={idx}
                item={item}
                type={type}
                fieldName="answer_file_attachment_input"
              />
            );
          case FormConstant.singleSelection:
            return (
              <SingleSelectionField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                index={idx}
                item={item}
                fieldName="answer_single_selection_input"
                type={type}
                customStyles={customStyles}
              />
            );
          case FormConstant.multiSelection:
            return (
              <MultiSelectionField
                isEditable={isEditable}
                state={values}
                setState={setValues}
                error={error}
                index={idx}
                item={item}
                type={type}
                fieldName="answer_multi_selection_input"
              />
            );
        }
      })()}
    </>
  );
};

export default AllQuestions;
