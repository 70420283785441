import { Flex, useDisclosure } from '@chakra-ui/react';
import {
  DELETE_WIDGET_DATA,
  GET_DASHBOARD_DATA,
  GET_WIDGET_META_DATA,
  GET_WIDGET_OVERALL_CONFIG,
} from 'api/urls/settings/Reports';
import makeDeleteRequest from 'api/utilities/makeDeleteRequest';
import makeGetRequest from 'api/utilities/makeGetRequest';
import useStore from 'AppZustand/store';
import AppAlertModal from 'components/AppAlertModal/AppAlertModal';
import AppButton from 'components/AppButton/AppButton';
import AppLoader from 'components/AppLoader/AppLoader';
import AppModal from 'components/AppModal/AppModal';
import AppHeader from 'components/typography/AppHeader';
import DashboardLayout from 'layout/DashboardLayout';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AddModal from './components/AddModal';
import AddWidget from './components/AddWidget';
import Charts from './components/Charts';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Reports = () => {
  const { setWidgetOverallConfigData, setWidgetMetaData, chartTypes } =
    useStore((state) => state);
  const {
    isOpen: addWidgetIsOpen,
    onOpen: addWidgetOnOpen,
    onClose: addWidgetOnClose,
  } = useDisclosure();

  const {
    isOpen: addModalIsOpen,
    onOpen: addModalOnOpen,
    onClose: addModalOnClose,
  } = useDisclosure();

  const {
    isOpen: isPermissionAlertOpen,
    onClose: onPermissionAlertClose,
    onOpen: onPermissionAlertOpen,
  } = useDisclosure();

  const { permissions } = useStore((state) => state);

  const queryClient = useQueryClient();

  const { data: dashboardData, isLoading: isDashboardDataLoading } = useQuery(
    GET_DASHBOARD_DATA(),
    () => makeGetRequest(GET_DASHBOARD_DATA()),
    {
      onError: (data) => {
        if (data.response.status === 403) {
          onPermissionAlertOpen();
        }
      },
    },
  );

  // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
  const { data: widgetOverallConfig } = useQuery(
    GET_WIDGET_OVERALL_CONFIG(),
    () => makeGetRequest(GET_WIDGET_OVERALL_CONFIG()),
    {
      onSuccess: (data) => {
        setWidgetOverallConfigData(data.data.types_config);
      },
    },
  );

  // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
  const { data: widgetMetaData } = useQuery(
    GET_WIDGET_META_DATA(),
    () => makeGetRequest(GET_WIDGET_META_DATA()),
    {
      onSuccess: (data) => {
        setWidgetMetaData(data.data.meta);
      },
    },
  );

  const { mutate: deleteWidgetData, isLoading: deleteWidgetDataLoading } =
    useMutation(
      DELETE_WIDGET_DATA(),
      (widgetData) => makeDeleteRequest(DELETE_WIDGET_DATA(widgetData.id)),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(GET_DASHBOARD_DATA());
        },
      },
    );

  function removeWidget(id) {
    deleteWidgetData(id);
  }

  return (
    <DashboardLayout menu={true} type="Settings" menuName="Reports">
      <AppLoader isLoading={isDashboardDataLoading}>
        <Flex justifyContent="space-between" alignItems="center">
          <AppHeader
            text="Reports"
            variant="modalText"
            customStyles={{ textAlign: 'start', margin: '0px !important' }}
          />
          {permissions.includes('reports_dashboard_manage') && (
            <AppButton
              text="Add Widget"
              customStyles={{ width: 'auto' }}
              onClick={() => addWidgetOnOpen()}
            />
          )}
        </Flex>

        {permissions.includes('reports_dashboard_view') && (
          <Flex flexWrap="wrap" gap={6}>
            {dashboardData?.data?.report_widgets_data?.map((item) => {
              // if (item.report_data.length === 0) return null;
              return (
                <Charts
                  key={item.id}
                  chartData={item}
                  removeWidget={removeWidget}
                  isPermission={permissions.includes(
                    'reports_dashboard_manage',
                  )}
                  isLoading={deleteWidgetDataLoading}
                />
              );
            })}
          </Flex>
        )}

        <AppModal
          isOpen={addModalIsOpen}
          onClose={addModalOnClose}
          keyName="add"
          text={`Add ${capitalizeFirstLetter(chartTypes)} ${
            chartTypes === 'pie' ? 'Chart' : 'Graph'
          } `}
          closeCheck={true}
          variant="addWidgetText"
        >
          <AddModal onClose={addModalOnClose} />
        </AppModal>

        <AppModal
          isOpen={addWidgetIsOpen}
          onClose={addWidgetOnClose}
          keyName="add"
          text="Add Widget"
          closeCheck={true}
          variant="addWidgetText"
          size="auto"
        >
          <AddWidget
            onClose={addWidgetOnClose}
            openNextModal={addModalOnOpen}
          />
        </AppModal>
      </AppLoader>
      <AppAlertModal
        isOpen={isPermissionAlertOpen}
        onClose={onPermissionAlertClose}
      />
    </DashboardLayout>
  );
};

export default Reports;
