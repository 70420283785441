import { BsCardImage } from 'react-icons/bs';
import { FaHeading } from 'react-icons/fa';

export const pdfTableData = [
  {
    id: 1,
    identity: 'Dummy',
    created_by__name: 'kkk',
    created: '2022-11-14T11:38:17.512665',
    modified: '2022-14-14T11:38:17.512665',
  },
];

export const PdfComponentConstant = {
  heading: 'heading',
  images: 'image_attachment',
};

export const PdfWidgets = {
  heading: {
    name: 'Heading',
    icon: FaHeading,
    width: 200,
    height: 50,
    x: 10,
    y: 10,
  },

  image_attachment: {
    name: 'Image',
    icon: BsCardImage,
    width: 200,
    height: 200,
    x: 10,
    y: 10,
  },
};
