import { theme as baseTheme } from '@chakra-ui/react';

const fonts = {
  heading: 'Inter, sans-serif',
  body: 'Inter, sans-serif ',
};

const fontSizes = {
  ...baseTheme.fontSizes,
  xs: '11px',
  sm: '14px',
  xl: '32px',
  20: '20px',
  22: '22px',
  18: '18px',
  24: '24px',
  16: '16px',
  36: '36px',
};

const fontWeights = {
  ...baseTheme.fontWeights,
};

export { fonts, fontSizes, fontWeights };
