import { Flex, useToast } from '@chakra-ui/react';
import {
  GET_ACCESS_MANAGEMENT_LISTING,
  GET_TEAM_MEMBER_UPDATING_META,
  TEAM_MEMBER_CUD,
} from 'api/urls/settings/accessManagement';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePutRequest from 'api/utilities/makePutRequest';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AppLoader from 'components/AppLoader/AppLoader';
import AppSelect from 'components/AppSelect/AppSelect';
import React, { useEffect, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';

// let validData = (_data) => ![undefined, null, ''].includes(_data || '');

const EditUser = ({ onClose, slug, setState, option }) => {
  const [formValues, setFormValues] = useState({
    name: '',
    role: '',
    status: '',
  });

  const [formSelectOptions, setFormSelectOptions] = useState({
    role: [],
    status: [],
  });

  const queryClient = useQueryClient();
  const { data: teamMemberMetaData, isLoading: teamMemberMetaIsLoading } =
    useQuery(GET_TEAM_MEMBER_UPDATING_META(slug), () =>
      makeGetRequest(GET_TEAM_MEMBER_UPDATING_META(slug)),
    );

  useEffect(() => {
    if (!teamMemberMetaIsLoading && teamMemberMetaData) {
      setFormValues({ ...formValues, ...teamMemberMetaData?.data?.initial });
      setFormSelectOptions(teamMemberMetaData?.data?.meta);
    }
  }, [teamMemberMetaIsLoading, teamMemberMetaData]);

  const {
    mutate: teamMemberUpdationMutation,
    error: teamMemberUpdationError,
    isLoading: teamMemberUpdationIsLoading,
    data: teamMemberUpdationData,
  } = useMutation(
    TEAM_MEMBER_CUD(slug),
    (formBody) => makePutRequest(TEAM_MEMBER_CUD(slug), formBody),
    {
      onSuccess: () => {
        setState({ ...option, type: 'Access management' });
        queryClient.invalidateQueries(GET_ACCESS_MANAGEMENT_LISTING);
      },
    },
  );

  const toast = useToast();

  useEffect(() => {
    if (!teamMemberUpdationIsLoading && teamMemberUpdationData) {
      toast({
        title: 'Edited User Successfully',
        status: 'success',
        duration: 3000,
      });
      onClose();
    }
  }, [teamMemberUpdationIsLoading, teamMemberUpdationData]);

  const formFields = [
    {
      fieldPlaceholder: formValues.name,
      fieldType: 'text',
      fieldName: 'name',
      label: 'Name',
    },
  ];

  const formSelectFields = [
    {
      fieldPlaceholder: formValues.role,
      fieldName: 'role',
      fieldOptions: 'roles',
      label: 'Role',
    },
    {
      fieldPlaceholder: formValues.status,
      fieldName: 'status',
      fieldOptions: 'statuses',
      label: 'Status',
    },
  ];

  const closeHandler = () => {
    onClose();
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const { name, role, status } = formValues;
    const finalData = {
      name,
      role,
      status,
    };

    teamMemberUpdationMutation(finalData);
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <AppLoader height="100px" isLoading={teamMemberMetaIsLoading}>
        {!teamMemberMetaIsLoading && (
          <>
            {formFields.map((field) => (
              <>
                <AppInput
                  {...field}
                  key={field.fieldName}
                  placeholder={field.fieldPlaceholder}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  fieldError={teamMemberUpdationError?.response?.data}
                  label={field.label}
                  innerLabel={true}
                />
              </>
            ))}

            {formSelectFields.map((field, i) => (
              <div style={{ marginBottom: '1.25rem' }} key={i}>
                <AppSelect
                  variant="filled"
                  fieldName={field.fieldName}
                  // placeholder={
                  //   validData(formValues[field.fieldName])
                  //     ? field.fieldPlaceholder
                  //     : null
                  // }
                  formValues={formValues}
                  setFormValues={setFormValues}
                  icon={MdArrowDropDown}
                  customStyles={{ fontSize: '14px' }}
                  fieldError={teamMemberUpdationError?.response?.data}
                  label={field.label}
                >
                  {/* {validData(formValues[field.fieldName]) ? (
                    ''
                  ) : (
                    <option key="__null__" value="">
                      Select An Option
                    </option>
                  )}
                 */}
                  {field.fieldName === 'role'
                    ? formSelectOptions?.roles?.map((data) => (
                        <option
                          selected={formValues.role === data.id ? true : false}
                          key={data.id}
                          value={data.id}
                        >
                          {data.identity}
                        </option>
                      ))
                    : formSelectOptions?.statuses?.map((data) => {
                        return (
                          <option
                            selected={
                              formValues.status === data.id ? true : false
                            }
                            key={data.id}
                            value={data.id}
                          >
                            {data.identity}
                          </option>
                        );
                      })}
                </AppSelect>
              </div>
            ))}
            <Flex>
              <AppButton
                text="Cancel"
                variant="cancelBtn"
                customStyles={{ margin: '0px 22px 27px 22px' }}
                onClick={closeHandler}
              />
              <AppButton
                text="Save"
                customStyles={{ margin: '0px 22px 27px 0px' }}
                type="submit"
                isLoading={teamMemberUpdationIsLoading}
              />
            </Flex>
          </>
        )}
      </AppLoader>
    </form>
  );
};

export default EditUser;
