import { Flex } from '@chakra-ui/react';
import AppText from 'components/typography/AppText';
import React from 'react';
import { RiComputerLine } from 'react-icons/ri';

const AppLoginSessionDetail = ({ value, time, customStyles }) => {
  return (
    <Flex alignItems="center" style={{ ...customStyles }}>
      {/* <AppText
        text={`${label} : `}
        variant="emailText"
        customStyles={{ fontWeight: 500, marginRight: '30px' }}
      /> */}
      <Flex alignItems="center">
        <RiComputerLine style={{ marginRight: '15px' }} />
        <AppText
          text={value}
          variant="emailText"
          customStyles={{ fontWeight: 500 }}
        />
        <AppText
          text={time}
          variant="labelText"
          customStyles={{ fontSize: '12px', fontWeight: 500, margin: '0px' }}
        />
      </Flex>
    </Flex>
  );
};

export default AppLoginSessionDetail;
