import { Flex, useDisclosure } from '@chakra-ui/react';
import useStore from 'AppZustand/store';
import AppButton from 'components/AppButton/AppButton';
import AppCard from 'components/AppCard/AppCard';
import AppModal from 'components/AppModal/AppModal';
import AppText from 'components/typography/AppText';
import React from 'react';
import { MdSettings } from 'react-icons/md';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import appColors from 'theme/appColors';

import { capitalizeFirstLetter } from '../Reports';
import AddModal from './AddModal';
import { BarGraph } from './charts/BarGraph';
import { DoughChart } from './charts/DoughChart';
import Linechart from './charts/LineChart';

const Charts = ({ chartData, removeWidget, isPermission, isLoading }) => {
  const { fetchChartTypes } = useStore((state) => state);
  const {
    isOpen: editChartIsOpen,
    onOpen: editChartOnOpen,
    onClose: editChartOnClose,
  } = useDisclosure();
  const {
    isOpen: deleteModalIsOpen,
    onOpen: deleteModalOnOpen,
    onClose: deleteModalOnClose,
  } = useDisclosure();

  return (
    <AppCard
      variant="chartsCard"
      customStyles={{ minWidth: '550px', minHeight: '282px' }}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        padding="10px"
        borderBottom="1px solid #D9DFEA"
      >
        <AppText
          text={`${chartData?.identity} (${
            new Date(chartData?.from_date).toLocaleDateString('en-IN', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            }) || ''
          } - ${
            new Date(chartData?.to_date).toLocaleDateString('en-IN', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            }) || ''
          })`}
          variant="permissionsText"
          customStyles={{ fontWeight: 500, margin: '0px' }}
        />
        {isPermission && (
          <Flex alignItems="center">
            <MdSettings
              style={{
                marginRight: '23px',
                width: '20px',
                height: '20px',
                color: appColors.brandGray['graymenuIcon'],
                cursor: 'pointer',
              }}
              onClick={() => editChartOnOpen()}
            />
            <RiDeleteBin6Fill
              style={{
                width: '20px',
                height: '20px',
                color: appColors.brandGray['graymenuIcon'],
                cursor: 'pointer',
              }}
              onClick={() => {
                fetchChartTypes('');
                deleteModalOnOpen();
              }}
            />
          </Flex>
        )}
      </Flex>
      {chartData?.type === 'pie' && (
        <DoughChart reportdata={chartData.report_data} />
      )}
      {chartData?.type === 'line' && (
        <Linechart reportdata={chartData.report_data} />
      )}
      {chartData?.type === 'bar' && (
        <BarGraph reportdata={chartData.report_data} />
      )}

      <AppModal
        isOpen={editChartIsOpen}
        onClose={editChartOnClose}
        keyName="Edit"
        text={`Edit Chart (${capitalizeFirstLetter(chartData.type)} Graph)`}
        closeCheck={true}
      >
        <AddModal onClose={editChartOnClose} chartData={chartData} />
      </AppModal>
      <AppModal
        isOpen={deleteModalIsOpen}
        onClose={deleteModalOnClose}
        closeCheck={true}
        size="xl"
      >
        <AppText
          text="Are you sure you want to delete?"
          customStyles={{ mb: 5, textAlign: 'center' }}
        />
        <Flex>
          <AppButton
            text="Cancel"
            variant="cancelBtn"
            customStyles={{ margin: '0px 22px 27px 22px' }}
            onClick={deleteModalOnClose}
          />
          <AppButton
            text="Delete"
            variant="deleteBtn"
            isLoading={isLoading}
            onClick={() => removeWidget({ id: chartData.id })}
            customStyles={{ margin: '0px 22px 27px 0px' }}
          />
        </Flex>
      </AppModal>
    </AppCard>
  );
};

export default Charts;
