import {
  ADD_TEAM_MEMBERS_SCREEN,
  APP_CREATE_NEW_PASSWORD_SCREEN,
  APP_EMAIL_VERIFICATION_SCREEN,
  APP_FORGOT_PASSWORD_SCREEN,
  APP_LOGIN_SCREEN,
  APP_SIGNUP_SCREEN,
  APP_TEAM_MEMBER_INVITE_MAGIC_LINK_VERIFICATION,
  APP_TEAM_MEMBER_ONBOARDING_MAGIC_LINK_VERIFICATION,
  APP_TEAM_MEMBER_PASSWORD_MAGIC_LINK,
  APP_USER_FORGOT_PASSWORD_MAGIC_LINK,
  APP_USER_SIGNUP_MAGIC_LINK_VERIFICATION,
  DOWNLOAD_MOBILE_APP_SCREEN,
} from 'navigation/routes/auth';
import AddTeamMemberScreen from 'pages/Auth/AddTeamMemberScreen/AddTeamMemberScreen';
import AppCreateNewPassword from 'pages/Auth/AppCreateNewPassword/AppCreateNewPassword';
import AppEmailVerification from 'pages/Auth/AppEmailVerification/AppEmailVerification';
import AppForgotPasswordScreen from 'pages/Auth/AppForgotPasswordScreen/AppForgotPasswordScreen';
import AppLoginScreen from 'pages/Auth/AppLoginScreen/AppLoginScreen';
import AppSignupScreen from 'pages/Auth/AppSignupScreen/AppSignupScreen';
import AppTeamMemberInviteMagicLinkVerification from 'pages/Auth/AppTeamMemberInviteMagicLinkVerification/AppTeamMemberInviteMagicLinkVerification';
import DownloadMobileAppScreen from 'pages/Auth/DownloadMobileAppScreen/DownloadMobileAppScreen';
import React from 'react';
import { Route } from 'react-router-dom';

import AppUserSignUpMagicLinkVerification from '../../pages/Auth/AppUserSignUpMagicLinkVerification/AppUserSignUpMagicLinkVerification';
/**
 * Route Data for `Auth` pages
 * */

const AuthRoutes = () => {
  return (
    <>
      <Route path={APP_LOGIN_SCREEN} element={<AppLoginScreen />} />
      <Route path={APP_SIGNUP_SCREEN} element={<AppSignupScreen />} />
      <Route
        path={APP_EMAIL_VERIFICATION_SCREEN}
        element={<AppEmailVerification />}
      />
      <Route
        path={APP_FORGOT_PASSWORD_SCREEN}
        element={<AppForgotPasswordScreen />}
      />
      <Route
        path={APP_CREATE_NEW_PASSWORD_SCREEN}
        element={<AppCreateNewPassword />}
      />

      <Route path={ADD_TEAM_MEMBERS_SCREEN} element={<AddTeamMemberScreen />} />

      <Route
        path={DOWNLOAD_MOBILE_APP_SCREEN}
        element={<DownloadMobileAppScreen />}
      />
      <Route
        path={APP_USER_FORGOT_PASSWORD_MAGIC_LINK}
        element={<AppCreateNewPassword />}
      />

      <Route
        path={APP_USER_SIGNUP_MAGIC_LINK_VERIFICATION}
        element={<AppUserSignUpMagicLinkVerification />}
      />

      <Route
        path={APP_TEAM_MEMBER_INVITE_MAGIC_LINK_VERIFICATION}
        element={<AppTeamMemberInviteMagicLinkVerification />}
      />
      <Route
        path={APP_TEAM_MEMBER_ONBOARDING_MAGIC_LINK_VERIFICATION}
        element={<AppTeamMemberInviteMagicLinkVerification />}
      />

      <Route
        path={APP_TEAM_MEMBER_PASSWORD_MAGIC_LINK}
        element={<AppCreateNewPassword />}
      />
    </>
  );
};

export default AuthRoutes;
