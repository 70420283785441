// Form builder screen

export const FORM_BUILDER_LIST = () => `api/form-builder/form/list/`;

export const SCRATCH_FORM_BUILDER = `api/form-builder/overall-config/`;

export const FORM_CREATION_META = (slug = '') =>
  `api/form-builder/form/cud/${slug && `${slug}/`}meta/`;

export const FORM_CREATION = `api/form-builder/form/cud/`;

export const FORM_CREATION_UPDATE = (slug) =>
  `api/form-builder/form/cud/${slug}/`;

export const FORM_GROUP_CREATION_META = `api/form-builder/form-group/cud/meta/`;

export const FORM_GROUP_CREATION = `api/form-builder/form-group/cud/`;

export const FORM_FIELD_CREATION = `api/form-builder/form-field/cud/`;

export const FORM_FIELD_CUD = (slug) =>
  `api/form-builder/form-field/cud/${slug}/`;

export const FORM_BUILDER_INITIAL_DATA = (slug) =>
  `api/form-builder/form/cud/${slug}/builder-data/`;

export const FORM_BUILDER_GROUP_UPDATE_META = (slug) =>
  `api/form-builder/form-group/cud/${slug}/meta/`;

export const FORM_BUILDER_GROUP_CUD = (slug) =>
  `api/form-builder/form-group/cud/${slug}/`;

export const GET_FORM_BUILDER_SHOW_LOGIC_DATA = (slug) =>
  `/api/form-builder/form-group/${slug}/show-logic-fields/`;

export const GET_CASCADE_META_LIST = (slug) =>
  `/api/form-builder/form/cud/${slug}/cascaded-groups-dropdown-data/`;

export const POST_DUPLICATE_FORM_FIELD = (slug) =>
  `/api/form-builder/form-field/cud/${slug}/duplicate/`;
