import styled from '@emotion/styled';
import appColors from 'theme/appColors';

const THead = styled.thead`
  background: ${appColors.brandTeal['tableHead']};
`;

const TH = styled.th`
  color: ${appColors.brandWhite['100']};
  padding: 16px;
  font-weight: 600;
  font-size: 14px;
  text-align: start;
`;

const TD = styled.td`
  color: ${appColors.brandBlack['600']};
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
  border-bottom: 1px solid #d9dfea;
  text-align: start;
`;

const TableFooterText = styled.text`
  color: ${appColors.brandGray['tableFooterText']};
  font-weight: 400;
  font-size: 14px;
`;

export { TableFooterText, TD, TH, THead };
