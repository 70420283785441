import appAxios from 'api/appAxios';
import { setHeadersInStorage } from 'api/utilities/headersHandler';

const makePutRequest = async (endpoint, body) => {
  const { data } = await appAxios.put(endpoint, body);
  setHeadersInStorage(data);
  return data;
};

export default makePutRequest;
