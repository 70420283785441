import { Flex, useDisclosure } from '@chakra-ui/react';
import {
  GET_SURVEY_TYPE_LISTING,
  GET_SURVEY_TYPE_LISTING_META,
} from 'api/urls/settings';
import useStore from 'AppZustand/store';
import AppAlertModal from 'components/AppAlertModal/AppAlertModal';
import AppButton from 'components/AppButton/AppButton';
import Card from 'components/AppCard/AppCard';
import AppLoader from 'components/AppLoader/AppLoader';
import AppModal from 'components/AppModal/AppModal';
import CustomTable from 'components/AppTable';
import AppHeader from 'components/typography/AppHeader';
import useGetTableFunctions from 'hooks/tableHelpers/useGetTableFunctions';
import DashboardLayout from 'layout/DashboardLayout';
import AddAndEditSurvey from 'pages/appSettings/surveyTypes/components/AddAndEditSurvey';
import React, { useEffect, useState } from 'react';
import { GrEdit } from 'react-icons/gr';
import { RiDeleteBinLine } from 'react-icons/ri';
import { timeStamp } from 'utils/TimeStampModifier';

const SurveyTypes = () => {
  const columnsIdentity = {
    identity: 'Survey Type',
    status: 'Status',
    created_by__name: 'Created By',
    project_type__identity: 'Applicable For',
    created: 'created',
    modified: 'modified',
  };

  const [currentItem, setCurrentItem] = useState();

  const { pageSize, fetchPageSize } = useStore((state) => state);

  useEffect(() => {
    getTableDataMutation();
    getTableMetaMutation();
    fetchPageSize(25);
  }, []);

  const {
    isOpen: createSurveyIsOpen,
    onOpen: createSurveyOnOpen,
    onClose: createSurveyOnClose,
  } = useDisclosure();

  const {
    isOpen: editSurveyTypeIsOpen,
    onOpen: editSurveyTypeOnOpen,
    onClose: editSurveyTypeOnClose,
  } = useDisclosure();

  const {
    isOpen: deleteSurveyTypeIsOpen,
    onOpen: deleteSurveyTypeOnOpen,
    onClose: deleteSurveyTypeOnClose,
  } = useDisclosure();

  const {
    isOpen: isPermissionAlertOpen,
    onClose: onPermissionAlertClose,
    onOpen: onPermissionAlertOpen,
  } = useDisclosure();

  const { permissions } = useStore((state) => state);

  const additionalColumns = [
    {
      Header: '',
      accessor: (originalRow) => (
        <Flex>
          <GrEdit
            cursor="pointer"
            style={{ marginRight: '24px' }}
            onClick={() => surveyEditHandler(originalRow)}
          />
          <RiDeleteBinLine
            cursor="pointer"
            onClick={() => surveyDeleteHandler(originalRow)}
          />
        </Flex>
      ),
      id: 'action_columns',
    },
  ];

  const surveyEditHandler = (originalRow) => {
    editSurveyTypeOnOpen();
    setCurrentItem(originalRow.id);
  };

  const surveyDeleteHandler = (originalRow) => {
    deleteSurveyTypeOnOpen();
    setCurrentItem(originalRow.id);
  };

  const {
    isTableLoading,
    tableData,
    columnsData,
    getTableDataMutation,
    getTableMetaMutation,
    setSearchParams,
  } = useGetTableFunctions({
    tableDataURL: GET_SURVEY_TYPE_LISTING,
    tableMetaURL: GET_SURVEY_TYPE_LISTING_META,
    columnsIdentity: columnsIdentity,
    additionalColumns: additionalColumns,
    permissionAlert: onPermissionAlertOpen,
  });

  useEffect(() => {
    setSearchParams(`?page-${pageSize}`);
  }, [pageSize]);

  return (
    <DashboardLayout menu={true} type="Settings" menuName="Survey Types">
      <Flex justifyContent="space-between" alignItems="center">
        <AppHeader
          text="Survey Types"
          variant="heading"
          customStyles={{ margin: '0px', textAlign: 'start' }}
        />
        {permissions.includes('survey_types_manage') && (
          <AppButton
            text="+ Add New Type"
            customStyles={{ width: '157px' }}
            onClick={() => createSurveyOnOpen()}
          />
        )}
      </Flex>

      {permissions.includes('survey_types_view') && (
        <>
          <Card variant="tableCard">
            <AppLoader isLoading={isTableLoading}>
              {!isTableLoading && (
                <CustomTable
                  columns={columnsData}
                  data={timeStamp(tableData?.data?.results) || []}
                  check={false}
                />
              )}
            </AppLoader>
          </Card>

          <AppModal
            isOpen={createSurveyIsOpen}
            onClose={createSurveyOnClose}
            keyName="addNew"
            text="New Survey Type"
            closeCheck={true}
          >
            <AddAndEditSurvey
              onClose={createSurveyOnClose}
              getTableDataMutation={getTableDataMutation}
            />
          </AppModal>

          {currentItem && (
            <AppModal
              isOpen={editSurveyTypeIsOpen}
              onClose={editSurveyTypeOnClose}
              keyName="edit"
              text="Edit Survey Type"
              closeCheck={true}
            >
              <AddAndEditSurvey
                isEdit
                onClose={editSurveyTypeOnClose}
                currentId={currentItem}
                getTableDataMutation={getTableDataMutation}
              />
            </AppModal>
          )}
          {currentItem && (
            <AppModal
              isOpen={deleteSurveyTypeIsOpen}
              onClose={deleteSurveyTypeOnClose}
              keyName="delete"
              text="Delete Survey Type"
              closeCheck={true}
            >
              <AddAndEditSurvey
                isDelete
                onClose={deleteSurveyTypeOnClose}
                currentId={currentItem}
                getTableDataMutation={getTableDataMutation}
              />
            </AppModal>
          )}
        </>
      )}
      <AppAlertModal
        isOpen={isPermissionAlertOpen}
        onClose={onPermissionAlertClose}
      />
    </DashboardLayout>
  );
};

export default SurveyTypes;
