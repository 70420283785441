import styled from '@emotion/styled';
import appColors from 'theme/appColors';
import { fontSizes } from 'theme/typographyStyles';

const RoundedButton = styled.div`
  position: relative;

  height: 25px;
  width: 25px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 34px;

  font-size: ${fontSizes.xs};

  background: ${(props) =>
    props.isActive ? appColors.brandTeal['500'] : appColors.brandTeal['100']};
  color: ${(props) =>
    props.isActive ? appColors.brandWhite['100'] : appColors.brandGray['300']};
`;

const StepLine = styled.div`
  position: absolute;
  left: 25px;

  width: 34px;
  height: 2px;

  background: ${(props) =>
    props.isActive ? appColors.brandTeal['500'] : appColors.brandTeal['100']};
`;

export { RoundedButton, StepLine };
