// user roles Listing

export const GET_USER_ROLES_LISTING_META = `/api/settings/user-role/list/table-meta/`;

export const GET_USER_ROLES_LISTING = (params = '') =>
  `/api/settings/user-role/list/?${params}`;

// User role Creation
export const USER_ROLE_CUD = (slug = '') =>
  `/api/settings/user-role/cud/${slug && `${slug}/`}`;
export const GET_USER_ROLES_CREATION_META = '/api/settings/user-role/cud/meta/';

// User role Updating
export const GET_USER_ROLE_UPDATING_META = (slug) =>
  `/api/settings/user-role/cud/${slug}/meta/`;

// User role Deleting
export const USER_ROLE_DELETING = (slug) =>
  `/api/settings/user-role/cud/${slug}/`;
