import { AiTwotoneSetting } from 'react-icons/ai';
import { BsFilterLeft } from 'react-icons/bs';
import { MdFilterAlt } from 'react-icons/md';

export const data = [
  {
    id: 4736371,
    name: 'David Warner’s House',
    address: '489 Golf Dr. Oakland, CA 94603',
    type: 'Resedential',
    stype: 'Pre - Survey',
    status: 'Completed',
    createdBy: 'Ron Weasely',
    assignedTo: 'Harrry Potter',
  },
  {
    id: 4736372,
    name: 'David Warner’s House',
    address: '489 Golf Dr. Oakland, CA 94603',
    type: 'Resedential',
    stype: 'Pre - Survey',
    status: 'Completed',
    createdBy: 'Ron Weasely',
    assignedTo: 'Harrry Potter',
  },
  {
    id: 4736373,
    name: 'David Warner’s House',
    address: '489 Golf Dr. Oakland, CA 94603',
    type: 'Resedential',
    stype: 'Pre - Survey',
    status: 'Completed',
    createdBy: 'Ron Weasely',
    assignedTo: 'Harrry Potter',
  },
  {
    id: 4736374,
    name: 'David Warner’s House',
    address: '489 Golf Dr. Oakland, CA 94603',
    type: 'Resedential',
    stype: 'Pre - Survey',
    status: 'Completed',
    createdBy: 'Ron Weasely',
    assignedTo: 'Harrry Potter',
  },
  {
    id: 4736375,
    name: 'David Warner’s House',
    address: '489 Golf Dr. Oakland, CA 94603',
    type: 'Resedential',
    stype: 'Pre - Survey',
    status: 'Completed',
    createdBy: 'Ron Weasely',
    assignedTo: 'Harrry Potter',
  },
  {
    id: 4736376,
    name: 'David Warner’s House',
    address: '489 Golf Dr. Oakland, CA 94603',
    type: 'Resedential',
    stype: 'Pre - Survey',
    status: 'Completed',
    createdBy: 'Ron Weasely',
    assignedTo: 'Harrry Potter',
  },
  {
    id: 4736377,
    name: 'David Warner’s House',
    address: '489 Golf Dr. Oakland, CA 94603',
    type: 'Resedential',
    stype: 'Pre - Survey',
    status: 'Completed',
    createdBy: 'Ron Weasely',
    assignedTo: 'Harrry Potter',
  },
  {
    id: 4736378,
    name: 'David Warner’s House',
    address: '489 Golf Dr. Oakland, CA 94603',
    type: 'Resedential',
    stype: 'Pre - Survey',
    status: 'Completed',
    createdBy: 'Ron Weasely',
    assignedTo: 'Harrry Potter',
  },
  {
    id: 4736379,
    name: 'David Warner’s House',
    address: '489 Golf Dr. Oakland, CA 94603',
    type: 'Resedential',
    stype: 'Pre - Survey',
    status: 'Completed',
    createdBy: 'Ron Weasely',
    assignedTo: 'Harrry Potter',
  },
  {
    id: 47363710,
    name: 'David Warner’s House',
    address: '489 Golf Dr. Oakland, CA 94603',
    type: 'Resedential',
    stype: 'Pre - Survey',
    status: 'Completed',
    createdBy: 'Ron Weasely',
    assignedTo: 'Harrry Potter',
  },
  {
    id: 47363711,
    name: 'David Warner’s House',
    address: '489 Golf Dr. Oakland, CA 94603',
    type: 'Resedential',
    stype: 'Pre - Survey',
    status: 'Completed',
    createdBy: 'Ron Weasely',
    assignedTo: 'Harrry Potter',
  },
  {
    id: 47363712,
    name: 'David Warner’s House',
    address: '489 Golf Dr. Oakland, CA 94603',
    type: 'Resedential',
    stype: 'Pre - Survey',
    status: 'Completed',
    createdBy: 'Ron Weasely',
    assignedTo: 'Harrry Potter',
  },
];

export const projectTypes = [
  {
    id: 1,
    projectType: 'Residential',
    status: 'Active',
    createdBy: 'Senthil',
    surveys: 2,
    createdOn: '12-04-2022',
    lastModifiedOn: '18-04-2022',
  },
  {
    id: 2,
    projectType: 'Commercial',
    status: 'Inactive',
    createdBy: 'Senthil',
    surveys: 5,
    createdOn: '12-04-2022',
    lastModifiedOn: '18-04-2022',
  },
  {
    id: 3,
    projectType: 'Residential',
    status: 'Active',
    createdBy: 'Senthil',
    surveys: 2,
    createdOn: '12-04-2022',
    lastModifiedOn: '18-04-2022',
  },
];

export const surveyTypes = [
  {
    id: 1,
    surveyType: 'Post install survey',
    status: 'Active',
    createdBy: 'Senthil',
    applicableFor: 'Residential',
    createdOn: '12-04-2022',
    lastModifiedOn: '18-04-2022',
  },

  {
    id: 2,
    surveyType: 'Post install survey',
    status: 'Active',
    createdBy: 'Senthil',
    applicableFor: 'Residential',
    createdOn: '12-04-2022',
    lastModifiedOn: '18-04-2022',
  },
  {
    id: 3,
    surveyType: 'Post install survey',
    status: 'Active',
    createdBy: 'Senthil',
    applicableFor: 'Residential',
    createdOn: '12-04-2022',
    lastModifiedOn: '18-04-2022',
  },
];

export const columns = [
  {
    Header: 'P ID',
    accessor: 'id',
  },
  {
    Header: 'P Name',
    accessor: 'identity',
  },
  {
    Header: 'P Address',
    accessor: 'address',
  },
  {
    Header: 'P Identity',
    accessor: 'project_type__identity',
  },
  {
    Header: 'Survey Identity',
    accessor: 'survey_type__identity',
  },
  {
    Header: 'Created By',
    accessor: 'created_by__name',
  },
];

export const iconsArr = [
  {
    id: 1,
    name: 'filter',
    icon: MdFilterAlt,
  },
  {
    id: 2,
    name: 'sort',
    icon: BsFilterLeft,
  },
  {
    id: 3,
    name: 'setting',
    icon: AiTwotoneSetting,
  },
];

export const workFlowData = [
  {
    id: 1,
    no: 1,
    order: 'Projected injested',
    description:
      'Project details have been created and is in the state before assinging it to the surveyor',
  },
  {
    id: 2,
    no: 2,
    order: 'Projected injested',
    description:
      'Project details have been created and is in the state before assinging it to the surveyor',
  },
  {
    id: 3,
    no: 3,
    order: 'Projected injested',
    description:
      'Project details have been created and is in the state before assinging it to the surveyor',
  },
  {
    id: 4,
    no: 4,
    order: 'Projected injested',
    description:
      'Project details have been created and is in the state before assinging it to the surveyor',
  },
];

export const actions = [
  {
    id: 1,
    label: 'Assign Project',
  },
  {
    id: 2,
    label: 'Reassign Project',
  },
];
