import { Flex } from '@chakra-ui/react';
import { AUTH_SIGNUP_MAGIC_LINK_RESEND } from 'api/urls';
import makePostRequest from 'api/utilities/makePostRequest';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AuthCard from 'components/AuthCard/AuthCard';
import AppHeader from 'components/typography/AppHeader';
import AppText from 'components/typography/AppText';
import AuthLayout from 'layout/AuthLayout';
import StepHelper from 'pages/Auth/components/StepHelper/StepHelper';
import TermsAndConditionsText from 'pages/Auth/components/TermsAndConditionsText/TermsAndConditionsText';
import React, { useEffect, useState } from 'react';
import { MdEmail } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';

const AppEmailVerification = () => {
  const location = useLocation();
  const state = location.state;

  const [formValues, setFormValues] = useState({
    user_email: state?.user_email,
  });
  const [resendTimer, setResendTimer] = useState(30);
  const [intervalDelay, setIntervalDelay] = useState(null);

  const { isLoading, mutate } = useMutation(
    AUTH_SIGNUP_MAGIC_LINK_RESEND(state?.tracker_id),
    () => makePostRequest(AUTH_SIGNUP_MAGIC_LINK_RESEND(state?.tracker_id), {}),
  );
  const handleResendLink = () => {
    mutate();
  };

  useInterval(() => {
    setResendTimer((timer) => {
      if (timer === 0) {
        setIntervalDelay(null);
        return timer;
      }
      return timer - 1;
    });
  }, intervalDelay);

  const handleOTPVerification = () => {
    setResendTimer(30);
    setIntervalDelay(1000);
  };

  useEffect(() => {
    handleOTPVerification();
  }, []);

  const formFields = [
    {
      fieldPlaceholder: 'Email Address',
      fieldType: 'email',
      fieldName: 'user_email',
      icon: MdEmail,
      disabled: true,
    },
  ];

  return (
    <AuthLayout>
      <AuthCard>
        <StepHelper totalCount={3} currentStep={1} />

        <AppHeader text={'Email Verification'} />

        <Flex flexFlow="column" minH={270} justifyContent={'space-between'}>
          <AppText
            text="We have sent an email to you for verification click on that link to continue signup process"
            size="sm"
            customStyles={{
              textAlign: 'center',
            }}
          />

          <form action="">
            {formFields.map((field) => (
              <AppInput
                {...field}
                key={field.fieldName}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            ))}
          </form>

          <AppButton
            text={`Resend Link ${resendTimer ? `(${resendTimer})` : ''}`}
            disabled={resendTimer != 0}
            isLoading={isLoading}
            onClick={handleResendLink}
          />

          <TermsAndConditionsText
            customStyles={{ display: 'flex', gap: '5px' }}
          />
        </Flex>
      </AuthCard>
    </AuthLayout>
  );
};

export default AppEmailVerification;
