import appAxios from 'api/appAxios';
import { setHeadersInStorage } from 'api/utilities/headersHandler';

const makePatchRequest = async (endpoint, body) => {
  const { data } = await appAxios.patch(endpoint, body);
  setHeadersInStorage(data);
  return data;
};

export default makePatchRequest;
