import { APP_DASHBOARD } from 'navigation/routes/dashboard';
import AppDashboard from 'pages/appDashboard/AppDashboard';
// import AppDashboard from 'pages/appDashboard/AppDashboard';
import { Route } from 'react-router-dom';

const DashboardRoutes = () => {
  return (
    <>
      <Route path={APP_DASHBOARD} element={<AppDashboard />} />
    </>
  );
};

export default DashboardRoutes;
