import {
  Flex,
  FormControl,
  FormErrorMessage,
  Textarea,
} from '@chakra-ui/react';
import useStore from 'AppZustand/store';
import React from 'react';
import appColors from 'theme/appColors';

const AppTextArea = ({
  fieldPlaceholder,
  variant,
  fieldName,
  isRequired,
  formValues,
  setFormValues,
  customStyles,
  fieldError,
  disabled = false,
  groupPadding = 5,
  item,
}) => {
  const { setCompanySettingsAlert } = useStore((state) => state);

  return (
    <FormControl
      isInvalid={Boolean(fieldError?.data?.[fieldName])}
      marginBottom={groupPadding}
      position="relative"
      display="flex"
      flexDirection="column"
    >
      <Flex width="100%" display="flex" justifyContent="flex-end">
        <Textarea
          style={{ ...customStyles }}
          placeholder={fieldPlaceholder}
          _placeholder={{ color: appColors.brandGray['placeholderColor'] }}
          name={fieldName}
          required={isRequired}
          variant={variant}
          size="md"
          disabled={disabled}
          value={
            formValues?.[item?.question?.custom_id]?.[fieldName]
              ? formValues?.[item?.question?.custom_id]?.[fieldName]
              : formValues?.[fieldName]
          }
          onChange={(e) => {
            if (item?.question?.field_type === 'regular') {
              setFormValues({
                ...formValues,
                [item?.question?.custom_id]: {
                  ...item.answer,
                  [fieldName]: e.target.value,
                },
              });
            } else if (item?.question?.field_type === 'cascade') {
              setFormValues({
                ...formValues,
                [item?.question?.custom_id]: {
                  ...item.question,
                  [fieldName]: e.target.value,
                },
              });
            } else {
              setFormValues({ ...formValues, [fieldName]: e.target.value });
              setCompanySettingsAlert(true);
            }
          }}
          backgroundColor={
            variant === 'filled' && formValues?.[fieldName]
              ? appColors.brandGray['selectedField']
              : variant === 'filled' && appColors.brandTeal['300']
          }
        />
      </Flex>
      <FormErrorMessage>{fieldError?.data?.[fieldName]}</FormErrorMessage>
    </FormControl>
  );
};

export default AppTextArea;
