import { theme } from '@chakra-ui/react';
import styled from '@emotion/styled';

const AppLandingLogo = styled.img`
  width: 200px;
  object-fit: contain;
`;

const Card = styled.div`
  position: relative;
  z-index: 9;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-width: 600px;
  max-width: 600px;
  border-radius: 24px;
  padding: 3rem;
  padding-bottom: 2rem;
  background: ${theme.colors.white};
  box-shadow: 0px 0px 48px rgba(27, 178, 147, 0.1);
  height: 95vh;
`;

export { AppLandingLogo, Card };
