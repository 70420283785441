import ButtonStyles from 'theme/components/ButtonStyles';
import CardStyles from 'theme/components/CardStyles';
import InputStyles from 'theme/components/InputStyles';
import LinkStyles from 'theme/components/LinkStyles';
import SelectStyles from 'theme/components/SelectStyles';
import TextStyles from 'theme/components/TextStyles';

import BoxStyles from './BoxStyles';

const components = {
  Input: InputStyles,
  Button: ButtonStyles,
  Text: TextStyles,
  Link: LinkStyles,
  Select: SelectStyles,
  CardStyles,
  BoxStyles,
};

export default components;
