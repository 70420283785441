import {
  Flex,
  FormControl,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import {
  GET_USER_ROLE_UPDATING_META,
  GET_USER_ROLES_LISTING,
  USER_ROLE_CUD,
} from 'api/urls/settings';
import makeGetRequest from 'api/utilities/makeGetRequest';
import makePutRequest from 'api/utilities/makePutRequest';
import { Select } from 'chakra-react-select';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AppLoader from 'components/AppLoader/AppLoader';
import React, { useEffect, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const EditUserRole = ({ onClose, slug, setState, option }) => {
  const [formValues, setFormValues] = useState({
    identity: '',
    permissions: [],
  });

  const [formSelectOptions, setFormSelectOptions] = useState([]);

  // const [selectedFilters, setSelectedFilters] = useState();

  const [render, setRender] = useState(false);

  const queryClient = useQueryClient();
  const { data: userRoleMetaData, isLoading: userRoleMetaIsLoading } = useQuery(
    GET_USER_ROLE_UPDATING_META(slug),
    () => makeGetRequest(GET_USER_ROLE_UPDATING_META(slug)),
  );

  useEffect(() => {
    if (!userRoleMetaIsLoading && userRoleMetaData) {
      const modifyData = userRoleMetaData?.data?.meta?.permissions?.map(
        (data) => {
          return { label: data.identity, value: data.id };
        },
      );

      const initialPermissions = modifyData?.filter((allData) => {
        return userRoleMetaData?.data?.initial?.permissions?.some(
          (initialData) => initialData === allData.value,
        );
      });

      setFormValues({
        identity: userRoleMetaData?.data?.initial.identity,
        permissions: initialPermissions,
      });

      setFormSelectOptions({ permissions: modifyData });
      setRender(!render);
    }
  }, [userRoleMetaIsLoading, userRoleMetaData]);

  const {
    mutate: userRoleUpdationMutation,
    error: userRoleUpdationError,
    isLoading: userRoleUpdationIsLoading,
    data: userRoleUpdationData,
  } = useMutation(
    USER_ROLE_CUD(slug),
    (formBody) => makePutRequest(USER_ROLE_CUD(slug), formBody),
    {
      onSuccess: () => {
        setState({ ...option, type: 'Roles and permissions' });
        queryClient.invalidateQueries(GET_USER_ROLES_LISTING);
      },
    },
  );

  const toast = useToast();

  useEffect(() => {
    if (!userRoleUpdationIsLoading && userRoleUpdationData) {
      toast({
        title: 'Edited User Roles Successfully',
        status: 'success',
        duration: 3000,
      });
      onClose();
    }
  }, [userRoleUpdationIsLoading, userRoleUpdationData]);

  // useEffect(() => {
  //   if (selectedFilters?.length > 0) {
  //     setFormValues({
  //       ...formValues,
  //       permissions: selectedFilters?.permissions,
  //     });
  //   }
  // }, [selectedFilters]);

  const formFields = [
    {
      fieldPlaceholder: formValues.identity,
      fieldType: 'text',
      fieldName: 'identity',
      label: 'Identity',
    },
  ];

  const formSelectFields = [
    {
      fieldName: 'permissions',
      fieldOptions: 'permissions',
      label: 'Permissions',
    },
  ];

  const closeHandler = () => {
    onClose();
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const { identity, permissions } = formValues;
    const finalData = {
      identity,
      permissions: permissions?.map((data) => data.value),
    };

    userRoleUpdationMutation(finalData);
  };

  return (
    (render || userRoleMetaIsLoading) && (
      <form onSubmit={handleFormSubmit}>
        <AppLoader height="100px" isLoading={userRoleMetaIsLoading}>
          <>
            {formFields.map((field) => (
              <>
                <AppInput
                  {...field}
                  key={field.fieldName}
                  placeholder={field.fieldPlaceholder}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  fieldError={userRoleUpdationError?.response?.data}
                  label={field.label}
                  innerLabel={true}
                />
              </>
            ))}

            {formSelectFields.map((field, i) => (
              <FormControl
                style={{ marginBottom: '1.25rem' }}
                key={i}
                isInvalid={Boolean(
                  userRoleUpdationError?.response?.data?.data?.[
                    field.fieldOptions
                  ]?.[0],
                )}
              >
                <Select
                  name={field.fieldName}
                  isInvalid={Boolean(
                    userRoleUpdationError?.response?.data?.data?.[
                      field.fieldOptions
                    ]?.[0],
                  )}
                  defaultValue={formValues.permissions}
                  options={formSelectOptions?.[field.fieldOptions]}
                  isMulti
                  variant="filled"
                  onChange={(ele, { name }) => {
                    setFormValues({
                      ...formValues,
                      [name]: ele,
                    });
                  }}
                  icon={MdArrowDropDown}
                  customStyles={{ fontSize: '14px' }}
                />

                <FormErrorMessage>
                  {
                    userRoleUpdationError?.response?.data?.data?.[
                      field.fieldOptions
                    ]?.[0]
                  }
                </FormErrorMessage>
              </FormControl>
            ))}

            <Flex>
              <AppButton
                text="Cancel"
                variant="cancelBtn"
                customStyles={{ margin: '0px 22px 27px 22px' }}
                onClick={closeHandler}
              />
              <AppButton
                text="Save"
                customStyles={{ margin: '0px 22px 27px 0px' }}
                type="submit"
                isLoading={userRoleUpdationIsLoading}
              />
            </Flex>
          </>
        </AppLoader>
      </form>
    )
  );
};

export default EditUserRole;
