/* eslint-disable no-unsafe-optional-chaining */
import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import useStore from 'AppZustand/store';
import AppText from 'components/typography/AppText';
import React from 'react';
import appColors from 'theme/appColors';

const getIcon = ({ Icon }) => {
  return React.createElement(Icon, {
    color: appColors.brandTeal['900'],
  });
};

const AppInput = ({
  fieldPlaceholder,
  fieldType = 'text',
  icon,
  fieldName,
  isRequired,
  fieldError,
  fieldErrorMessage,
  iconClick,
  formValues,
  setFormValues,
  disabled,
  variant = 'filled',
  label,
  innerLabel = false,
  size = 'md',
  inputGroupPadding,
  onBlur,
  beforeLabel,
  defaultValue,
  isFloat,
  componentType,
  InputStyles,
  marginTop,
  zIndex = '',
  item,
  answerId,
}) => {
  /**
   * A common wrapper for all inputs in the application
   * */

  const { setCompanySettingsAlert } = useStore((state) => state);

  return (
    <FormControl
      isInvalid={Boolean(fieldError?.data?.[fieldName]) || fieldErrorMessage}
      mb={inputGroupPadding || 5}
      mt={marginTop}
    >
      <InputGroup>
        {beforeLabel && (
          <InputRightElement
            left="0"
            fontSize="13px"
            lineHeight={'17px'}
            background="#D5DEE2"
            height={'100%'}
            borderRadius="6px"
            style={{ width: '72px', justifyContent: 'center' }}
            zIndex={'2'}
          >
            <AppText text={beforeLabel} variant="innerLabelText" />
          </InputRightElement>
        )}
        <Input
          placeholder={fieldPlaceholder}
          _placeholder={{ color: appColors.brandGray['placeholderColor'] }}
          type={fieldType}
          name={fieldName}
          required={isRequired}
          variant={variant}
          size={size}
          {...InputStyles}
          value={
            formValues?.[item?.question?.custom_id]?.[fieldName]
              ? formValues?.[item?.question?.custom_id]?.[fieldName]
              : defaultValue
              ? defaultValue
              : answerId
              ? formValues?.[answerId]?.[fieldName]
              : formValues?.[componentType]?.[fieldName]
              ? formValues?.[componentType]?.[fieldName]
              : formValues?.[fieldName]
          }
          zIndex={innerLabel ? '0' : zIndex ? zIndex : '9'}
          onBlur={(e) => {
            onBlur && onBlur(e);
          }}
          onChange={(e) => {
            if (
              (fieldName === 'prefix' || fieldName === 'suffix') &&
              e.target.value.length > 4
            ) {
              return;
            }

            if (item?.question?.field_type === 'regular') {
              setFormValues({
                ...formValues,
                [item?.question?.custom_id]: {
                  ...item.answer,
                  [fieldName]: e.target.value,
                },
              });
            } else if (item?.question?.field_type === 'cascade') {
              setFormValues({
                ...formValues,
                [item?.question?.custom_id]: {
                  ...item.question,
                  [fieldName]: e.target.value,
                },
              });
            } else if (
              componentType ===
                'single_selection_input_choices_validation_obj' ||
              componentType === 'multi_selection_input_choices_validation_obj'
            ) {
              setFormValues({
                ...formValues,
                [componentType]: {
                  ...formValues[componentType],
                  [fieldName]: e.target.value,
                },
              });
            } else {
              setFormValues({
                ...formValues,
                [fieldName]: isFloat
                  ? parseFloat(e.target.value)
                  : fieldType === 'number'
                  ? parseInt(e.target.value)
                  : e.target.value,
              });
              setCompanySettingsAlert(true);
            }
          }}
          disabled={disabled}
          backgroundColor={
            variant === 'filled' && formValues?.[fieldName]
              ? appColors.brandGray['selectedField']
              : variant === 'filled' && appColors.brandTeal['300']
          }
        />

        {(innerLabel || icon) && (
          <InputRightElement
            top="5px"
            right={innerLabel ? '0px' : '15px'}
            cursor={iconClick && 'pointer'}
            onClick={iconClick}
            style={{
              width: innerLabel ? '110px' : '20px',
              justifyContent: 'flex-end',
            }}
            zIndex={innerLabel ? '2' : '10'}
          >
            {innerLabel && (
              <AppText
                text={label}
                variant="innerLabelText"
                customStyles={{ marginRight: '15px' }}
              />
            )}
            {icon && getIcon({ Icon: icon })}
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>
        {fieldError?.data?.[fieldName]}
        {fieldErrorMessage}
      </FormErrorMessage>
    </FormControl>
  );
};

export default AppInput;
