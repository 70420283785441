import { SiteScribeLogo } from 'assets';
import React from 'react';

const PdfTemplate = () => {
  return (
    <>
      {/* HEADER START */}
      <div style={{ position: 'fixed', top: '0mm', width: '100%' }}>
        <header style={{ display: 'flex', flexDirection: 'column' }}>
          <img
            src={SiteScribeLogo}
            alt="logo"
            style={{ width: '150px', height: '60px' }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
              padding: '10px',
            }}
          >
            <span style={{ fontWeight: 500, fontSize: '15px' }}>
              Company Name
            </span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <span style={{ fontWeight: 500, fontSize: '15px' }}>ID: </span>
                <span style={{ fontWeight: 500, fontSize: '15px' }}>
                  8475465476475
                </span>
              </div>

              <div>
                <span style={{ fontWeight: 500, fontSize: '15px' }}>Date:</span>
                <span style={{ fontWeight: 500, fontSize: '15px' }}>
                  04/07/2022
                </span>
              </div>
            </div>
          </div>
          <hr style={{ borderBottom: '2px solid black' }} />
        </header>
      </div>
      {/* HEADER END */}

      {/* FOOTER START */}

      <footer style={{ position: 'fixed', bottom: '0px', width: '100%' }}>
        <hr style={{ borderTop: '2px solid black' }} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
            }}
          >
            <span style={{ fontWeight: 500, fontSize: '12px' }}>
              Submitted by Quinn Gardner at 04/07/2022 19:18 UTC
            </span>
          </div>
          <div>
            <span style={{ fontWeight: 500, fontSize: '12px' }}>page: 1</span>
          </div>
        </div>
      </footer>

      {/* FOOTER END */}

      <table style={{ width: '100%' }}>
        <thead style={{ display: 'table-header-group' }}>
          <tr>
            <td>
              <div style={{ height: '150px' }}></div>
            </td>
          </tr>
        </thead>

        {/* CONTENT */}
        <tbody>
          <tr>
            <td>
              <div style={{ pageBreakAfter: 'always', width: '100%' }}>
                {/* GROUP 1 */}
                <section style={{ marginBottom: '20px' }}>
                  <div>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: '18px',
                      }}
                    >
                      Group Name 1
                    </span>
                    <hr style={{ borderBottom: '2px solid gray' }} />
                  </div>

                  {/* normal question */}

                  <div>
                    <span style={{ fontWeight: 700, fontSize: '14px' }}>
                      question
                    </span>
                    <br />
                    <span style={{ fontWeight: 500, fontSize: '14px' }}>
                      answer
                    </span>

                    <hr style={{ borderBottom: '1px solid gray' }} />
                  </div>

                  {/* image question */}
                  <div>
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: '14px',
                      }}
                    >
                      question
                    </span>

                    <div>
                      <img
                        src="https://image.shutterstock.com/image-photo/aerial-view-roof-work-done-260nw-1844186038.jpg"
                        style={{
                          marginTop: '20px',
                          width: '220px',
                          height: '180px',
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src="https://image.shutterstock.com/image-photo/aerial-view-roof-work-done-260nw-1844186038.jpg"
                        style={{
                          marginTop: '20px',
                          width: '220px',
                          height: '180px',
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src="https://image.shutterstock.com/image-photo/aerial-view-roof-work-done-260nw-1844186038.jpg"
                        style={{
                          marginTop: '20px',
                          width: '220px',
                          height: '180px',
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src="https://image.shutterstock.com/image-photo/aerial-view-roof-work-done-260nw-1844186038.jpg"
                        style={{
                          marginTop: '20px',
                          width: '220px',
                          height: '180px',
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src="https://image.shutterstock.com/image-photo/aerial-view-roof-work-done-260nw-1844186038.jpg"
                        style={{
                          marginTop: '20px',
                          width: '220px',
                          height: '180px',
                        }}
                      />
                    </div>
                    <hr style={{ borderBottom: '1px solid gray' }} />
                  </div>
                </section>

                {/* GROUP 2 */}

                <section>
                  <div>
                    <span style={{ fontWeight: 500, fontSize: '18px' }}>
                      Group Name 2
                    </span>
                    <hr style={{ borderBottom: '2px solid gray' }} />
                  </div>
                  <div>
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: '14px',
                      }}
                    >
                      question
                    </span>

                    <div>
                      <img
                        src="https://image.shutterstock.com/image-photo/aerial-view-roof-work-done-260nw-1844186038.jpg"
                        style={{
                          marginTop: '20px',
                          width: '220px',
                          height: '180px',
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src="https://image.shutterstock.com/image-photo/aerial-view-roof-work-done-260nw-1844186038.jpg"
                        style={{
                          marginTop: '20px',
                          width: '220px',
                          height: '180px',
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src="https://image.shutterstock.com/image-photo/aerial-view-roof-work-done-260nw-1844186038.jpg"
                        style={{
                          marginTop: '20px',
                          width: '220px',
                          height: '180px',
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src="https://image.shutterstock.com/image-photo/aerial-view-roof-work-done-260nw-1844186038.jpg"
                        style={{
                          marginTop: '20px',
                          width: '220px',
                          height: '180px',
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src="https://image.shutterstock.com/image-photo/aerial-view-roof-work-done-260nw-1844186038.jpg"
                        style={{
                          marginTop: '20px',
                          width: '220px',
                          height: '180px',
                        }}
                      />
                    </div>
                    <hr style={{ borderBottom: '1px solid gray' }} />
                  </div>
                </section>
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot style={{ display: 'table-footer-group' }}>
          <tr>
            <td>
              <div style={{ height: '20px' }}></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default PdfTemplate;
