import {
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import React from 'react';

const AppSearch = ({
  fieldPlaceholder,
  fieldType = 'text',
  icon,
  fieldName,
  formValues,
  setFormValues,
  variant,
  size,
  customStyles,
  groupStyles,
  iconStyles,
  rightIconStyles,
  top,
  rightIcon,
  width = '100%',
}) => {
  /**
   * A common wrapper for all inputs in the application
   * */
  return (
    <FormControl display="flex" justifyContent="center" width={width}>
      <InputGroup {...groupStyles}>
        <InputLeftElement cursor={'pointer'} top={top} {...iconStyles}>
          {icon}
        </InputLeftElement>

        <Input
          {...customStyles}
          placeholder={fieldPlaceholder}
          type={fieldType}
          name={fieldName}
          variant={variant}
          size={size}
          value={formValues?.[fieldName]}
          onChange={(e) =>
            setFormValues({ ...formValues, [fieldName]: e.target.value })
          }
        />
        {rightIcon && (
          <InputRightElement cursor={'pointer'} {...rightIconStyles}>
            {rightIcon}
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  );
};

export default AppSearch;
