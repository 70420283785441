import { Flex, useToast } from '@chakra-ui/react';
import {
  GET_ACTION_DATA,
  GET_PROJECT_LISTING_DATA,
  MOVE_PROJECTS,
} from 'api/urls/projects';
import { QC_SCREEN_DATA } from 'api/urls/settings/qcScreen';
import makePostRequest from 'api/utilities/makePostRequest';
import AppButton from 'components/AppButton/AppButton';
import AppInput from 'components/AppInput/AppInput';
import AppLoader from 'components/AppLoader/AppLoader';
import AppSelect from 'components/AppSelect/AppSelect';
import AppText from 'components/typography/AppText';
import React, { useEffect, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

const ActionProject = ({
  onClose,
  selectedData,
  fromState,
  projectsCheckedId,
  getTableDataMutation,
  getQcMetadata,
}) => {
  const params = useParams();
  const [formValues, setFormValues] = useState({});

  const formFields = [];

  const formSelectFields = [];

  if (selectedData[1].name === 'Assign Field Auditor') {
    formSelectFields.push({
      fieldPlaceholder: 'Select Field Auditor',
      fieldName: 'assigned_field_auditor',
    });
  } else if (selectedData[1].name === 'Reject & Re-audit') {
    formSelectFields.push({
      fieldPlaceholder: 'Assign To Surveyor',
      fieldName: 'assigned_field_auditor',
    });

    formFields.push({
      fieldPlaceholder: 'Remarks',
      fieldName: 'qc_pending_to_pending_audit_remarks',
    });
  } else if (selectedData[1].name === 'Assign Survey Manager') {
    formSelectFields.push({
      fieldPlaceholder: 'Assign To Survey Manager',
      fieldName: 'assigned_survey_manager',
    });
  }
  useEffect(() => {
    actionMutation({
      from_state: fromState,
      to_state: selectedData[0],
    });
  }, [selectedData, fromState]);

  const toast = useToast();

  const queryClient = useQueryClient();

  const {
    mutate: actionMutation,
    data: actionData,
    isLoading: actionIsloading,
  } = useMutation(
    GET_ACTION_DATA,
    (formBody) => makePostRequest(GET_ACTION_DATA, formBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PROJECT_LISTING_DATA());
        queryClient.invalidateQueries(QC_SCREEN_DATA(params.slug));
      },
    },
  );

  const {
    mutate: moveProjectsMutation,
    error: moveProjectsError,
    isLoading: moveProjectsIsloading,
  } = useMutation(
    MOVE_PROJECTS,
    (formBody) => makePostRequest(MOVE_PROJECTS, formBody),
    {
      onSuccess: () => {
        onClose();
        toast({
          title: 'Workflow State Updated Successfully',
          status: 'success',
          duration: 3000,
        });
        getTableDataMutation();
        getQcMetadata();
      },
    },
  );

  const handleSubmitForm = (event) => {
    event.preventDefault();
    moveProjectsMutation({
      projects: projectsCheckedId,
      to_state: selectedData[0],
      required_inputs: {
        ...formValues,
      },
    });
    actionMutation({
      from_state: fromState,
      to_state: selectedData[0],
    });
  };

  return (
    <form onSubmit={handleSubmitForm}>
      <AppLoader height="100px" isLoading={actionIsloading}>
        {!actionIsloading && (
          <>
            {formSelectFields?.map((field, i) => (
              <div key={i}>
                <AppSelect
                  variant="filled"
                  placeholder={field.fieldPlaceholder}
                  icon={MdArrowDropDown}
                  customStyles={{ fontSize: '14px' }}
                  formValues={formValues}
                  fieldName={field.fieldName}
                  setFormValues={setFormValues}
                  fieldError={moveProjectsError?.response?.data}
                  inputGroupPadding={0}
                >
                  {actionData &&
                    actionData?.data?.[field.fieldName]?.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                </AppSelect>
              </div>
            ))}

            {formFields.map((field) => (
              <>
                <AppInput
                  {...field}
                  key={field.fieldName}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  fieldError={
                    moveProjectsError?.response?.data?.non_field_errors
                  }
                  marginTop={5}
                />
              </>
            ))}

            <AppText
              text={
                moveProjectsError?.response?.data?.data?.non_field_errors?.[0]
              }
              variant="errorText"
            />

            <Flex mt={5}>
              <AppButton
                text="Cancel"
                variant="cancelBtn"
                customStyles={{ margin: '0px 22px 27px 22px' }}
                onClick={onClose}
              />
              <AppButton
                type="submit"
                text="Submit"
                isLoading={moveProjectsIsloading}
                customStyles={{ margin: '0px 22px 27px 0px' }}
              />
            </Flex>
          </>
        )}
      </AppLoader>
    </form>
  );
};

export default ActionProject;
