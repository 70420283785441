import { CloseButton, Flex } from '@chakra-ui/react';
import { GET_PROJECT_LISTING_FILTER_META } from 'api/urls/projects';
import makeGetRequest from 'api/utilities/makeGetRequest';
import { Select } from 'chakra-react-select';
import AppButton from 'components/AppButton/AppButton';
import AppInputDate from 'components/AppInputDate/AppInputDate';
import AppLabel from 'components/AppLabel/AppLabel';
import AppSelect from 'components/AppSelect/AppSelect';
import AppText from 'components/typography/AppText';
import { APP_PROJECTS } from 'navigation/routes';
import React, { useEffect, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import appColors from 'theme/appColors';

const Filter = ({
  setState,
  filterCallback,
  setOptionId,
  getTableDataMutation,
  selectedFilters,
  setSelectedFilters,
}) => {
  const formFields = [
    {
      fieldPlaceholder: 'Start Date',
      fieldType: 'date',
      fieldName: 'created__gte',
    },
    {
      fieldPlaceholder: 'End Date',
      fieldType: 'date',
      fieldName: 'created__lte',
    },
  ];

  const formStatusField = [
    {
      fieldPlaceholder: 'Status',
      fieldName: 'status',
      filterValueKeys: 'statuses',
    },
  ];

  const formSelectFields = [
    {
      fieldPlaceholder: 'Project type',
      fieldName: 'project_type__in',
      filterValueKeys: 'project_types',
    },
    {
      fieldPlaceholder: 'Survey type',
      fieldName: 'survey_type__in',
      filterValueKeys: 'survey_types',
    },
  ];

  const [processedFilterData, setProcessedFilterData] = useState({});
  const { data: filterMeta, isLoading: filterMetaIsLoading } = useQuery(
    GET_PROJECT_LISTING_FILTER_META,
    () => makeGetRequest(GET_PROJECT_LISTING_FILTER_META),
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!filterMetaIsLoading && filterMeta) {
      const list_filter = ['project_types', 'survey_types', 'statuses'];
      const tempFilterObj = {};

      list_filter.forEach((key) => {
        tempFilterObj[key] = filterMeta.data?.[key].data.map((filterData) => ({
          label: filterData.identity,
          value: filterData.id,
        }));
      });

      setProcessedFilterData(tempFilterObj);
    }
  }, [filterMeta, filterMetaIsLoading]);

  const resetHandler = () => {
    setSelectedFilters({
      project_type__in: [],
      survey_type__in: [],
      status: '',
    });
    getTableDataMutation();
    navigate({ pathname: APP_PROJECTS, search: '?status=active' });
  };

  return (
    <div>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom="24px"
      >
        <AppText text="FILTERS" variant="filterText" />
        <CloseButton
          onClick={() => {
            setState({ filter: false });
            setOptionId('');
          }}
        />
      </Flex>

      {formSelectFields.map((field, i) => {
        return (
          <div style={{ marginBottom: '1.25rem' }} key={i}>
            <AppLabel
              fieldName={field.fieldPlaceholder}
              customStyles={{
                fontSize: '14px',
                fontWeight: 400,
                color: appColors.brandBlack['600'],
              }}
            />
            <Select
              name={field.fieldName}
              options={processedFilterData?.[field.filterValueKeys]}
              isMulti
              variant="filled"
              placeholder={field.fieldPlaceholder}
              onChange={(ele, { name }) => {
                if (ele.length === 0) {
                  const newItems = { ...selectedFilters };
                  delete newItems?.[name];
                  setSelectedFilters(newItems);
                } else {
                  setSelectedFilters({
                    ...selectedFilters,
                    [name]: ele,
                  });
                }
              }}
              icon={MdArrowDropDown}
              customStyles={{ fontSize: '14px' }}
              value={selectedFilters?.[field.fieldName]}
            />
          </div>
        );
      })}
      {formFields.map((field) => (
        <>
          <AppLabel
            fieldName={field.fieldPlaceholder}
            customStyles={{
              fontSize: '14px',
              fontWeight: 400,
              color: appColors.brandBlack['600'],
            }}
          />
          <AppInputDate
            {...field}
            key={field.fieldName}
            formValues={selectedFilters}
            setFormValues={setSelectedFilters}
            margin="0px 0px 20px 0px"
            startDate={selectedFilters?.created__gte}
            toDate={selectedFilters?.created__lte}
            startDateKey="created__lte"
            toDateKey="created__gte"
          />
        </>
      ))}

      {formStatusField?.map((field, i) => (
        <div style={{ marginBottom: '1.25rem' }} key={i}>
          <AppLabel
            fieldName={field.fieldPlaceholder}
            customStyles={{
              fontSize: '14px',
              fontWeight: 400,
              color: appColors.brandBlack['600'],
            }}
          />
          <AppSelect
            variant="filled"
            fieldName={field.fieldName}
            icon={MdArrowDropDown}
            customStyles={{
              fontSize: '14px',
            }}
            formValues={selectedFilters}
            setFormValues={setSelectedFilters}
            isRequired={field.isRequired}
            value={selectedFilters?.[field.fieldName]}
          >
            <option value="" hidden>
              {field.fieldPlaceholder}
            </option>
            {processedFilterData?.[field.filterValueKeys]?.map((data) => {
              return (
                <>
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                </>
              );
            })}
          </AppSelect>
        </div>
      ))}

      <Flex gap="20px">
        <AppButton
          text="Reset"
          variant="cancelBtn"
          customStyles={{ margin: '73px 0px 0px 0px' }}
          onClick={() => resetHandler()}
        />

        <AppButton
          text="Apply Filter"
          customStyles={{ margin: '73px 0px 0px 0px' }}
          onClick={() => filterCallback(selectedFilters)}
        />
      </Flex>
    </div>
  );
};

export default Filter;
