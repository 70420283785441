import { Flex, Link, useDisclosure } from '@chakra-ui/react';
import {
  GET_USER_ROLES_LISTING,
  GET_USER_ROLES_LISTING_META,
  USER_ROLE_DELETING,
} from 'api/urls/settings';
import makeDeleteRequest from 'api/utilities/makeDeleteRequest';
import useStore from 'AppZustand/store';
import AppAlertModal from 'components/AppAlertModal/AppAlertModal';
import Card from 'components/AppCard/AppCard';
import AppLoader from 'components/AppLoader/AppLoader';
import AppModal from 'components/AppModal/AppModal';
import CustomTable from 'components/AppTable';
import useGetTableFunctions from 'hooks/tableHelpers/useGetTableFunctions';
import React, { useEffect, useState } from 'react';
import { BsArrowRightCircle } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useMutation, useQueryClient } from 'react-query';
import appColors from 'theme/appColors';

import DeleteUser from './components/DeleteUser';
import EditUserRole from './components/EditUserRole';

const RolesAndPermission = ({ option, setOption }) => {
  const [slug, setSlug] = useState('');

  const {
    isOpen: isEditUserRoleOpen,
    onClose: onEditUserRoleClose,
    onOpen: onEditUserRoleOpen,
  } = useDisclosure();

  const {
    isOpen: isDeleteUserRoleOpen,
    onClose: onDeleteUserRoleClose,
    onOpen: onDeleteUserRoleOpen,
  } = useDisclosure();

  const {
    isOpen: isPermissionAlertOpen,
    onClose: onPermissionAlertClose,
    onOpen: onPermissionAlertOpen,
  } = useDisclosure();

  const { permissions } = useStore((state) => state);
  const columnsIdentity = {
    id: 'ID',
    identity: 'Roles',
    linked_users_count: 'No. Users',
  };

  const additionalColumns = [
    {
      Header: '',
      accessor: (originalRow) => (
        <Flex
          alignItems="center"
          display={
            permissions.includes('role_management_manage') ? 'flex' : 'none'
          }
        >
          {originalRow?.linked_users_count > 0 && originalRow !== null ? (
            <>
              <Flex
                alignItems="center"
                mr={8}
                onClick={() => editHandler(originalRow)}
              >
                <BsArrowRightCircle
                  color={appColors.brandTeal['800']}
                  cursor="pointer"
                />
                <Link
                  ml={2}
                  color={appColors.brandTeal['800']}
                  _hover={{
                    color: appColors.brandTeal['800'],
                    textDecoration: 'underline',
                  }}
                >
                  Permissions
                </Link>
              </Flex>
            </>
          ) : (
            <>
              <Flex
                alignItems="center"
                mr={8}
                onClick={() => editHandler(originalRow)}
              >
                <BsArrowRightCircle
                  color={appColors.brandTeal['800']}
                  cursor="pointer"
                />
                <Link
                  ml={2}
                  color={appColors.brandTeal['800']}
                  _hover={{
                    color: appColors.brandTeal['800'],
                    textDecoration: 'underline',
                  }}
                >
                  Permissions
                </Link>
              </Flex>
              <RiDeleteBinLine
                cursor="pointer"
                size={15}
                style={{ marginLeft: '20px' }}
                onClick={() => deleteHandler(originalRow)}
              />
            </>
          )}
        </Flex>
      ),
      id: 'action',
    },
  ];

  useEffect(() => {
    getTableDataMutation();
    getTableMetaMutation();
  }, [option]);

  const {
    isTableLoading,
    tableData,
    columnsData,
    getTableDataMutation,
    getTableMetaMutation,
  } = useGetTableFunctions({
    tableDataURL:
      option?.type === 'Roles and permissions'
        ? GET_USER_ROLES_LISTING
        : () => {},
    tableMetaURL:
      option?.type === 'Roles and permissions'
        ? GET_USER_ROLES_LISTING_META
        : () => {},
    columnsIdentity: columnsIdentity,
    additionalColumns: additionalColumns,
    permissionAlert: onPermissionAlertOpen,
  });
  const queryClient = useQueryClient();
  const {
    mutate: userRoleDeletionMutation,
    isLoading: userRoleDeletionIsLoading,
  } = useMutation(
    USER_ROLE_DELETING(slug),
    (slug) => makeDeleteRequest(USER_ROLE_DELETING(slug)),
    {
      onSuccess: () => {
        getTableDataMutation();
        queryClient.invalidateQueries(GET_USER_ROLES_LISTING);
        onDeleteUserRoleClose();
      },
    },
  );

  const editHandler = (originalRow) => {
    setSlug(originalRow.id);
    onEditUserRoleOpen();
  };
  const deleteHandler = (originalRow) => {
    setSlug(originalRow.id);
    onDeleteUserRoleOpen();
  };

  const confirmDelete = () => {
    userRoleDeletionMutation(slug);
  };
  //   const drawerHandler = () => {
  //      // setSlug(originalRow.id);
  //      setOption({ ...option, permissions: true });
  //  };

  return (
    <>
      {permissions.includes('role_management_view') && (
        <>
          <Card variant="tableCard">
            <AppLoader isLoading={isTableLoading}>
              {!isTableLoading && (
                <CustomTable
                  columns={columnsData.filter((item) => item.accessor !== 'id')}
                  data={(tableData?.data?.results || []).map((_data) => ({
                    ..._data,
                    linked_users_count: _data.linked_users_count || 0,
                  }))}
                  check={false}
                />
              )}
            </AppLoader>
          </Card>

          <AppModal
            isOpen={isDeleteUserRoleOpen}
            onClose={onDeleteUserRoleClose}
            keyName="delete"
            text="Delete Team Member"
            closeCheck={true}
          >
            <DeleteUser
              isDelete
              onClose={onDeleteUserRoleClose}
              handleDelete={confirmDelete}
              isSubmitLoading={userRoleDeletionIsLoading}
              text="Are you sure you wanna delete this role"
            />
          </AppModal>

          {isEditUserRoleOpen && (
            <AppModal
              isOpen={isEditUserRoleOpen}
              onClose={onEditUserRoleClose}
              keyName="accessEdit"
              text="Edit user role"
              closeCheck={true}
            >
              <EditUserRole
                slug={slug}
                setState={setOption}
                option={option}
                onClose={onEditUserRoleClose}
              />
            </AppModal>
          )}
        </>
      )}
      {option?.type === 'Roles and permissions'}
      <AppAlertModal
        isOpen={isPermissionAlertOpen}
        onClose={onPermissionAlertClose}
      />
    </>
  );
};

export default RolesAndPermission;
